import React, { Fragment } from 'react';
import { appRoute } from '../../utils/routeConfigs';
import UserRolePage from '../pages/user_roles/Usersrolepage';
import Userspage from './../pages/Users/Userspage';
import PrivateRoute from './PrivateRoute';

export default function RouteUserManagement() {

    return (
        <Fragment>
            <PrivateRoute path={appRoute.userList}>
                <Userspage />
            </PrivateRoute>
            <PrivateRoute path={appRoute.userRoleList}>
                <UserRolePage />
            </PrivateRoute>
        </Fragment>
    )
}