import { Badge, IconButton } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Notifications as NotificationIcon } from '@material-ui/icons';
import React, { useContext } from 'react';
import { statusConfigs } from '../../utils/configs';
import { AppContext } from './../AuthenticatedPage';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: 0,
        '& svg': {
            fontSize: 30,
            color: theme.palette.primary.main
        }
    },
}));

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        color: "#fff",
        backgroundColor: "#f90909"
    },
}))(Badge);

export default function NotificationBadge() {
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { notifications } = state;
    const unreadNotifications = notifications?.filter(n => n.seen_status && n.seen_status.description_static === statusConfigs.Active) || [];

    const handleOpen = () => {
        dispatch({ "type": "setOpenNotificationDrawer", value: true })
    }

    return (
        <IconButton onClick={handleOpen} className={classes.root}>
            <StyledBadge badgeContent={unreadNotifications.length} color="primary" className={classes.largeIcon}>
                <NotificationIcon />
            </StyledBadge>
        </IconButton>
    );
}
