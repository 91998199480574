import { Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIos as BackIcon, Cancel as DeclineDialogIcon, CheckCircle as DoneDialogIcon } from '@material-ui/icons';
import MaterialTable from 'material-table';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { dataFetchKeys } from '../../../utils/configs';
import { appRoute } from '../../../utils/routeConfigs';
import BackDrop from '../../Common/BackDrop';
import ActionFinishedPage from '../ActionFinishedPage';
import ComponentAttachments from '../Attachments/ComponentAttachments';
import UnauthorizedPage from '../UnauthorizedPage';
import { getData, postData } from './../../../utils/ApiRequest';
import { getSingleNotificationUrl, requestAttachementGetUrl, requestClosureGetUrl, requestClosureResponsePostUrl, requestClosureResponsesGetUrl } from './../../../utils/ApiUrl';
import { appStatuses, attachmentType, downloadType, languages, requestValidation } from './../../../utils/AppConstants';
import { AppContext, queryClient } from './../../AuthenticatedPage';
import CollapseDivButton from './../../Common/CollapseDivButton';
import ConfirmationDialog from './../../Common/ConfirmationDialog';
import RequestDetailsDialog from './../../pages/Requests/RequestDetailsDialog';
import tableIcons from './../../tableIcons';

const dateFormat = require('dateformat');
const dateStringFormat = "dd-mm-yyyy";

const useStyles = makeStyles(theme => ({

    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 3),
        marginLeft: theme.spacing(10),
        marginTop: theme.spacing(10),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(10),
    },
    heading: {
        textAlign: 'center',
        marginBottom: theme.spacing(3),
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        textAlign: 'center',
        width: '100%'
    },
    buttonsDiv: {
        position: 'fixed',
        right: theme.spacing(1),
        bottom: theme.spacing(10),
        left: 'auto',
        top: 'auto'
    },
    button: {
        marginLeft: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    textarea: {
        paddingBottom: theme.spacing(3),
    },
    documentList: {
        width: '100%',
        minWidth: '70ch',
        backgroundColor: theme.palette.background.paper,
    },
    attachmentHeader: {
        fontSize: theme.typography.pxToRem(20),
    },
    fileUploadDate: {
        color: 'gray',
    },
    table: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function RequestClosureAsbuiltPage() {

    const [t] = useTranslation();
    const classes = useStyles();
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { selectedRequest, selectedRequestActionId, selectedLanguage, authToken } = state;

    let history = useHistory();
    const { notificationId } = useParams();

    const [attachements, setAttachements] = React.useState([]);
    const [closureDetailsExpanded, setClosureDetailsExpanded] = useState(true);
    const [closureResponsesExpanded, setClosureResponsesExpanded] = useState(false);
    const [attachmentsExpanded, setAttachmentsExpanded] = useState(true);
    const [closureDetails, setClosureDetais] = React.useState("");
    const [closureResponses, setClosureResponses] = React.useState([]);
    const [openApproveForm, setOpenApproveForm] = React.useState(false);
    const [remarks, setRemarks] = React.useState("");
    const [openRequestDetailsDialog, setOpenRequestDetailsDialog] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [isApprove, setIsApprove] = useState(true);
    const [requestActionId, setRequestActionId] = useState("")
    const [actionStatus, setActionStatus] = useState("")
    const [request, setRequest] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isBusy, setIsBusy] = useState(false);

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        history.push('/notifications');
    };

    //get notification data 
    useEffect(() => {
        if (notificationId) {
            
            async function getNotificationData() {
                try {
                    setLoading(true);

                    const response = await getData(
                        getSingleNotificationUrl(notificationId),
                        authToken
                    );
                    const status = response.status;
                    if (status === 200 || status === 201) {
                        const response_json = await response.json();
                        setRequest(response_json.request);
                        setRequestActionId(response_json.requestActionId);
                        setActionStatus(response_json.request_action_status.description_static)
                    } else if (status === 401) {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('User Unauthorized'), isError: true } });
                        dispatch({ "type": "setOpenSnackbar", value: true });
                        history.push(appRoute.unauthorized);
                    }
                }
                finally {
                    setLoading(false);
                }
            }

            getNotificationData();
        }
        else if (selectedRequest && selectedRequestActionId) {
            setRequest(selectedRequest);
            setRequestActionId(selectedRequestActionId);
            setActionStatus(appStatuses.active || appStatuses.assigned)
        }
        else if (!request) {
            history.push(appRoute.home);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRequest, notificationId]);

    //Get project attachments
    useEffect(() => {

        if (!request || actionStatus === appStatuses.approved)
            return;

        //Get session token
        async function GetProjectAttachments() {
            try {
                setLoading(true);
                const apiURL = requestAttachementGetUrl(request.id, attachmentType.asBuilt);
                const response = await getData(apiURL, authToken);
                const status = response.status;
                if (status === 200 || status === 201) {
                    const responseJson = await response.json();
                    setAttachements(responseJson);
                }
                else if (status === 401) {
                    dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
                    dispatch({ "type": "setOpenSnackbar", value: true })
                }
                else {
                    dispatch({ "type": "setSnackBarInfo", value: { message: t('generalErrorGetData'), isError: true } })
                    dispatch({ "type": "setOpenSnackbar", value: true })
                }
            }
            finally {
                setLoading(false);
            }
        }

        async function GetClosureDetails() {
            try {
                setLoading(true);
                const apiURL = requestClosureGetUrl(request.id);
                const response = await getData(apiURL, authToken);
                const status = response.status;
                if (status === 200 || status === 201) {
                    const responseJson = await response.json();
                    setClosureDetais(responseJson);
                }
                else if (status === 401) {
                    dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
                    dispatch({ "type": "setOpenSnackbar", value: true })
                }
                else {
                    dispatch({ "type": "setSnackBarInfo", value: { message: t('generalErrorGetData'), isError: true } })
                    dispatch({ "type": "setOpenSnackbar", value: true })
                }
            }
            finally {
                setLoading(false);
            }
        }

        async function GetClosureResponses() {
            try {
                setLoading(true);
                const apiURL = requestClosureResponsesGetUrl(request.id);
                const response = await getData(apiURL, authToken);
                const status = response.status;
                if (status === 200 || status === 201) {
                    const responseJson = await response.json();
                    setClosureResponses(responseJson);
                }
                else if (status === 401) {
                    dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
                    dispatch({ "type": "setOpenSnackbar", value: true })
                }
                else {
                    dispatch({ "type": "setSnackBarInfo", value: { message: t('generalErrorGetData'), isError: true } })
                    dispatch({ "type": "setOpenSnackbar", value: true })
                }
            }
            finally {
                setLoading(false);
            }
        }

        GetProjectAttachments();
        GetClosureDetails();
        GetClosureResponses();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request, actionStatus]);

    const handleApproveClick = () => {
        setIsApprove(true);
        setOpenApproveForm(true);
    }

    const handleRejectClick = () => {
        setIsApprove(false);
        setOpenApproveForm(true);
    }

    const handleApproveClose = () => {
        setOpenApproveForm(false);
    }

    const handleSubmitClick = async () => {

        try{
            setIsBusy(true);
            const lang = selectedLanguage === languages.nl ? 'nl' : 'en';
            const apiURL = requestClosureResponsePostUrl(lang, requestActionId, closureDetails.id);
            const data = {
                "approve": isApprove,
                "remarks": remarks
            };
            const response = await postData(apiURL, data, authToken);
            const status = response.status;
            if (status === 200 || status === 201) {
                const responseJson = await response.json();
                setClosureDetais(responseJson);
                //Update notifications
                queryClient.invalidateQueries(dataFetchKeys.notifications);
                dispatch({ "type": "setSnackBarInfo", value: { message: t('pageClosureResponseSaved'), isError: false } })
                dispatch({ "type": "setOpenSnackbar", value: true })
                setOpenApproveForm(false);
                history.push('/home');
            }
            else if (status === 404) {
                //Update notifications
                queryClient.invalidateQueries(dataFetchKeys.notifications);
                dispatch({ "type": "setSnackBarInfo", value: { message: t('actionAlreadyPerformed'), isError: true } })
                dispatch({ "type": "setOpenSnackbar", value: true })
                history.push('/action_performed')
            }
            else if (status === 401) {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
                dispatch({ "type": "setOpenSnackbar", value: true })
            }
            else {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } })
                dispatch({ "type": "setOpenSnackbar", value: true })
            }
        }
        finally{
            setIsBusy(false);
        }

        //Get session token
  
    }

    const handleClosureDetailsExpandClick = () => {
        setClosureDetailsExpanded(!closureDetailsExpanded);
    };

    const handleClosureResponsesExpandClick = () => {
        setClosureResponsesExpanded(!closureResponsesExpanded);
    };

    const handleAttachmentsExpandClick = () => {
        setAttachmentsExpanded(!attachmentsExpanded);
    };

    const handleOpenRequestDetailsClick = () => {
        setOpenRequestDetailsDialog(true);
    };

    const closeRequestDetailsDialog = () => {
        setOpenRequestDetailsDialog(false);
    };

    const isValidated = () => {
        return (remarks !== undefined && remarks.length <= requestValidation.remarksMax);
    }

    if (loading)
        return <BackDrop open={loading} />;
    else if (!request)
        return <UnauthorizedPage />
    else if (actionStatus === appStatuses.approved)
        return <ActionFinishedPage />;

    return (
        <Fragment>
            <div className={classes.root}>
                <form className={classes.form} noValidate autoComplete="off">
                    <div>
                        <Typography variant="h5" className={classes.heading}>
                            {t('pageAsbuiltApprovalTitle')}
                        </Typography>
                        <Button variant="outlined" onClick={handleOpenRequestDetailsClick}>
                            {t('generalReqDetails')}
                        </Button>
                        <RequestDetailsDialog request={request} open={openRequestDetailsDialog} handleClose={closeRequestDetailsDialog} />
                        <br />
                        <CollapseDivButton expanded={closureDetailsExpanded} handleExpandClick={handleClosureDetailsExpandClick} title="Closure details" />
                        <Collapse in={closureDetailsExpanded} timeout="auto" unmountOnExit>
                            <Typography variant="h5" className={classes.heading}>
                                {t('pageClosureClosureDetails')}
                            </Typography>
                            <TextField
                                variant="outlined"
                                margin="none"
                                fullWidth
                                disabled
                                label={t('pageClosureClosureReqDate')}
                                value={dateFormat(closureDetails.closure_request_date, dateStringFormat)}
                            />
                            <br />
                            <TextField
                                variant="outlined"
                                margin="none"
                                fullWidth
                                disabled
                                label={t('pageClosureClosureRemarks')}
                                value={closureDetails ? closureDetails.remarks : ""}
                                multiline
                            />
                        </Collapse>
                        <Divider />
                        <CollapseDivButton expanded={closureResponsesExpanded} handleExpandClick={handleClosureResponsesExpandClick} title="Closure responses" />
                        <Collapse in={closureResponsesExpanded} timeout="auto" className={classes.table} unmountOnExit>
                            {
                                closureResponses && closureResponses.length > 0 ?
                                    <MaterialTable
                                        columns={[
                                            { title: t('pageClosureResponseBy'), field: 'createdBy' },
                                            {
                                                title: t('pageClosureResponseDate'),
                                                render: row => <span>{dateFormat(new Date(row["response_date"]), dateStringFormat)}</span>
                                            },
                                            { title: t('generalRemarks'), field: 'remarks' },
                                            { title: t('generalStatus'), field: 'status_desc' },
                                        ]}
                                        data={closureResponses}
                                        icons={tableIcons}
                                        options={{
                                            headerStyle: {
                                                backgroundColor: '#01579b',
                                                color: '#FFF'
                                            },
                                            exportButton: true,
                                            paging: false
                                        }}
                                    />
                                    : <Typography>
                                        {t('pageClosureNoResponse')}
                                    </Typography>
                            }
                        </Collapse>
                        <Divider />

                        <CollapseDivButton expanded={attachmentsExpanded} handleExpandClick={handleAttachmentsExpandClick}
                            title={t('generalAttachmentsAsBuilt')} />
                        <Collapse in={attachmentsExpanded} timeout="auto" className={classes.table} unmountOnExit>
                            <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                                <ComponentAttachments
                                    attachments={attachements} setAttachments={setAttachements}
                                    uploadDisabled={true}
                                    typeDownload={downloadType.request} />
                            </Grid>
                        </Collapse>
                        <Divider />
                    </div>
                </form>
                <div className={classes.buttonsDiv}>
                    <Fab variant="extended" color="default" aria-label="add" onClick={handleClickOpenDialog} className={classes.button}>
                        <BackIcon className={classes.extendedIcon} />
                        {t('generalBack')}
                    </Fab>
                    <Fab variant="extended" style={{ backgroundColor: "#ff4000", color: '#fff' }} aria-label="add" className={classes.button} onClick={handleRejectClick}>
                        <DeclineDialogIcon className={classes.extendedIcon} />
                        {t('generalDecline')}
                    </Fab>
                    <Fab variant="extended" color="primary" aria-label="add" className={classes.button} onClick={handleApproveClick}>
                        <DoneDialogIcon className={classes.extendedIcon} />
                        {t('generalApprove')}
                    </Fab>
                </div>
            </div>

            <Dialog open={openApproveForm} onClose={handleApproveClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        {isApprove ? <DoneDialogIcon className={classes.extendedIcon} /> : <DeclineDialogIcon className={classes.extendedIcon} />}
                        <span>{isApprove ?
                            t('pageClosureApproval') :
                            t('pageClosureDeclined')}</span>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {isApprove ?
                            t('pageClosureApprovalInstruction') :
                            t('pageClosureDeclineInstruction')
                        }

                    </DialogContentText>
                    <TextField
                        autoFocus
                        fullWidth
                        multiline
                        margin="dense"
                        id="remarks"
                        label={t('generalRemarks')}
                        value={remarks}
                        helperText={t("charsRemaining", { num_char: requestValidation.remarksMax - remarks.length })}
                        error={remarks.length < requestValidation.remarksMin || remarks.length > requestValidation.remarksMax}
                        onChange={(e) => setRemarks(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleApproveClose} color="secondary" variant="outlined">
                        {t('generalCancel')}
                    </Button>
                    <Button onClick={handleSubmitClick} color="primary" variant="contained" autoFocus
                        disabled={isBusy || !isValidated()}>
                         {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} /> }      
                        {t('generalSubmit')}
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} />
        </Fragment>
    );
}