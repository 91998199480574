import { Button, Checkbox, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, FormControlLabel, Grid, List, ListSubheader, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIos as BackIcon, CheckCircle as DoneDialogIcon } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { dataFetchKeys } from '../../../utils/configs';
import { appRoute } from '../../../utils/routeConfigs';
import WarningOrErrorGrid from '../../Common/WarningOrErrorGrid';
import ActionFinishedPage from '../ActionFinishedPage';
import ComponentAttachments from '../Attachments/ComponentAttachments';
import UnauthorizedPage from '../UnauthorizedPage';
import { getData, postData } from './../../../utils/ApiRequest';
import { getSingleNotificationUrl, getVerifyAsbuiltUsersURL, projectScopePostUrl, requestAttachementGetUrl, requestClosureGetUrl } from './../../../utils/ApiUrl';
import { appStatuses, attachmentType, downloadType, languages, requestValidation } from './../../../utils/AppConstants';
import { AppContext, queryClient } from './../../AuthenticatedPage';
import CollapseDivButton from './../../Common/CollapseDivButton';
import ConfirmationDialog from './../../Common/ConfirmationDialog';
import RequestDetailsDialog from './../../pages/Requests/RequestDetailsDialog';

const dateFormat = require('dateformat');
const dateStringFormat = "dd-mm-yyyy";

const useStyles = makeStyles(theme => ({

  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 3),
    marginLeft: theme.spacing(10),
    marginTop: theme.spacing(10),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(10),
  },
  heading: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '40%',
      minWidth: '200px'
    },
    textAlign: 'center',
    width: '100%'
  },
  buttonsDiv: {
    position: 'fixed',
    right: theme.spacing(1),
    bottom: theme.spacing(10),
    left: 'auto',
    top: 'auto'
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  textarea: {
    paddingBottom: theme.spacing(3),
  },
  documentList: {
    width: '100%',
    minWidth: '70ch',
    backgroundColor: theme.palette.background.paper,
  },
  attachmentHeader: {
    fontSize: theme.typography.pxToRem(20),
  },
  fileUploadDate: {
    color: 'gray',
  },
  table: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginRight: theme.spacing(1),
  },
}));

export default function RequestInternalOrExternalPage(props) {

  const [t] = useTranslation();
  const classes = useStyles();

  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const { selectedRequest, selectedRequestActionId, selectedLanguage, authToken } = state;

  let history = useHistory();
  const { notificationId } = useParams();

  const [attachements, setAttachements] = React.useState([]);
  const [closureDetailsExpanded, setClosureDetailsExpanded] = useState(false);
  const [attachmentsExpanded, setAttachmentsExpanded] = useState(false);
  const [closureDetails, setClosureDetais] = React.useState("");
  const [openSubmitForm, setOpenSubmitForm] = React.useState(false);
  const [remarks, setRemarks] = React.useState("");

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openRequestDetailsDialog, setOpenRequestDetailsDialog] = React.useState(false);

  const [canVerifyAsbuilt, setCanVerifyAsbuilt] = useState(true);
  const [asbuiltVerifyUsers, setAsbuiltVerifyUsers] = React.useState([]);
  const [selectedAsbuiltVerifyUsers, setSelectedAsbuiltVerifyUsers] = React.useState([]);
  const [requestActionId, setRequestActionId] = useState("")
  const [actionStatus, setActionStatus] = useState("");
  const [request, setRequest] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [isChangesExpected, setIsChangesExpected] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    history.push('/notifications');
  };
  //get notification data     

  useEffect(() => {
    if (notificationId) {

      async function getNotificationData() {
        const response = await getData(
          getSingleNotificationUrl(notificationId),
          authToken
        );
        const status = response.status;
        if (status === 200 || status === 201) {
          const response_json = await response.json();
          setRequest(response_json.request);
          setRequestActionId(response_json.requestActionId);
          setActionStatus(response_json.request_action_status.description_static)
        } else if (status === 401) {
          dispatch({ "type": "setSnackBarInfo", value: { message: t('User Unauthorized'), isError: true } });
          dispatch({ "type": "setOpenSnackbar", value: true });
          history.push(appRoute.unauthorized);
        }
      }
      getNotificationData();
    }
    else if (selectedRequest && selectedRequestActionId) {
      setRequest(selectedRequest);
      setRequestActionId(selectedRequestActionId);
      setActionStatus(appStatuses.active || appStatuses.assigned)
    }
    else if (!request) {
      history.push(appRoute.home);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRequest, notificationId]);

  //Get project attachments
  useEffect(() => {

    if (!request)
      return;

    //Get session token
    async function GetProjectAttachments() {
      const apiURL = requestAttachementGetUrl(request.id, attachmentType.asBuilt);
      await getData(apiURL, authToken)
        .then(async response => {
          const status = response.status;
          if (status === 200 || status === 201) {
            const responseJson = await response.json();
            setAttachements(responseJson);
          }
          else if (status === 401) {
            dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
            dispatch({ "type": "setOpenSnackbar", value: true })
          }
          else {
            dispatch({ "type": "setSnackBarInfo", value: { message: t('generalErrorGetData'), isError: true } })
            dispatch({ "type": "setOpenSnackbar", value: true })
          }
        })
    }

    async function GetClosureDetails() {
      if (!request || request.status.description_static === appStatuses.closed)
        return;

      const apiURL = requestClosureGetUrl(request.id);
      await getData(apiURL, authToken)
        .then(async response => {
          const status = response.status;
          if (status === 200 || status === 201) {
            const responseJson = await response.json();
            setClosureDetais(responseJson);
          }
          else if (status === 401) {
            dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
            dispatch({ "type": "setOpenSnackbar", value: true })
          }
          else {
            dispatch({ "type": "setSnackBarInfo", value: { message: t('generalErrorGetData'), isError: true } })
            dispatch({ "type": "setOpenSnackbar", value: true })
          }
        })

    }

    async function GetUsersCanVerifyAsbuilt() {
      const apiURL = getVerifyAsbuiltUsersURL();
      const response = await getData(apiURL, authToken);
      if (response.status === 200 || response.status === 201) {
        const verifyUsers = await response.json();
        setAsbuiltVerifyUsers(verifyUsers);

        //Set default or first verify user as default
        if (verifyUsers && verifyUsers.length > 0) {
          const defaultUser = verifyUsers.find(u => u.default_check_asbuilt);
          if (defaultUser)
            setSelectedAsbuiltVerifyUsers([defaultUser]);
          else
            setSelectedAsbuiltVerifyUsers([verifyUsers[0]]);
        }
      }
      else {
        setAsbuiltVerifyUsers([]);
      }
    }

    GetUsersCanVerifyAsbuilt();
    GetProjectAttachments();
    GetClosureDetails();
    setIsChangesExpected(request && request.isChangesExpected)
    setCanVerifyAsbuilt(request && request.isChangesExpected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const handleSaveClick = () => {
    setOpenSubmitForm(true);
  }

  const handleSubmitClose = () => {
    setOpenSubmitForm(false);
  }

  const handleSelectAsbuiltUsers = (event, value) => {
    setSelectedAsbuiltVerifyUsers(value ? value : []);
  };

  const handleSubmitClick = async () => {

    try {
      //Get session token
      setIsBusy(true);
      const lang = selectedLanguage === languages.nl ? 'nl' : 'en';
      const apiURL = projectScopePostUrl(lang, request.id, requestActionId);
      const data = {
        "remarks": remarks,
        "isChangesExpected": isChangesExpected,
        "asbuiltVerifyUserIds": !isChangesExpected || !canVerifyAsbuilt ? [] : selectedAsbuiltVerifyUsers.map(u => u.id)
      };

      const response = await postData(apiURL, data, authToken)
      const status = response.status;
      if (status === 200 || status === 201) {
        //Update notifications
        queryClient.invalidateQueries(dataFetchKeys.notifications);
        dispatch({ "type": "setSnackBarInfo", value: { message: t('pageInternalResponseSaved'), isError: false } })
        dispatch({ "type": "setOpenSnackbar", value: true })
        setOpenSubmitForm(false);
        history.push('/home');
      }
      else if (status === 401) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
    }
    finally {
      setIsBusy(false);

    }

  }

  const handleClosureDetailsExpandClick = () => {
    setClosureDetailsExpanded(!closureDetailsExpanded);
  };

  const handleAttachmentsExpandClick = () => {
    setAttachmentsExpanded(!attachmentsExpanded);
  };

  const handleOpenRequestDetailsClick = () => {
    setOpenRequestDetailsDialog(true);
  };

  const closeRequestDetailsDialog = () => {
    setOpenRequestDetailsDialog(false);
  };

  const isValidated = () => {
    return (remarks !== undefined && remarks.length <= requestValidation.remarksMax);
  }
  const handleIsChangesExpected = (event) => {
    setIsChangesExpected(event.target.checked)
    setCanVerifyAsbuilt(event.target.checked)
    if (!event.target.checked) {
      setCanVerifyAsbuilt(event.target.checked)
      setAsbuiltVerifyUsers([])
    }
  }
  console.log('CanVerifyAsbuilt', canVerifyAsbuilt)
  if (!request)
    return <UnauthorizedPage />
  else if (actionStatus !== appStatuses.active)
    return <ActionFinishedPage />

  return (
    <Fragment>
      <div className={classes.root}>
        <form className={classes.form} noValidate autoComplete="off">
          <div>
            <Typography variant="h5" className={classes.heading}>
              {t('pageInternalProjectScope')}
            </Typography>
            <Button variant="outlined" onClick={handleOpenRequestDetailsClick}>
              {t('generalReqDetails')}
            </Button><br />
            <RequestDetailsDialog request={request} open={openRequestDetailsDialog} handleClose={closeRequestDetailsDialog} />
            <FormControlLabel
              value="start"
              control={<Checkbox color="primary"
                checked={isChangesExpected} onChange={handleIsChangesExpected} />}
              label={t('isChangesExpected')}
            />
            <br />
            {isChangesExpected &&
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    color="primary"
                    checked={canVerifyAsbuilt}
                    onChange={(e) => setCanVerifyAsbuilt(e.target.checked)}
                  />}
                label={t('requestNeedToVerifyAsbuilt')}
              />
            }
            <br />
            {!isChangesExpected && 
              <WarningOrErrorGrid warning message={t('changesExpectedUncheckWarning')} /> }

            {!canVerifyAsbuilt ? <div /> :
              <Autocomplete
                margin="none"
                fullWidth
                required
                multiple
                id="select-request-type"
                value={selectedAsbuiltVerifyUsers}
                options={asbuiltVerifyUsers}
                getOptionLabel={(option) => option.firstName ? `${option.firstName} ${option.lastName}` : ''}
                onChange={(event, value) => { handleSelectAsbuiltUsers(event, value) }}
                renderInput={(params) => <TextField {...params} placeholder={t('selectUsers')} label={t('selectUsersCanVerifyAsBuilt')} variant="outlined" />}
              />}

            <Divider />
            <CollapseDivButton expanded={closureDetailsExpanded} handleExpandClick={handleClosureDetailsExpandClick} title="Closure details" />
            <Collapse in={closureDetailsExpanded} timeout="auto" unmountOnExit>
              <Typography variant="h5" className={classes.heading}>
                {t('pageClosureClosureDetails')}
              </Typography>
              <TextField
                variant="outlined"
                margin="none"
                fullWidth
                disabled
                label={t('pageInternalClosureReqDate')}
                value={dateFormat(closureDetails.closure_request_date, dateStringFormat)}
              />
              <br />
              <TextField
                variant="outlined"
                margin="none"
                fullWidth
                disabled
                label={t('pageInternalClosureRemarks')}
                value={closureDetails ? closureDetails.remarks : ""}
                multiline
              />
            </Collapse>
            <Divider />

            <CollapseDivButton expanded={attachmentsExpanded} handleExpandClick={handleAttachmentsExpandClick} title={t('generalAttachmentsAsBuilt')} />
            <Collapse in={attachmentsExpanded} timeout="auto" className={classes.table} unmountOnExit>
              <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                <Grid item xs={12}>
                  <List className={classes.documentList}
                    subheader={
                      <ListSubheader color="primary" className={classes.attachmentHeader} component="div" id="nested-list-subheader">
                        {t('generalAttachmentsAsBuilt')}
                      </ListSubheader>
                    }>
                    <ComponentAttachments
                      attachments={attachements} setAttachments={setAttachements}
                      uploadDisabled={true}
                      typeDownload={downloadType.request} />
                  </List>
                </Grid>
              </Grid>
            </Collapse>
            <Divider />
          </div>
        </form>
        <div className={classes.buttonsDiv}>
          <Fab variant="extended" color="default" aria-label="add" onClick={handleClickOpenDialog} className={classes.button}>
            <BackIcon className={classes.extendedIcon} />
            {t('generalBack')}
          </Fab>
          <Fab variant="extended" color="primary" aria-label="add" className={classes.button} onClick={handleSaveClick}>
            <SaveIcon className={classes.extendedIcon} />
            {t('generalSave')}
          </Fab>
        </div>
      </div>

      <Dialog open={openSubmitForm} onClose={handleSubmitClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <div style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}>
            <DoneDialogIcon className={classes.extendedIcon} />
            <span>{t('pageInternalAsBuiltSelection')}</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`${t('pageInternalAsBuiltInstruction')} ${t('pageInternalInstruction')}`}
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            multiline
            margin="dense"
            id="remarks"
            label={t('generalRemarks')}
            value={remarks}
            helperText={t("charsRemaining", { num_char: requestValidation.remarksMax - remarks.length })}
            error={remarks.length < requestValidation.remarksMin || remarks.length > requestValidation.remarksMax}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitClose} color="secondary" variant="outlined">
            {t('generalCancel')}
          </Button>
          <Button onClick={handleSubmitClick} color="primary" variant="contained" autoFocus
            disabled={isBusy || !isValidated()}>
            {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />}
            {t('generalSubmit')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} />
    </Fragment>
  );
}