import DateFnsUtils from '@date-io/date-fns';
import { Button, CircularProgress, Fab, FormLabel, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, Switch, TextField, Tooltip, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIos as BackIcon, Description as DescriptionIcon, PostAdd as AddDocumentIcon, Save as SaveIcon, } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { appConfigurations, appPages, dataFetchKeys } from '../../../utils/configs';
import { appRoute } from '../../../utils/routeConfigs';
import DropzoneAttachmentDialog from '../../Common/DropzoneAttachmentDialog';
import { getData, postFormData } from './../../../utils/ApiRequest';
import { getAllRequestTypesHavingWorkflowURL, getCreateRequestUrl } from './../../../utils/ApiUrl';
import { languages, requestValidation } from './../../../utils/AppConstants';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext, queryClient } from './../../AuthenticatedPage';
import ConfirmationDialog from './../../Common/ConfirmationDialog';
import RequestCheckList from './RequestCheckList';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import CADRulesAndRegulation from './../../../static/IB_HTC-305 HTCE CAD-afsprakenmap v.2022.pdf'
import highTechCampusLogo from '../../../img/hightechcampus.png'

const dateNow = new Date();

const useStyles = makeStyles(theme => ({

  root: {
    padding: theme.spacing(0, 3),
    marginTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    '& .check-list': {
      '& .MuiGrid-item': {
        width: '80%'
      },
    },
    '& .request-form': {
      '& h3': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiGrid-item': {
        width: '40%'
      },
      '& .MuiTextField-root, .MuiAutocomplete-root': {
        marginBottom: theme.spacing(1),
      },
    },
    '& .MuiSwitch-root': {
      marginLeft: theme.spacing(2),
    },
  },
  submit: {
    marginLeft: theme.spacing(2),
  },
  buttonsDiv: {
    position: 'fixed',
    right: theme.spacing(1),
    bottom: theme.spacing(10),
    left: 'auto',
    top: 'auto',
    '& .MuiFab-root': {
      marginRight: theme.spacing(1),
    }
  },
  saveIcon: {
    marginRight: theme.spacing(1),
  },
  documentList: {
    width: '40%',
    minWidth: '200px',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 50
  },
  gridAttachments: {
    marginTop: theme.spacing(2),
  },
  tooltipButton: {
    position: 'absolute',
    marginTop: theme.spacing(0.5),
  },
  progress: {
    marginRight: theme.spacing(1),
  },
}));

export default function RequestCreatePage(props) {

  const [t] = useTranslation();
  const classes = useStyles();
  let history = useHistory();
  let documentId = 0;

  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const { selectedLanguage, authToken } = state;

  const [requestTypes, setRequestTypes] = React.useState([]);
  const [selectedRequestType, setSelectedRequestType] = React.useState([]);
  const [description, setdescription] = useState("");
  const [referenceNumber, setreferenceNumber] = useState("");
  const [motivation, setmotivation] = useState("");
  const [isUrgent, setIsUrgent] = React.useState(false);
  const [expStartDate, setexpStartDate] = useState(new Date());
  const [isBusy, setIsBusy] = useState(false);
  const [expEndDate, setexpEndDate] = useState(new Date());
  const [remarks, setRemarks] = useState("");
  const [planologicRequestNumber, setPlanologicRequestNumber] = useState("");

  const [openDialog, setOpenDialog] = React.useState(false);
  const [attachments, setAttachments] = React.useState([]);
  const [urgencyDocument, setUrgencyDocument] = React.useState(null);
  const [openAttachementDialog, setOpenAttachementDialog] = React.useState(false);
  const [openPrivacyDocumentDialog, setOpenPrivacyDocumentDialog] = React.useState(false);
  const [planologicApproved, setPlanologicApproved] = React.useState(false);
  const [ruleAndRagulation, setRuleAndRagulation] = React.useState(false);
  const [hasPlanologischAcceptance, setHasPlanologischAcceptance] = React.useState(false);
  const [isPlanologisch, setIsPlanologisch] = React.useState(false);
  const [isChangesExpected, setIsChangesExpected] = React.useState(false);
  const [checkListCategories, setCheckListCategories] = useState([]);
  const isAmpApp = appConfigurations.isAmpApp;
  const safetyPermitsUrl = `${appConfigurations.safetyPermitsUrl}`;
  useEffect(() => {
    async function GetRequestTypes() {
      const apiURL = getAllRequestTypesHavingWorkflowURL();
      const response = await getData(apiURL, GetToken());

      if (response.status === 200) {
        const response_json = await response.json();
        setRequestTypes(response_json);
      }
      else {
        setRequestTypes([]);
      }
    }
    GetRequestTypes();
  }, []);

  useEffect(() => {

    const initializeCheckList = () => {
      const categories = !state.checkListCategories ? [] : JSON.parse(JSON.stringify(state.checkListCategories));
      setCheckListCategories(categories);
    }

    initializeCheckList();
  }, [state.checkListCategories]);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleGoBack = () => {
    dispatch({ "type": "setSelectedPage", value: appPages.home });
    history.push({
      pathname: appRoute.home,
    });
  }
  const handleStartDateChange = (date) => {
    setexpStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setexpEndDate(date);
  };

  const handleSelectRequestType = (event, value) => {
    setSelectedRequestType(value);
    setIsPlanologisch(value && !value.is_planologic);
  };

  const handleAttachmentDialogClose = () => {
    setOpenAttachementDialog(false);
  }

  const handleOpenAttachmentDialog = () => {
    setOpenAttachementDialog(true);
  }

  const handleUploadAttachment = async (f) => {

    if (!f || f.length <= 0) { return; }

    const newArray = [...attachments];
    f.forEach(file => {
      newArray.push({ id: ++documentId, path: file.path, data: file });
    })
    setAttachments(newArray);
    setOpenAttachementDialog(false);
  }

  const handleDeleteAttachment = async (fileId) => {
    const newArray = [...attachments];
    const attachmentIndex = newArray.findIndex(a => a.id === fileId);
    if (attachmentIndex >= 0) {
      newArray.splice(attachmentIndex, 1);
      setAttachments(newArray);
    }
  }

  const handleUrgencyDocumentClose = () => {
    setOpenPrivacyDocumentDialog(false);
  }

  const handleUrgencyDocumentOpen = () => {
    setOpenPrivacyDocumentDialog(true);
  }

  const handleUploadUrgencyDocument = async (f) => {
    setUrgencyDocument({ id: ++documentId, path: f[0].path, data: f[0] });
    setOpenPrivacyDocumentDialog(false);
  }

  const handlePlanologicApprovedChange = (event) => {
    setPlanologicApproved(event.target.checked);
    if (!event.target.checked) {
      setPlanologicRequestNumber("");
    }
  };
  const handleRuleAndRegulationsChange = (event) => {
    setRuleAndRagulation(event.target.checked);
  };
  const handlePlanologicAcceptanceChange = (event) => {
    if (hasPlanologischAcceptance) {
      setPlanologicApproved(!planologicApproved);
    }
    setHasPlanologischAcceptance(event.target.checked);

  };
  const handleUrgent = (event) => {
    setIsUrgent(event.target.checked);
  }

  const handleDeleteUrgencyDocument = () => {
    setUrgencyDocument(null);
  }

  const handleCADRulesAndRegulation = () => {
    window.open(CADRulesAndRegulation);
  }
  function validateForm() {

    const checkListItems = !isAmpApp ? [] : (checkListCategories || []).flatMap(c => c.items || []);
    const isCheckListValid = !isAmpApp || checkListItems.length === 0 ? true : !checkListItems.some(c => c.itemValue === undefined || c.itemValue === "");

    return (selectedRequestType &&
      description && description.length > requestValidation.descriptionMin && description.length < requestValidation.descriptionMax &&
      referenceNumber && referenceNumber.length >= requestValidation.referenceNumberMin && referenceNumber.length <= requestValidation.referenceNumberMax &&
      motivation && motivation.length >= requestValidation.motivationMin && motivation.length <= requestValidation.motivationeMax &&
      expStartDate >= dateNow && expEndDate >= expStartDate &&
      (!planologicApproved ? true : planologicApproved && planologicRequestNumber.length >= requestValidation.planologicRequestNumberMin && planologicRequestNumber.length <= requestValidation.planologicRequestNumberMax) &&
      (checkListCategories.length === 0 ? true : !checkListCategories.some(c => c.itemValue === "")) &&
      (!isAmpApp ? true : ruleAndRagulation) &&
      remarks.length <= requestValidation.remarksMax &&
      isCheckListValid);
    // (!isAmpApp ? true : !isCheckListValid));
  }

  async function handleSubmit(event) {

    if (!selectedRequestType) {
      return;
    }

    event.preventDefault();

    try {
      setIsBusy(true);
      //Get session token
      const formData = new FormData();
      formData.append("request_type", selectedRequestType.id);
      formData.append("description", description);
      formData.append("reference_number", referenceNumber);
      formData.append("motivation", motivation);
      formData.append("start_date", expStartDate);
      formData.append("end_date", expEndDate);
      formData.append("remarks", remarks);
      formData.append("isUrgent", isUrgent ? "true" : "false");
      formData.append("hasPlanologischAcceptance", hasPlanologischAcceptance);
      formData.append("isChangesExpected", isChangesExpected);
      formData.append("planologicApproved", planologicApproved);
      formData.append("planologicRequestNumber", planologicRequestNumber);

      if (checkListCategories.length > 0) {
        const items = checkListCategories.flatMap(c => c.items).map(c => ({
          checkListItemId: c.id,
          itemValue: c.itemValue
        }));
        formData.append("checkListItems", JSON.stringify(items));
      }

      const lang = selectedLanguage === languages.nl ? 'nl' : 'en';
      for (const file of attachments) {
        formData.append('files', file.data, file.path);
      }

      if (isUrgent && urgencyDocument) {
        formData.append('files', urgencyDocument.data, `__uuu__${urgencyDocument.path}`);
      }

      const response = await postFormData(getCreateRequestUrl(lang), formData, authToken);
      const status = response.status;
      if (status === 200 || status === 201) {
        const response_json = await response.json();

        queryClient.invalidateQueries(dataFetchKeys.allRequests);
        queryClient.invalidateQueries(dataFetchKeys.notifications);
        dispatch({ "type": "setSelectedRequest", value: response_json });
        dispatch({ "type": "setSnackBarInfo", value: { message: t('pageReqCreateCreated'), isError: false } });
        dispatch({ "type": "setOpenSnackbar", value: true });
        history.push(appRoute.trackRequest);
      }
      else if (status === 401) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else if (status === 406) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('urgentDocumentRequired'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
    }
    finally {
      setIsBusy(false);
    }
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid container justifyContent="center" alignItems="center" direction="column" className="request-form">
          <Typography variant="h3" color="primary">{t('generalCreateReq')}</Typography>
          <Grid item>
            <Autocomplete
              required
              id="select-request-type"
              value={selectedRequestType}
              options={requestTypes}
              getOptionLabel={(option) => option.description ? option.description : ''}
              disabled={isBusy}
              onChange={(event, value) => { handleSelectRequestType(event, value) }}
              renderInput={(params) => <TextField {...params} placeholder={t('generalSelectRequestType')} label={t('generalRequestType')} variant="outlined" />}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              fullWidth
              required
              multiline
              id="description"
              label={t(isAmpApp ? 'buildingNumber' : 'generalDescription')}
              name="description"
              autoComplete="off"
              value={description}
              disabled={isBusy}
              helperText={t("charsRemaining", { num_char: requestValidation.descriptionMax - description.length })}
              error={description.length > requestValidation.descriptionMax}
              onChange={e => setdescription(e.target.value)}
            />{isAmpApp &&
              <Tooltip title={t('pageReqCreateHelperBuildingNumber')} disabled={isBusy}>
                <IconButton className={classes.tooltipButton}>
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip>
            }
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              required
              multiline
              fullWidth
              id="referenceNumber"
              label={t(isAmpApp ? 'projectName' : 'pageReqCreateRefNo')}
              name="referenceNumber"
              autoComplete="off"
              value={referenceNumber}
              disabled={isBusy}
              helperText={t("charsRemaining", { num_char: requestValidation.referenceNumberMax - referenceNumber.length })}
              error={referenceNumber.length > requestValidation.referenceNumberMax}
              onChange={e => setreferenceNumber(e.target.value)}
            />
            <Tooltip title={isAmpApp ? t('pageReqCreateHelperTextProjectNo') : t('pageReqCreateHelperTextRefNo')}>
              <IconButton className={classes.tooltipButton} disabled={isBusy}>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              required
              multiline
              fullWidth
              id="motivation"
              label={t(isAmpApp ? 'briefDescription' : 'pageReqCreateMotivation')}
              name="motivation"
              autoComplete="off"
              value={motivation}
              disabled={isBusy}
              helperText={t("charsRemaining", { num_char: requestValidation.motivationeMax - motivation.length })}
              error={motivation.length > requestValidation.motivationeMax}
              onChange={e => setmotivation(e.target.value)}
            />
            <Tooltip title={t('pageReqCreateHelperTextMotivation')} disabled={isBusy}>
              <IconButton className={classes.tooltipButton}>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                required
                fullWidth
                format="dd-MM-yyyy"
                margin="normal"
                id="date-picker-inline"
                label={t('pageReqCreateExpStartDate')}
                value={expStartDate}
                disabled={isBusy}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              {isAmpApp &&
                <Tooltip title={t('pageReqCreateHelperExpStartDate')} style={{ marginTop: "20px" }} disabled={isBusy}>
                  <IconButton className={classes.tooltipButton}>
                    <HelpOutlineIcon />
                  </IconButton>
                </Tooltip>
              }
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                required
                fullWidth
                format="dd-MM-yyyy"
                margin="normal"
                id="date-picker-inline"
                label={t('pageReqCreateExpEndDate')}
                value={expEndDate}
                disabled={isBusy}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
          <Grid item>
            <TextField
              margin="none"
              variant="outlined"
              multiline
              fullWidth
              minRows={2}
              id="remarks"
              label={t('generalRemarks')}
              name="remarks"
              autoComplete="off"
              value={remarks}
              disabled={isBusy}
              helperText={t("charsRemaining", { num_char: requestValidation.remarksMax - remarks.length })}
              error={remarks.length > requestValidation.remarksMax}
              onChange={e => setRemarks(e.target.value)}
            />
          </Grid>
          {
            !isAmpApp && (isPlanologisch &&
              <Grid item>
                <Grid container justify="center" alignItems="center">
                  <FormLabel component="legend">{t('isChangesExpected')}</FormLabel>
                  <FormControlLabel label={isChangesExpected ? t('generalYes') : 'N.V.T.'}
                    control={<Switch disabled={isBusy} color="primary" checked={isChangesExpected || false} onChange={(e) => setIsChangesExpected(e.target.checked)} name="isChangesExpected" />}
                  />
                </Grid>
              </Grid>)
          }
          {!isAmpApp &&
            <Grid item>
              <Grid container justify="center" alignItems="center">
                <FormLabel component="legend">{t('urgent')}</FormLabel>
                <FormControlLabel label={isUrgent ? t('generalYes') : 'N.V.T.'}
                  control={<Switch color="primary" checked={isUrgent} onChange={handleUrgent} disabled={isBusy} name="isUrgent" />}
                />
              </Grid>
            </Grid>
          }

          {isUrgent &&
            <Fragment>
              <Grid item>
                <Grid container>
                  <Grid item xs={10}>
                    <TextField
                      margin="none"
                      variant="outlined"
                      multiline
                      fullWidth
                      id="urgencyDocument"
                      label={t('urgencyDocument')}
                      name="urgencyDocument"
                      value={urgencyDocument?.path || ""}
                      disabled={isBusy}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {!urgencyDocument ?
                      <Tooltip title={t('selectUrgencyDocument')}>
                        <IconButton color="primary" className={classes.tooltipButton} onClick={handleUrgencyDocumentOpen} disabled={isBusy}>
                          <AddCircleIcon />
                        </IconButton>
                      </Tooltip> :
                      <Tooltip title={t('deleteUrgencyDocument')}>
                        <IconButton className={classes.tooltipButton} onClick={handleDeleteUrgencyDocument} disabled={isBusy}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>}
                  </Grid>
                  {isAmpApp &&
                    <Grid item xs={1}>
                      <Tooltip title={t('pageReqCreateHelperUrgencyDocument')} disabled={isBusy}>
                        <IconButton className={classes.tooltipButton}>
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Fragment>
          }
          {!isAmpApp && isPlanologisch &&
            <Grid container justify="center" alignItems="center">
              <FormLabel component="legend">{t('planningAcceptance')}</FormLabel>
              <FormControlLabel label={hasPlanologischAcceptance ? t('generalYes') : 'N.V.T.'}
                control={<Switch color="primary" checked={hasPlanologischAcceptance} onChange={handlePlanologicAcceptanceChange} disabled={isBusy} name="planningAcceptance" />}
              />
            </Grid>
          }

          {!isAmpApp && isPlanologisch && hasPlanologischAcceptance &&
            <Grid item>
              <Grid container justify="center" alignItems="center">
                <FormLabel component="legend">{t('planningApproved')}</FormLabel>
                <FormControlLabel label={planologicApproved ? t('generalYes') : 'N.V.T.'}
                  control={<Switch color="primary" checked={planologicApproved} onChange={handlePlanologicApprovedChange} disabled={isBusy} name="planologicApproved" />}
                />
              </Grid>

              {planologicApproved &&
                <TextField
                  required
                  fullWidth
                  margin="none"
                  variant="outlined"
                  id="remarks"
                  label={t('planningApplicationNumber')}
                  name="remarks"
                  autoComplete="off"
                  helperText={t("charsRemaining", { num_char: requestValidation.planologicRequestNumberMax - planologicRequestNumber.length })}
                  error={planologicRequestNumber.length > requestValidation.planologicRequestNumberMax}
                  value={planologicRequestNumber}
                  disabled={isBusy}
                  onChange={e => setPlanologicRequestNumber(e.target.value)}
                />}
            </Grid>}

          <Grid item>
              <List dense={false}
                subheader={
                  <ListSubheader color="primary" id="nested-list-subheader">
                    <Typography variant="h5" align="center">
                      {t('generalAttachments')}
                    </Typography>
                  </ListSubheader>
                }>
                <ListItem style={{ justifyContent: 'center', marginLeft: '25px' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={isBusy}
                    onClick={handleOpenAttachmentDialog}
                    startIcon={<AddDocumentIcon />}
                  >
                    {t('generalAddAttachment')}
                  </Button>
                  <Tooltip placement={'right-start'} title={t('pageReqCreateHelperAttachementValidation')} disabled={isBusy}>
                    <IconButton >
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </ListItem>
                {attachments.map(attachment =>
                  <ListItem key={attachment.id} button divider={true}>
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={attachment.path}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" color="primary" disabled={isBusy} onClick={() => handleDeleteAttachment(attachment.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
              </List>
            {
              isAmpApp &&
              <div>
                <div style={{ display: 'inline' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ruleAndRagulation}
                        onChange={handleRuleAndRegulationsChange}
                        name="ruleAndRagulation"
                        color="primary"
                      />
                    }
                    label={`${t('pageReqCreateRuleAndRagulation')}`}
                  />
                </div>
                <Grid container style={{ paddingTop: '5px' }} >
                  <img src={highTechCampusLogo} style={{ paddingRight: '12px' }} height={20} width={20} alt='highTechCampusLogo' />
                  <Link href={safetyPermitsUrl} target="_blank" >
                    {t('pageReqCreateSafetyPermitsLinkTitle')}
                  </Link>
                </Grid>
                <Grid container style={{ paddingTop: '5px' }} >
                  <img src={highTechCampusLogo} style={{ paddingRight: '12px' }} height={20} width={20} alt='highTechCampusLogo' />
                  <Link style={{ cursor: 'pointer' }} underline="always" onClick={handleCADRulesAndRegulation}>
                    {t('pageReqCreateCheckRuleAndRegulationPdf')}
                  </Link>
                </Grid>
              </div>
            }
          </Grid>
        </Grid>
        {
          isAmpApp &&
          <Grid container style={{ paddingTop: '12px' }} justifyContent="center" alignItems="center" direction="column" className="check-list">
            <RequestCheckList
              checkListCategories={checkListCategories}
              setCheckListCategories={setCheckListCategories}
            />
          </Grid>
        }
      </div>
      <div className={classes.buttonsDiv}>
        <Fab variant="extended" color="default" aria-label="add" onClick={handleClickOpenDialog} disabled={isBusy}>
          <BackIcon /> {t('generalBack')}
        </Fab>
        <Fab variant="extended" color="primary" aria-label="add" onClick={handleSubmit} disabled={isBusy || !validateForm()}>
          {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />}
          <SaveIcon className={classes.saveIcon} /> {t('pageReqCreateSave')}
        </Fab>
      </div>

      <DropzoneAttachmentDialog
        title={t('generalUploadDocument')}
        fileLimit={10}
        open={openAttachementDialog}
        handleSave={handleUploadAttachment}
        handleClose={handleAttachmentDialogClose}
        dropzoneTitle={t('pageReqCreateDragDropFile')} />

      <DropzoneAttachmentDialog
        title={t('generalUploadDocument')}
        fileLimit={1}
        open={openPrivacyDocumentDialog}
        handleSave={handleUploadUrgencyDocument}
        handleClose={handleUrgencyDocumentClose}
        dropzoneTitle={t('pageReqCreateDragDropFile')} />

      <ConfirmationDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} handleGoBack={handleGoBack} />
    </Fragment>
  );
}