import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Collapse, Divider, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore as ExpandMoreIcon, Info as InfoIcon, Person as PersonIcon, Visibility as UnseenIcon, VisibilityOff as SeenIcon } from '@material-ui/icons';
import FinishedIcon from '@material-ui/icons/AssignmentTurnedIn';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import clsx from 'clsx';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { statusConfigs } from '../../../utils/configs';
import { appRoute, userActionType } from '../../../utils/routeConfigs';
import { AppContext } from '../../AuthenticatedPage';
import StyledBadge from '../../Common/StyledBadge';
import UserDetailsDialog from '../../Common/UserDetailsDialog';
import { putData } from './../../../utils/ApiRequest';
import { getNotificationMarkUrl } from './../../../utils/ApiUrl';
import RequestDetailsDialog from './../../pages/Requests/RequestDetailsDialog';
import PanToolIcon from '@material-ui/icons/PanTool';

const dateFormat = require('dateformat');
const dateStringFormat = "dd-mm-yyyy hh:MM";
const useStyles = makeStyles((theme) => ({

    root: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(2),
        width: '95%',
        minWidth: '95%',
        borderRadius: 0,
        '& .MuiCardContent-root': {
            margin: 0,
            padding: 0
        },
        '& .div-details': {
            margin: theme.spacing(1),
            marginLeft: theme.spacing(2),
            '& h6': {
                color: theme.palette.primary.main
            }
        }
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    notificationCardFinished: {
        //backgroundColor: '#b9cb8d'
    },
    cardButton: {
        marginRight: '10px'
    },
    avatarClass: {
        color: 'black'
    },
    ActiveClass: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
    HoldClass: {
        backgroundColor: theme.palette.warning.main,
        color: 'white',
    },
    PendingClass: {
        backgroundColor: 'red',
        color: 'white',
    },
    SeenClass: {
        backgroundColor: 'green',
        color: 'white',
    },
    notificationTitle: {
        fontSize: 14,
    },
    notificationDate: {
        fontSize: 14,
        color: 'gray',
    },
    notificationUnread: {
        backgroundColor: "#ffcc80",
        '& .MuiTypography-root': {
            color: '#663d00',
        },
        '& svg': {
            color: '#995c00',
        }
    },
    progress: {
        marginRight: theme.spacing(1),
    },
    releaseRequestButton: {
        backgroundColor: theme.palette.warning.main,
    },
    holdTitle: {
        color: theme.palette.warning.main,
        fontSize: 14,
    }
}));

export default function NotificationItem(props) {

    const history = useHistory();
    const [t] = useTranslation();
    const classes = useStyles();
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { notifications, authToken } = state;
    const { item, isFinished } = props;
    const [openRequestDialog, setOpenRequestDialog] = React.useState(false);
    const [openUserDialog, setOpenUserDialog] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [isBusy, setIsBusy] = React.useState(false);
    const seenStatus = item?.seen_status || null;
    const notificationStatus = item?.status || null;
    const isActive = notificationStatus?.description_static === statusConfigs.Active || false;
    const isRead = seenStatus && seenStatus.description_static === statusConfigs.Seen;
    const onHold = item?.request?.status?.description_static === statusConfigs.Hold || false;
    const holdByUser = item.holdByUser;
    const isPending = !isActive && !onHold && seenStatus && seenStatus.description_static === statusConfigs.Pending;
    const isTaskFinished = notificationStatus?.description_static === statusConfigs.Finished || false;
    const actionAllowed = item.requestActionId && (onHold ? holdByUser : true);

    const getAvatar = () => {
        return (
            <Avatar aria-label="recipe"
                className={clsx(classes.avatarClass, {
                    [classes.HoldClass]: onHold,
                    [classes.ActiveClass]: isActive && !onHold,
                    [classes.PendingClass]: isPending,
                    [classes.SeenClass]: !isActive && !onHold && isRead,
                })}>
                {onHold &&
                    <PanToolIcon />}
                {!onHold && isTaskFinished && 
                    <FinishedIcon />}
                {!onHold && !isTaskFinished &&
                    <NewReleasesIcon />}
            </Avatar>
        );
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const closeRequestDialog = () => {
        setOpenRequestDialog(false);
    };

    const closeUserDialog = () => {
        setOpenUserDialog(false);
    };

    const handleRequestClick = (param) => {

        if (param === "request") {
            setOpenRequestDialog(true);
        }
        else if (param === "user") {
            setOpenUserDialog(true);
        }
    };

    const handleMarkReadClick = async (id, readStatus) => {
        try{
            setIsBusy(true);
            const response = await putData(getNotificationMarkUrl(id, readStatus), null, authToken);
            const status = response.status;
            if (status === 200 || status === 201) {
                const newStatus = await response.json();
                const newArray = [...notifications];
                const index = newArray.findIndex(n => n.id === id);
                if (index >= 0) {
                    const item = newArray.find(n => n.id === id);
                    item.seen_status = newStatus;
                    if (readStatus && !item.action) {
                        item.status = { description_static: statusConfigs.Finished }
                    }
                    newArray.splice(index, 1, item);
                    dispatch({ "type": "setNotifications", value: newArray });
                }
    
                dispatch({ "type": "setSnackBarInfo", value: { message: t(readStatus ? 'pageNotificationMarkedRead' : 'pageNotificationMarkedUnread'), isError: false } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else if (status === 401) {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
        }
        finally{
        setIsBusy(false);
        }
 
    };

    const handleActionClick = () => {

        dispatch({ "type": "setSelectedNotification", value: item });
        dispatch({ "type": "setSelectedRequest", value: item?.request });
        dispatch({ "type": "setSelectedAction", value: item?.action });
        dispatch({ "type": "setSelectedRequestActionId", value: item?.requestActionId });
        dispatch({ "type": "setActionById", value: item?.actionById || "" });
        
        if (item?.action?.type === userActionType.assignAssetType) {
            history.push({
                pathname: appRoute.assignAssetType,
            });
        }
        else if (item?.action?.type === userActionType.uploadAsBuilt) {
            history.push({
                pathname: appRoute.uploadAsBuilt,
            });
        }
        else if (item?.action?.type === userActionType.asBuiltDecision) {
            history.push({
                pathname: appRoute.asBuiltDecision,
            });
        }
        else if (item?.action?.type === userActionType.verifyAsBuilt) {
            history.push({
                pathname: appRoute.verifyAsBuilt,
            });
        }
        else if (item?.action?.type === userActionType.projectClosure || item?.action?.type === userActionType.finalApproval) {
            history.push({
                pathname: appRoute.closeRequest,
            });
        }
        else {
            dispatch({ "type": "setSelectedAssetType", value: item?.assetType });
            history.push({
                pathname: appRoute.requestAction,
            });
        }

        dispatch({ "type": "setOpenNotificationDrawer", value: false });
    };

    return (
        <Card elevation={4} className={clsx(classes.root, {
            [classes.notificationCardSeen]: isRead,
            [classes.notificationUnread]: !isRead,
            [classes.notificationCardFinished]: isFinished,
        })}>
            <CardHeader
                avatar={
                    <Fragment>
                        {isRead && !onHold ? getAvatar() :
                            <StyledBadge
                                overlap="rectangle"
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                variant="dot"
                            >
                                {getAvatar()}
                            </StyledBadge>
                        }
                    </Fragment>
                }
                title={<Fragment>
                    <Typography className={classes.notificationTitle}>
                        {item.description}
                    </Typography>
                </Fragment>}
                subheader={
                    <Fragment>
                        <Typography className={classes.notificationDate}>
                            {dateFormat(item.sent_on, dateStringFormat)}
                        </Typography>
                        {onHold &&
                            <Typography variant="h5" className={classes.holdTitle}>
                                {t(holdByUser ? "onHoldByCurrentUser" : "onHoldByOtherUser")}
                            </Typography>}
                    </Fragment>}
            />
            <CardActions disableSpacing>
                <Grid container justify="flex-start">
                    <Tooltip title={t('generalReqDetails')} aria-label="request-details">
                        <IconButton color="default" aria-label="request-details" component="span" onClick={() => handleRequestClick("request")}>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('generalUserDetails')} aria-label="user-details">
                        <IconButton color="default" aria-label="user-details" component="span" onClick={() => handleRequestClick("user")}>
                            <PersonIcon />
                        </IconButton>
                    </Tooltip>
                    {!isFinished &&
                        <div>
                            <Tooltip title={seenStatus && seenStatus.description_static === statusConfigs.Active ? t('pageNotificationMarkAsRead') : t('pageNotificationMarkAsUnread')} aria-label="add">
                                    <label htmlFor="icon-button-file">

                                    {seenStatus && seenStatus.description_static === statusConfigs.Active ?
                                                <IconButton color="primary" aria-label="Mark as read" component="span" onClick={() => handleMarkReadClick(item.id, true)}>
                                                  {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />  }    
                                                 <SeenIcon />
                                                </IconButton> :
                                                <IconButton color="primary" aria-label="Mark as unread" component="span" onClick={() => handleMarkReadClick(item.id, false)}>
                                                 {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />  }
                                                    <UnseenIcon />
                                                </IconButton>}
                                    </label>
                            </Tooltip>
                            {actionAllowed &&
                                    <Tooltip title={t(onHold ? "releaseRequest" : "pageNotificationTakeActionOnNotification")} aria-label="add">
                                        <Button size="small"
                                            variant="contained"
                                            color={onHold ? "secondary" : "primary"}
                                            onClick={handleActionClick}
                                            className={clsx(classes.cardButton, {
                                                [classes.releaseRequestButton]: onHold,
                                            })}
                                        >
                                            {t(onHold ? "releaseRequest" : "pageNotificationTakeAction")}
                                        </Button>
                                    </Tooltip>}
                            </div>
                    }
                </Grid>

                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>

            </CardActions>
            <CardContent>
                <Collapse in={expanded} timeout="auto" unmountOnExit className="div-details">
                    <Divider />
                    {!item.action ? <div /> :
                        <div>
                            <Typography variant="h6">
                                {t('generalAction')}
                            </Typography>
                            <Typography paragraph>{item.action.description}</Typography>
                            <Typography variant="h6">
                                {t('generalStatus')}
                            </Typography>
                            <Typography paragraph>{item.status.description}</Typography>
                        </div>
                    }
                    <div>
                        <Typography variant="h6">
                            {t('generalRemarks')}
                        </Typography>
                        <Typography paragraph>{item.remarks}</Typography>
                    </div>
                </Collapse>
            </CardContent>

            <RequestDetailsDialog request={item.request} open={openRequestDialog} handleClose={closeRequestDialog} />
            <UserDetailsDialog userDetails={item.sent_by} openDialog={openUserDialog} handleCloseDialog={closeUserDialog} />
        </Card>
    )
}