import { AppBar, Button, CircularProgress, Dialog, DialogActions, DialogContent, Fab, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, TextField, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, Save as SaveIcon } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { putData } from '../../../utils/ApiRequest';
import { respondToDelayRequestUrl } from '../../../utils/ApiUrl';
import RequestDetailsDialog from '../Requests/RequestDetailsDialog';
import { languages, requestValidation } from './../../../utils/AppConstants';
import { AppContext } from './../../AuthenticatedPage';
const dateFormat = require('dateformat');
const dateStringFormat = "dd-mm-yyyy";

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiGrid-container': {
            '& h3': {
                marginBottom: theme.spacing(2),
            },
            '& .MuiGrid-item': {
                width: '40%'
            },
            '& .MuiTextField-root, .MuiAutocomplete-root': {
                marginTop: theme.spacing(0.75),
                marginBottom: theme.spacing(0.75),
            },
        },
        '& .MuiSwitch-root': {
            marginLeft: theme.spacing(2),
        },
        '& .MuiList-root': {
            paddingBottom: theme.spacing(10),
        },
    },
    appBar: {
        position: 'relative',
        '& .MuiToolbar-root': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function DialogRespondToDelayRequest(props) {

    const [t] = useTranslation();
    const classes = useStyles();
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { selectedLanguage, requests, authToken } = state;
    const { open, setOpen, request, delayRequest, setDelayRequest } = props;
    const [isSaving, setIsSaving] = React.useState(false);
    const [openRequestDetailsDialog, setOpenRequestDetailsDialog] = React.useState(false);
    const [remarks, setRemarks] = useState("");
    const [decision, setDecision] = useState("");
    const [remarksError, setRemarksError] = useState(true);

    const possibleDecisions = {
        "approved": "approved",
        "rejected": "rejected",
    }

    async function handleSubmit(event) {

        event.preventDefault();

        try {
            setIsSaving(true);

            const approved = decision === possibleDecisions.approved;
            const data = {
                "approved": approved,
                "remarks": remarks
            };

            const lang = selectedLanguage === languages.nl ? 'nl' : 'en';

            const response = await putData(respondToDelayRequestUrl(request.id, lang), data, authToken);
            const status = response.status;
            if (status === 200 || status === 201) {
                const arr = [...requests];
                const index = arr.findIndex(r => r.id === request.id);
                if (index >= 0) {
                    const requestToUpdate = arr[index];
                    requestToUpdate.hasDelayRequest = false;

                    //Update end date if approved
                    if (approved) {
                        requestToUpdate.end_date = delayRequest.delayDate;
                    }

                    arr.splice(index, 1, requestToUpdate);
                    dispatch({ "type": "setRequests", value: arr });
                }

                setDelayRequest(null);
                setOpen(false);
                dispatch({ "type": "setSnackBarInfo", value: { message: t('delay.response.success'), isError: false } });
            }
            else if (status === 401) {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
        }
        finally {
            setIsSaving(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const validateForm = () => {
        return decision && !remarksError;
    }

    const handleOpenRequestDetailsClick = () => {
        setOpenRequestDetailsDialog(true);
    };

    const closeRequestDetailsDialog = () => {
        setOpenRequestDetailsDialog(false);
    };

    const handleRemarksChange = (e) => {
        const newValue = e.target.value;
        setRemarks(newValue);
        setRemarksError(!newValue || newValue.length < requestValidation.remarksMin || newValue.length > requestValidation.remarksMax);
    };

    const handleDecisionChange = (e) => {
        setDecision(e.target.value);
    };

    if (!delayRequest) {
        return <Fragment/>
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            style={{ backgroundColor: '#fff' }}
            fullScreen>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" className={classes.title}>
                        {t('delay.response.title')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.root} style={{ backgroundColor: '#fff' }}>
                <Grid container justifyContent="center" alignItems="center" direction="column">
                    <ErrorIcon fontSize="large" />
                    <Button variant="outlined" onClick={handleOpenRequestDetailsClick} >
                        {t('generalReqDetails')}
                    </Button>

                    <RequestDetailsDialog request={request} open={openRequestDetailsDialog} handleClose={closeRequestDetailsDialog} />

                    <Typography variant="h6" >
                        {t('delay.response.description')}
                    </Typography>

                    <Grid item>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="motivation"
                            label={t('delay.prposed.end.date')}
                            value={dateFormat(new Date(delayRequest.delayDate), dateStringFormat)}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            multiline
                            fullWidth
                            rows={5}
                            id="remarks"
                            label={t('delay.request.user.remarks')}
                            value={delayRequest.userRemarks}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="motivation"
                            label={t('generalCreatedOn')}
                            value={dateFormat(new Date(delayRequest.createdOn), dateStringFormat)}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="motivation"
                            label={t('generalCreatedBy')}
                            value={delayRequest.createdBy}
                            disabled
                        />
                    </Grid>
                    
                    <Grid item>
                        <TextField
                            margin="none"
                            variant="outlined"
                            multiline
                            fullWidth
                            rows={5}
                            id="remarks"
                            label={t('generalRemarks')}
                            name="remarks"
                            autoComplete="off"
                            value={remarks}
                            disabled={isSaving}
                            helperText={t("charsRemaining", { num_char: requestValidation.remarksMax - remarks.length })}
                            error={remarks.length > requestValidation.remarksMax}
                            onChange={handleRemarksChange}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" color="primary">Make your decision</FormLabel>
                            <RadioGroup aria-label="approval" name="approval1" value={decision} row onChange={handleDecisionChange}>
                                <FormControlLabel value={possibleDecisions.approved} color="primary" control={<Radio color="primary" />} label={t('generalApprove')} labelPlacement="end" />
                                <FormControlLabel value={possibleDecisions.rejected} control={<Radio />} label={t('generalDecline')} labelPlacement="end" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#fff' }}>
                <Fab variant="extended" color="primary" aria-label="add" disabled={!validateForm() || isSaving}
                    onClick={handleSubmit} >
                    <SaveIcon className={classes.extendedIcon} />
                    {isSaving && <CircularProgress color="primary" size={25} className={classes.progress} />}
                    {t('submit')}
                </Fab>
            </DialogActions>
        </Dialog>
    )
}