import { AppBar, Button, CircularProgress, Dialog, DialogActions, DialogContent, Fab, Grid, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, Save as SaveIcon } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { statusConfigs } from '../../../utils/configs';
import { postData } from './../../../utils/ApiRequest';
import { changeRequestTypeofExistingRequestUrl } from './../../../utils/ApiUrl';
import { languages } from './../../../utils/AppConstants';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext } from './../../AuthenticatedPage';
import RequestDetailsDialog from './RequestDetailsDialog';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(10),
    },
    container: {
        maxHeight: 440,
    },
    formControl: {
        fullWidth: true,
        display: 'flex'
    },
    dialog: {
        //backgroundColor: '#F5F7FA !important'
    },
    appBar: {
        position: 'relative',
        '& .MuiToolbar-root': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '40%',
            minWidth: '200px',
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        '& .MuiTypography-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: '40%',
            minWidth: '200px',
            color: theme.palette.warning.main,
        },
        '& svg': {
            color: theme.palette.warning.main,
        },
        textAlign: 'center',
        width: '100%',
        backgroundColor: '#fff'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function DialogChangeRequestType(props) {

    const [t] = useTranslation();
    const classes = useStyles();
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { selectedLanguage, requests } = state;
    const { open, setOpen, request, requestTypes } = props;
    const [isSaving, setIsSaving] = React.useState(false);
    const [openRequestDetailsDialog, setOpenRequestDetailsDialog] = React.useState(false);
    const [selectedRequestType, setSelectedRequestTypes] = React.useState([]);
    const [requestTypeId, setRequestTypeId] = useState("");
    const IsRequestHold = request ? request.status.description === statusConfigs.Hold : false;

    async function handleSubmit(event) {
        event.preventDefault();

        try {
            setIsSaving(true);

            const lang = selectedLanguage === languages.nl ? 'nl' : 'en';

            const response =  await postData(changeRequestTypeofExistingRequestUrl(request.id, requestTypeId, lang), null, GetToken())
                    const status = response.status;
                    if (status === 200 || status === 201) {
                        const selectedRequestType = requestTypes.find(f => f.id === requestTypeId);
                        const cloneRequests = [...requests];
                        const findRequest = cloneRequests.find(f => f.id === request.id);
                        if (findRequest) {
                            const index = cloneRequests.indexOf(findRequest);
                            findRequest.requestType = selectedRequestType;
                            cloneRequests.splice(index, 1, findRequest);
                            dispatch({ type: "setRequests", value: cloneRequests });
                        }
                        
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('requestChangeRequestTypeSuccess'), isError: false } });
                        dispatch({ "type": "setOpenSnackbar", value: true });
                    }
                    else if (status === 401) {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
                        dispatch({ "type": "setOpenSnackbar", value: true });
                    }
                    else {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
                        dispatch({ "type": "setOpenSnackbar", value: true });
                    }
        }
        finally {
            setIsSaving(false);
            setOpen(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    function validateForm() {
        return (requestTypeId && requestTypeId.length > 0);
    }

    const handleOpenRequestDetailsClick = () => {
        setOpenRequestDetailsDialog(true);
    };

    const closeRequestDetailsDialog = () => {
        setOpenRequestDetailsDialog(false);
    };

    const handleSelectRequestType = (event, value) => {
        setRequestTypeId(value ? value.id : "");
        setSelectedRequestTypes(value ? value: []);
    };
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            style={{ backgroundColor: '#fff' }}
            fullScreen>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" className={classes.title}>
                        {t('requestChangeRequestTypeTitle')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.form}
                >
                    <ErrorIcon fontSize="large" />
                    <Typography variant="h6" >
                        {t('requestChangeRequestTypeMessage1')}
                    </Typography>
                    <Typography variant="h6" >
                        {t('requestChangeRequestTypeMessage2')}
                    </Typography>

                    <Button variant="outlined" onClick={handleOpenRequestDetailsClick} >
                        {t('generalReqDetails')}
                    </Button>
                    <RequestDetailsDialog request={request} open={openRequestDetailsDialog} handleClose={closeRequestDetailsDialog} />

                    <TextField
                        disabled
                        margin="none"
                        variant="outlined"
                        fullWidth
                        id="referenceNumber"
                        label={t('existingRequestType')}
                        name="referenceNumber"
                        autoComplete="off"
                        value={request && request.requestType ? request.requestType.description: ""}
                    />

                    <Autocomplete
                        margin="none"
                        fullWidth
                        required
                        disabled={ isSaving || IsRequestHold}
                        id="select-request-type"
                        value={selectedRequestType}
                        options={requestTypes}
                        getOptionLabel={(option) => option.description ? option.description : ''}
                        getOptionDisabled={option => option.id === (request && request.requestType ? request.requestType.id : "")}
                        onChange={(event, value) => { handleSelectRequestType(event, value) }}
                        renderInput={(params) => <TextField {...params} placeholder={t('generalSelectRequestType')} label={t('newRequestType')} variant="outlined" />}
                    />
                </Grid>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#fff' }}>
                <Fab variant="extended" color="primary" aria-label="add" disabled={!validateForm() || isSaving}
                    onClick={handleSubmit} >
                    <SaveIcon className={classes.extendedIcon} />
                    {isSaving && <CircularProgress color="primary" size={25} className={classes.progress} />}
                    {t('submit')}
                </Fab>
            </DialogActions>
        </Dialog>
        )
}