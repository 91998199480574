import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { MuiThemeProvider } from '@material-ui/core';
import { baseTheme } from './themes/baseTheme';
import * as serviceWorker from './serviceWorker';
import './i18nextConf';

ReactDOM.render(
    <MuiThemeProvider theme={baseTheme}>
        <Suspense fallback={null}>
            <App />
        </Suspense>
    </MuiThemeProvider>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
