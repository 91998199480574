import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';

const SnackBarComponent = (props) => {

    const { open, info, dispatch, autoHideDuration } = props;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return null;
        }
        else {
            const newInfo = { message: "", isError: false };
            dispatch({ type: "setSnackBarInfo", value: newInfo });
            dispatch({ type: "setOpenSnackbar", value: false });
            return;
        }
    };
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
        <Snackbar open={open} autoHideDuration={autoHideDuration ? autoHideDuration : 5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={info && info.isError ? "error" : "success"}>
                {info? info.message: ""}
            </Alert>
        </Snackbar>
    );
}
export default SnackBarComponent;