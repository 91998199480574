import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import LogoRhdhv from './../../img/logo.png';
import LogoAMP from './../../img/hightechcampus.png';
import { appConfigurations } from '../../utils/configs';
require('dotenv').config()

const useStyles = makeStyles(theme => ({
    navTitle: {
        fontSize: '24px',
        color: theme.palette.rhdhvTheme1.textColor,
        flexGrow: 1,
        paddingLeft: '15px',
    },
    logo: {
        zIndex: '1000',
        width: 'auto',
        height: '35px'
    }
}));

export default function AppLogo(props) {

    const { title } = props;
    const isAmpApp = appConfigurations.isAmpApp
    const classes = useStyles();
    return (
        <React.Fragment>
            <img src={isAmpApp ? LogoAMP : LogoRhdhv} alt="logo" className={classes.logo} />
            <section className={classes.leftToolbar}>
                <div style={{ display: 'inline-flex' }}>
                    <Typography className={classes.navTitle}>{title}</Typography>
                </div>
            </section>
        </React.Fragment>
    );
}