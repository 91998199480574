import { ICanvasInnerDefaultProps } from "@mrblenny/react-flow-chart"
import styled from 'styled-components'

const CanvasInnerCustom = styled.div<ICanvasInnerDefaultProps>`
  position: relative;
  outline: 1px dashed rgba(0,0,0,0.1);
  width: 10000px;
  height: 10000px;
  cursor: move;
  flex-shrink: 0;
.react-transform-element > div {
  flex-shrink: 0;
};
` as any

export default CanvasInnerCustom;

