import * as React from "react";
import styled from 'styled-components';

const DecisionOuter = styled.div`
 padding: inherit;
    justify-content: center;
  align-items: center;
`
const DecisionInner = styled.div`
 
`
const NodeInnerCustom = ({ node, config }) => (

    <DecisionOuter>
        <div>
            <DecisionInner>
                <h5>{node.properties.name}</h5>
            </DecisionInner>
        </div>
    </DecisionOuter>
)

export default NodeInnerCustom;