import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { getData } from './../../../utils/ApiRequest';
import { getAllResponseUrl } from './../../../utils/ApiUrl';
import { GetToken } from './../../../utils/tokenActions';
import RequestResponseCard from './RequestResponseCard';

const useStyles = makeStyles(theme => ({
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        //textAlign: 'center',
        width: '100%'
    },

}));

const RequestResponse = (props) => {

    const classes = useStyles();
    let history = useHistory();
    const { selectedRequestId , currentUserRole } = props;
    const [responses, setResponses] = useState([]);
    useEffect(() => {

        if (!selectedRequestId)
            return;

        async function GetResponses() {

            setResponses([]);

            const apiURL = getAllResponseUrl(selectedRequestId);
            const response = await getData(apiURL, GetToken());
            if (response.status === 200 || response.status === 201) {
                const responseJson = await response.json();
                setResponses(responseJson);
            }
            else if (response.status === 401) {
                history.push('/unauthorized')
            }
            else {
                setResponses([]);
            }
        }

        GetResponses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRequestId])
    return (
        <div className={classes.form} style={{ backgroundColor: '#fff' }}>
            {responses && responses.length > 0 ?
                responses.map(requestAction =>
                    <RequestResponseCard key={requestAction.id}
                        requestAction={requestAction}
                        currentUserRole={currentUserRole}
                        />
                ) : <div />}
        </div>
    )
}

export default RequestResponse;