import { Collapse, Divider, Icon, List, ListItem as MuiListItem, ListItemIcon, ListItemText, SvgIcon, Tooltip, Zoom } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AddCircle as NewRequestIcon, AllInbox as AllInboxIcon, CallToAction as CallToActionIcon, Dashboard as DashboardIcon, EnhancedEncryption as EnhancedEncryptionIcon, ExpandLess, ExpandMore, PeopleAlt as PeopleAltIcon, Restore as RequestHistoryIcon, ScatterPlot as AssetTypeIcon, Settings as SettingsIcon, TrackChanges as TrackRequestIcon, VerticalSplit as ActionTaskIcon, Widgets as RequestTypeIcon } from '@material-ui/icons';
import AllListIcon from '@material-ui/icons/FlipToFront';
import CompletedListIcon from '@material-ui/icons/LibraryAddCheck';
import ActiveListIcon from '@material-ui/icons/LibraryBooks';
import PanToolIcon from '@material-ui/icons/PanTool';
import clsx from 'clsx';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { appConfigurations, appPages } from '../../utils/configs';
import { ReactComponent as SvgWorkflow } from './../../img/icons-svg/icon-flowchart-1.svg';
import { isAdmin, isManager } from './../../utils/Roles';
import { appRoute, getRequestListRoute, requestListType } from './../../utils/routeConfigs';
import { AppContext } from './../AuthenticatedPage';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(0)
  },
  listItem: {
    display: 'inline-flex',
    maxWidth: 'auto',
    width: 'auto',
  },
  listItemIcon: {
    minWidth: '40px',
  },
  listItemIconActiveLarge: {
    color: theme.palette.primary.main,
  },
  listItemIconActive: {
    color: theme.palette.primary.main,
    minWidth: '40px',
  },
  listItemText: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#3c3c3c',
    marginLeft: theme.spacing(1),
  },
  listItemTextActive: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '500',
  },
  heading: {
    padding: '10px'
  },
  nested: {
    paddingLeft: theme.spacing(2.5),
  },
  tooltip: {
    fontSize: '54px',
  },
  largeIcon: {
    '& svg': {
      fontSize: 32
    }
  },
  smallIcon: {
    '& svg': {
      fontSize: 24
    }
  },
  expended: {
    backgroundColor: "#696969",
    color: "#fff"
  },
  collapsed: {
    backgroundColor: "#DCDCDC",
    color: "#000"
  },
  test: {
    color: theme.palette.primary.main,
  },
  listItemBadge: {
    marginLeft: theme.spacing(3),
  }
}));
const ListItem = withStyles(theme => ({
  root: {
    "&$selected": {
      backgroundColor: "red",
    },
    "&$selected:hover": {
      backgroundColor: "purple",
    },
    "&:hover": {
      backgroundColor: theme.palette.rhdhvTheme1.hoverColor,
      color: theme.palette.rhdhvTheme1.hoverTextColor,
    },
  },
  selected: {}
}))(MuiListItem);

function CustomListItem(props) {
  const classes = useStyles();
  const { path, text, icon, isHeading, isSelected } = props;
  return (
    <React.Fragment>
      <ListItemIcon
        className={clsx({ [classes.largeIcon]: isHeading }, { [classes.smallIcon]: !isHeading }, { [classes.listItemIconActiveLarge]: isSelected })}>
        {icon ? icon : <Icon><img alt="titleImage" src={path} width={isHeading ? "32px" : "24px"} height={isHeading ? "32px" : "24px"} /></Icon>}
      </ListItemIcon>
      <ListItemText primary={text} />
    </React.Fragment>
  );
}

function CustomListItemNested(props) {
  const classes = useStyles();
  let history = useHistory();
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const { selectedPage } = state;
  const { page, navUrl, tooltipText, text, icon, isHeading } = props;

  const handleClickEvent = () => {
    dispatch({ "type": "setSelectedPage", value: page })
    history.push(navUrl);
  }

  return (
    <React.Fragment>
      <Tooltip TransitionComponent={Zoom} TransitionProps={{ timeout: tooltipTimeOut }} placement={tooltipPlacement} arrow title={tooltipText}>
        <ListItem button
          className={classes.nested}
          onClick={handleClickEvent}>
          {icon ? <ListItemIcon
            className={clsx(classes.listItemIcon, classes.smallIcon, { [classes.largeIcon]: isHeading }, { [classes.listItemIconActive]: selectedPage === page })}>
            {icon}
          </ListItemIcon> :
            <ListItemIcon
              className={clsx(classes.listItemIcon, classes.smallIcon, { [classes.largeIcon]: isHeading }, { [classes.listItemIconActive]: selectedPage === page })}>
              <SvgIcon
                component={SvgWorkflow}
                viewBox="0 0 500 500" />
            </ListItemIcon>}
          <ListItemText
            className={clsx(classes.listItemText, { [classes.listItemTextActive]: selectedPage === page })}
            primary={text} />
        </ListItem>
      </Tooltip>
    </React.Fragment>
  );
}

const tooltipTimeOut = 200;
const tooltipPlacement = "right";

export default function Sidemenu() {

  const classes = useStyles();
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const { selectedPage } = state;

  let history = useHistory();
  const [t] = useTranslation();

  const [openAdministration, setOpenAdministration] = React.useState(false);
  const [openRequestMgmt, setOpenRequestMgmt] = React.useState(false);
  const [openRequestHistory, setOpenRequestHistory] = React.useState(true);

  const isUserAdmin = isAdmin();
  const isUserManager = isManager();

  const handleAdministrationClick = () => {
    setOpenRequestMgmt(false);
    setOpenRequestHistory(false);
    setOpenAdministration(!openAdministration);
  };

  const handleRequestMgmtClick = () => {
    setOpenAdministration(false);
    setOpenRequestHistory(false);
    setOpenRequestMgmt(!openRequestMgmt);
  };

  const handleRequestHistoryClick = () => {
    setOpenAdministration(false);
    setOpenRequestMgmt(false);
    setOpenRequestHistory(!openRequestHistory);
  };

  const handleIconClick = (page, url) => {
    dispatch({ "type": "setSelectedPage", value: page })
    history.push(url);
  };

  return (
    <div>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}>

        {isUserManager ? <div /> :
          <Tooltip TransitionComponent={Zoom} TransitionProps={{ timeout: tooltipTimeOut }} placement={tooltipPlacement} arrow title={t('newRequest')}>
            <ListItem button className={classes.collapsed} onClick={() => handleIconClick(appPages.createRequest, appRoute.createRequest)}>
              <CustomListItem icon={<NewRequestIcon />} isHeading={true} text={t('newRequest')} page={appPages.createRequest} navUrl={appRoute.createRequest} isSelected={selectedPage === appPages.createRequest} />
            </ListItem>
          </Tooltip>}

        {(isUserAdmin || isUserManager) &&
          <Fragment>
            <Tooltip TransitionComponent={Zoom} TransitionProps={{ timeout: tooltipTimeOut }} placement={tooltipPlacement} arrow title={t('adminActions')}>
              <ListItem button onClick={handleAdministrationClick} className={openAdministration ? classes.expended : classes.collapsed}>
                <CustomListItem icon={<EnhancedEncryptionIcon />} text={t('administration')} isHeading={true} />
                {openAdministration ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            </Tooltip>
            <Collapse in={openAdministration} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <CustomListItemNested icon={<PeopleAltIcon />} text={t('users')} tooltipText={t('userManagegementActions')} page={appPages.users} navUrl={appRoute.userList} />
                <CustomListItemNested icon={<CallToActionIcon />} text={t('userRoles')} tooltipText={t('rolesToUsers')} page={appPages.userRoles} navUrl={appRoute.userRoleList} />
                <CustomListItemNested icon={<AssetTypeIcon />} text={t('assetTypes')} tooltipText={t('assetTypes')} page={appPages.assetTypes} navUrl={appRoute.assetTypes} />
                {!isUserAdmin ? <div /> :
                  <div>
                    <CustomListItemNested icon={<AllInboxIcon />} text={t('emailFormats')} tooltipText={t('editEmailFormats')} page={appPages.formats} navUrl={appRoute.formats} />
                    <CustomListItemNested icon={<SettingsIcon />} text={t('configurations')} tooltipText={t('appConfigurations')} page={appPages.configurations} />
                  </div>}
              </List>
            </Collapse>
          </Fragment>}

        {(isUserAdmin || isUserManager) &&
          <Fragment>
            <Tooltip TransitionComponent={Zoom} TransitionProps={{ timeout: tooltipTimeOut }} placement={tooltipPlacement} arrow title={t('requestActions')}>
              <ListItem button onClick={handleRequestMgmtClick} className={openRequestMgmt ? classes.expended : classes.collapsed}>
                <CustomListItem icon={<RequestTypeIcon />} text={t('requestManagement')} isHeading={true} />
                {openRequestMgmt ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            </Tooltip>

            <Collapse in={openRequestMgmt} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <CustomListItemNested type="admin" path={SvgWorkflow} text={t('workflows')} tooltipText={t('workWithWorkflows')} page={appPages.workflows} navUrl={appRoute.workflows} />
                <CustomListItemNested icon={<RequestTypeIcon />} text={t('requestTypes')} tooltipText={t('requestTypeDetails')} page={appPages.requestTypes} navUrl={appRoute.requestTypes} />
                <CustomListItemNested icon={<ActionTaskIcon />} text={t('actionTasks')} tooltipText={t('actionRelatedTasks')} page={appPages.actiontasks} navUrl={appRoute.actionTasks} />
              </List>
            </Collapse>
          </Fragment>}

        <Tooltip TransitionComponent={Zoom} TransitionProps={{ timeout: tooltipTimeOut }} placement={tooltipPlacement} arrow title={t('viewPreviousRequests')}>
          <ListItem button onClick={handleRequestHistoryClick} className={openRequestHistory ? classes.expended : classes.collapsed}>
            <CustomListItem icon={<RequestHistoryIcon />} text={t('requestHistory')} isHeading={true} />
            {openRequestHistory ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        </Tooltip>

        <Collapse in={openRequestHistory} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <CustomListItemNested icon={<AllListIcon />} text={t('allRequests')} tooltipText={t('viewAllRequests')} page={appPages.requests} navUrl={getRequestListRoute(requestListType.all)} />
            <CustomListItemNested icon={<ActiveListIcon />} text={t('active')} tooltipText={t('viewActiveRequests')} page={appPages.requestsActive} navUrl={getRequestListRoute(requestListType.active)} />
            <CustomListItemNested icon={<PanToolIcon />} text={t('hold')} tooltipText={t('viewHoldRequests')} page={appPages.requestsHold} navUrl={getRequestListRoute(requestListType.hold)} />
            <CustomListItemNested icon={<ThumbUpAltIcon />} text={t('approved')} tooltipText={t('viewApprovedRequests')} page={appPages.requestsApproved} navUrl={getRequestListRoute(requestListType.approved)} />
            <CustomListItemNested icon={<RemoveCircleOutlineIcon />} text={t('approactiveExpiredved')} tooltipText={t('viewActiveExpiredRequests')} page={appPages.requestsActiveExpired} navUrl={getRequestListRoute(requestListType.active_expired)} />
           {!appConfigurations.isAmpApp &&  <CustomListItemNested icon={<NotInterestedIcon />} text={t('activeExpiredAsBuilt')} tooltipText={t('viewActiveExpiredAsBuiltRequests')} page={appPages.requestsActiveExpiredAsBuilt} navUrl={getRequestListRoute(requestListType.active_expired_AsBuilt)} />} 
            <CustomListItemNested icon={<CompletedListIcon />} text={t('closed')} tooltipText={t('viewClosedRequests')} page={appPages.requestsCompleted} navUrl={getRequestListRoute(requestListType.complete)} />
            <CustomListItemNested icon={<TrackRequestIcon />} text={t('requestTracking')} tooltipText={t('trackRequest')} page={appPages.requestTrack} navUrl={appRoute.trackRequest} />
          </List>
        </Collapse>

        {!appConfigurations.isAmpApp &&
          <>
            <Divider />
            <Tooltip TransitionComponent={Zoom} TransitionProps={{ timeout: tooltipTimeOut }} placement={tooltipPlacement} arrow title={t('viewDashboard')}>
              <ListItem button className={classes.collapsed} onClick={() => handleIconClick(appPages.dashboard, appRoute.dashboard)}>
                <CustomListItem icon={<DashboardIcon />} text={t('dashboard')} isHeading={true} page={appPages.dashboard} navUrl={appRoute.dashboard} isSelected={selectedPage === appPages.dashboard} />
              </ListItem>
            </Tooltip>
            <Divider />
          </>}
      </List>
    </div>
  );
}
