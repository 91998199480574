const appConfigs = () => {
  return {
    title: process.env.REACT_APP_TITLE,
    authUrl: process.env.REACT_APP_AUTH_URL,
    backendUrl: process.env.REACT_APP_BACKEND_URL,
    safetyPermitsUrl: process.env.REACT_APP_SAFETY_PERMITS_URL,
    isAmpApp: process.env.REACT_APP_AMP_APPLICATION === 'true' ? true : false,
    useMFA: process.env.REACT_APP_USE_MFA === 'true' ? true : false,
  }
}
export const appConfigurations = appConfigs();

export const statusConfigs = {
  Pending: "Pending",
  Active: "Active",
  Inactive: "Inactive",
  Deleted: "Deleted",
  Assigned: "Assigned",
  Seen: "Seen",
  Approved: "Approved",
  Finished: "Finished",
  Rejected: "Rejected",
  Closed: "Closed",
  Hold: "Hold",
  Skipped: "Skipped",
  Expired: "Expired",
  ActiveExpired: "ActiveExpired",
  ActiveExpiredAsBuilt: "ActiveExpiredAsBuilt"
}

export const appPages = {
  createRequest: "createRequest",
  users: "users",
  userRoles: "userroles",
  assetTypes: "assettypes",
  formats: "formats",
  configurations: "configurations",
  workflows: "workflows",
  requestTypes: "requesttypes",
  actiontasks: "actiontasks",
  requests: "requests",
  requestsActive: "requestsActive",
  requestsHold: "requestsHold",
  requestsClosed: "requestsClosed",
  requestsApproved: "requestsApproved",
  requestsActiveExpired: "requestsActiveExpired",
  requestsActiveExpiredAsBuilt: "requestsActiveExpiredAsBuilt",
  requestTrack: "requestTrack",
  dashboard: "dashboard",
}

export const dataFetchKeys = {
  allRequests: "allRequests",
  notifications: "notifications",
  workflows: "workflows",
  requestTypes: "requestTypes",
  usersForActionTransfer: "usersForActionTransfer",
  users: "users",
  roles: "roles",
  usersWithRoles: "usersWithRoles",
  userRole: "userRole",
  checkListCategories: "checkListCategories",
  requestCheckList: "requestCheckList",
  requestAttachments: "requestAttachments",
}

export const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export const queryOptionsRefetchOnMount = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
}

export const queryOptionsDisabled = {
  enabled: false
}

export const queryOptionsEnabled = (enabled = true) => {
  return {
    enabled: enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  }
};