import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginLeft: theme.spacing(10),
    },
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: 400,
        justifyContent: 'center',
    },
}));

export default function UnauthorizedPage() {
    const classes = useStyles();
    const [t] = useTranslation();
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Typography variant="h4" gutterBottom>
                        {t('unauthorizedAccess')}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}