import { makeStyles } from '@material-ui/core/styles'
import ToggleButton from '@material-ui/lab/ToggleButton'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
    progress: {
        marginLeft: theme.spacing(1),
    },
    toggleButton: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        height: theme.spacing(4),
        backgroundColor: '#fff',
        color: '#000',
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        }
    }
}))

export default function AssetTypeButton(props) {
    const classes = useStyles()
    const { assetType, handleSelectAssetType, selectedAssetTypeIds, isRequestOnHold } = props;
    const [exists, setExists] = useState(false);

    useEffect(() => {

        const existing = selectedAssetTypeIds.find(a => a === assetType.id);
        setExists(existing ? true : false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAssetTypeIds])

    return (
        <ToggleButton
            disabled={isRequestOnHold}
            value={assetType.id}
            onClick={() => handleSelectAssetType(assetType.id)}
            color="primary"
            className={classes.toggleButton}
            selected={exists}
        >
            {assetType.description}
        </ToggleButton>
    )
}
