import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ConfirmationDialog(props) {

    const [t] = useTranslation();
    const { openDialog, handleCloseDialog ,handleGoBack } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">{t('generalLeaveWithoutSave')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('generalLeaveWithoutSaveConfirm')}
                 </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleGoBack} color="secondary" variant="outlined">
                    {t('generalGoBack')}
                        </Button>
                <Button autoFocus onClick={handleCloseDialog} color="primary" variant="contained" >
                    {t('generalNoStay')}
                        </Button>
            </DialogActions>
        </Dialog>
    );
}