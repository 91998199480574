import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AssignmentInd as AssignmentPersonIcon, AvTimer as StatusIcon, CheckCircle as DoneIcon, EventNote as CalanderIcon, NewReleases as AssignedIcon, WatchLater as PendingIcon } from '@material-ui/icons';
import React from 'react';
import SkipIcon from '@material-ui/icons/ExitToApp';
import { appStatuses, statusColors } from '../../../utils/AppConstants';
import clsx from 'clsx';
import PanToolIcon from '@material-ui/icons/PanTool';
import BlockIcon from '@material-ui/icons/Block';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const useStyles = makeStyles((theme) => ({

    finished: {
        backgroundColor: '#fff',
        color: statusColors.finished,
    },
    rejected: {
        backgroundColor: '#fff',
        color: statusColors.rejected,
    },
    assigned: {
        backgroundColor: '#fff',
        color: statusColors.assigned,
    },
    pending: {
        backgroundColor: '#fff',
        color: statusColors.pending,
    },
    skipped: {
        backgroundColor: '#fff',
        color: statusColors.skipped,
    },
    hold: {
        backgroundColor: '#fff',
        color: statusColors.hold,
    },
    primaryText: {
        fontSize: theme.typography.pxToRem(20),
    },
    primaryCardText: {
        fontSize: theme.typography.pxToRem(18),
    },
    secondaryText: {
        color: '#fff',
    },
    secondaryCardText: {
        fontSize: theme.typography.pxToRem(15),
        color: '#000',
    }
}));

function getAvatar(avatarText) {
    switch (avatarText) {
        case "status":
            return <StatusIcon />;

        case appStatuses.assigned:
        case appStatuses.active:
            return <AssignedIcon />;

        case appStatuses.approved:
        case appStatuses.finished:
            return <DoneIcon />;

        case appStatuses.pending:
            return <PendingIcon />;

        case appStatuses.skipped:
            return <SkipIcon />;

        case appStatuses.hold:
            return <PanToolIcon />;

        case appStatuses.notRequired:
            return <BlockIcon />;

        case appStatuses.rejected:
            return <ThumbDownIcon />;

        case appStatuses.expired:
            return <RemoveCircleIcon />;

        case "calander":
            return <CalanderIcon />;

        default:
            return <AssignmentPersonIcon />;
    }
}

export default function RequestTrackListItem(props) {
    const classes = useStyles();
    const { requestAction, avatar, primaryText, secondaryText, isCard } = props;

    return (
        <ListItem disableGutters={true} style={{ padding: 0, margin: 0 }}>
            <ListItemAvatar>
                <Avatar
                    className={clsx({
                        [classes.assigned]: [appStatuses.assigned, appStatuses.active].includes(requestAction.status),
                        [classes.finished]: [appStatuses.finished, appStatuses.approved].includes(requestAction.status),
                        [classes.skipped]: [appStatuses.skipped, appStatuses.notRequired].includes(requestAction.status),
                        [classes.rejected]: [appStatuses.rejected, appStatuses.expired].includes(requestAction.status),
                        [classes.hold]: requestAction.status === appStatuses.hold,
                        [classes.pending]: requestAction.status === appStatuses.pending,
                    })}>
                    {getAvatar(avatar)}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={<Typography className={isCard ? classes.primaryCardText : classes.primaryText}>{primaryText}</Typography>}
                secondary={<Typography className={isCard ? classes.secondaryCardText: classes.secondaryText}>{secondaryText}</Typography>} />
        </ListItem>
    );
}