import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getData } from './../../../utils/ApiRequest';
import { getAllAssetTypeURL } from './../../../utils/ApiUrl';
import { appStatuses, userActions } from './../../../utils/AppConstants';
import { isAdmin } from './../../../utils/Roles';
import { GetToken } from './../../../utils/tokenActions';
import tableIcons from './../../tableIcons';
import AssetTypeDialog from './AssetTypeDialog';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginLeft: theme.spacing(10),
        marginTop: theme.spacing(10),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(10),
    },
    container: {
        maxHeight: 440,
    },
    formControl: {
        fullWidth: true,
        display: 'flex'
    },
    dialog: {
        //backgroundColor: '#F5F7FA !important'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        textAlign: 'center',
        width: '100%'
    },
}));

export default function AssetTypePage() {

    const [t] = useTranslation();
    const classes = useStyles();
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [assetTypes, setAssetTypes] = React.useState([]);
    const [selectedAssetType, setSelectedAssetType] = useState(null);
    const [openAssetTypeDialog, setOpenAssetTypeDialog] = React.useState(false);
    const [assetTypeAction, setAssetTypeAction] = React.useState('');
    const isUserAdmin = isAdmin();

    async function handleAdd() {
        setSelectedAssetType(null);
        setAssetTypeAction("");
        setOpenAssetTypeDialog(true);
    };

    async function handleEdit(event, rowData) {
        setSelectedAssetType(rowData);
        setAssetTypeAction("");
        setOpenAssetTypeDialog(true);
    };

    async function handleAction(event, rowData, userAction) {
        setSelectedAssetType(rowData);
        setAssetTypeAction(userAction);
        setOpenAssetTypeDialog(true);
    };

    useEffect(() => {

        //Get session token
        async function GetAssetTypes() {
            const apiURL = getAllAssetTypeURL();
            const response = await getData(apiURL, GetToken());
            if (response.status === 200 || response.status === 201) {
                const response_json = await response.json();
                setAssetTypes(response_json);
            }
            else {
                setAssetTypes([]);
            }
        }

        GetAssetTypes();
    }, []);
    return (
        <Fragment>

            <Paper className={classes.root}>
                <MaterialTable
                    title={t('generalAssetType')}
                    columns={[
                        { title: t('generalDescription'), field: 'description' },
                        { title: t('generalRemarks'), field: 'remarks' },
                    ]}
                    data={assetTypes}
                    icons={tableIcons}
                    detailPanel={[
                        {
                            icon: tableIcons.Asset,
                            openIcon: tableIcons.Asset,
                            render: () => { return (<div />) },
                        },
                    ]}
                    actions={isUserAdmin ? [
                        {
                            icon: tableIcons.Add,
                            tooltip: t('pageAssetTypeNew'),
                            position: "toolbar",
                            onClick: () => handleAdd(),
                        },
                        {
                            icon: tableIcons.Edit,
                            tooltip: t('pageAssetTypeEdit'),
                            onClick: (event, rowData) => handleEdit(event, rowData),

                        },
                        rowData => ({
                            icon: (rowData.status_desc === appStatuses.active ? tableIcons.Delete : tableIcons.Activate),
                            tooltip: (rowData.status_desc === appStatuses.active ? t('generalDeactivate') : t('generalActivate')),
                            onClick: (event, rowData) => handleAction(event, rowData, rowData.status_desc === appStatuses.active ? userActions.deactivate : userActions.activate),
                        }),
                    ] : []}
                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                    options={{
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF'
                        },
                        rowStyle: rowData => ({
                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                        }),
                        exportButton: true,
                        actionsColumnIndex: 100,
                        pageSize: 10,
                    }}
                />


            </Paper>

            <AssetTypeDialog
                assetType={selectedAssetType} setAssetType={setSelectedAssetType}
                assetTypes={assetTypes} setAssetTypes={setAssetTypes}
                action={assetTypeAction}
                open={openAssetTypeDialog} setOpen={setOpenAssetTypeDialog} />
        </Fragment>
    );
}