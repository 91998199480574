import React, { Fragment, useContext, useEffect,useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import i18n from '../../../i18nextConf';
import { postData } from '../../../utils/ApiRequest';
import { requestAttachementDownloadUrl } from '../../../utils/ApiUrl';
import { appRoute } from '../../../utils/routeConfigs';
import { AppContext } from './../../AuthenticatedPage';
import BackDrop from '../../Common/BackDrop';

export default function DownloadPage() {

    let history = useHistory();
    const { fileId } = useParams();
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { authToken } = state;
    const [isBusy, setIsBusy] = useState(false);
    
    useEffect(() => {

        const handleDownloadDocument = async (fileId) => {
            
            if (!fileId) { return; }
            try{
                setIsBusy(true);
                const downloadUrl = requestAttachementDownloadUrl(fileId);
                const response = await postData(downloadUrl, null, authToken);
                if (response?.status === 201) {
    
                    const headerFilename = response.headers.get('Content-Disposition')?.split('filename=');
                    const filename = !headerFilename || headerFilename.length < 2 ? "" : headerFilename[1].replace(/['"]+/g, '');
    
                    const blob = await response.blob();
                    // Create blob link to download
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement("a");
                    link.href = url;
    
                    if (filename) {
                        link.setAttribute("download", filename);
                    }
    
                    // Append to html link element page
                    document.body.appendChild(link);
    
                    // Start download
                    link.click();
    
                     //Clean up and remove the link
                    if (link && link.parentNode) {
                        link.parentNode.removeChild(link);
                    }
    
                    dispatch({ "type": "setSnackBarInfo", value: { message: i18n.t('generalDownloaded'), isError: false } })
                    dispatch({ "type": "setOpenSnackbar", value: true })
                    history.push(appRoute.root);
                }
                else {
                    dispatch({ "type": "setSnackBarInfo", value: { message: i18n.t('generalErrorDownload'), isError: true } })
                    dispatch({ "type": "setOpenSnackbar", value: true })
                }
            }
            finally{
                setIsBusy(false);
                
            }
        
        }

        handleDownloadDocument(fileId);
    }, [fileId, authToken, history, dispatch])

    return (<><Fragment/><BackDrop loading={isBusy}/></>);
}