import { InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 250,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        '& svg': {
            color: theme.palette.grey[400]
        }
    },
}));

export default function SearchTextBox(props) {

    const [t] = useTranslation();
    const classes = useStyles();
    const { handleChange } = props;

    return (
        <TextField
            id="search-request"
            className={classes.root}
            variant="outlined"
            size="small"
            placeholder={t('gen_Search')}
            onChange={(e) => handleChange(e.target.value)}
            autoComplete="off"
            InputProps={{
                startAdornment:
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>,
            }}
        />
    );
};