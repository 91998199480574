import { Grid, InputAdornment, SwipeableDrawer, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { statusConfigs } from '../../../utils/configs';
import { AppContext } from './../../AuthenticatedPage';
import CollapseNotifyHeading from './CollapseNotifyHeading';
import CollapseNotifyItems from './CollapseNotifyItems';

const positionConfigs = {
    drawerWidth: '30%',
};

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: positionConfigs.drawerWidth,
        },
        '& .drawer-title': {
            margin: theme.spacing(1),
            marginLeft: theme.spacing(2),
        }
    },
    searchDiv: {
        marginLeft: theme.spacing(4),
    },
    searchTextBox: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
        '& svg': {
            color: 'gray'
        }
    },
}));

export default function DrawerNotifications() {

    
    const [t] = useTranslation();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { notifications, openNotificationDrawer } = state;

    const [activeItems, setActiveItems] = useState([]);
    const [finishedItems, setFinishedItems] = useState([]);
    const [filteredActiveItems, setFilteredActiveItems] = useState([]);
    const [filteredFinishedItems, setFilteredFinishedItems] = useState([]);

    const [expandedActive, setExpandedActive] = useState(true);
    const [expandedPrevious, setExpandedPrevious] = useState(false);
    const [searchedText, setSearchedText] = useState('');
    
    useEffect(() => {
        const activeNotifications = notifications.filter(n => n.status && n.status.description_static === statusConfigs.Active);
        const finishedNotifications = notifications.filter(n => n.status && n.status.description_static === statusConfigs.Finished);
        setActiveItems(activeNotifications);
        setFilteredActiveItems(activeNotifications);
        setFinishedItems(finishedNotifications);
        setFilteredFinishedItems(finishedNotifications);

    }, [notifications])

    const handleSearch = (e) => {

        setSearchedText(e.target.value);
        const textToSearch = e.target.value.toLowerCase();

        if (!e.target.value) {
            setFilteredActiveItems([...activeItems]);
            return setFilteredFinishedItems([...finishedItems]);
        }

        const searchedActiveItems = activeItems.filter((item) =>
            item.request.request_number.toLowerCase().indexOf(textToSearch) > -1 ||
            item.request.reference_number.toLowerCase().indexOf(textToSearch) > -1 ||
            item.request.motivation.toLowerCase().indexOf(textToSearch) > -1 ||
            item.request.description.toLowerCase().indexOf(textToSearch) > -1);
        setFilteredActiveItems(searchedActiveItems);

        const searchedFinishedItems = finishedItems.filter((item) =>
            item.request.request_number.toLowerCase().indexOf(textToSearch) > -1 ||
            item.request.reference_number.toLowerCase().indexOf(textToSearch) > -1 ||
            item.request.motivation.toLowerCase().indexOf(textToSearch) > -1 ||
            item.request.description.toLowerCase().indexOf(textToSearch) > -1);
        setFilteredFinishedItems(searchedFinishedItems);
    };

    const onOpen = () => {}
    const handleClose = () => {
        dispatch({ "type": "setOpenNotificationDrawer", value: false });
    }

    return (
        <SwipeableDrawer
            className={classes.root}
            anchor={"right"}
            open={openNotificationDrawer}
            onOpen={onOpen}
            onClose={handleClose}>

            <Typography variant="h5" color="primary" className="drawer-title">
                {t('generalNotifications')}
            </Typography>

            <Grid className={classes.searchDiv}>
                <Grid item xs={11}>
                    <TextField
                        size="small"
                        margin="none"
                        fullWidth
                        id="description"
                        name="description"
                        autoComplete="off"
                        value={searchedText}
                        label={t('gen_Search')}
                        placeholder={t('gen_TypeHere')}
                        helperText={t('searchRequestHelperText')}
                        onChange={handleSearch}
                        className={classes.searchTextBox}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <CollapseNotifyHeading expanded={expandedActive} setExpanded={setExpandedActive} isActiveNotification={true} />
                    <CollapseNotifyItems expanded={expandedActive} items={filteredActiveItems} isFinished={false} />
                </Grid>
                <Grid item xs={12}>
                    <CollapseNotifyHeading expanded={expandedPrevious} setExpanded={setExpandedPrevious} isActiveNotification={false} />
                    <CollapseNotifyItems expanded={expandedPrevious} items={filteredFinishedItems} isFinished={true} />
                </Grid>
            </Grid>
        </SwipeableDrawer>
    );
}