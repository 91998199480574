import {CircularProgress, Dialog, DialogActions, DialogContent, Fab, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save as SaveIcon } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogAppBar from '../../Common/DialogAppBar';
import { putData } from './../../../utils/ApiRequest';
import { updateUserURL } from './../../../utils/ApiUrl';
import { userValidation } from './../../../utils/AppConstants';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext } from './../../AuthenticatedPage';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0, 3),
        marginTop: theme.spacing(3),
        '& .MuiGrid-container': {
            '& h3': {
                marginBottom: theme.spacing(2),
            },
            '& .MuiGrid-item': {
                width: '40%'
            },
            '& .MuiTextField-root, .MuiAutocomplete-root': {
                marginBottom: theme.spacing(2),
            },
        },
        '& .MuiSwitch-root': {
            marginLeft: theme.spacing(2),
        },

        '& .Mui-disabled': {
            color: 'grey'
        },
    },
    submitButton: {
            marginRight: theme.spacing(1),
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function DialogEditUser(props) {

    const [t] = useTranslation();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { users } = state;

    const { open, setOpen, user } = props;
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [isBusy, setIsBusy] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [companyNameError, setCompanyNameError] = useState(false);

    useEffect(() => {
        setFirstNameError(user.firstName ? false : true);
        setLastNameError(user.lastName ? false : true);
        setPhoneNumberError(user.phone_number ? false : true);
        setEmail(user.email || "");
        setFirstName(user.firstName || "");
        setLastName(user.lastName || "");
        setPhoneNumber(user.phone_number || "");
        setCompanyName(user.company_name || "");
    }, [user])

    const handleClose = () => {
        setOpen(false);
    };

    async function handleSubmit(event) {
        event.preventDefault(); 
        try{
            setIsBusy(true)
            const data =
            {
                "firstName": firstName,
                "lastName": lastName,
                "phone_number": phoneNumber,
                "company_name": companyName,
            }
           const response =  await putData(updateUserURL(user.id), data, GetToken());
               const status = response.status;
               if (status === 200 || status === 201) {
                   if (users) {
                       const editedUser = await response.json();
                       const objIndex = users.findIndex(e => e.id === user.id);
                       let newArray = [...users];
                       newArray[objIndex] = {
                           ...newArray[objIndex],
                           firstName: editedUser.firstName,
                           lastName: editedUser.lastName,
                           phone_number: editedUser.phone_number,
                           company_name: editedUser.company_name
                       }
                       dispatch({ "type": "setUsers", value: newArray });
                   }

                   dispatch({ "type": "setSnackBarInfo", value: { message: `${t('dialogUsersUser')} '${firstName} ${lastName}' ${t('dialogUsersUserUpdated')}`, isError: false } })
                   dispatch({ "type": "setOpenSnackbar", value: true })
               }
               else if (status === 401) {
                   dispatch({ "type": "setSnackBarInfo", value: { message: t('dialogUsersSessionExpired'), isError: true } })
                   dispatch({ "type": "setOpenSnackbar", value: true })
               }
               else {
                   dispatch({ "type": "setSnackBarInfo", value: { message: t('dialogUsersSaveError'), isError: true } })
                   dispatch({ "type": "setOpenSnackbar", value: true })
               }
        }
        finally{
            setOpen(false);
            setIsBusy(false);
        }
    };

    const isValidFirstName = (value) => {
        return value.length >= userValidation.firstNameMin && value.length <= userValidation.firstNameMax;
    }

    const isValidLastName = (value) => {
        return value.length >= userValidation.lastNameMin && value.length <= userValidation.lastNameMax;
    }

    const isValidPhoneNumber = (value) => {
        return value.length >= userValidation.phoneMin && value.length <= userValidation.phoneMax;
    }

    const isValidCompanyName = (value) => {
        return value.length >= userValidation.companyMin && value.length <= userValidation.companyMax;
    }

    function validateForm() {
        return isValidFirstName(firstName) &&
            isValidLastName(lastName) &&
            isValidPhoneNumber(phoneNumber) &&
            isValidCompanyName(companyName);
    }

    const handleFirstNameChange = (e) => {
        const newVal = e.target.value;
        setFirstName(newVal);
        setFirstNameError(!isValidFirstName(newVal));
    };

    const handleLastNameChange = (e) => {
        const newVal = e.target.value;
        setLastName(newVal);
        setLastNameError(!isValidLastName(newVal));
    };

    const handlePhoneNumberChange = (e) => {
        const newVal = e.target.value;
        setPhoneNumber(newVal);
        setPhoneNumberError(!isValidPhoneNumber(newVal));
    };

    const handleCompanyNameChange = (e) => {
        const newVal = e.target.value;
        setCompanyName(newVal);
        setCompanyNameError(!isValidCompanyName(newVal));
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullScreen>
            <DialogAppBar setOpen={setOpen} title={t('dialogUsersEditUser')} />
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div className={classes.root}>
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                        <Grid item>
                            <Typography variant="h5" style={{ fontSize: 18, marginBottom: "20px" }}>
                                {t('dialogUsersEditDetails')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                disabled
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="off"
                                value={email}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="firstName"
                                label={t('dialogUsersFirstName')}
                                name="firstName"
                                autoComplete="off"
                                value={firstName}
                                error={firstNameError}
                                disabled={isBusy}
                                helperText={firstNameError ? t('firstNameError', { max_chars: `${userValidation.firstNameMin}-${userValidation.firstNameMax}` }) : ""}
                                onChange={handleFirstNameChange}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label={t('dialogUsersLastName')}
                                name="lastName"
                                autoComplete="off"
                                disabled={isBusy}
                                value={lastName}
                                error={lastNameError}
                                helperText={lastNameError ? t('lastNameError', { max_chars: `${userValidation.lastNameMin}-${userValidation.lastNameMax}` }) : ""}
                                onChange={handleLastNameChange}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="phoneNumber"
                                label={t('dialogUsersPhone')}
                                name="phoneNumber"
                                autoComplete="off"
                                disabled={isBusy}
                                value={phoneNumber}
                                error={phoneNumberError}
                                helperText={phoneNumberError ? t('phoneNumberError', { max_chars: `${userValidation.phoneMin}-${userValidation.phoneMax}` }) : ""}
                                onChange={handlePhoneNumberChange}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                id="company_name"
                                label={t('dialogUsersCompany')}
                                name="company_name"
                                autoComplete="off"
                                disabled={isBusy}
                                value={companyName}
                                error={companyNameError}
                                helperText={companyNameError ? t('companyNameError', { max_chars: `${userValidation.companyMin}-${userValidation.companyMax}` }) : ""}
                                onChange={handleCompanyNameChange}
                            />
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#fff' }}>
                <Fab variant="extended" color="primary" aria-label="add"
                    onClick={handleSubmit} disabled={!validateForm() || isBusy}>
                         {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />  }
                    <SaveIcon className={classes.submitButton}/>
                    {t('dialogUsersSaveUser')}
                </Fab>
            </DialogActions>
        </Dialog>
    )
}