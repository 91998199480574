import { languages } from './../utils/AppConstants';

export const initialAnonymousAppState = {
    selectedLanguage: languages.en,
    openSnackbar: false,
    snackBarInfo: null
}

export function anonymousAppReducer(state, action) {
    switch(action.type){
        case "setSelectedLanguage":{
            const newState = { ...state, selectedLanguage: action.value};
            return newState;
        }
        case "setOpenSnackbar": {
            const newState = { ...state, openSnackbar: action.value };
            return newState;
        }
        case "setSnackBarInfo": {
            const newState = { ...state, snackBarInfo: action.value };
            return newState;
        }
        default:
        return state;
    }
}