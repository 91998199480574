import { IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomListItem from './../Formats/CustomListItem';

const useStyles = makeStyles(theme => ({
    heading: {
        '& .MuiListItemText-primary': {
            fontSize: 20,
            color: theme.palette.primary.main,
            padding: 0,
            margin: theme.spacing(1)
        }
    },
}));

export default function CollapseNotifyHeading(props) {

    const [t] = useTranslation();
    const classes = useStyles();
    const { expanded, setExpanded, isActiveNotification } = props;

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <CustomListItem button onClick={handleExpandClick} className={classes.heading}>
            <ListItemIcon>
                <IconButton edge="start" aria-label="comments">
                    {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </ListItemIcon>
            <ListItemText primary={t(isActiveNotification ? 'pageNotificationActiveNotifications' : 'pageNotificationPreviousNotifications')} />
        </CustomListItem>
    );
}