import { Button, Checkbox, Fab, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import ReleaseIcon from '@material-ui/icons/AssignmentTurnedIn';
import PanToolIcon from '@material-ui/icons/PanTool';
import SaveIcon from '@material-ui/icons/Save';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { appConfigurations, dataFetchKeys, statusConfigs } from '../../../utils/configs';
import { appRoute } from '../../../utils/routeConfigs';
import BackDrop from '../../Common/BackDrop';
import ActionFinishedPage from '../ActionFinishedPage';
import UnauthorizedPage from '../UnauthorizedPage';
import { getData, postData } from './../../../utils/ApiRequest';
import { getSingleNotificationUrl, getWorkflowAssetTypeUrl, linkAssetTypeRequestURL } from './../../../utils/ApiUrl';
import { appStatuses, languages, requestValidation } from './../../../utils/AppConstants';
import { AppContext, queryClient } from './../../AuthenticatedPage';
import ConfirmationDialog from './../../Common/ConfirmationDialog';
import RequestDetailsDialog from './../../pages/Requests/RequestDetailsDialog';
import AssetTypeButton from './AssetTypeButton';
import DialogHoldRequest from './DialogHoldRequest';
import DialogReleaseHoldRequest from './DialogReleaseHoldRequest';

const useStyles = makeStyles(theme => ({

    root: {
        flexGrow: 1,
        overflow: 'hidden',
        padding: theme.spacing(0, 3),
        marginLeft: theme.spacing(10),
        marginTop: theme.spacing(10),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(10),
    },
    paper: {
        maxWidth: 400,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
    heading: {
        textAlign: 'center',
        marginBottom: theme.spacing(3),
    },
    formControl: {
        fullWidth: true,
        display: 'flex'
    },
    submit: {
        marginLeft: theme.spacing(2),
    },
    filter: {
        maxWidth: '650px'
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        textAlign: 'center',
        width: '100%'
    },
    footerDiv: {
        position: 'fixed',
        bottom: theme.spacing(10),
        marginLeft: theme.spacing(-4),
        paddingRight: theme.spacing(10),
    },
    button: {
        marginLeft: theme.spacing(1),
    },
    buttonHold: {
        marginLeft: theme.spacing(1),
        backgroundColor: theme.palette.warning.main,
    },
    buttonRelease: {
        marginLeft: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    assetTypeSwitcher: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    infoText: {
        fontSize: '1.0rem',
        color: theme.palette.warning.main,
        marginBottom: theme.spacing(1),
    }
}));

export default function AssignAssetTypePage(props) {

    const [t] = useTranslation();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { selectedRequest, selectedRequestActionId, selectedLanguage, authToken } = state;

    let history = useHistory();
    const { notificationId } = useParams();

    const [openRequestDetailsDialog, setOpenRequestDetailsDialog] = React.useState(false);
    const [openRequestHoldDialog, setOpenRequestHoldDialog] = React.useState(false);
    const [openRequestReleaseDialog, setOpenRequestReleaseDialog] = React.useState(false);
    const [selectedAssetTypeIds, setSelectedAssetTypeIds] = useState([]);
    const [remarks, setRemarks] = useState("")
    const [assetTypes, setAssetTypes] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [requestActionId, setRequestActionId] = useState("")
    const [request, setRequest] = useState(null);
    const [workflowId, setWorkflowId] = useState('');
    const [hasError, setHasError] = React.useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [actionStatus, setActionStatus] = useState("");
    const [loading, setLoading] = React.useState(true);
    const isFinished = actionStatus && (actionStatus === appStatuses.approved || actionStatus !== appStatuses.assigned);
    const isRequestOnHold = request?.status?.description_static === statusConfigs.Hold;
    const [isChangesExpected, setIsChangesExpected] = React.useState(false);
    const isAmpApp =  appConfigurations.isAmpApp;
    
    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleClickHoldDialog = () => {
        setOpenRequestHoldDialog(true);
    };

    const handleClickReleaseDialog = () => {
        setOpenRequestReleaseDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        history.goBack();
    };

    useEffect(() => {
        if (notificationId) {
            async function getNotificationData() {
                setHasError(false)
                const response = await getData(getSingleNotificationUrl(notificationId), authToken);
                const status = response.status
                if (status === 200 || status === 201) {
                    const response_json = await response.json()

                    setActionStatus(response_json.request_action_status.description_static)
                    setRequest(response_json.request)
                    setRequestActionId(response_json.requestActionId)
                    setWorkflowId(response_json.request.workflow.id)
                    setLoading(true);

                }
                else if (status === 401) {
                    setHasError(true);
                    setLoading(false);
                    dispatch({ "type": "setSnackBarInfo", value: { message: t('User Unauthorized'), isError: true } })
                    dispatch({ "type": "setOpenSnackbar", value: true })
                }
            }
            getNotificationData()
        }
        else if (selectedRequest && selectedRequestActionId) {
            setRequest(selectedRequest)
            setRequestActionId(selectedRequestActionId)
            setActionStatus(appStatuses.assigned)
            setLoading(false);
        }
        else if (!request) {
            history.push(appRoute.home)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRequest, notificationId])

    useEffect(() => {

        if (!request)
            return;

        async function GetAssetTypes() {
            try {
                setLoading(true);
                const apiURL = getWorkflowAssetTypeUrl(request === null ? workflowId : request.workflow.id);
                const response = await getData(apiURL, authToken);
                if (response.status === 200 || response.status === 201) {
                    const response_json = await response.json();
                    setAssetTypes(response_json);
                }
                else {
                    setAssetTypes([]);
                }
            }
            finally {
                setLoading(false);
            }
        }
        GetAssetTypes();
        setIsChangesExpected(request && request.isChangesExpected)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request]);

    function validateForm() {
        return selectedAssetTypeIds && selectedAssetTypeIds.length > 0
            && remarks && remarks.length <= requestValidation.remarksMax;
    }

    async function handleSubmit(event) {
        
        event.preventDefault();
        try {
            setIsBusy(true);
            const data = {
                "assetsTypeIds": selectedAssetTypeIds,
                "isChangesExpected":isChangesExpected,
                "remarks": remarks
            };

            const lang = selectedLanguage === languages.nl ? 'nl' : 'en';
            const response = await postData(linkAssetTypeRequestURL(lang, request.id), data, authToken);
            const status = response.status;
            if (status === 200 || status === 201) {
                //Update notifications
                queryClient.invalidateQueries(dataFetchKeys.notifications);
                dispatch({ "type": "setSnackBarInfo", value: { message: t('pageAssignAssetSaved'), isError: false } })
                dispatch({ "type": "setOpenSnackbar", value: true })
                //Navigate
                history.push({
                    pathname: appRoute.home,
                });
            }
            else if (status === 401) {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('unauthorizedAccess'), isError: true } })
                dispatch({ "type": "setOpenSnackbar", value: true })
            }
            else if (status === 406) {
                //Update notifications
                queryClient.invalidateQueries(dataFetchKeys.notifications);
                dispatch({ "type": "setSnackBarInfo", value: { message: t('Action Already Performed'), isError: true } })
                dispatch({ "type": "setOpenSnackbar", value: true })
                history.push(appRoute.actionPerformed);
            }
            else {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } })
                dispatch({ "type": "setOpenSnackbar", value: true })
            }
        }
        finally {
            setIsBusy(false);
        }
    }

    const handleOpenRequestDetailsClick = () => {
        setOpenRequestDetailsDialog(true);
    };

    const closeRequestDetailsDialog = () => {
        setOpenRequestDetailsDialog(false);
    };


    const assetTypeButtonRow = assetTypes.map((assetType) => {
        return (
            <ToggleButtonGroup key={assetType.id} value={selectedAssetTypeIds}>
                <AssetTypeButton assetType={assetType}
                    handleSelectAssetType={handleSelectAssetType}
                    selectedAssetTypeIds={selectedAssetTypeIds}
                    isRequestOnHold={isRequestOnHold}
                />
            </ToggleButtonGroup>
        )
    })

    function handleSelectAssetType(assetTypeId) {

        const newArray = [...selectedAssetTypeIds]
        if (newArray.includes(assetTypeId)) {
            const index = newArray.indexOf(assetTypeId)
            if (index > -1)
                newArray.splice(index, 1)
        }
        else
            newArray.push(assetTypeId)
        setSelectedAssetTypeIds(newArray);
    }

    if (loading)
        return <BackDrop loading={loading} />;
    else if (hasError)
        return <UnauthorizedPage />;
    else if (isFinished)
        return <ActionFinishedPage />;

    return (
        <Fragment>
            <div className={classes.root}>
                <form className={classes.form} noValidate autoComplete="off">
                    <div>
                        <Typography variant="h5" className={classes.heading}>
                            {t('pageResponseAssign')}
                        </Typography>
                        <Button variant="outlined" onClick={handleOpenRequestDetailsClick} >
                            {t('generalReqDetails')}
                        </Button>
                        <br/>
                        {!isAmpApp &&                        
                         <FormControlLabel
                            value="start"
                            control={<Checkbox color="primary"
                            checked={isChangesExpected} onChange={(e) => setIsChangesExpected(e.target.checked)}  />}
                            label={t('isChangesExpected')}
                        />}
                        <Grid item xs={12} sm={12} className={classes.assetTypeSwitcher}>
                            {assetTypeButtonRow}
                        </Grid>
                        <Grid>
                            <TextField
                                variant='outlined'
                                multiline
                                disabled={isRequestOnHold}
                                rows={4}
                                label={t('Remarks*')}
                                helperText={t("charsRemaining", { num_char: requestValidation.remarksMax - remarks.length })}
                                error={isRequestOnHold? false : remarks.length < requestValidation.remarksMin || remarks.length > requestValidation.remarksMax}
                                onChange={e => setRemarks(e.target.value)} />
                        </Grid>
                        {isRequestOnHold &&
                            <Typography className={classes.infoText}>
                                {t('requestHoldInfoMessage')}
                            </Typography>}
                    </div>
                </form>

                <Grid container className={classes.footerDiv}>
                    <Grid item xs={6}>
                        <Fab variant="extended" color="default" aria-label="add" onClick={handleClickOpenDialog} className={classes.button}>
                            <BackIcon className={classes.extendedIcon} />
                            {t('generalBack')}
                        </Fab>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justify="flex-end">
                            {isRequestOnHold ?
                                <Fab variant="extended" color="secondary" aria-label="hold" onClick={handleClickReleaseDialog} className={classes.buttonRelease} disabled={isBusy}>
                                    <ReleaseIcon className={classes.extendedIcon} />
                                    {t('Release')}
                                </Fab> :
                                <Fab variant="extended" color="secondary" aria-label="hold" onClick={handleClickHoldDialog} disabled={isBusy} className={classes.buttonHold}>
                                    <PanToolIcon className={classes.extendedIcon} />
                                    {t('pageResponseAssignHold')}
                                </Fab>
                            }
                            <Fab variant="extended" color="primary" aria-label="save" onClick={handleSubmit} disabled={isBusy || isRequestOnHold || !validateForm()} className={classes.button}>
                                <SaveIcon className={classes.extendedIcon} />
                                {t('generalSave')}
                            </Fab>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <RequestDetailsDialog request={request} open={openRequestDetailsDialog} handleClose={closeRequestDetailsDialog} />
            <ConfirmationDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} />

            <DialogHoldRequest open={openRequestHoldDialog} setOpen={setOpenRequestHoldDialog}
                requestId={request ? request.id : ''} requestActionId={requestActionId} />

            <DialogReleaseHoldRequest open={openRequestReleaseDialog} setOpen={setOpenRequestReleaseDialog}
                requestId={request ? request.id : ''} requestActionId={requestActionId} />
        </Fragment>
    );
}