import { createMuiTheme } from '@material-ui/core/styles';

export const baseTheme = createMuiTheme({
    overrides: {
        // Name of component that need to be overwritten:
        MuiButton: {
            // Name of the rule
            contained: {
                // CSS (JSX syntax)
                backgroundColor: '#2A3448',
                color: '#fff',
                '&:hover': {
                    backgroundColor: '#4C5465'
                },
                '&:focus': {
                    backgroundColor: '#4C5465'
                },
            },
            containedPrimary: {
                '&:hover': {
                    backgroundColor: '#0F66BD'
                },
                '&:focus': {
                    backgroundColor: '#0F66BD'
                },
            },
        },
        MuiCssBaseline: {
            "@global": {
                body: {
                    //backgroundImage: 'url(' + backImg + ')',
                }
            }
        },
        MuiDialog: {
            paper: {
                borderWidth: 1,
                borderRadius: 10,
                borderColor: "#000000",
                borderStyle: "solid",
                backgroundColor: "#e6e6e6",
                color: "#000000",
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: "1em"
            }
        },
        toggle: {
            thumbOnColor: 'yellow',
            trackOnColor: 'red'
        }
    },
    palette: {
        background: {
            default: '#696969',
            paper: "#fff",
            dark: '#2A3448'
        },
        common: {
            black: "rgba(32, 32, 32, 1)",
            white: "#fff",
        },
        error: {
            contrastText: "#fff",
            dark: "rgb(255, 51, 0)",
            light: "rgb(255, 128, 128)",
            main: "rgb(255, 92, 51)"
        },
        primary: {
            contrastText: "#fff",
            dark: "#0C44AB",
            light: "rgba(142, 202, 255, 1)",
            main: "rgba(13, 116, 220, 1)"
        },
        secondary: {
            contrastText: "#fff",
            dark: "rgba(0, 107, 0, 1)",
            light: "rgba(160, 214, 106, 1)",
            main: "rgba(42, 52, 72, 1)"
        },
        text: {
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)",
            primary: "rgba(42, 52, 72, 1)",
            secondary: "rgba(42, 52, 72, 1)",
            dark: "#2A3448",
            heading3: "#2A3448",
            white: "#fff",
        },
        rhdhvTheme1: {
            pageBackgroundColor: '#e6e6e6',
            divBackgroundColor: '#313847',
            navbarColor:"#f2f2f2",
            textColor: '#000000',
            hoverColor: '#a5c100',
            hoverTextColor: '#000000',
            h1Color: '#fff',
            h2Color: '#fff',
            light:'#696969'
        }
    },
    typography: {
        fontFamily: [
            'Calibri','Arial', 'Roboto', 
        ].join(','),
    },
})