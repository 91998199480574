import { tokens } from './keys';

const userRoles = {
    admin: 'Admin',
    manager: 'Manager',
    user: 'User',
    assetOwner: 'Asset owner'
};


function isAdmin() {
    return hasReleventRole(userRoles.admin);
}

function isManager() {
    return hasReleventRole(userRoles.manager);
}

function isAssetOwner() {
    return hasReleventRole(userRoles.assetOwner);
}

function hasReleventRole(applicableRole) {
    const rolesToValidate = localStorage.getItem(tokens.keyUserroles) && localStorage.getItem(tokens.keyUserroles) !== "null" && localStorage.getItem(tokens.keyUserroles) !== null ?
        localStorage.getItem(tokens.keyUserroles) : sessionStorage.getItem(tokens.keyUserroles);

    if (!rolesToValidate || rolesToValidate.length <= 0) return false;
    return JSON.parse(rolesToValidate).find(({ role }) => role === applicableRole)? true: false;
}

export { userRoles, isAdmin, isManager, isAssetOwner };