import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { ClearTokens } from '../../utils/keys';
import { appRoute } from '../../utils/routeConfigs';
import { appConfigurations } from '../../utils/configs';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogLogoutConfirmation(props) {

    const { open, setOpen, setAnchor, setAuthenticated } = props;
    const [t] = useTranslation();
    const history = useHistory();
    const isAmpApp = appConfigurations.isAmpApp;
    const handleDialogLogout = () => {
        setOpen(false);
        handleLogout();
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function handleLogout() {
        setAnchor(null);
        ClearTokens();
        setAuthenticated(false);
        history.push(appRoute.root);
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-slide-title">{`${t('loggingOut')}?`}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {!isAmpApp ? t('logoutConfirmationAcceptatie') : t('logoutConfirmationAMP') }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleDialogLogout} color="primary" >
                    {t('logOutYes')}
                </Button>
                <Button variant="contained" onClick={handleClose} color="primary" autoFocus>
                    {t('logOutNo')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}