const tokens = {
    keyUserId: 'tokenAcceptentieUserid',
    keyEmail: 'tokenAcceptentieEmail',
    keyToken: 'tokenAcceptentieToken',
    keyUserroles: 'tokenAcceptentieRoles',
    keyFirstname: 'tokenAcceptentieUserFirstName',
    keyLastname: 'tokenAcceptentieUserLastName',
    searchedRequestText: 'searchedRequestText',
}

async function ClearTokens() {
    //Remove from local storage
    localStorage.removeItem(tokens.keyUserId);
    localStorage.removeItem(tokens.keyEmail);
    localStorage.removeItem(tokens.keyToken);
    localStorage.removeItem(tokens.keyUserroles);
    localStorage.removeItem(tokens.keyFirstname);
    localStorage.removeItem(tokens.keyLastname);

    //Remove from session storage
    sessionStorage.removeItem(tokens.keyUserId);
    sessionStorage.removeItem(tokens.keyEmail);
    sessionStorage.removeItem(tokens.keyToken);
    sessionStorage.removeItem(tokens.keyUserroles);
    sessionStorage.removeItem(tokens.keyFirstname);
    sessionStorage.removeItem(tokens.keyLastname);
}

export { tokens, ClearTokens }