import { Grid, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from "@material-ui/icons/Warning";
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        "& .MuiTypography-root": {
            marginRight: theme.spacing(2),
        },
        "& svg": {
            fontSize: 34,
        },
    },
}));

export default function WarningOrErrorComponent(props) {
    const theme = useTheme();
    const classes = useStyles();
    const { warning, info, error, message } = props;

    const getStyle = () => {
        if (warning) { return { color: theme.palette.warning.main } }
        if (info) { return { color: theme.palette.primary.main } }
        if (error) { return { color: 'orangered' } }

        return {};
    }

    return (
        <Grid container justify="center" alignItems="center" className={classes.root}>
            <Grid item xs={1}>
                {warning && <WarningIcon style={getStyle()} />}
                {info && <InfoIcon style={getStyle()} />}
                {error && <InfoIcon style={getStyle()} />}
            </Grid>
            <Grid item xs={11}>
                <Typography align="justify" variant="body1" style={getStyle()}>
                    {message}
                </Typography>
            </Grid>
        </Grid>
    );
}
