import { AppBar, CircularProgress, Dialog, DialogActions, DialogContent, Fab, Grid, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, Save as SaveIcon } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { dataFetchKeys, statusConfigs } from '../../../utils/configs';
import { appRoute } from '../../../utils/routeConfigs';
import { postData } from './../../../utils/ApiRequest';
import { putRequestOnHoldUrl } from './../../../utils/ApiUrl';
import { languages, requestValidation } from './../../../utils/AppConstants';
import { AppContext, queryClient } from './../../AuthenticatedPage';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(10),
    },
    container: {
        maxHeight: 440,
    },
    formControl: {
        fullWidth: true,
        display: 'flex'
    },
    dialog: {
        //backgroundColor: '#F5F7FA !important'
    },
    appBar: {
        position: 'relative',
        '& .MuiToolbar-root': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '40%',
            minWidth: '200px',
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        '& .MuiTypography-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: '40%',
            minWidth: '200px',
            color: theme.palette.primary.main,
        },
        '& svg': {
            color: theme.palette.primary.main,
        },
        textAlign: 'center',
        width: '100%',
        backgroundColor: '#fff'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function DialogReleaseHoldRequest(props) {

    const [t] = useTranslation();
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { selectedLanguage, notifications, selectedNotification, authToken } = state;

    let history = useHistory();
    const classes = useStyles();
    const { open, setOpen, requestActionId } = props;
    const [remarks, setRemarks] = useState("");
    const [isSaving, setIsSaving] = React.useState(false);
   
    async function handleSubmit(event) {
        event.preventDefault();

        try {
            setIsSaving(true);
            const lang = selectedLanguage === languages.nl ? 'nl' : 'en';
            const data = {
                "notificationId": selectedNotification?.id,
                "remarks": remarks
            };
            const response = await postData(putRequestOnHoldUrl(requestActionId, lang, true), data, authToken)
                    const status = response.status;
                    if (status === 200 || status === 201) {

                        //Update notification
                        if (selectedNotification?.seen_status?.description_static !== statusConfigs.Seen) {
                            const newArray = [...notifications];
                            const index = newArray.findIndex(n => n.id === selectedNotification.id);
                            if (index >= 0) {
                                const item = newArray.find(n => n.id === selectedNotification.id);
                                item.seen_status = { description_static: statusConfigs.Seen };
                                newArray.splice(index, 1, item);
                                dispatch({ "type": "setNotifications", value: newArray });
                            }
                        }

                        queryClient.invalidateQueries(dataFetchKeys.allRequests);
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('requestHoldReleaseSuccess'), isError: false } })
                        dispatch({ "type": "setOpenSnackbar", value: true })
                        history.push(appRoute.activeRequest);
                    }
                    else if (status === 406) {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('requestAlreadyReleased'), isError: true } })
                        dispatch({ "type": "setOpenSnackbar", value: true })
                    }
                    else if (status === 401) {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
                        dispatch({ "type": "setOpenSnackbar", value: true })
                    }
                    else {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } })
                        dispatch({ "type": "setOpenSnackbar", value: true })
                    }
        }
        finally {
            setRemarks('');
            setIsSaving(false);
            setOpen(false);
        }
    };

    const handleClose = () => {
        setRemarks('');
        setOpen(false);
    };

    function validateForm() {
        return (remarks && remarks.length <= requestValidation.remarksMax);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            style={{ backgroundColor: '#fff' }}
            fullScreen>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" className={classes.title}>
                        {t('requestHoldReleaseTitle')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.form}
                >
                    <ErrorIcon fontSize="large" />
                    <Typography variant="h6" >
                        {t('requestHoldReleaseMessage1')}
                    </Typography>
                    <Typography variant="h6" >
                        {t('requestHoldReleaseMessage2')}
                    </Typography>
                    <TextField
                        margin="none"
                        required
                        fullWidth
                        multiline
                        variant="outlined"
                        id="remarks"
                        label={t('generalRemarks')}
                        name="remarks"
                        autoComplete="off"
                        value={remarks}
                        disabled={isSaving}
                        placeholder={t('requestHoldReleaseRemarkPlaceholder')}
                        onChange={e => setRemarks(e.target.value)}
                        helperText={t("charsRemaining", { num_char: requestValidation.remarksMax - remarks.length })}
                        error={remarks.length < requestValidation.remarksMin || remarks.length > requestValidation.remarksMax}
                        inputProps={{ style: { minHeight: '100px' } }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#fff' }}>
                <Fab variant="extended" color="primary" aria-label="add" disabled={!validateForm() || isSaving}
                    onClick={handleSubmit} >
                    <SaveIcon className={classes.extendedIcon} />
                    {isSaving && <CircularProgress color="primary" size={25} className={classes.progress} />}
                    {t('submit')}
                   
                </Fab>
            </DialogActions>
        </Dialog>
        )
}