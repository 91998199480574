import { AppBar, CircularProgress, Dialog, DialogActions, DialogContent, Fab, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircle as AddIcon, Cancel as DeactivateIcon, CheckCircle as ActivateIcon, Close as CloseIcon, Save as SaveIcon } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postData, putData } from './../../../utils/ApiRequest';
import { activateAssetTypeURL, deactivateAssetTypeURL, getCreateAssetTypeURL, getUpdateAssetTypeURL } from './../../../utils/ApiUrl';
import { userActions } from './../../../utils/AppConstants';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext } from './../../AuthenticatedPage';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(10),
    },
    container: {
        maxHeight: 440,
    },
    formControl: {
        fullWidth: true,
        display: 'flex'
    },
    dialog: {
        //backgroundColor: '#F5F7FA !important'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        textAlign: 'center',
        width: '100%'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function AssetTypeDialog(props) {

    const [t] = useTranslation();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { dispatch } = appContext;

    const { assetType, setAssetType, assetTypes, setAssetTypes, action, open, setOpen  } = props;
    const [description, setDescription] = useState("");
    const [remarks, setRemarks] = useState("");
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {

        setDescription(assetType ? assetType.description : "");
        setRemarks(assetType ? assetType.remarks : "");

    }, [assetType])

    async function handleCreateSubmit(event) {
        event.preventDefault();
        try{
            setIsBusy(true)
            const data =
            {
                "description": description,
                "remarks": remarks,
            }
            const response = await postData(getCreateAssetTypeURL(), data, GetToken())
            const status = response.status;
            if (status === 200 || status === 201) {
                const newObj = await response.json();
                let newArray = [...assetTypes];
                newArray.push(newObj);
                setAssetTypes(newArray);

                dispatch({ "type": "setSnackBarInfo", value: { message: `${t('generalAssetType')} '${description}' ${t('generalCreated')}`, isError: false } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else if (status === 409) {
                dispatch({ "type": "setSnackBarInfo", value: { message: `${t('pageAssetTypeConflict')} i.e. ${description}`, isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else if (status === 401) {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
  

        }
        finally{
            setIsBusy(false)
            setAssetType(null);
            setOpen(false);
        }
  
    };

    async function handleEditSubmit(event) {
        event.preventDefault();
        try{
            setIsBusy(true);
            const data =
            {
                "description": description,
                "remarks": remarks
            }
            const response  = await putData(getUpdateAssetTypeURL(assetType.id), data, GetToken())
                    const status = response.status;
                    if (status === 200 || status === 201) {
                        const newObj = await response.json();
                        const objIndex = assetTypes.findIndex(e => e.id === assetType.id);
                        let newArray = [...assetTypes];
                        newArray[objIndex] = { ...newArray[objIndex], description: newObj.description, remarks: newObj.remarks }
                        setAssetTypes(newArray);
    
                        dispatch({ "type": "setSnackBarInfo", value: { message: `${t('generalAssetType')} '${description}' ${t('generalUpdated')}`, isError: false } });
                        dispatch({ "type": "setOpenSnackbar", value: true });
                    }
                    else if (status === 409) {
                        dispatch({ "type": "setSnackBarInfo", value: { message: `${t('pageAssetTypeConflict')} i.e. ${description}`, isError: true } });
                        dispatch({ "type": "setOpenSnackbar", value: true });
                    }
                    else if (status === 401) {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
                        dispatch({ "type": "setOpenSnackbar", value: true });
                    }
                    else {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
                        dispatch({ "type": "setOpenSnackbar", value: true });
                    }
    
        }
        finally{
            setIsBusy(false);
            setAssetType(null);
            setOpen(false);
        }

    };

    async function handleActivateOrDeactivate(event) {

        event.preventDefault();
        try{
            setIsBusy(true)
            const url = action === userActions.activate ? activateAssetTypeURL(assetType.id) : deactivateAssetTypeURL(assetType.id);
            const response= await putData(url, null, GetToken())
   
                   const status = response.status;
                   if (status === 201 || status === 200) {
                       const objIndex = assetTypes.findIndex(e => e.id === assetType.id);
                       let newArray = [...assetTypes];
                       newArray[objIndex] = {
                           ...newArray[objIndex],
                           status_desc: action === userActions.activate ? 'Active' : 'Inactive'
                       }
                       setAssetTypes(newArray);
   
                       if (action === userActions.activate)
                           dispatch({ "type": "setSnackBarInfo", value: { message: `${t('generalAssetType')} '${assetType.description}' ${t('pageAssetTypeActiveted')}`, isError: false } });
                       else
                           dispatch({ "type": "setSnackBarInfo", value: { message: `${t('generalAssetType')} '${assetType.description}' ${t('pageAssetTypeDeactivated')}`, isError: false } });
                       dispatch({ "type": "setOpenSnackbar", value: true });
                   }
                   else if (status === 401) {
                       dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
                       dispatch({ "type": "setOpenSnackbar", value: true });
                   }
                   else {
                       dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
                       dispatch({ "type": "setOpenSnackbar", value: true });
                   }
   
        }
        finally{
         setIsBusy(false)      
        setAssetType(null);
        setOpen(false);
        }

    };

    const handleClose = () => {
        setOpen(false);
    };

    function validateForm() {
        return (description && description.length > 3 && description.length <= 100 && (remarks? remarks.length <= 255: true));
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            style={{ backgroundColor: '#fff' }}
            fullScreen>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" className={classes.title}>
                        {action ? (action === userActions.activate ? t('pageAssetTypeActivate') : t('pageAssetTypeDeactivate')) :
                            (assetType ? t('pageAssetTypeEdit') : t('pageAssetTypeAddNew'))}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <form className={classes.form} autoComplete="off" style={{ backgroundColor: '#fff' }}>
                    <div>
                        <br />
                        <Typography variant="h6" >
                            {action ?
                                (action === userActions.activate ?
                                    t('pageAssetTypeActivateInstruction') :
                                    t('pageAssetTypeDeactivateInstruction')) :
                                (assetType ?
                                    t('pageAssetTypeEditInstruction') :
                                    t('pageAssetTypeCreateInstruction'))}
                        </Typography>
                        <TextField
                            margin="none"
                            disabled={isBusy || action !== ''}
                            required
                            fullWidth
                            variant="outlined"
                            id="description"
                            label={t('generalDescription')}
                            name="description"
                            autoComplete="off"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        /><br />
                        <TextField
                            margin="none"
                            fullWidth
                            disabled={isBusy || action !== ''}
                            variant="outlined"
                            id="remarks"
                            label={t('generalRemarks')}
                            name="remarks"
                            autoComplete="off"
                            value={remarks}
                            onChange={e => setRemarks(e.target.value)}
                        />
                    </div>
                </form>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#fff' }}>

                <Fab variant="extended" color="primary" aria-label="add" disabled={!validateForm()}
                    onClick={action ? handleActivateOrDeactivate : (assetType ? handleEditSubmit : handleCreateSubmit)} >
                        {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />  }
                    {action ?
                        (action === userActions.activate ? <ActivateIcon className={classes.extendedIcon} /> : <DeactivateIcon className={classes.extendedIcon} />) :
                        (assetType ? <SaveIcon className={classes.extendedIcon} /> : <AddIcon className={classes.extendedIcon} />)}  
                    {action ? (action === userActions.activate ? "Activate asset type" : "Deactivate asset type") :
                        (assetType ? t('pageAssetTypeSave') : t('pageAssetTypeAdd'))}
                          
                </Fab>
            </DialogActions>
        </Dialog>
        )
}