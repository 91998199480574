import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import React, { useContext, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { putData } from './../../../utils/ApiRequest';
import { updateFormatDetail } from './../../../utils/ApiUrl';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext } from './../../AuthenticatedPage';

const useStyles = makeStyles(theme => ({

    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(0.75),
            width: '90%',
            minWidth: '200px'
        },
        textAlign: 'center',
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    dialogTitle: {
        cursor: 'move',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '& .MuiTypography-root': {
            fontSize: '1.2rem',
        },
    },
    dialogContents: {
        fontSize: '1rem',
        marginTop: theme.spacing(1),
    },
    checkBox: {
        marginLeft: theme.spacing(1),
    },
    warningText: {
        fontSize: '0.9rem',
        color: 'orange',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} style={{ backgroundColor: '#fff'}} />
        </Draggable>
    );
}

export default function DialogDetailUpdate(props) {

    const [t] = useTranslation();
    const appContext = useContext(AppContext);
    const { dispatch } = appContext;

    const { open, currentScopeName, currentDetail, setCurrentDetail, setOpen, scopes, setScopes } = props;
    const classes = useStyles();
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [hasDescriptionError, setHasDescriptionError] = useState(false);

    const [descriptionNl, setDescriptionNl] = useState("");
    const [descriptionNlError, setDescriptionNlError] = useState("");
    const [hasDescriptionNlError, setHasDescriptionNlError] = useState(false);
    const [isBusy, setIsBusy] = useState(false);

    const [remarks, setRemarks] = useState([]);

    useEffect(() => {

        setDescription(currentDetail ? currentDetail.description : "");
        setDescriptionNl(currentDetail ? currentDetail.description_nl : "");
        setRemarks(currentDetail ? currentDetail.remarks : "");
        setDescriptionError(currentDetail ? `${t('pageFormatsEngValidation')} ${currentDetail.numberParameters} ${t('pageFormatsParameters')} (%s)` : "");
        setDescriptionNlError(currentDetail ? `${t('pageFormatsDutchValidation')} ${currentDetail.numberParameters} ${t('pageFormatsParameters')} (%s)` : "");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDetail])

    function validateForm() {
        if (!description || !descriptionNl || !currentDetail) return false;

        if (currentDetail.numberParameters > 0) {
            const count = !description.includes('%s')? 0: (description.match(/%s/g) || []).length;
            if (count !== currentDetail.numberParameters) {
                return false;
            }
            else if (!descriptionNl.includes('%s') || (descriptionNl.match(/%s/g) || []).length !== currentDetail.numberParameters) {
                return false;
            }
        }

        return (description && description.length > 3 &&
            descriptionNl && descriptionNl.length > 3);
    }

    const handleClose = () => {

        setCurrentDetail(null);
        setOpen(false);
    }

    const handleDescriptionChange = (e) => {
        const count = !e.target.value.includes('%s') ? 0 : (e.target.value.match(/%s/g) || []).length;
        setHasDescriptionError(count !== currentDetail.numberParameters);
        setDescription(e.target.value);
    }

    const handleDescriptionNlChange = (e) => {
        const count = !e.target.value.includes('%s') ? 0 : (e.target.value.match(/%s/g) || []).length;
        setHasDescriptionNlError(count !== currentDetail.numberParameters);
        setDescriptionNl(e.target.value);
    }

    async function handleCreateSubmit(event) {

        event.preventDefault();
        try{
            setIsBusy(true)
            const data =
            {
                "description": description,
                "description_nl": descriptionNl,
                "remarks": remarks
            }
    
            const response = await putData(updateFormatDetail(currentDetail.id), data, GetToken());
            const status = response.status;
            if (status === 200 || status === 201) {
                let newArray = [...scopes];
                for (var scope of newArray) {
                    if (!scope.details || scope.details.length <= 0) continue;
                    const obj = scope.details.find(e => e.id === currentDetail.id);
                    if (obj) {
                        obj.description = description;
                        obj.description_nl = descriptionNl;
                        obj.remarks = remarks;
                        break;
                    }
                }
    
                setScopes(newArray);
                setCurrentDetail(null);
                dispatch({ "type": "setSnackBarInfo", value: { message: t('pageFormatsFormatted'), isError: false } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else if (status === 401) {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
        }
        finally{
            setOpen(false); 
            setIsBusy(false); 
        }
       
    };

    return (
        <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="form-dialog-title">
            <DialogTitle className={classes.dialogTitle} id="draggable-dialog-title">
                <Typography>
                    {!currentDetail ? "" : `${t('generalEdit')}: ${currentDetail.description_type}`}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography className={classes.dialogContents}>
                    {t('pageFormatsEditFormatTextInstruction')}
                </Typography>
                {currentDetail && currentDetail.numberParameters > 0 ?
                    <div className={classes.warningText}>
                        <WarningIcon />
                        <span style={{ marginLeft: '5px' }}>
                            {t('pageFormatsParameterValidation')}
                        </span>
                    </div> : <div/>}
                <br />
                <form className={classes.form} autoComplete="off">
                    <TextField
                        disabled
                        margin="none"
                        fullWidth
                        variant="outlined"
                        id="name"
                        label={"Scope"}
                        value={currentScopeName}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        margin="none"
                        error={hasDescriptionError}
                        helperText={hasDescriptionError ? descriptionError : ""}
                        required
                        disabled={isBusy}
                        fullWidth
                        multiline
                        variant="outlined"
                        id="name"
                        label={t('pageFormatsEnglishText')}
                        placeholder={t('pageFormatsEnglishText')}
                        name="name"
                        autoComplete="off"
                        value={description}
                        InputLabelProps={{ shrink: true }}
                        onChange={e => handleDescriptionChange(e)}
                    />
                    <TextField
                        margin="none"
                        error={hasDescriptionNlError}
                        helperText={hasDescriptionNlError ? descriptionNlError : ""}
                        required
                        disabled={isBusy}
                        fullWidth
                        multiline
                        variant="outlined"
                        id="name"
                        label={t('pageFormatsDutchText')}
                        placeholder={t('pageFormatsDutchText')}
                        name="name"
                        autoComplete="off"
                        value={descriptionNl}
                        InputLabelProps={{ shrink: true }}
                        onChange={e => handleDescriptionNlChange(e)}
                    />
                    <TextField
                        margin="none"
                        multiline
                        disabled={isBusy}
                        fullWidth
                        id="name"
                        label={t('generalRemarks')}
                        variant="outlined"
                        placeholder={t('generalRemarks')}
                        name="name"
                        autoComplete="off"
                        value={remarks}
                        InputLabelProps={{ shrink: true }}
                        onChange={e => setRemarks(e.target.value)}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose} color="primary">
                    {t('generalCancel')}
                    </Button>
                <Button variant="contained" onClick={handleCreateSubmit} color="primary" disabled={ isBusy || !validateForm()} type="submit">
                {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} /> }       
                    {t('generalSave')}
                    </Button>
            </DialogActions>
        </Dialog>
    );
}