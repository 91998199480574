import { Grid, IconButton, LinearProgress, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DescriptionIcon from '@material-ui/icons/Description';
import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadType, roleConfigs } from '../../../utils/AppConstants';
import { statusConfigs } from '../../../utils/configs';
import { postData } from './../../../utils/ApiRequest';
import { requestActionAttachmentDownloadUrl, requestAttachementDownloadUrl, responseAttachementDownloadUrl } from './../../../utils/ApiUrl';
import { AppContext } from './../../AuthenticatedPage';
import DialogDeleteAttachment from './DialogDeleteAttachment';
import { dateToString } from '../../../utils/DateFormat';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .deleted-attachment': {
      '& .MuiListItemText-root, svg': {
        color: "red !important",
        textDecoration: "line-through !important",
      },
    }
  },
  downloadButton: {
    //marginLeft: theme.spacing(5)
  },
  progress: {
    marginLeft: theme.spacing(1),
  },
  deleteButton: {
    color: "red"
  }
}));

export default function CardAttachment(props) {

  const classes = useStyles();
  const [t] = useTranslation();

  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const { authToken, userRoles } = state;
  const { attachment, typeDownload, disabledDownload, allowDelete, attachments, setAttachments } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const isAdmin = userRoles?.some(r => [roleConfigs.admin].includes(r.role));
  const deleted = attachment?.status?.description_static === statusConfigs.Deleted;

  const handleDownloadDocument = async () => {

    try {
      setIsBusy(true);
      const getUrl = () => {

        if (typeDownload === downloadType.request || typeDownload === downloadType.requestActionUpload) {
          return requestAttachementDownloadUrl(attachment.id);
        }
        if (typeDownload === downloadType.requestAction) {
          return requestActionAttachmentDownloadUrl(attachment.id);
        }
        if (typeDownload === downloadType.requestResponse) {
          return responseAttachementDownloadUrl(attachment.id);
        }
        return "";
      }

      const apiURL = getUrl();
      if (!getUrl) {
        return;
      }

      const response = await postData(apiURL, null, authToken);
      if (response.status >= 200 && response.status <= 300) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.download = attachment.original_file_name;
        anchor.href = url;
        anchor.target = "_blank";
        anchor.click();

        dispatch({ "type": "setSnackBarInfo", value: { message: t('generalDownloaded'), isError: false } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('generalErrorDownload'), isError: true } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
    }
    finally {
      setIsBusy(false);
    }
  }

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  }
  return (
    deleted && !isAdmin ? <Fragment /> :
      <Grid container direction="column" className={classes.root}>
        <ListItem key={attachment.id} button divider={true} className={deleted ? "deleted-attachment" : ""}>
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText
            primary={attachment.original_file_name}
            secondary={attachment.created_on ? dateToString(attachment.created_on) : ""}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="download"
              color="primary"
              className={classes.downloadButton}
              disabled={isBusy || disabledDownload}
              onClick={handleDownloadDocument}>
              <CloudDownloadIcon />
            </IconButton>
            {!deleted && allowDelete &&
              <IconButton edge="end" aria-label="delete"
                color="primary"
                className={classes.deleteButton}
                disabled={isBusy}
                onClick={handleDelete}>
                <DeleteForeverIcon />
              </IconButton>}
          </ListItemSecondaryAction>
        </ListItem>
        {isBusy &&
          <LinearProgress color="primary" />}

        <DialogDeleteAttachment
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          attachment={attachment}
          attachments={attachments}
          setAttachments={setAttachments}
        />
      </Grid>
  )
}