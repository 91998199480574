import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getData } from './../../../utils/ApiRequest';
import { getActiveWorkflowHavingContentsUrl } from './../../../utils/ApiUrl';
import { appStatuses, userActions } from './../../../utils/AppConstants';
import { isAdmin } from './../../../utils/Roles';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext } from './../../AuthenticatedPage';
import tableIcons from './../../tableIcons';
import DialogActivateOrDeactivateRequestType from './DialogActivateOrDeactivateRequestType';
import DialogCreateRequestType from './DialogCreateRequestType';
import DialogEditRequestType from './DialogEditRequestType';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginLeft: theme.spacing(10),
        marginTop: theme.spacing(10),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(10),
    },
    container: {
        maxHeight: 440,
    },
    formControl: {
        fullWidth: true,
        display: 'flex'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function RequestTypesPage() {

    const [t] = useTranslation();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { requestTypes } = state;
    
    const [unusedWorkflows, setUnusedWorkflows] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [openRequestTypeDialog, setOpenRequestTypeDialog] = React.useState(false);
    const [openEditRequestTypeDialog, setOpenEditRequestTypeDialog] = React.useState(false);
    const [openActivateRequestTypeDialog, setOpenActivateRequestTypeDialog] = React.useState(false);
    const [requestTypeAction, setRequestTypeAction] = React.useState('');
    const [selectedRequestType, setSelectedRequestType] = useState(null);

    const isUserAdmin = isAdmin();

    const yesText = t('yes');
    const noText = t('no');

    async function handleAdd() {
        setSelectedRequestType(null);
        setRequestTypeAction("");
        setOpenRequestTypeDialog(true);
    };

    async function handleEdit(event, rowData) {
        setSelectedRequestType(rowData);
        setRequestTypeAction("");
        setOpenEditRequestTypeDialog(true);
    };

    async function handleAction(event, rowData, userAction) {
        setSelectedRequestType(rowData);
        setRequestTypeAction(userAction);
        setOpenActivateRequestTypeDialog(true);
    };

    useEffect(() => {

        async function GetUnusedWorkflows() {
            const apiURL = getActiveWorkflowHavingContentsUrl;
            await getData(apiURL, GetToken())
                .then(async response => {
                    if (response.status === 200 || response.status === 201) {
                        const response_json = await response.json();
                        setUnusedWorkflows(response_json);
                    }
                    else {
                        setUnusedWorkflows([]);
                    }
                })
        }

        GetUnusedWorkflows();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Paper className={classes.root}>
                <MaterialTable
                    title={t('pageRequestTypeRequestTypes')}
                    columns={[
                        { title: t('generalDescription'), field: 'description' },
                        { title: t('pageRequestTypePrefixLetter'), field: 'num_prefix' },
                        {
                            title: t('pageRequestTypeUploadRequired'), field: 'upload_required', grouping: false,
                            render: row => <span>{row["upload_required"] ? yesText : noText}</span>
                        },
                        {
                            title: t('isPlanologisch'), field: 'is_planologic', grouping: false,
                            render: row => <span>{row["is_planologic"] ? yesText : noText}</span>
                        },
                        { title: t('generalRemarks'), field: 'remarks' },
                    ]}
                    data={requestTypes}
                    icons={tableIcons}
                    actions={isUserAdmin ? [
                        {
                            icon: tableIcons.Add,
                            tooltip: t('pageRequestTypeNew'),
                            position: "toolbar",
                            onClick: () => handleAdd(),
                        },
                        {
                            icon: tableIcons.Edit,
                            tooltip: t('pageRequestTypeEdit'),
                            onClick: (event, rowData) => handleEdit(event, rowData),

                        },
                        rowData => ({
                            icon: (rowData.status_desc === appStatuses.active ? tableIcons.Delete : tableIcons.Activate),
                            tooltip: (rowData.status_desc === appStatuses.active ? t('generalDeactivate') : t('generalActivate')),
                            onClick: (event, rowData) => handleAction(event, rowData, rowData.status_desc === appStatuses.active ? userActions.deactivate : userActions.activate),
                        }),
                    ] : []}
                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                    options={{
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF'
                        },
                        rowStyle: rowData => ({
                            backgroundColor: (rowData.status_desc === appStatuses.inactive) ? '#F9F5F0' :
                                (rowData.status_desc === 'Deleted') ? '#F9F0F0' :
                                    (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                        }),
                        exportButton: true,
                        actionsColumnIndex: 100,
                    }}
                />

                <DialogCreateRequestType
                    open={openRequestTypeDialog}
                    setOpen={setOpenRequestTypeDialog}
                    unusedWorkflows={unusedWorkflows}
                    action={requestTypeAction} />

                {selectedRequestType &&
                    <DialogEditRequestType
                    open={openEditRequestTypeDialog}
                    setOpen={setOpenEditRequestTypeDialog}
                        requestType={selectedRequestType} />}

                {selectedRequestType && requestTypeAction &&
                    <DialogActivateOrDeactivateRequestType
                    action={requestTypeAction}
                    open={openActivateRequestTypeDialog}
                    setOpen={setOpenActivateRequestTypeDialog}
                        requestType={selectedRequestType} />}
            </Paper>
        </Fragment>
    );
}