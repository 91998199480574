import React, { Fragment } from 'react';
import { appRoute } from '../../utils/routeConfigs';
import ActionFinishedPage from './../pages/ActionFinishedPage';
import UnauthorizedPage from './../pages/UnauthorizedPage';
import PrivateRoute from './PrivateRoute';

export default function RouteErrors() {

    return (
        <Fragment>
            <PrivateRoute path={appRoute.unauthorized}>
                <UnauthorizedPage />
            </PrivateRoute>
            <PrivateRoute path={appRoute.actionPerformed}>
                <ActionFinishedPage />
            </PrivateRoute>
        </Fragment>
    )
}