import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
    },
    error: {
        backgroundColor: theme.palette.error.main,
    },
}));

export default function DialogAppBar(props) {

    const classes = useStyles();
    const { setOpen, title, warning, error } = props;

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <AppBar className={clsx(classes.appBar,
            { [classes.warning]: warning },
            { [classes.error]: error }
        )}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h4" className={classes.title}>
                    {title}
                </Typography>
            </Toolbar>
        </AppBar>
    )
}