import { Dialog, DialogActions, DialogContent, Fab, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserDeleteURL } from '../../../utils/ApiUrl';
import { statusConfigs } from '../../../utils/configs';
import DialogAppBar from '../../Common/DialogAppBar';
import WarningOrErrorComponent from '../../Common/WarningOrErrorComponent';
import { deleteData } from './../../../utils/ApiRequest';
import { AppContext } from './../../AuthenticatedPage';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0, 3),
        marginTop: theme.spacing(3),
        '& .MuiGrid-container': {
            '& h3': {
                marginBottom: theme.spacing(2),
            },
            '& .MuiGrid-item': {
                width: '40%'
            },
            '& .MuiTextField-root, .MuiAutocomplete-root': {
                marginBottom: theme.spacing(2),
            },
        },
        '& .MuiSwitch-root': {
            marginLeft: theme.spacing(2),
        },

        '& .Mui-disabled': {
            color: 'grey'
        },
    },
    submitButton: {
        backgroundColor: theme.palette.error.main,
        '& svg': {
            marginRight: theme.spacing(1),
        }
    }
}));

export default function DialogDeleteUser(props) {

    const [t] = useTranslation();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { users, authToken } = state;
    const { open, setOpen, user } = props;

    const handleClose = () => {
        setOpen(false);
    };

    async function handleSubmit(event) {
        event.preventDefault();
        const response = await deleteData(getUserDeleteURL(user.id), authToken)
        const status = response.status;
        if (status === 201 || status === 200) {
            const userName = `${user.firstName} ${user.lastName}`;
            let newArray = [...users];
            const index = newArray.findIndex(e => e.id === user.id);
            const deletedUser = newArray[index];
            deletedUser.email = "#Deleted";
            deletedUser.phone_number = "#Deleted";
            deletedUser.company_name = "#Deleted";
            deletedUser.firstName = "#Deleted";
            deletedUser.lastName = "#Deleted";
            deletedUser.default_admin = false;
            deletedUser.can_check_asbuilt = false;
            deletedUser.default_check_asbuilt = false;
            deletedUser.status_desc = statusConfigs.Deleted;
            newArray.splice(index, 1, deletedUser);

            dispatch({ "type": "setUsers", value: newArray });
            dispatch({
                "type": "setSnackBarInfo", value: {
                    message: `${t('userDeleted', { user_name: userName })}`, isError: false
                }
            });
            dispatch({ "type": "setOpenSnackbar", value: true })
        }
        else if (status === 401) {
            dispatch({ "type": "setSnackBarInfo", value: { message: t('dialogUsersSessionExpired'), isError: true } })
            dispatch({ "type": "setOpenSnackbar", value: true })
        }
        else {
            dispatch({ "type": "setSnackBarInfo", value: { message: t('dialogUsersSaveError'), isError: true } })
            dispatch({ "type": "setOpenSnackbar", value: true })
        }

        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullScreen>
            <DialogAppBar error setOpen={setOpen} title={t('deleteUserTitle')} />
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div className={classes.root}>
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                        <Grid item>
                            <Typography variant="h5" style={{ fontSize: 18, marginBottom: "20px" }}>
                                {t('userDetailsDescription')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                disabled
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="off"
                                value={user.email}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                disabled
                                fullWidth
                                id="firstName"
                                label={t('dialogUsersFirstName')}
                                name="firstName"
                                autoComplete="off"
                                value={user.firstName}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                disabled
                                fullWidth
                                id="lastName"
                                label={t('dialogUsersLastName')}
                                name="lastName"
                                autoComplete="off"
                                value={user.lastName}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                disabled
                                fullWidth
                                id="phoneNumber"
                                label={t('dialogUsersPhone')}
                                name="phoneNumber"
                                autoComplete="off"
                                value={user.phone_number}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                fullWidth
                                disabled
                                id="company_name"
                                label={t('dialogUsersCompany')}
                                name="company_name"
                                autoComplete="off"
                                value={user.company_name}
                            />
                        </Grid>
                        <Grid item>
                            <WarningOrErrorComponent error message={t('deleteUserDescription')} />
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#fff' }}>
                <Fab variant="extended" color="primary" aria-label="add" className={classes.submitButton}
                    onClick={handleSubmit}>
                    <DeleteForeverIcon />
                    {t('deleteUser')}
                </Fab>
            </DialogActions>
        </Dialog>
    )
}