import * as React from 'react'
import styled from 'styled-components'

const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  max-width: 100vw;
  max-height: 100vh;
`

export const Page = ({ children }: { children: any}) => (
  <PageContent>
    {children}
    
  </PageContent>
)
