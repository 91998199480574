import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';

const useStyles = makeStyles(theme => ({

    texts: {
        "&$textfieldDisabled": {
            color: theme.palette.grey[900]
        }
    },
}));

export default function UserDetailsDialog(props) {

    const classes = useStyles();
    const { openDialog, handleCloseDialog, userDetails } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">{"User details"}</DialogTitle>
            <DialogContent>
                <TextField
                    className={classes.texts}
                    margin="none"
                    fullWidth
                    disabled
                    id="email"
                    label="Reference Number"
                    value={userDetails.email}
                />
                <TextField
                    margin="none"
                    fullWidth
                    disabled
                    id="firstName"
                    label="First name"
                    value={userDetails.firstName}
                />
                <TextField
                    margin="none"
                    fullWidth
                    disabled
                    id="lastName"
                    label="Last name"
                    value={userDetails.lastName}
                />
                <TextField
                    margin="none"
                    fullWidth
                    disabled
                    id="phone_number"
                    label="Phone"
                    value={userDetails.phone_number}
                />
                <TextField
                    margin="none"
                    fullWidth
                    disabled
                    id="company_name"
                    label="Company"
                    value={userDetails.company_name}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary" variant="contained">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

