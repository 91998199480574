import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import PanToolIcon from '@material-ui/icons/PanTool';
import { statusConfigs } from '../../../utils/configs';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '& svg': {
            color: theme.palette.common.white,
            fontSize: 20,
        },
        boxShadow: '1px 2px 2px 2px lightgray'
    },
    hold: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.common.white,
    },
    closed: {
        backgroundColor: "#009933",
        color: theme.palette.common.white,
    },
    rejected: {
        backgroundColor: "orangered",
        color: theme.palette.common.white,
    },
    expired: {
        backgroundColor: "#ff6666",
        color: theme.palette.common.white,
    },
}));

export default function ChipStatus(props) {

    const classes = useStyles();
    const { status } = props;

    const getIcon = () => {

        switch (status.description_static) {
            case statusConfigs.Rejected:
                return <CancelIcon />;
            case statusConfigs.Hold:
                return <PanToolIcon />;
            case statusConfigs.Expired:
                return <RemoveCircleIcon />;
            default:
                return <DoneIcon />;
        }
    }

    return (
        !status ? <Fragment /> :
            <Chip
                label={status.description_static}
                icon={getIcon()}
                className={clsx(classes.root, {
                    [classes.hold]: status.description_static === statusConfigs.Hold,
                    [classes.closed]: [statusConfigs.Closed, statusConfigs.Finished, statusConfigs.Approved].includes(status.description_static),
                    [classes.rejected]: status.description_static === statusConfigs.Rejected,
                    [classes.expired]: status.description_static === statusConfigs.Expired,
                })}
            />
    )
}