import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import { useQuery } from "@tanstack/react-query";
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { getData } from '../../../utils/ApiRequest';
import { getUsersURL } from '../../../utils/ApiUrl';
import { dataFetchKeys, queryOptions, statusConfigs } from '../../../utils/configs';
import { AppContext } from '../../AuthenticatedPage';
import BackDrop from '../../Common/BackDrop';
import DialogActivateUser from './DialogActivateUser';
import DialogAddUser from './DialogAddUser';
import DialogDeleteUser from './DialogDeleteUser';
import DialogEditUser from './DialogEditUser';
import { EnhancedTableHead, EnhancedTableToolbar, getComparator, stableSort, StyledTableCell, StyledTableRow } from './UserListHeader';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(10),
        width: '95%',
        marginBottom: theme.spacing(10),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    deleted: {
        '& .MuiTableCell-root': {
            color: theme.palette.error.main,
            fontWeight: "bold",
        },
    },
    inactive: {
        '& .MuiTableCell-root': {
            color: theme.palette.warning.main,
            fontWeight: "bold",
        },
    },
    panding: {
        '& .MuiTableCell-root': {
            color: theme.palette.warning.light,
            fontWeight: "bold",
        },
    },
    //Important for not displaying the sort label
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function Userspage() {

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { authToken, users, currentUser } = state;

    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredUsers, setFilteredUsers] = useState([]);

    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openActivateDialog, setOpenActivateDialog] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [includeDeletedUsers, setIncludeDeletedUsers] = React.useState(false);

    const [windowScreenHeight, setwindowScreenHeight] = useState(window.innerHeight)
    const screenHeigth = windowScreenHeight - 301;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);
    const userId = currentUser?.id || "";
    const {
        data: userList,
        isLoading: userListLoading,
    } = useQuery([`${dataFetchKeys.users}${userId}`],
        async () => {
            const result = await getData(getUsersURL, authToken);
            return await result.json()
        }, queryOptions
    );

    const detectScreenHeigth = () => {
        setwindowScreenHeight(window.innerHeight,)
    }

    useEffect(() => {
        window.addEventListener('resize', detectScreenHeigth)
        return () => {
            window.removeEventListener('resize', detectScreenHeigth)
        }
    }, [windowScreenHeight])


    useEffect(() => {
        dispatch({ "type": "setUsers", value: userList || [] });
    }, [userList, dispatch])

    useEffect(() => {
        setFilteredUsers(users.filter(u => u.status_desc !== statusConfigs.Deleted));
    }, [users])

    const handleSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event, id) => {

        if (!id) { return; }
        if (selected && selected.id === id) {
            return setSelected(null);
        }

        const user = filteredUsers.find(r => r.id === id);
        setSelected(user || null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleIncludeDeletedUsers = () => {
        const newValue = !includeDeletedUsers;
        if (newValue) {
            setFilteredUsers(users);
        }
        else {
            setFilteredUsers(users.filter(u => u.status_desc !== statusConfigs.Deleted));
        }

        setIncludeDeletedUsers(newValue);
    }

    const handleAdd = () => {
        setOpenAddDialog(true);
    };

    const handleEdit = () => {
        setOpenEditDialog(true);
    };

    const handleActivateOrDeactivate = () => {
        setOpenActivateDialog(true);
    };

    const handleDelete = () => {
        setOpenDeleteDialog(true);
    };

    const handleSearch = (newValue) => {

        const fUsers = includeDeletedUsers ? users.filter(u => u.status_desc !== statusConfigs.Deleted) : users;
        if (!newValue) {
            setFilteredUsers(fUsers);
            return setPage(0);
        }

        const searchedUsers = fUsers.filter(r =>
            r.firstName.toLowerCase().indexOf(newValue) > -1 ||
            r.lastName.toLowerCase().indexOf(newValue) > -1 ||
            r.email.toLowerCase().indexOf(newValue) > -1 ||
            r.phone_number.toLowerCase().indexOf(newValue) > -1 ||
            r.company_name.toLowerCase().indexOf(newValue) > -1 ||
            r?.status_desc?.toLowerCase().indexOf(newValue) > -1
        );

        setFilteredUsers(searchedUsers);
        setPage(0);
    }

    return (
        userListLoading ? <BackDrop loading={userListLoading} /> :
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar
                        selected={selected}
                        includeDeletedUsers={includeDeletedUsers}
                        handleIncludeDeletedUsers={handleIncludeDeletedUsers}
                        handleAdd={handleAdd}
                        handleEdit={handleEdit}
                        handleActivateOrDeactivate={handleActivateOrDeactivate}
                        handleDelete={handleDelete}
                        handleSearch={handleSearch}
                    />
                    <TableContainer style={{ maxHeight: screenHeigth }}>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                selected={selected}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleSort}
                                rowCount={filteredUsers.length}
                            />
                            <TableBody>
                                {stableSort(filteredUsers, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((user, index) => {
                                        const isItemSelected = selected && selected.id === user.id;
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <StyledTableRow
                                                hover
                                                onClick={(event) => handleClick(event, user.id)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={user.id}
                                                selected={isItemSelected}
                                                className={clsx(
                                                    { [classes.deleted]: user.status_desc === statusConfigs.Deleted },
                                                    { [classes.inactive]: user.status_desc === statusConfigs.Inactive },
                                                    { [classes.panding]: user.status_desc === statusConfigs.Pending }
                                                )}
                                            >
                                                <StyledTableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                                    {`${user.firstName} ${user.lastName}`}
                                                </StyledTableCell>
                                                <StyledTableCell>{user.email}</StyledTableCell>
                                                <StyledTableCell>{user.phone_number}</StyledTableCell>
                                                <StyledTableCell>{user.company_name}</StyledTableCell>
                                                <StyledTableCell>{user.status_desc}</StyledTableCell>
                                            </StyledTableRow >
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <StyledTableRow style={{ height: 53 * emptyRows }}>
                                        <StyledTableCell colSpan={6} />
                                    </StyledTableRow >
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>

                <DialogAddUser
                    open={openAddDialog}
                    setOpen={setOpenAddDialog} />

                {selected &&
                    <Fragment>
                        <DialogEditUser
                            open={openEditDialog}
                            setOpen={setOpenEditDialog}
                            user={selected} />

                        <DialogActivateUser
                            open={openActivateDialog}
                            setOpen={setOpenActivateDialog}
                            user={selected} />

                        <DialogDeleteUser
                            open={openDeleteDialog}
                            setOpen={setOpenDeleteDialog}
                            user={selected} />
                    </Fragment>}
            </div>
    );
}