import { IconButton, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import MenuIcon from '@material-ui/icons/Menu';
import React, { Fragment } from 'react';
import ChipStatus from './ChipStatus';
import { StyledTableCell, StyledTableRow } from './RequestListHeader';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';

const dateFormat = require('dateformat');
const dateStringFormat = "dd-mm-yyyy";

export default function RequestTableRow(props) {

    const { request, setAnchorEl, selectedRequest, setSelectedRequest } = props;
    const isItemSelected = selectedRequest && selectedRequest.id === request.id;

    const handleClick = (id) => {

        if (!id) { return; }
        if (selectedRequest && selectedRequest.id === id) {
            return setSelectedRequest(null);
        }
        setSelectedRequest(request);
    };

    const handleMenuClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    return (
        <Fragment>
            <StyledTableRow
                hover
                onClick={() => handleClick(request.id)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={request.id}
                selected={isItemSelected}
            >
                <StyledTableCell padding="checkbox">
                    {!request.hasDelayRequest && request.isUrgent &&
                        <Tooltip title={"Urgent"}>
                            <InfoIcon style={{ color: "orange", marginLeft: '5px', marginBottom: '-5px' }} />
                        </Tooltip>}

                    {request.hasDelayRequest &&
                        <Tooltip title={"This request has a delay request"}>
                            <AssignmentLateIcon style={{ color: "orangered", marginLeft: '5px', marginBottom: '-5px', fontSize: 26 }} />
                        </Tooltip>}

                </StyledTableCell>
                <StyledTableCell component="th" id={`enhanced-table-row-${request.id}`} scope="row" padding="none">
                    {request.requestType?.description || ""}
                </StyledTableCell>
                <StyledTableCell>{request.request_number}</StyledTableCell>
                <StyledTableCell>{request.reference_number}</StyledTableCell>
                <StyledTableCell>{request.description}</StyledTableCell>
                <StyledTableCell>{request.assetTypes}</StyledTableCell>
                <StyledTableCell align="center">{<ChipStatus status={request.status} />}</StyledTableCell>
                <StyledTableCell>{request.createdBy?.fullName || ""}</StyledTableCell>
                <StyledTableCell>{request.createdBy?.company_name || ""}</StyledTableCell>
                <StyledTableCell align="right">{dateFormat(new Date(request.created_on), dateStringFormat)}</StyledTableCell>
                <StyledTableCell align="right">{dateFormat(new Date(request.start_date), dateStringFormat)}</StyledTableCell>
                <StyledTableCell align="right">{dateFormat(new Date(request.end_date), dateStringFormat)}</StyledTableCell>
                <StyledTableCell padding="checkbox">
                    <Tooltip title={"Menu"}>
                        <IconButton size='small'
                            onClick={handleMenuClick}>
                            <MenuIcon />
                        </IconButton>
                    </Tooltip>
                </StyledTableCell>
            </StyledTableRow >
        </Fragment>
    );
}