import { Card, CardMedia, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import { appConfigurations } from '../../utils/configs';
import imgApproval from './../../img/screenshots/screenshot_approval.png';
import imgApproval1 from './../../img/screenshots/screenshot_approval1.png';
import imgDashboard from './../../img/screenshots/screenshot_dashboard.png';
import imgNotification from './../../img/screenshots/screenshot_notification.png';
import imgNotification1 from './../../img/screenshots/screenshot_notification1.png';
import imgProjectCreate from './../../img/screenshots/screenshot_project_create.png';
import imgProjectTrack from './../../img/screenshots/screenshot_project_track.png';
import imgProjectTrackAmp from './../../img/screenshots/screenshot_project_track_amp.png';
import imgRequestList from './../../img/screenshots/screenshot_request_list.png';
import Footer from './Authorization/footer';

const useStyles = makeStyles(theme => ({
  grid: {
    //margin: theme.spacing(2),
  },
  root: {
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(10),
    marginBottom: theme.spacing(10),
    flexGrow: 1,
  },
  heading1: {
    fontSize: '1.5rem',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  heading2: {
    fontSize: '1.3rem',
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    color: theme.palette.primary.dark,
  },
  divHeading3: {
    '& h3': {
      fontSize: '1.1rem',
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(1.5),
      color: theme.palette.primary.dark,
    },
    '& ul': {
      margin: 0,
    },
    '& li': {
      fontSize: '1.0rem',
      marginLeft: theme.spacing(5),
      marginTop: theme.spacing(0.25),
    }
  },
  paragraph: {
    fontSize: '1.0rem',
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(3),
  },
  bulletedParagraph: {
    fontSize: '1.0rem',
    marginLeft: theme.spacing(3),
  },
  screenshot: {
    width: '628px',
    margin: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },
}));

function AmpCheckListItem(props) {

  const classes = useStyles();
  const { title, items } = props;

  return (
    <Grid className={classes.divHeading3}>
      <Typography variant="h3">
        {title}
      </Typography>
      <ul>
        {items.map(i =>
          <li>
            <Typography variant="body2" align="justify">
              {i}
            </Typography>
          </li>)}
      </ul>
    </Grid>
  )
}

export default function HomePage() {

  const [t] = useTranslation();
  const classes = useStyles();
  const isAmpApp = appConfigurations.isAmpApp;

  return (
    <div>
      <Grid className={classes.root}>
        <Grid className={classes.grid}>
          <Typography variant="h2" className={classes.heading1}>
            &bull; {t('pageInfoProjRegister')}
          </Typography>
          <Typography className={classes.paragraph}>
            {t('pageInfoProjRegisterInfo')}
          </Typography>
          <ul>
            <li>
              <Typography align="justify" className={classes.bulletedParagraph}>
                {t('pageInfoReqType')}
              </Typography>
            </li>
            <li>
              <Typography align="justify" className={classes.bulletedParagraph}>
                {t('pageInfoReqDescription')}
              </Typography>
            </li>
            <li>
              <Typography align="justify" className={classes.bulletedParagraph}>
                {t('pageInfoReqRef')}
              </Typography>
            </li>
            <li>
              <Typography align="justify" className={classes.bulletedParagraph}>
                {t('pageInfoReqMotivation')}
              </Typography>
            </li>
            <li>
              <Typography align="justify" className={classes.bulletedParagraph}>
                {t('pageInfoReqStartEnd')}
              </Typography>
            </li>
            <li>
              <Typography align="justify" className={classes.bulletedParagraph}>
                {t('pageInfoReqAttachments')}
              </Typography>
            </li>
          </ul>
          <Card elevation={4} className={classes.screenshot}>
            <CardMedia
              component="img"
              image={imgProjectCreate}
            />
          </Card>

          <Typography variant="h2" className={classes.heading1}>
            &bull; {t('pageInfoProjTrack')}
          </Typography>
          <Typography align="justify" className={classes.paragraph}>
            {t('pageInfoProjTrackInfo')}
          </Typography>
          <Card elevation={4} className={classes.screenshot}>
            <CardMedia
              component="img"
              image={isAmpApp ? imgProjectTrackAmp : imgProjectTrack}
            />
          </Card>

          <Typography variant="h2" className={classes.heading1}>
            &bull; {t('pageInfoReqList')}
          </Typography>
          <Typography align="justify" className={classes.paragraph}>
            {t('pageInfoReqListInfo')}
          </Typography>
          <Card elevation={4} className={classes.screenshot} style={{ width: '800px' }}>
            <CardMedia
              component="img"
              image={imgRequestList}
            />
          </Card>
          {
            !isAmpApp &&
            <div>
              <Typography variant="h2" className={classes.heading1}>
                &bull; {t('pageInfoDashboard')}
              </Typography>
              <Typography align="justify" className={classes.paragraph}>
                {t('pageInfoDashboardInfo')}
              </Typography>
              <Card elevation={4} className={classes.screenshot}>
                <CardMedia
                  component="img"
                  image={imgDashboard}
                />
              </Card>
            </div>
          }
          <Typography variant="h2" className={classes.heading1}>
            &bull; {t('pageInfoNotification')}
          </Typography>
          <Typography align="justify" className={classes.paragraph}>
            {t('pageInfoNotificationInfo1')}
          </Typography>
          <Card elevation={4} className={classes.screenshot} style={{ width: '350px' }}>
            <CardMedia
              component="img"
              image={imgNotification}
            />
          </Card>
          <Typography align="justify" className={classes.paragraph}>
            {t('pageInfoNotificationInfo2')}
          </Typography>
          <Card elevation={4} className={classes.screenshot} style={{ width: '350px' }}>
            <CardMedia
              component="img"
              image={imgNotification1}
            />
          </Card>

          <Typography variant="h2" className={classes.heading1}>
            &bull; {t('pageInfoApproval')}
          </Typography>
          <Typography align="justify" className={classes.paragraph}>
            {t('pageInfoApprovalInfo1')}
          </Typography>
          <Card elevation={4} className={classes.screenshot} style={{ width: '350px' }}>
            <CardMedia
              component="img"
              image={imgApproval}
            />
          </Card>
          <Card elevation={4} className={classes.screenshot} style={{ width: '350px' }}>
            <CardMedia
              component="img"
              image={imgApproval1}
            />
          </Card>

          {isAmpApp &&
            <>
              <Typography variant="h2" className={classes.heading1}>
                &bull; {t('Checklist - Aanmeldprocedure (mutatie) projecten')}
              </Typography>
              <Typography className={classes.paragraph}>
                {t('Checklist (Hulplijst ter beeldvorming en keuze voor punt 8 in het Aanmeldformulier (Mutatie) Projecten)')}
              </Typography>
              <Typography variant="h2" className={classes.heading2}>
                {t('Aanvraag heeft invloed op;')}
              </Typography>

              <AmpCheckListItem title={t('Beeldkwaliteitsplan')}
                items={[t('Gevelaanzichten'), t('Voetafdruk gebouw'), t('Omgeving')]}
              />

              <AmpCheckListItem title={t('Wettelijke eisen')}
                items={[t('Toegankelijkheid'), t('Mindervalide voorzieningen'), t('Arbo'), t('Calamiteiten'), t('Vergunningen')]}
              />

              <AmpCheckListItem title={t('Milieu aspecten')}
                items={[t('Cooperatieve vereniging Milieu (CVM)'), t('Levering- en opslag bedrijfsstoffen'), t('Geluidscontouren HTCE')]}
              />

              <AmpCheckListItem title={t('Infrastructuur')}
                items={[t('Bovengronds infra'), t('Ondergrondse infra')]}
              />

              <AmpCheckListItem title={t('Bouwkundig')}
                items={[t('Wanden'), t('Vloeren'), t('Plafonds'), t('Deuren'), t('Vast interieur'), t('Dak')]}
              />

              <AmpCheckListItem title={t('Technische installaties')}
                items={[t('Elektrotechnisch'), t('Werktuigbouwkundig'), t('Meet & Regel installatie'), t('ICT Netwerk)'), t('Zend- en ontvangstapparatuur (Portofoon, Satellietschotels, Antennes)')]}
              />

              <AmpCheckListItem title={t('Security & Safety')}
                items={[t('Noodroute (Vluchtplan) en noodaanduidingen (Ontruimingsplattegronden)'), t('Toegankelijkheid'), t('Bereikbaarheid bij calamiteiten'), t('(Keys & Lock) Sluitplan (Buiten-schil, Binnen-schil)'), t('Procedures (afhandeling van afspraken)')]}
              />

              <Typography variant="h2" className={classes.heading2}>
                {t('Facilitaire systemen')}
              </Typography>

              <AmpCheckListItem title={t('(RBS) Risico Beheer Systeem')}
                items={[t('Inbraak (Glasbreuk, Zone detectie, (deur)contacten)'), t('Toegang (inclusief Zonering en Badge autorisatie)'), t("CCTV (camera's)"), t('Communicatie (o.a. intercom)')]}
              />

              <AmpCheckListItem title={t('(BIS) Brandmeld Informatie Systeem')}
                items={[t("Brandmeldcentrale ( o.a. PvE (Programma van Eisen) – 'Gebruiksvergunning')"), t('Brandmelders en indicatoren'), t('Sprinkler'), t('Ontruiming(sdrukkers)')]}
              />

              <AmpCheckListItem title={t('(SCADA-IBS) Gebouwbeheersysteem')}
                items={[t('Klimaat - Regeltechniek, Sensoren en Actuatoren (verwarming / koeling / lucht)'), t('Verlichting – Besturing'), t("Controle 'klanten'  installaties"), t('Visualisatie (o.a. plattegrond) en bediening'), t('Testrapporten, tekeningen en documenten ')]}
              />

              <AmpCheckListItem title={t('Archiefsysteem')}
                items={[t('CAD afspraken '), t('Tekeningen (o.a. collectief verplichte – omvang / detail)'), t('Technische documentatie')]}
              />

              <AmpCheckListItem title={t('Picasse systeem (Vervanger van Pager systeem)')}
                items={[t('Calamiteiten oproep'), t('BHV Team / Pagers')]}
              />

              <AmpCheckListItem title={t('Energie management Systeem (ERBIS)')}
                items={[t('Energiestromen'), t('Bemetering')]}
              />

              <AmpCheckListItem title={t('Zend- en ontvangstapparatuur')}
                items={[t('Portofoon'), t('Centrale antenne installatie (CAI)  Functionaliteit vervallen vanaf 01-01-2017'), t('Satellietschotels'), t('Antennes')]}
              />
            </>}

          <Typography align="justify" className={classes.paragraph}>
            {t('pageInfoApprovalInfo2')}
            <Link variant="body2" href={`mailto:${isAmpApp ? "tech.info.beheer@hightechcampus.com" : "acceptatiecommissie@rhdhv.com"}`} target="_top">
              {t('contactUs')}
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}