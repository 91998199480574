import { Avatar, Chip } from '@material-ui/core';
import React, { useContext } from 'react';
import i18n from '../../i18nextConf';
import { languages } from '../../utils/AppConstants';
import { AnonymousAppContext } from '../AnonymousPage';

export default function LanguageSwitch() {

    const appContext = useContext(AnonymousAppContext);
    const { state, dispatch } = appContext;
    const { selectedLanguage } = state;

    const handleLanguageChange = () => {
        const newLanguage = selectedLanguage === languages.en ? languages.nl : languages.en;
        i18n.changeLanguage(newLanguage);
        dispatch({ "type": "setSelectedLanguage", value: newLanguage })
    }

    return (
        <Chip
            color="primary"
            avatar={<Avatar>{selectedLanguage === 'en' ? 'NL' : 'EN'}</Avatar>}
            label={selectedLanguage === 'en' ? 'Dutch' : 'English'}
            onClick={handleLanguageChange}
            variant="outlined"
        />
    );
}