import { Checkbox, FormControlLabel } from '@material-ui/core';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { appConfigurations } from '../../../utils/configs';
import SearchTextBox from '../../Common/SearchTextBox';

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      //backgroundColor: theme.palette.common.white,
    },
  },
}))(TableRow);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {

  const isAmpApp = appConfigurations.isAmpApp;
  const [t] = useTranslation();
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    { id: 'requestType.description', disablePadding: true, label: t('generalRequestType') },
    { id: 'request_number', disablePadding: false, label: t('generalNumber') },
    { id: 'reference_number', disablePadding: false, label: t(isAmpApp ? 'projectName' : 'generalRef') },
    { id: 'description', disablePadding: false, label: t(isAmpApp ? 'buildingNumber' : 'generalDescription') },
    { id: 'assetTypes', disablePadding: false, label: t('generalAssetType') },
    { id: 'status.description', align: "center", disablePadding: false, label: t('generalStatus') },
    { id: 'createdBy.fullName', disablePadding: false, label: t('generalCreatedBy') },
    { id: 'createdBy.company_name', disablePadding: false, label: t('pageUsersCompany') },
    { id: 'created_on', align: "right", disablePadding: false, label: t('generalCreatedOn') },
    { id: 'start_date', align: "right", disablePadding: false, label: t('generalStartDate') },
    { id: 'end_date', align: "right", disablePadding: false, label: t('generalEndDate') },
  ];

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontSize: 16 }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        <StyledTableCell padding="checkbox" />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    '& .MuiTextField-root': {
      width: 250,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    }
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 40%',
    '& svg': {
      marginBottom: theme.spacing(-0.5),
      marginRight: theme.spacing(1),
    }
  },
}));

const EnhancedTableToolbar = (props) => {

  const [t] = useTranslation();
  const classes = useToolbarStyles();
    const { selected, title, icon, showUrgentOnly, handleShowUrgentOnly, handleSearch, isAmpApp } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: selected !== null,
      })}
    >
      <Typography className={classes.title} variant="h5" color="primary" id="tableTitle" component="div">
        {icon}
        {title}
          </Typography>
          {!isAmpApp && <FormControlLabel
              component="div"
              control={<Checkbox checked={showUrgentOnly} onChange={handleShowUrgentOnly} />}
              label={t('showUrgentOnly')}
          />}
    
      <SearchTextBox handleChange={handleSearch} />
    </Toolbar>
  );
};

export { EnhancedTableToolbar, EnhancedTableHead, stableSort, getComparator };
