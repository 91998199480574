import { Button, Card, CardActions, CardContent, CardHeader, Grid, List, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle as DoneIcon, NewReleases as AssignedIcon, NotificationsActive as NotificationIcon, WatchLater as PendingIcon } from '@material-ui/icons';
import ReplyIcon from '@material-ui/icons/Reply';
import TransferIcon from '@material-ui/icons/TransferWithinAStation';
import clsx from 'clsx';
import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { appStatuses, languages, roleConfigs } from './../../../utils/AppConstants';
import RequestTrackListItem from './RequestTrackListItem';
import PanToolIcon from '@material-ui/icons/PanTool';
import BlockIcon from '@material-ui/icons/Block';
import { AppContext } from '../../AuthenticatedPage';
import { postData } from '../../../utils/ApiRequest';
import { sendActionReminderUrl } from '../../../utils/ApiUrl';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import InfoIcon from '@material-ui/icons/Info';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { appConfigurations } from '../../../utils/configs';

const dateFormat = require('dateformat');
const dateStringFormat = "dd-mm-yyyy";

const useStyles = makeStyles((theme) => ({

  detailsCard: {
    minWidth: 300,
    maxWidth: 400,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  assignedCard: {
    backgroundImage: 'linear-gradient(0deg, #FF7F50, #F8F8FF)',
  },
  completedCard: {
    backgroundImage: 'linear-gradient(0deg, #008000, #F8F8FF)',
  },
  pendingCard: {
    backgroundImage: 'linear-gradient(0deg, #DCDCDC, #F8F8FF)',
  },
  holdCard: {
    backgroundImage: 'linear-gradient(0deg, #ff9900, #F8F8FF)',
  },
  cardIcon: {
    width: 40,
    height: 40,
    color: '#7A7A7A'
  },
  forwardIcon: {
    transform: 'scaleX(-1)'
  },
  MuiCardActions_root: {
    display: 'block',
    '& .MuiButton-root': {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1)
    },
  },
  infoIcon: {
    marginBottom: theme.spacing(-1),
  }
}));

export default function RequestTrackCard(props) {

  const [t] = useTranslation();
  const classes = useStyles();
  const { assetType, assetTypeAction, setOpenActTransfer, setCurrentAction, canShowForward, canShowReminder, handleActionClick, currentUserId } = props;
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const { selectedLanguage, authToken, currentUserRole } = state;
  const [isBusy, setIsBusy] = useState(false);

  const lastReminderDate = assetTypeAction?.lastReminderDate ? new Date(assetTypeAction.lastReminderDate) : new Date();
  const difference = (new Date() - lastReminderDate) / 3600000;
  const hoursFromLastReminder = isNaN(difference) ? 0 : difference;
  const formattedDate = dateFormat(new Date(lastReminderDate), "dd-mm-yyyy HH:MM");
  const tooltipText = t('actionReminderTooltipText', { remaining_hours: 24 - Math.round(difference) });

  const isAmpApp = appConfigurations.isAmpApp;
  const isUser = currentUserRole?.role?.role_static === roleConfigs.user;
  const hideResponsibleUserName = isAmpApp && isUser;

  const handleOpenActionTransfer = () => {
    assetTypeAction.assetType = assetType ? assetType : "";
    setCurrentAction(assetTypeAction);
    setOpenActTransfer(true);
  };

  async function handleSendReminder(event) {
    event.preventDefault();

    try {
      setIsBusy(false);
      const lang = selectedLanguage === languages.nl ? 'nl' : 'en';
      const response = await postData(sendActionReminderUrl(assetTypeAction.request_action_id, lang), null, authToken);
      const status = response.status;
      if (status === 200 || status === 201) {
        assetTypeAction.lastReminderDate = new Date();
        dispatch({ "type": "setSnackBarInfo", value: { message: t('actionReminderSuccessMessage'), isError: false } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else if (status === 401) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
    }
    finally {
      setIsBusy(false);
    }
  };

  const getAvatar = () => {
    switch (assetTypeAction.status) {
      case appStatuses.assigned:
        return <AssignedIcon className={classes.cardIcon} />;
      case appStatuses.pending:
        return <PendingIcon className={classes.cardIcon} />;
      case appStatuses.hold:
        return <PanToolIcon className={classes.cardIcon} />;
      case appStatuses.notRequired:
        return <BlockIcon className={classes.cardIcon} />;
      case appStatuses.expired:
        return <RemoveCircleIcon className={classes.cardIcon} />;
      case appStatuses.rejected:
        return <ThumbDownIcon className={classes.cardIcon} />;
      default:
        return <DoneIcon className={classes.cardIcon} />;
    }
  }

  return (
    <Card elevation={5}
      className={clsx(classes.detailsCard, {
        [classes.assignedCard]: assetTypeAction.status === appStatuses.assigned,
        [classes.completedCard]: assetTypeAction.status === appStatuses.finished || assetTypeAction.status === appStatuses.approved,
        [classes.pendingCard]: assetTypeAction.status === appStatuses.pending,
        [classes.holdCard]: assetTypeAction.status === appStatuses.hold,
      })}>
      {assetTypeAction?.user_name &&
        <CardHeader
          avatar={getAvatar()}
          title={<Typography variant="h6">{assetTypeAction.node_name}</Typography>}
          subheader={assetTypeAction.status}
        />}

      <CardContent>
        <List disablePadding>
          {!hideResponsibleUserName &&
            <RequestTrackListItem isCard={true} requestAction={assetTypeAction}
              primaryText={t('cardReqTrackResponsible')}
              secondaryText={assetTypeAction?.user_name || 'N/A'} />}
          <RequestTrackListItem isCard={true} requestAction={assetTypeAction} avatar="calander"
            primaryText={t('cardReqTrackAssignmentDate')}
            secondaryText={assetTypeAction.assignment_date ? dateFormat(new Date(assetTypeAction.assignment_date), dateStringFormat) : 'N/A'} />
          {assetTypeAction.finish_date &&
            <RequestTrackListItem isCard={true} requestAction={assetTypeAction} avatar="calander"
              primaryText={t('cardReqTrackFinishDate')}
              secondaryText={assetTypeAction.finish_date ? dateFormat(new Date(assetTypeAction.finish_date), dateStringFormat) : 'N/A'} />}

          {assetTypeAction.status === appStatuses.hold && assetTypeAction.remarks &&
            <RequestTrackListItem isCard={true} requestAction={assetTypeAction}
              primaryText={t('generalRemarks')}
              secondaryText={assetTypeAction.remarks} />}

        </List>
      </CardContent>
      <CardActions className={classes.MuiCardActions_root}>
        <div>
          {assetTypeAction?.user_name && [appStatuses.assigned, appStatuses.hold].includes(assetTypeAction.status) &&
            <Grid container spacing={0} direction="row" alignItems="center" justify="center">
              {canShowReminder && assetTypeAction?.request_action_id &&
                <Fragment>
                  {assetTypeAction?.lastReminderDate && hoursFromLastReminder <= 24 ?
                    <Fragment>
                      <Tooltip arrow title={tooltipText} >
                        <InfoIcon fontSize="small" className={classes.infoIcon} />
                      </Tooltip>
                      <Button
                        size="small"
                        variant="contained"
                        startIcon={<NotificationsOffIcon />}
                        disabled
                      >
                        {formattedDate}
                      </Button>
                    </Fragment> :
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<NotificationIcon />}
                      onClick={handleSendReminder}
                      disabled={isBusy}
                    >
                      {t('cardReqTrackSendReminder')}
                    </Button>}
                </Fragment>}
              {canShowForward && [appStatuses.assigned, appStatuses.hold].includes(assetTypeAction.status) &&
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleOpenActionTransfer}
                  startIcon={<TransferIcon className={classes.forwardIcon} />}
                  disabled={isBusy}
                >
                  {t('cardReqTrackTransfer')}
                </Button>}
            </Grid>
          }
        </div>
        <div>
          {assetTypeAction.user_id === currentUserId && [appStatuses.active, appStatuses.assigned].includes(assetTypeAction.status) &&
            <Grid container spacing={0} direction="row" alignItems="center" justify="center">
              <Button
                size="small"
                variant="contained"
                onClick={() => handleActionClick(assetTypeAction)}
                startIcon={<ReplyIcon />}
              >
                {assetType} {assetTypeAction.node_name}
              </Button>
            </Grid>}
        </div>
      </CardActions>
    </Card>
  );
}