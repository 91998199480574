import { actions, FlowChart } from "@mrblenny/react-flow-chart";
import { mapValues } from "lodash";
import React, { Component } from "react";
import BackDrop from "../Common/BackDrop";
import { getData, postData } from './../../utils/ApiRequest';
import { getWorkflowUrl, updateWorkflowContentsUrl } from './../../utils/ApiUrl';
import { GetToken } from './../../utils/tokenActions';
import CanvasInnerCustom from './CanvasInnerCustom';
import CanvasOuterCustom from './CanvasOuterCustom';
import CustomPort from './CustomPort';
import NodeCustom from './NodeCustom';
import NodeInnerCustom from './NodeInnerCustom';

const chartSimple = {
    workflow_id: "",
    offset: {},
    nodes: {},
    links: {},
    selected: {},
    hovered: {}
};

const startNodeKey = "Start";
const endNodeKey = "End";
const taskKey = "Task";
const userKey = "User";
const assignAssetTypeKey = "Assign asset type";
const assetTypeKey = "Asset-type";
const mergeAssetTypeKey = "Merge asset types";

class Workflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scale: 1,
            workflow_id: chartSimple.workflow_id,
            links: chartSimple.links,
            nodes: chartSimple.nodes,
            offset: chartSimple.offset,
            hovered: chartSimple.hovered,
            selected: {},
            description: "",
            remarks: "",
            isLoaded: false,
            setSnackBarInfo: props.setSnackBarInfo,
            setOpenSnackbar: props.setOpenSnackbar
        };
    }

    async componentDidMount() {

        if (!this.props.workflowId)
            return;

        const apiURL = getWorkflowUrl(this.props.workflowId);
        const response = await getData(apiURL, GetToken());

        if (response.status === 200) {
            const response_json = await response.json();
            this.setState({
                workflow_id: response_json.workflow_id,
                description: response_json.description,
                remarks: response_json.remarks,
                offset: response_json.offset,
                nodes: response_json.nodes,
                links: response_json.links,
                isLoaded: true,
            });

            this.props.setSnackBarInfo({ message: "Workflow loaded", isError: false });
            this.props.setOpenSnackbar(true);
        }
    }

    clearState() {
        this.setState({ nodes: {} });
        this.setState({ links: {} });
    }

    GetNodeNameType(nodeId) {
        // eslint-disable-next-line
        for (let [nodeKey, node] of Object.entries(this.state.nodes)) {
            if (node.id === nodeId) {
                return {
                    key: node.id,
                    name: node.properties.name,
                    type: node.properties.type,
                    actionType: node.properties.actionType,
                    portCount: Object.keys(node.ports)
                };
            }
        }

        return { name: "", type: "", portCount: 0 };
    }

    ValidateWorkflowObjects() {

        if (Object.keys(this.state.nodes).length <= 0) {
            this.props.setSnackBarInfo({ message: "No objects defined in the workflow", isError: true });
            this.props.setOpenSnackbar(true);
            return false;
        }
        else if (Object.keys(this.state.links).length <= 0) {
            this.props.setSnackBarInfo({ message: "No links defined in the workflow", isError: true });
            this.props.setOpenSnackbar(true);
            return false;
        }

        console.log(this.state.nodes)

        const mergeAssetTypes = Object.entries(this.state.nodes).filter(
            ([key, o]) => o.properties.type === mergeAssetTypeKey);
        if (mergeAssetTypes && mergeAssetTypes.length > 1) {
            this.props.setSnackBarInfo({ message: "Multiple merge are not allowed", isError: true });
            this.props.setOpenSnackbar(true);
            return false;
        }

        const assignAssetTypeNodes = Object.entries(this.state.nodes).filter(
            ([key, o]) => o.properties.type === assignAssetTypeKey);
        if (assignAssetTypeNodes && assignAssetTypeNodes.length > 1) {
            this.props.setSnackBarInfo({ message: "Multiple 'Assign asset type' tasks are not allowed", isError: true });
            this.props.setOpenSnackbar(true);
            return false;
        }

        if ((!assignAssetTypeNodes || assignAssetTypeNodes.length <= 0) && mergeAssetTypes && mergeAssetTypes.length > 0) {
            this.props.setSnackBarInfo({ message: "Merge asset type is not allowed without assign asset type", isError: true });
            this.props.setOpenSnackbar(true);
            return false;
        }

        var hasStartNode = false;
        var hasEndNode = false;
        var startNodeId = "";
        //var endNodeId = "";
        let assetTypes = [];

        // eslint-disable-next-line
        for (let [nodeKey, workflowNode] of Object.entries(this.state.nodes)) {

            if (workflowNode.properties.type === startNodeKey) {
                startNodeId = workflowNode.properties.id;
                hasStartNode = true;
            }
            else if (workflowNode.properties.type === endNodeKey) {
                //endNodeId = nodeKey;
                hasEndNode = true;
            }

            var hasInNode = false;
            var hasOutNode = false;

            //Create filter function
            Object.filter = (obj, predicate) =>
                Object.keys(obj)
                    .filter(key => predicate(obj[key]))
                    .reduce((res, key) => (res[key] = obj[key], res), {});// eslint-disable-line

            if (workflowNode.type === assetTypeKey) {

                const existingAssetType = assetTypes.find((nodeid) => nodeid === workflowNode.properties.type);
                if (!existingAssetType) {
                    assetTypes.push(workflowNode.properties.type);
                }
                else {
                    this.props.setSnackBarInfo({ message: `Asset type '${workflowNode.properties.name}' cannot appear more than once`, isError: true });
                    this.props.setOpenSnackbar(true);
                    return false;
                }
            }

            else if (workflowNode.properties.type === mergeAssetTypeKey) {
                //Validate outputs
                var filtered = Object.filter(this.state.links, link => link.from && link.from.nodeId === workflowNode.id);
                if (filtered && Object.keys(filtered).length > 1) {
                    this.props.setSnackBarInfo({ message: `'${workflowNode.properties.name}' cannot have more than one output links`, isError: true });
                    this.props.setOpenSnackbar(true);
                    return false;
                }
            }

            else if (workflowNode.actionType === userKey) {

                //Validate outputs
                var filtered1 = Object.filter(this.state.links, link => link.from && link.from.nodeId === workflowNode.id);
                if (filtered1 && Object.keys(filtered1).length > 1) {
                    this.props.setSnackBarInfo({ message: `User '${workflowNode.properties.name}' cannot have more than one output links`, isError: true });
                    this.props.setOpenSnackbar(true);
                    return false;
                }
            }

            for (let [linkKey, link] of Object.entries(this.state.links)) {// eslint-disable-line
                if (link.from.nodeId === workflowNode.id) {
                    hasOutNode = true;
                }
                else if (link.to.nodeId === workflowNode.id) {
                    hasInNode = true;
                }

                //Check start node connection
                if (link.from.nodeId === startNodeId) {
                    var nodeType = this.GetNodeNameType(link.to.nodeId);
                    if (nodeType.actionType !== userKey) {
                        this.props.setSnackBarInfo({ message: `Start node should be followed by a user`, isError: true });
                        this.props.setOpenSnackbar(true);
                        return false;
                    }
                }
                else {
                    var fromNodeType = this.GetNodeNameType(link.from.nodeId);
                    var toNodeType = this.GetNodeNameType(link.to.nodeId);

                    if (fromNodeType.actionType === userKey && toNodeType.actionType !== taskKey) {
                        this.props.setSnackBarInfo({ message: `User must be followed by a task`, isError: true });
                        this.props.setOpenSnackbar(true);
                        return false;
                    }
                    else if (fromNodeType.actionType === taskKey) {

                        if (fromNodeType.type === assignAssetTypeKey && toNodeType.actionType !== assetTypeKey) {
                            alert(JSON.stringify(fromNodeType))
                            alert(JSON.stringify(toNodeType))

                            this.props.setSnackBarInfo({ message: "Assign asset type must be followed by an asset type", isError: true });
                            this.props.setOpenSnackbar(true);

                            return false;
                        }
                        else if (toNodeType.actionType !== assetTypeKey &&
                            toNodeType.actionType !== userKey &&
                            toNodeType.type !== mergeAssetTypeKey &&
                            toNodeType.type !== endNodeKey) {
                            this.props.setSnackBarInfo({ message: "Task must be followed by a user or asset type or merge asset type or by an end", isError: true });
                            this.props.setOpenSnackbar(true);
                            return false;
                        }
                        else if (fromNodeType.type !== assignAssetTypeKey && toNodeType.actionType === assetTypeKey) {
                            this.props.setSnackBarInfo({ message: "Asset type must be followed by Assign asset type only", isError: true });
                            this.props.setOpenSnackbar(true);
                            return false;
                        }
                    }
                    else if (fromNodeType.type === assetTypeKey && toNodeType.actionType !== userKey) {
                        this.props.setSnackBarInfo({ message: "Asset type must be followed by a user", isError: true });
                        this.props.setOpenSnackbar(true);
                        return false;
                    }
                    else if (fromNodeType.type === mergeAssetTypeKey && toNodeType.actionType !== userKey) {
                        this.props.setSnackBarInfo({ message: "Merge asset type must be followed by a user", isError: true });
                        this.props.setOpenSnackbar(true);
                        return false;
                    }
                }
            }

            if ((!hasInNode || !hasOutNode) && Object.keys(workflowNode.ports).length > 1) {
                if (!hasInNode) {
                    this.props.setSnackBarInfo({ message: `'${workflowNode.properties.name}' has no input link`, isError: true });
                    this.props.setOpenSnackbar(true);
                    return false;
                }
                if (!hasOutNode) {
                    this.props.setSnackBarInfo({ message: `'${workflowNode.properties.name}' has no output link`, isError: true });
                    this.props.setOpenSnackbar(true);
                    return false;
                }
            }
        }

        if (!hasStartNode) {
            this.props.setSnackBarInfo({ message: `No Start node found`, isError: true });
            this.props.setOpenSnackbar(true);
            return false;
        }
        else if (!hasEndNode) {
            this.props.setSnackBarInfo({ message: `No End node found`, isError: true });
            this.props.setOpenSnackbar(true);
            return false;
        }

        assetTypes = [];
        return true;
    }

    async saveState() {

        if (this.ValidateWorkflowObjects()) {

            await postData(updateWorkflowContentsUrl(this.state.workflow_id), this.state, GetToken())
                .then(response => {
                    const status = response.status;
                    if (status === 200 || status === 201) {
                        this.props.setSnackBarInfo({ message: "Workflow has been saved successfully", isError: false });
                        this.props.setOpenSnackbar(true);
                        this.componentDidMount();
                        return this.props.history.goBack();
                    }
                    else if (status === 401) {
                        this.props.setSnackBarInfo({ message: "Session expired. Please login again", isError: true });
                        this.props.setOpenSnackbar(true);
                    }
                    else {
                        this.props.setSnackBarInfo({ message: "Error while saving the data. Please try again. If the problem persists, do write email to us", isError: true });
                        return this.props.setOpenSnackbar(true);
                    }
                })
        }
    }

    render() {
        const chart = this.state;
        const { isLoaded } = this.state;
        const stateActions = mapValues(actions, func => (...args) =>
            this.setState(func(...args))
        );

        return (
            isLoaded ?
                <div>
                    <FlowChart chart={chart}
                        callbacks={stateActions}
                        Components={{
                            Node: NodeCustom,
                            NodeInner: NodeInnerCustom,
                            CanvasOuter: CanvasOuterCustom,
                            CanvasInner: CanvasInnerCustom,
                            Port: CustomPort,
                        }} />
                </div>
                :
                <BackDrop loading={!isLoaded} />
        );
    }
}

export default Workflow;