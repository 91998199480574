import DateFnsUtils from '@date-io/date-fns';
import { Button, CircularProgress, Fab, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIos as BackIcon, PostAdd as AddDocumentIcon, Save as SaveIcon, Description as DescriptionIcon } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { attachmentType, downloadType, requestValidation } from '../../../utils/AppConstants';
import { appConfigurations, dataFetchKeys } from '../../../utils/configs';
import { appRoute } from '../../../utils/routeConfigs';
import DeleteIcon from '@material-ui/icons/Delete';
import { getData, postFormData } from './../../../utils/ApiRequest';
import { getUpdateRequestUrl, requestAttachementGetUrl } from './../../../utils/ApiUrl';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext, queryClient } from './../../AuthenticatedPage';
import ConfirmationDialog from './../../Common/ConfirmationDialog';
import DropzoneAttachmentDialog from '../../Common/DropzoneAttachmentDialog';
import ComponentAttachments from '../Attachments/ComponentAttachments';

const useStyles = makeStyles(theme => ({

  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
    marginTop: theme.spacing(10),
  },
  paper: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  heading: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: '200px',
  },
  submit: {
    marginLeft: theme.spacing(2),
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      width: '40%',
      minWidth: '200px'
    },
    textAlign: 'center',
    width: '100%'
  },
  autocomplete: {
    marginBottom: theme.spacing(0.5),
  },
  buttonsDiv: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(10),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  documentList: {
    width: '40%',
    minWidth: '200px',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(11),
  },
  headerText: {
    fontSize: '1.5rem',
  },
  progress: {
    marginRight: theme.spacing(1),
  },
}));

export default function RequestEditPage() {

  const [t] = useTranslation();
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const { selectedRequest, authToken } = state;
  let history = useHistory();
  let documentId = 0;
  const [attachments, setAttachments] = React.useState([]);
  const [newAttachments, setNewAttachments] = React.useState([]);
  const [request, setRequest] = React.useState(null);
  const [description, setDescription] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [motivation, setMotivation] = useState("");
  const [expStartDate, setExpStartDate] = useState(new Date());
  const [expEndDate, setExpEndDate] = useState(new Date());
  const [remarks, setRemarks] = useState("");
  const [openAttachementDialog, setOpenAttachementDialog] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const isAmpApp = appConfigurations.isAmpApp;

  useEffect(() => {

    setRequest(selectedRequest || null);

    async function GetProjectAttachments() {

      if (!selectedRequest)
        return;

      const apiURL = requestAttachementGetUrl(selectedRequest.id, attachmentType.create);
      const response = await getData(apiURL, GetToken());
      if (response.status === 200 || response.status === 201) {
        const responseJson = await response.json();
        setAttachments(responseJson);
      }
      else
        setAttachments([]);
    }

    GetProjectAttachments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRequest]);

  useEffect(() => {
    setDescription(request?.description || "");
    setReferenceNumber(request?.reference_number || "");
    setMotivation(request?.motivation || "");
    setExpStartDate(!request ? "" : new Date(request.start_date));
    setExpEndDate(!request ? "" : new Date(request.end_date));
    setRemarks(request?.remarks || "");

  }, [request])

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    history.goBack();
  };

  const handleStartDateChange = (date) => {
    setExpStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setExpEndDate(date);
  };

  const handleAttachmentDialogClose = () => {
    setOpenAttachementDialog(false)
  }

  const handleOpenAttachmentDialog = () => {
    setOpenAttachementDialog(true)
  }
  const handleDeleteAttachment = async (fileId) => {
    const newArray = [...newAttachments];
    const newattachmentIndex = newArray.findIndex(a => a.id === fileId);
    if (newattachmentIndex >= 0) {
      newArray.splice(newattachmentIndex, 1);
      setNewAttachments(newArray);
    }
  }
  const handleUploadAttachment = async (f) => {

    if (!f || f.length <= 0) { return; }

    const newArray = [...newAttachments];
    f.forEach(file => {
      newArray.push({ id: ++documentId, path: file.path, data: file });
    })
    setNewAttachments(newArray);
    setOpenAttachementDialog(false);
  }

  async function handleSubmit(event) {

    event.preventDefault();

    try {
      setIsBusy(true);

      const formData = new FormData();
      formData.append("description", description);
      formData.append("reference_number", referenceNumber);
      formData.append("motivation", motivation);
      formData.append("start_date", expStartDate);
      formData.append("end_date", expEndDate);
      formData.append("remarks", remarks);

      for (const file of newAttachments) {
        formData.append('files', file.data, file.path);
      }

      const response = await postFormData(getUpdateRequestUrl(request.id), formData, authToken);
      const status = response.status;
      if (status === 200 || status === 201) {
        queryClient.invalidateQueries(dataFetchKeys.allRequests);
        queryClient.invalidateQueries(dataFetchKeys.notifications);
        dispatch({ "type": "setSnackBarInfo", value: { message: t('pageReqUpdateRequestUpdated'), isError: false } });
        dispatch({ "type": "setOpenSnackbar", value: true });
        history.push(appRoute.trackRequest);

        setOpenDialog(false);
      }
      else if (status === 401) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
    }
    finally {
      setIsBusy(false);
    }
  }

  function validateForm() {
    return (description && description.length > requestValidation.descriptionMin && description.length < requestValidation.descriptionMax &&
      referenceNumber && referenceNumber.length >= requestValidation.referenceNumberMin && referenceNumber.length <= requestValidation.referenceNumberMax &&
      motivation && motivation.length >= requestValidation.motivationMin && motivation.length <= requestValidation.motivationeMax &&
      expEndDate >= expStartDate &&
      remarks.length <= requestValidation.remarksMax);
  }

  if (!request)
    return <Fragment />

  return (
    <div className={classes.root}>
      <form className={classes.form} autoComplete="off">
        <Typography variant="h4" className={classes.heading}>
          {t('generalEditReq')}
        </Typography>
        <TextField
          variant="outlined"
          margin="none"
          fullWidth
          disabled
          label={t('generalRequestType')}
          value={request && request.requestType ? request.requestType.description : ""}
        /><br />
        <TextField
          margin="none"
          variant="outlined"
          required
          fullWidth
          multiline
          disabled={isBusy}
          id="description"
          label={t(isAmpApp ? 'buildingNumber': 'generalDescription')}
          name="description"
          autoComplete="off"
          value={description}
          helperText={t("charsRemaining", { num_char: requestValidation.descriptionMax - description.length })}
          error={description.length > requestValidation.descriptionMax}
          onChange={e => setDescription(e.target.value)}
        />
        <br />
        <TextField
          margin="none"
          variant="outlined"
          required
          disabled={isBusy}
          fullWidth
          id="referenceNumber"
          label={t(isAmpApp ? 'projectName' : 'pageReqCreateRefNo')}
          name="referenceNumber"
          autoComplete="off"
          helperText={t("charsRemaining", { num_char: requestValidation.referenceNumberMax - referenceNumber.length })}
          error={referenceNumber.length > requestValidation.referenceNumberMax}
          value={referenceNumber}
          onChange={e => setReferenceNumber(e.target.value)}
        />
        <br />
        <TextField
          margin="none"
          variant="outlined"
          required
          disabled={isBusy}
          fullWidth
          multiline
          id="motivation"
          label={t(isAmpApp ? 'briefDescription' : 'pageReqCreateMotivation')}
          name="motivation"
          autoComplete="off"
          helperText={t("charsRemaining", { num_char: requestValidation.motivationeMax - motivation.length })}
          error={motivation.length > requestValidation.motivationeMax}
          value={motivation}
          onChange={e => setMotivation(e.target.value)}
        />
        <br />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            required
            disabled={isBusy}
            fullWidth
            format="dd-MM-yyyy"
            margin="normal"
            id="date-picker-inline"
            label={t('pageReqCreateExpStartDate')}
            value={expStartDate}
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <br />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            required
            disabled={isBusy}
            fullWidth
            format="dd-MM-yyyy"
            margin="normal"
            id="date-picker-inline"
            label={t('pageReqCreateExpEndDate')}
            value={expEndDate}
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <br />
        </MuiPickersUtilsProvider>

        <TextField
          margin="none"
          variant="outlined"
          disabled={isBusy}
          fullWidth
          multiline
          id="remarks"
          label={t('generalRemarks')}
          name="remarks"
          autoComplete="off"
          value={remarks}
          helperText={t("charsRemaining", { num_char: requestValidation.remarksMax - remarks.length })}
          error={remarks.length > requestValidation.remarksMax}
          onChange={e => setRemarks(e.target.value)}
        />
        <div className={classes.buttonsDiv}>
          <Fab variant="extended" color="default" aria-label="add" onClick={handleClickOpenDialog} className={classes.button}>
            <BackIcon className={classes.extendedIcon} />
            {t('generalBack')}
          </Fab>
          <Fab variant="extended" color="primary" aria-label="add" onClick={handleSubmit} disabled={isBusy || !validateForm()} className={classes.button}>
            {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />}
            <SaveIcon className={classes.extendedIcon} />
            {t('generalSave')}
          </Fab>
        </div>
        <Grid container direction="row" className={classes.grid} spacing={3} justify="center" alignItems="center">
          <List dense={false} className={classes.documentList}
            subheader={
              <ListSubheader color="primary" className={classes.attachmentHeader} component="div" id="nested-list-subheader">
                <Typography className={classes.headerText}>
                  {t('generalAttachments')}
                </Typography>
              </ListSubheader>
            }>

            <ListItem style={{ display: 'flex', justifyContent: 'center' }} >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenAttachmentDialog}
                startIcon={<AddDocumentIcon />}
              >
                {t('generalAddAttachment')}
              </Button>
            </ListItem>
            <Grid item >
              <Grid container direction="row" justify="center" alignItems="center">
                {newAttachments.map(attachment =>
                  <ListItem style={{ minWidth: '500px' }} key={attachment.id} button divider={true}>
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={attachment.path}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" color="primary" disabled={isBusy} onClick={() => handleDeleteAttachment(attachment.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
              </Grid>
            </Grid>

            <Grid item>
              <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                <ComponentAttachments
                  attachments={attachments} setAttachments={setAttachments}
                  uploadDisabled={true}
                  typeDownload={downloadType.request}
                  disabledDownload={isBusy}
                />
              </Grid>
            </Grid>

          </List>
        </Grid>

        <DropzoneAttachmentDialog
          title={t('generalUploadDocument')}
          fileLimit={10}
          open={openAttachementDialog}
          handleSave={handleUploadAttachment}
          handleClose={handleAttachmentDialogClose}
          dropzoneTitle={t('pageReqCreateDragDropFile')} />
      </form>

      <ConfirmationDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} />
    </div>
  );
}