import { Tooltip } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { appConfigurations } from "../../utils/configs";
import ImgHTCHeader from './../../img/Background/High_Tech_Campus_Header.jpg';
import ImgHeader from './../../img/header-rhdhv.jpg';
import HTCLogo from './../../img/logo-hightechcampus.png';
import RhdhvLogo from './../../img/logo-rhdhv-white.png';
import CircleLogo from './../../img/logo-circle-client.svg';

const isAmpApp = appConfigurations.isAmpApp;

const useStyles = makeStyles(theme => ({
  IntroductionDiv: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    margin: "0",
    textAlign: 'center',
    '& .MuiGrid-root': {
      paddingTop: theme.spacing(5),
      '& .MuiTypography-root': {
        color: theme.palette.common.white,
        marginBottom: theme.spacing(1),
        textShadow: `${isAmpApp ? "2px 2px black" : ""}`
      },
    },
  },
  IntroductionDivRhdhv: {
    backgroundImage: `url(${ImgHeader})`,
    minHeight: '200px',
    opacity: 1,
  },
  IntroductionDivHtc: {
    opacity: 0.9,
    minHeight: '1000px',
    backgroundImage: `url(${ImgHTCHeader})`,
    boxShadow: 'inset 0 0 0 50vw rgba(6, 114, 154, 0.75)',
  },
  img: {
    position: 'absolute',
    zindex: 3,
    '&:hover': {
      cursor: 'pointer',
      filter: "contrast(130%) brightness(130%)",
    },
  },
  imgRhdhv: {
    position: 'absolute',
    top: theme.spacing(12),
    left: theme.spacing(12),
  },
  imgCircle: {
    top: theme.spacing(10),
    right: theme.spacing(5),
  },
  imgHTC: {
    top: theme.spacing(13),
    right: theme.spacing(5),
    filter: 'brightness(0) invert(1)',
    transition: 'all .25s',
    '&:hover': {
      height: '65px',
      filter: 'brightness(0) invert(1)',
      transition: 'all .25s'
    },
  },
  gridRhdhv: {
    position: 'absolute',
    top: '12%',
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  gridHtc: {
    position: 'absolute',
    top: '35%',
  },
  htcHeadingText: {
    fontSize: 100,
    color: theme.palette.common.white,
  },
  htcSubHeadingText: {
    fontSize: 30,
    color: theme.palette.common.white,
    maxWidth: '1000px',
    textAlign: 'justify'
  }
}));

export default function HomePageImage() {

  const classes = useStyles();
  const [t] = useTranslation();

  const handleBottomNavigationChange = (val) => {
    if (val === 'rhdhv')
      return window.open("https://www.royalhaskoningdhv.com/", "_blank");
    else if (val === 'circle')
      return window.open("https://circleinfrapartners.com/", "_blank");
    else if (val === 'hightechcampus')
      return window.open("https://www.hightechcampus.com/", "_blank");
  };

  return (
    <div>
      <div className={`${classes.IntroductionDiv} ${isAmpApp ? classes.IntroductionDivHtc : classes.IntroductionDivRhdhv}`} />
      {!isAmpApp &&
        <>
          <Tooltip title="Visit Royal HaskoningDHV official website">
            <img alt="rhdhv" src={RhdhvLogo} height="100px" onClick={() => handleBottomNavigationChange('rhdhv')}
              className={clsx(classes.img, classes.imgRhdhv)} />
          </Tooltip>
          <Tooltip title="Visit Circle official website">
            <img alt="circle" src={CircleLogo} height="100px" onClick={() => handleBottomNavigationChange('circle')}
              className={clsx(classes.img, classes.imgCircle)} />
          </Tooltip>
        </>
      }
      {isAmpApp &&
        <Tooltip title="Visit High Tech Campus official website">
          <img alt="hightechcampus" src={HTCLogo} height="60px" onClick={() => handleBottomNavigationChange('hightechcampus')}
            className={clsx(classes.img, classes.imgHTC)} />
        </Tooltip>}

      <Grid container justify="center"
        className={isAmpApp ? classes.gridHtc : classes.gridRhdhv}
      >
        <Grid item xs={6}>
          {isAmpApp &&
            <Typography
              variant="h5"
              className={classes.htcHeadingText}
            >
              {t('homeGetApprovalHTC')}
            </Typography>}

          {!isAmpApp &&
            <Typography  variant="h5">
              {t('homeWantStart')}
            </Typography>
          }
          <Typography
            variant="h5"
            className={isAmpApp ? classes.htcSubHeadingText : ''}
          >
            {isAmpApp ? t('homeStepGuideHTC') : t('homeStepGuide')}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}
