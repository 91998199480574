import { Button, CircularProgress, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, Link, OutlinedInput, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { appConfigurations } from '../../../utils/configs';
import LanguageSwitch from '../../Common/LanguageSwitch';
import { postData } from './../../../utils/ApiRequest';
import { forgotPasswordUpdateURL, forgotPasswordVerifyURL } from './../../../utils/ApiUrl';
import { getAppTitle, isValidEmail, isValidPassword, userValidation } from './../../../utils/AppConstants';
import { AnonymousAppContext } from './../../AnonymousPage';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    marginTop: theme.spacing(3),
    fontSize: '2.5rem',
  },
  subTitle: {
    fontSize: '2.1rem',
  },
  outlinedInput: {
    '& .MuiInputLabel-root': {
      paddingLeft: theme.spacing(1.5),
      marginTop: theme.spacing(-0.5),
    },
    '& .MuiInputLabel-shrink': {
      paddingLeft: theme.spacing(2.5),
      marginTop: theme.spacing(-1),
    }
  },
  progress: {
    marginRight: theme.spacing(1),
  },
}));

export default function ForgotPassword() {

  const [t] = useTranslation();
  const appContext = useContext(AnonymousAppContext);
  const { dispatch } = appContext;

  let history = useHistory();
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState();
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const classes = useStyles();
  const [isBusy, setIsBusy] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState(false);

  function validateForm() {
    return isValidEmail(email);
  }

  function validatePasswordForm() {
    return isValidEmail(email) &&
      isValidPassword(newPassword) &&
      newPassword === confirmNewPassword &&
      verificationCode > 0;
  }

  async function handleVerifySubmit(event) {
    event.preventDefault();
    try {
      setIsBusy(true);
      const data = { "email": email };
      const lang = 'en';
      const response = await postData(forgotPasswordVerifyURL(lang), data, null)
      const status = response.status;
      if (status === 200 || status === 201) {
        setVerificationCodeSent(true);

        dispatch({ "type": "setSnackBarInfo", value: { message: t('pageForgotPwdEmailSent'), isError: false } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('pageForgotPwdInvalidCredentials'), isError: true } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
    }
    finally {
      setIsBusy(false)
    }
  }

  async function handleChangePasswordSubmit(event) {
    event.preventDefault();
    try {
      setIsBusy(true);
      const data = {
        "email": email,
        "verification_code": verificationCode,
        "new_password": newPassword
      };
      const response = await postData(forgotPasswordUpdateURL(), data, null)
      const status = response.status;
      if (status === 200 || status === 201) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('pageForgotPwdChanged'), isError: false } })
        dispatch({ "type": "setOpenSnackbar", value: true })
        history.replace('/');
      }
      else if (status === 406) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('pageForgotPwdInvalidCode'), isError: true } })
        dispatch({ "type": "setOpenSnackbar", value: true })
        setVerificationCodeError(true);
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('pageForgotPwdInvalidEmailOrCode'), isError: true } })
        dispatch({ "type": "setOpenSnackbar", value: true })
        setVerificationCodeError(true);
      }
    }
    finally {
      setIsBusy(false);
    }

  }

  function onLoginClick() {
    history.replace('/');
  }

  const handleClickShowPassword = (sender) => {
    if (sender === 'newp')
      return setShowNewPassword(!showNewPassword);
    else if (sender === 'confirmnewp')
      return setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(!isValidEmail(e.target.value));
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setNewPasswordError(!isValidPassword(e.target.value));
    setConfirmNewPasswordError(e.target.value !== confirmNewPassword);
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
    setConfirmNewPasswordError(e.target.value !== newPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <div className={classes.root}>
      <Grid container direction="column" alignItems="center" justify="center" className={classes.titleGrid}>
        <Typography color="primary" gutterBottom align="center" className={classes.title}>
          {getAppTitle(appConfigurations.isAmpApp)}
        </Typography>
        <Typography color="primary" className={classes.subTitle}>
          {t('pageForgotPwdForgotPwd')}
        </Typography>
      </Grid>
      <form>
        <Typography color="primary" className={classes.subTitle1}>
          {!verificationCodeSent ?
            t('forgotPwdInstructions') :
            t('forgotPwdInstructions_verification')}
        </Typography>
        <TextField
          margin="none"
          required
          fullWidth
          variant="outlined"
          id="email"
          label="Email"
          name="email"
          autoFocus
          autoComplete="off"
          value={email}
          disabled={verificationCodeSent || isBusy}
          error={emailError}
          helperText={emailError ? t('emailError') : ""}
          onChange={handleEmailChange}
        />
        {verificationCodeSent &&
          <div>
            <FormControl className={classes.outlinedInput}>
              <InputLabel htmlFor="new-password">
                {t('dialogChangePwdNewPwd')}
              </InputLabel>
              <OutlinedInput
                id="new-password"
                error={newPasswordError}
                type={showNewPassword ? 'text' : 'password'}
                disabled={isBusy}
                required
                fullWidth
                name="password"
                label={t('dialogChangePwdNewPwd')}
                value={newPassword}
                onChange={e => handleNewPasswordChange(e)}
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => handleClickShowPassword('newp')}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
              <FormHelperText error={newPasswordError} id="password-helper-text">
                {newPasswordError ? t('newPasswordError', { max_chars: `${userValidation.passwordMin}-${userValidation.passwordMax}` }) : ""}
              </FormHelperText>
            </FormControl><br />

            <FormControl className={classes.outlinedInput}>
              <InputLabel htmlFor="confirm-password">
                {t('dialogChangePwdConfirmPwd')}
              </InputLabel>
              <OutlinedInput
                id="confirm-password"
                error={confirmNewPasswordError}
                type={showConfirmNewPassword ? 'text' : 'password'}
                disabled={isBusy}
                required
                fullWidth
                name="password"
                label={t('pageRegisterConfirmPwd')}
                value={confirmNewPassword}
                onChange={e => handleConfirmNewPasswordChange(e)}
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => handleClickShowPassword('confirmnewp')}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
              <FormHelperText error={confirmNewPasswordError} id="confirm-password-helper-text">
                {confirmNewPasswordError ? t('pageRegisterPwdMatchValidation') : ""}
              </FormHelperText>
            </FormControl>

            <TextField
              variant="outlined"
              error={verificationCodeError}
              helperText={verificationCodeError ? t('pageForgotPwdVerificationValidation') : ""}
              margin="none"
              type="number"
              disabled={isBusy}
              required
              fullWidth
              id="verificationCode"
              label={t('pageForgotPwdVerificationCode')}
              name="verificationCode"
              autoComplete="off"
              value={verificationCode}
              onChange={e => setVerificationCode(e.target.value)}
            />

            <Button
              fullWidth
              onClick={handleChangePasswordSubmit}
              variant="contained"
              color="primary"
              className={classes.submit}
              type="submit"
              disabled={!validatePasswordForm()}
            >
              {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />}
              {t('pageForgotPwdChangePwd')}
            </Button>
          </div>
        }

        {!verificationCodeSent &&
          <Button
            fullWidth
            onClick={handleVerifySubmit}
            variant="contained"
            color="primary"
            className={classes.submit}
            type="submit"
            disabled={!validateForm() || isBusy}
          >
            {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />}
            {t('pageForgotPwdSendEmail')}
          </Button>}

        <Grid container direction="row">
          <Grid item xs={10}>
            <Link onClick={onLoginClick}>
              {t('pageForgotPwdLoginInstead')}
            </Link>
          </Grid>
          <Grid item xs={2} justify="flex-end">
            <LanguageSwitch />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}