import { AppBar, CircularProgress, Dialog, DialogActions, DialogContent, Fab, Grid, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ErrorIcon from '@material-ui/icons/Error';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { statusConfigs } from '../../../utils/configs';
import { deleteData } from './../../../utils/ApiRequest';
import { deleteAttachementUrl } from './../../../utils/ApiUrl';
import { roleConfigs } from './../../../utils/AppConstants';
import { AppContext } from './../../AuthenticatedPage';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(10),
    },
    container: {
        maxHeight: 440,
    },
    formControl: {
        fullWidth: true,
        display: 'flex'
    },
    dialog: {
        //backgroundColor: '#F5F7FA !important'
    },
    appBar: {
        position: 'relative',
        '& .MuiToolbar-root': {
            backgroundColor: theme.palette.warning.main,
        }
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '40%',
            minWidth: '200px',
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        '& .MuiTypography-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: '40%',
            minWidth: '200px',
            color: theme.palette.warning.main,
        },
        '& svg': {
            color: theme.palette.warning.main,
        },
        textAlign: 'center',
        width: '100%',
        backgroundColor: '#fff'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function DialogDeleteAttachment(props) {

    const [t] = useTranslation();
    const classes = useStyles();
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { authToken, userRoles } = state;
    const { open, setOpen, attachment, attachments, setAttachments } = props;
    const [isSaving, setIsSaving] = React.useState(false);
    const isAdmin = userRoles?.some(r => [roleConfigs.admin].includes(r.role));

    const handleDeleteDocument = async () => {

        try {
            setIsSaving(true);

            const apiURL = deleteAttachementUrl(attachment.id);
            const response = await deleteData(apiURL, authToken);
            if (response.status >= 200 && response.status <= 300) {

                const arr = [...attachments];
                const index = arr.findIndex(a => a.id === attachment.id);
                if (index > -1) {
                    if (isAdmin) {
                        const obj = arr[index];
                        obj.status = { description_static: statusConfigs.Deleted };
                        arr.splice(index, 1, obj);
                    }
                    else {
                        arr.splice(index, 1);
                    }

                    setAttachments(arr);
                    setOpen(false);
                }

                dispatch({ "type": "setSnackBarInfo", value: { message: t('attachmentSuccess'), isError: false } })
                dispatch({ "type": "setOpenSnackbar", value: true })
            }
            else {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('generalErrorDownload'), isError: true } })
                dispatch({ "type": "setOpenSnackbar", value: true })
            }
        }
        finally {
            setIsSaving(false);
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            style={{ backgroundColor: '#fff' }}
            fullScreen>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" className={classes.title}>
                        {t('deleteAttachmentTitle')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.form}
                >
                    <ErrorIcon fontSize="large" />
                    <Typography variant="h6" >
                        {t('deleteAttachmentMessage1')}
                    </Typography>
                    <Typography variant="h6" >
                        {t('deleteAttachmentMessage2')}
                    </Typography>

                    <TextField
                        disabled
                        margin="none"
                        variant="outlined"
                        fullWidth
                        label={t('attachment')}
                        value={attachment.original_file_name}
                    />
                </Grid>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#fff' }}>
                <Fab variant="extended" color="warning" aria-label="add" disabled={isSaving}
                    onClick={handleDeleteDocument} >
                    <DeleteForeverIcon className={classes.extendedIcon} />
                    {isSaving && <CircularProgress color="primary" size={25} className={classes.progress} />}
                    {t('submit')}
                </Fab>
            </DialogActions>
        </Dialog>
        )
}