import { IconButton, Tooltip } from '@material-ui/core';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DefaultAsbuiltVerifierIcon from '@material-ui/icons/AssignmentTurnedIn';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import EditIcon from '@material-ui/icons/Edit';
import ReplyIcon from '@material-ui/icons/Reply';
import DefaultAdminIcon from '@material-ui/icons/VerifiedUser';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { roleConfigs } from '../../../utils/AppConstants';
import { AppContext } from '../../AuthenticatedPage';
import SearchTextBox from '../../Common/SearchTextBox';

export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            //backgroundColor: theme.palette.common.white,
        },
    },
}))(TableRow);

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {

    const [t] = useTranslation();
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const headCells = [
        { id: 'user.firstName', disablePadding: true, label: t('pageUserRolesFirstName') },
        { id: 'user.lastName', disablePadding: true, label: t('pageUserRolesLastName') },
        { id: 'user.email', disablePadding: false, label: t('pageUserRolesEmail') },
        { id: 'role.name', disablePadding: false, label: t('pageUserRolesRole') },
    ];

    return (
        <TableHead>
            <TableRow>
                <StyledTableCell padding="checkbox" />
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.align || 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{fontSize: 16}}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
                <StyledTableCell align="center">
                    Additional roles
                </StyledTableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 40%',
        '& svg': {
            marginBottom: theme.spacing(-0.5),
            marginRight: theme.spacing(1),
        }
    },
}));

const EnhancedTableToolbar = (props) => {

    const [t] = useTranslation();
    const appContext = useContext(AppContext);
    const { currentUser } = appContext.state;

    const classes = useToolbarStyles();
    const { userRole,
        handleEdit,
        handleSearch,
        handleDefaultAdmin,
        handleDefaultAsBuilt,
        handleDelegateUser
    } = props;

    const isCurrentUser = currentUser && currentUser?.id === userRole?.user?.id;
    const isAdmin = userRole?.role?.role_static === roleConfigs.admin || false;
    const isDefaultAdmin = isAdmin && (userRole?.user?.default_admin || false);
    const isAsbuiltVerifier = userRole?.user?.can_check_asbuilt || false;
    const isDefaultAsbuiltVerifier = isAsbuiltVerifier && ((userRole?.user?.can_check_asbuilt && userRole?.user?.default_check_asbuilt) || false);
    const canBeDelegated = (!isCurrentUser && !userRole?.delegated && !userRole?.hasDelegations && [roleConfigs.admin, roleConfigs.assetOwner, roleConfigs.manager].includes(userRole?.role?.role_static)) || false;
    const canCancelDelegation = (!isCurrentUser && userRole?.delegated && [roleConfigs.admin, roleConfigs.assetOwner, roleConfigs.manager].includes(userRole?.role?.role_static)) || false;

    return (
        <Toolbar>
            <Typography className={classes.title} variant="h5" color="primary" id="tableTitle" component="div">
                {t('pageUserRolesUserroles')}
            </Typography>

            {userRole &&
                <Fragment>
                    {handleEdit &&
                        <Tooltip title="Edit user">
                            <IconButton color="primary" onClick={handleEdit}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>}

                    {handleDefaultAdmin && isAdmin && !isDefaultAdmin &&
                        <Tooltip title="Set as default administrator">
                            <IconButton onClick={handleDefaultAdmin} color="primary">
                                <DefaultAdminIcon />
                            </IconButton>
                        </Tooltip>}
                    
                    {handleDefaultAsBuilt && isAsbuiltVerifier && !isDefaultAsbuiltVerifier &&
                        <Tooltip title="Set as default as-built verifier">
                            <IconButton onClick={handleDefaultAsBuilt} color="primary">
                                <DefaultAsbuiltVerifierIcon />
                            </IconButton>
                        </Tooltip>}

                    {handleDelegateUser && canBeDelegated &&
                        <Tooltip title="Delegate user">
                            <IconButton onClick={handleDelegateUser} style={{ color: "orange", transform: "rotateY(180deg)" }}>
                                <ReplyIcon />
                            </IconButton>
                        </Tooltip>}

                    {handleDelegateUser && canCancelDelegation &&
                        <Tooltip title="Cancel delegation">
                            <IconButton onClick={handleDelegateUser} style={{ color: "red" }}>
                                <CancelScheduleSendIcon />
                            </IconButton>
                        </Tooltip>}
                    
                </Fragment>}            
            <SearchTextBox handleChange={handleSearch} />
        </Toolbar>
    );
};

export { EnhancedTableToolbar, EnhancedTableHead, stableSort, getComparator };
