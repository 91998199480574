import React, { useContext } from 'react';
import { Redirect, Route } from "react-router-dom";
import { appRoute } from '../../utils/routeConfigs';
import { AppContext } from './../AuthenticatedPage';

export default function PrivateRoute({ children, ...rest }) {

    const appContext = useContext(AppContext);
    const { authToken } = appContext.state;

    const isAuthenticated = authToken && authToken !== '';

    return (
        <Route
            {...rest}
            render={
                ({ location }) =>
                    isAuthenticated ? (children) : (
                        <Redirect to={{ pathname: appRoute.login, state: { from: location } }} />
                    )
            }
        />
    );
}