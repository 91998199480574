import { Button, Card, CardContent, Checkbox, CircularProgress, Fab, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIos as BackIcon } from '@material-ui/icons';
import ReleaseIcon from '@material-ui/icons/AssignmentTurnedIn';
import FinalIcon from '@material-ui/icons/AssistantPhoto';
import DoneIcon from '@material-ui/icons/Done';
import SkipIcon from '@material-ui/icons/ExitToApp';
import PanToolIcon from '@material-ui/icons/PanTool';
import SaveIcon from '@material-ui/icons/Save';
import clsx from 'clsx';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { appConfigurations, dataFetchKeys, statusConfigs } from '../../../utils/configs';
import { tokens } from '../../../utils/keys';
import { appRoute, userActionType } from '../../../utils/routeConfigs';
import BackDrop from '../../Common/BackDrop';
import ActionFinishedPage from '../ActionFinishedPage';
import ComponentAttachments from '../Attachments/ComponentAttachments';
import UnauthorizedPage from '../UnauthorizedPage';
import { getData, putData } from './../../../utils/ApiRequest';
import { getHoldDetailByRequestActionUrl, getRequestActionResponseUrl, getSingleNotificationUrl, requestActionAttachmentListUrl, requestActionAttachmentUploadUrl, requestActionResponseApproveUrl, requestActionResponseSaveUrl } from './../../../utils/ApiUrl';
import { appStatuses, downloadType, languages, requestValidation } from './../../../utils/AppConstants';
import { AppContext, queryClient } from './../../AuthenticatedPage';
import ConfirmationDialog from './../../Common/ConfirmationDialog';
import RequestDetailsDialog from './../../pages/Requests/RequestDetailsDialog';
import RequestActionItem from './../RequestAction/RequestActionItem';
import DialogHoldRequest from './DialogHoldRequest';
import DialogReleaseHoldRequest from './DialogReleaseHoldRequest';
import DialogSkipRequest from './DialogSkipRequest';

const useStyles = makeStyles(theme => ({

  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
    marginLeft: theme.spacing(10),
    marginTop: theme.spacing(10),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(10),
  },
  heading: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    '& svg': {
      marginTop: theme.spacing(-5),
      marginRight: theme.spacing(1),
    }
  },
  headingFinal: {
    color: theme.palette.primary.main
  },
  formControl: {
    fullWidth: true,
    display: 'flex',
    margin: 0
  },
  submit: {
    marginLeft: theme.spacing(2),
  },
  filter: {
    maxWidth: '650px'
  },
  form: {
    '& .MuiTextField-root': {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      minWidth: '200px'
    },
    textAlign: 'center',
    width: '100%'
  },
  footerDiv: {
    position: 'fixed',
    bottom: theme.spacing(10),
    marginLeft: theme.spacing(-4),
    paddingRight: theme.spacing(10),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  buttonHold: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.warning.main,
  },
  buttonRelease: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  warningCard: {
    '& .MuiTypography-root': {
      fontSize: 14,
      margin: theme.spacing(1),
    },
    '& .MuiGrid-root, .MuiCardContent-root': {
      padding: 0,
      margin: 0,
    },
    marginBottom: theme.spacing(1),
    textAlign: 'left',
  },
  warningCardLeft: {
    backgroundColor: theme.palette.primary.main,
  },
  infoText: {
    fontSize: '1.0rem',
    color: theme.palette.warning.main,
    marginBottom: theme.spacing(1),
  },
  progress: {
    marginRight: theme.spacing(1),
  }
}));

export default function RequestActionPage() {

  let history = useHistory();
  const classes = useStyles();
  const [t] = useTranslation();
  const { notificationId } = useParams();
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const { selectedRequest, selectedAction, selectedRequestActionId, selectedAssetType, selectedLanguage, actionById, authToken, currentUser } = state;

  const [loading, setLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openSkipDialog, setOpenSkipDialog] = React.useState(false);
  const [openRequestHoldDialog, setOpenRequestHoldDialog] = React.useState(false);
  const [openRequestDetailsDialog, setOpenRequestDetailsDialog] = React.useState(false);
  const [openRequestReleaseDialog, setOpenRequestReleaseDialog] = React.useState(false);

  const [remarks, setRemarks] = React.useState('');
  const [isBusy, setIsBusy] = useState(false);
  const [actionTasks, setActionTasks] = React.useState([]);
  const [reviewAsBuilt, setReviewAsBuilt] = React.useState(true);
  const [attachments, setAttachments] = React.useState([]);
  const [actionStatus, setActionStatus] = React.useState("");

  const [requestObject, setRequestObject] = React.useState(null);
  const [actionObject, setActionObject] = React.useState('');
  const [assetTypeObject, setAssetTypeObject] = React.useState(null);
  const [localRequestActionId, setLocalRequestActionId] = React.useState();

  const [isRequestOnHold, setIsRequestOnHold] = useState(false);
  const currentUserId = localStorage.getItem(tokens.keyUserId) ? localStorage.getItem(tokens.keyUserId) : sessionStorage.getItem(tokens.keyUserId);
  const canTakeAction = currentUserId === actionById;
  const isAmpApp = appConfigurations.isAmpApp; 

  useEffect(() => {

    const checkIfrequestOnHold = async () => {
      if (!isAmpApp || !selectedRequestActionId) {
        return setIsRequestOnHold(requestObject?.status?.description_static === statusConfigs.Hold);
      }

      try {
        setLoading(true);
        setHasError(false);

        const response = await getData(getHoldDetailByRequestActionUrl(selectedRequestActionId), authToken);
        if (response.status === 200 || response.status === 201) {
          const holdDetails = await response.json();
          const isOnHoldByUser = holdDetails?.holdById === currentUser?.id && (!holdDetails?.releaseDate || !holdDetails?.releaseById);
          setIsRequestOnHold(isOnHoldByUser);
          console.log({ holdDetails })
          console.log({ isOnHoldByUser })
          console.log({ aa: !holdDetails?.releaseDate || !holdDetails?.releaseById })
        }
      }
      finally {
        setLoading(false);
      }
    }

    checkIfrequestOnHold();
  }, [selectedRequestActionId, requestObject])


  useEffect(() => {

    async function getNotificationData() {

      try {
        setLoading(true);
        setHasError(false);

        const response = await getData(getSingleNotificationUrl(notificationId), authToken);
        if (response.status === 200 || response.status === 201) {
          const response_json = await response.json();
          setActionStatus(response_json.request_action_status.description_static);
          setRequestObject(response_json.request);
          setActionObject(response_json.action);
          setAssetTypeObject(response_json.assetType);
          setLocalRequestActionId(response_json.requestActionId);
        }
        else if (response.status === 401) {
          dispatch({ "type": "setSnackBarInfo", value: { message: t('User Unauthorized'), isError: true } });
          dispatch({ "type": "setOpenSnackbar", value: true });
          setHasError(true);
        }
      }
      finally {
        setLoading(false);
      }
    }

    if (!notificationId && (selectedRequest || selectedAction || selectedAssetType || selectedRequestActionId)) {
      setRequestObject(selectedRequest);
      setActionObject(selectedAction);
      setAssetTypeObject(selectedAssetType);
      setLocalRequestActionId(selectedRequestActionId);
      setActionStatus(appStatuses.assigned);
      return;
    }
    else if (notificationId) {
      getNotificationData();
    }
    else {
      history.push(appRoute.home);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationId, selectedRequest, selectedAction, selectedRequestActionId, selectedAssetType])

  useEffect(() => {

    if (!assetTypeObject || !requestObject || !localRequestActionId)
      return;
    //Get session token
    async function GetActionTasks() {

      try {
        setLoading(true);
        setActionTasks([]);

        const apiURL = getRequestActionResponseUrl(requestObject.id, assetTypeObject.id, localRequestActionId);
        const responseActionItem = await getData(apiURL, authToken);
        if (responseActionItem.status === 200 || responseActionItem.status === 201) {
          const responseActionItemJson = await responseActionItem.json();
          setRemarks(responseActionItemJson.remarks ? responseActionItemJson.remarks : "");
          setActionTasks(responseActionItemJson.responses);
        }
      }
      finally {
        setLoading(false);
      }
    }

    GetActionTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObject, assetTypeObject, localRequestActionId]);

  useEffect(() => {

    //Get session token
    async function GetRequestActionAttachments() {

      try {
        setLoading(true);
        setAttachments([]);
        if (!localRequestActionId) return;

        const apiURL = requestActionAttachmentListUrl(localRequestActionId);
        const responseActionItem = await getData(apiURL, authToken);
        if (responseActionItem.status === 200 || responseActionItem.status === 201) {
          const responseActionItemJson = await responseActionItem.json();
          setAttachments(responseActionItemJson);
        }
      }
      finally {
        setLoading(false);
      }
    }

    GetRequestActionAttachments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localRequestActionId])

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    history.goBack();
  };

  const handleClickSkipDialog = () => {
    setOpenSkipDialog(true);
  };

  const handleClickHoldDialog = () => {
    setOpenRequestHoldDialog(true);
  };

  const handleClickReleaseDialog = () => {
    setOpenRequestReleaseDialog(true);
  };

  const handleOpenRequestDetailsClick = () => {
    setOpenRequestDetailsDialog(true);
  };

  const closeRequestDetailsDialog = () => {
    setOpenRequestDetailsDialog(false);
  };

  const [validated, setValidated] = useState(false);
  useEffect(() => {

    const checkValidation = () => {
      if (!remarks || remarks.length <= 0 || remarks.length > requestValidation.remarksMax) {
        return setValidated(false);
      }

      const actionWithNoResponse = actionTasks.filter(a => a.actionResponse === null || a.actionResponse === undefined);
      if (actionWithNoResponse && actionWithNoResponse.length > 0) {
        return setValidated(false);
      }

      const actionsAllNo = actionTasks.filter(a => !a.actionResponse);
      if (actionTasks.length > 0 && actionsAllNo && actionsAllNo.length === actionTasks.length) {
        return setValidated(true);
      }

      const yesInvalidTasks = actionTasks.filter(a =>
        a.actionResponse &&
        (a.investigation_executed_on === null || a.investigation_executed_on === undefined || !a.remarks || a.remarks.length <= 0 || a.remarks.length > 1000));
      if (yesInvalidTasks && yesInvalidTasks.length > 0) {
        return setValidated(false);
      }

      setValidated(true);
    }

    checkValidation();

  }, [remarks, actionTasks])

  function getTaskData() {
    const data = [];
    for (var actionTask of actionTasks) {
      data.push({
        "id": actionTask.id,
        "actionResponse": actionTask.actionResponse,
        "remarks": actionTask.remarks,
        "investigation_executed_on": actionTask.investigation_executed_on ? actionTask.investigation_executed_on : null
      });
    }

    return {
      "id": localRequestActionId,
      "remarks": remarks,
      "asset_type_id": assetTypeObject ? assetTypeObject.id : "",
      "reviewAsBuilt": !assetTypeObject ? false : reviewAsBuilt,
      "responses": data
    };
  }

  async function handleSubmit(event) {

    event.preventDefault();
    try {
      setIsBusy(true);
      const data = getTaskData();
      const lang = selectedLanguage === languages.nl ? 'nl' : 'en';
      const response = await putData(requestActionResponseSaveUrl(lang, requestObject.id), data, authToken)
      const status = response.status;
      if (status === 200 || status === 201) {
        //Update notifications
        queryClient.invalidateQueries(dataFetchKeys.notifications);
        dispatch({ "type": "setSnackBarInfo", value: { message: t('pageResponseSaved'), isError: false } })
        dispatch({ "type": "setOpenSnackbar", value: true })

        history.push(appRoute.home);
      }
      else if (status === 401) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
    }
    finally {
      setIsBusy(false);
    }
  }

  async function handleApprove(event) {

    event.preventDefault();
    try {
      setIsBusy(true);
      const data = getTaskData();
      const lang = selectedLanguage === languages.nl ? 'nl' : 'en';
      const response = await putData(requestActionResponseApproveUrl(lang, requestObject.id), data, authToken)
      const status = response.status;
      if (status === 200 || status === 201) {
        //Update notifications
        queryClient.invalidateQueries(dataFetchKeys.notifications);
        dispatch({ "type": "setSnackBarInfo", value: { message: t('pageResponseApproved'), isError: false } })
        dispatch({ "type": "setOpenSnackbar", value: true })
        history.push(appRoute.home);
      }
      else if (status === 406) {
        //Update notifications
        queryClient.invalidateQueries(dataFetchKeys.notifications);
        dispatch({ "type": "setSnackBarInfo", value: { message: t('actionAlreadyPerformed'), isError: false } })
        dispatch({ "type": "setOpenSnackbar", value: true })
        history.push(appRoute.actionPerformed)
      }
      else if (status === 401) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
    }
    finally {
      setIsBusy(false);
    }

  }

  const handleReviewAsBuiltChange = (event) => {
    setReviewAsBuilt(event.target.checked);
  };

  const isFinalAction = [userActionType.finalControl, userActionType.finalFeedback, userActionType.finalReview].includes(actionObject.type);
  const hasPendingAction = actionStatus === appStatuses.assigned || isFinalAction;


  const getDescription = () => {
    switch (actionObject.type) {
      case userActionType.finalControl:
        return "Definitief Controle";
      case userActionType.finalFeedback:
        return "Definitief feedback";
      case userActionType.finalReview:
        return "Definitief review";
      default:
        return `${t('pageResponseActionOnReq')} (${actionObject.description})`;
    }
  }

  if (loading)
    return <BackDrop loading={loading} />
  else if (hasError)
    return <UnauthorizedPage />
  else if (!hasPendingAction)
    return <ActionFinishedPage />

  return (
    <Fragment>
      <div className={classes.root}>
        <form className={classes.form} noValidate autoComplete="off">
          <div>
            <Typography
              variant="h5"
              className={clsx(classes.heading,
                { [classes.headingFinal]: isFinalAction })}
            >
              {isFinalAction &&
                <FinalIcon />}
              {getDescription()}
            </Typography>

            {assetTypeObject &&
              <Typography variant="h6" color="primary">
                {`Asset type: ${assetTypeObject.description}`}
              </Typography>}

            {isFinalAction &&
              <Grid container justify="flex-start">
                <Grid item xs={3} />
                <Grid item xs={6}>
                  <Card className={classes.warningCard}>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={1} className={classes.warningCardLeft}>
                        </Grid>
                        <Grid item xs={11}>
                          <Typography>
                            {t(isAmpApp ? 'actionPageApproveAmpLine1' : 'actionPageApproveLine1')}
                          </Typography>
                          <Typography>
                            {t(isAmpApp ? 'actionPageApproveAmpLine2' : 'actionPageApproveLine2')}
                          </Typography>
                          {!isAmpApp &&
                            <Typography>
                              {t('actionPageApproveLine3')}
                            </Typography>}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={3} />
              </Grid>}

            <Button variant="outlined" onClick={handleOpenRequestDetailsClick} >
              {t('generalReqDetails')}
            </Button>
            <RequestDetailsDialog request={requestObject} open={openRequestDetailsDialog} handleClose={closeRequestDetailsDialog} />
            <br />
            {!isAmpApp && 
              <FormControlLabel
              value="start"
              disabled
              control={<Checkbox color="primary"
                checked={requestObject?.isChangesExpected} />}
              label={t('isChangesExpected')}
            />
            }

            <Grid container>
              {!actionTasks || actionTasks.length <= 0 ? <div /> :
                <Grid item xs={8}>
                  <Grid container justify="flex-start">
                    <Typography variant="h6">
                      {t('pageResponseAssesment')}
                    </Typography>
                  </Grid>
                  {actionTasks.map(item =>
                    <RequestActionItem
                      key={item.id}
                      item={item}
                      isRequestOnHold={isRequestOnHold}
                      actionTasks={actionTasks}
                      setActionTasks={setActionTasks}
                    />)
                  }
                </Grid>
              }
              <Grid item xs={!actionTasks || actionTasks.length <= 0 ? 12 : 4}>
                <Grid container style={{ display: !actionTasks || actionTasks.length <= 0 ? 'block' : 'flex' }}>
                  <Typography variant="h6" style={{ marginLeft: '15px' }}>
                    {t('generalRemarks')}
                  </Typography>
                </Grid>
                <Grid container style={{ display: !actionTasks || actionTasks.length <= 0 ? 'block' : 'flex' }}>
                  <TextField
                    multiline
                    style={{ maxWidth: '600px' }}
                    inputProps={{ style: { minHeight: '200px' } }}
                    disabled={isBusy || isRequestOnHold}
                    margin="none"
                    variant="outlined"
                    required
                    fullWidth
                    id="description"
                    label={t('generalRemarks')}
                    name="description"
                    autoComplete="off"
                    value={remarks}
                    helperText={t("charsRemaining", { num_char: requestValidation.remarksMax - remarks.length })}
                    error={remarks.length < requestValidation.remarksMin || remarks.length > requestValidation.remarksMax}
                    onChange={e => setRemarks(e.target.value)}
                  />
                </Grid>
                {actionObject && assetTypeObject &&
                  <Grid container style={{ display: !actionTasks || actionTasks.length <= 0 ? 'block' : 'flex' }}>
                 {!isAmpApp && 
                 <FormControlLabel
                      style={{ marginLeft: '5px' }}
                      control={<Checkbox color="primary"
                        disabled={isRequestOnHold}
                        checked={reviewAsBuilt}
                        onChange={handleReviewAsBuiltChange}
                        name="reviewAsBuilt" />}
                      label="Review As-Built bestanden"
                    />}  
                  </Grid>}
                {isRequestOnHold &&
                  <Typography className={classes.infoText}>
                    {t('requestHoldInfoMessage')}
                  </Typography>}
              </Grid>
            </Grid>

            <ComponentAttachments
              attachments={attachments} setAttachments={setAttachments}
              uploadDisabled={isRequestOnHold}
              uploadUrl={requestActionAttachmentUploadUrl(localRequestActionId)}
              typeDownload={downloadType.requestAction} />
          </div>
        </form>
        <Grid container className={classes.footerDiv}>
          <Grid item xs={6}>
            <Fab variant="extended" color="default" aria-label="add" onClick={handleClickOpenDialog} className={classes.button}>
              <BackIcon className={classes.extendedIcon} />
              {t('generalBack')}
            </Fab>
            {!assetTypeObject || isRequestOnHold ? <div /> :
              <Fab variant="extended" color="secondary" aria-label="hold" onClick={handleClickSkipDialog} className={classes.buttonHold}>
                <SkipIcon className={classes.extendedIcon} />
                {t('Skip')}
              </Fab>}
          </Grid>
          <Grid item xs={6}>
            <Grid container justify="flex-end">
              {isRequestOnHold ?
                <Fab disabled={!canTakeAction} variant="extended" color="secondary" aria-label="hold" onClick={handleClickReleaseDialog} className={classes.buttonRelease}>
                  <ReleaseIcon className={classes.extendedIcon} />
                  {t('Release')}
                </Fab> :
                <Fab disabled={!canTakeAction} variant="extended" color="secondary" aria-label="hold" onClick={handleClickHoldDialog} className={classes.buttonHold}>
                  <PanToolIcon className={classes.extendedIcon} />
                  {t('pageResponseAssignHold')}
                </Fab>
              }
              {!isRequestOnHold &&
                <div>
                  <Fab disabled={isBusy || !canTakeAction} variant="extended" color="primary" aria-label="add" onClick={handleSubmit} className={classes.button}>
                    {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />}
                    <SaveIcon className={classes.extendedIcon} />
                    {t('generalSave')}
                  </Fab>
                  <Fab variant="extended" color="primary" aria-label="add" onClick={handleApprove} disabled={isBusy || !validated} className={classes.button}>
                    {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />}
                    <DoneIcon className={classes.extendedIcon} />
                    {t('generalApprove')}
                  </Fab>
                </div>}
            </Grid>
          </Grid>
        </Grid>
      </div>

      <ConfirmationDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} />

      <DialogHoldRequest open={openRequestHoldDialog} setOpen={setOpenRequestHoldDialog}
        requestId={requestObject ? requestObject.id : ''} requestActionId={localRequestActionId} holdInWorkflow={true} />

      <DialogReleaseHoldRequest open={openRequestReleaseDialog} setOpen={setOpenRequestReleaseDialog}
        requestId={requestObject ? requestObject.id : ''} requestActionId={localRequestActionId} />

      <DialogSkipRequest open={openSkipDialog} setOpen={setOpenSkipDialog}
        requestId={requestObject ? requestObject.id : ''} requestActionId={localRequestActionId} />
    </Fragment>
  );
}