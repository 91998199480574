import { AppBar, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, Fab, FormControlLabel, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Cancel as DeactivateIcon, CheckCircle as ActivateIcon, Close as CloseIcon } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dataFetchKeys } from '../../../utils/configs';
import { putData } from './../../../utils/ApiRequest';
import { activateRequestTypeURL, deactivateRequestTypeURL } from './../../../utils/ApiUrl';
import { userActions } from './../../../utils/AppConstants';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext, queryClient } from './../../AuthenticatedPage';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(10),
    },
    container: {
        maxHeight: 440,
    },
    formControl: {
        fullWidth: true,
        display: 'flex'
    },
    dialog: {
        //backgroundColor: '#F5F7FA !important'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        textAlign: 'center',
        width: '100%'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function DialogActivateOrDeactivateRequestType(props) {

    const [t] = useTranslation();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { workflows } = state;

    const { requestType, open, setOpen, action } = props;
    const [description, setDescription] = useState("");
    const [remarks, setRemarks] = useState("");
    const [requestNumberPrefix, setRequestNumberPrefix] = useState("");
    const [uploadRequired, setUploadRequired] = useState(true);
    const [isPlanologisch, setIsPlanologisch] = useState(false);
    const [selectedWorkflow, setSelectedWorkflow] = useState("");
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        setSelectedWorkflow(requestType && requestType.workflow ? requestType.workflow : []);
        setRequestNumberPrefix(requestType ? requestType.num_prefix : "");
        setUploadRequired(requestType ? requestType.upload_required : "");
        setIsPlanologisch(requestType ? requestType.is_planologic : "");
        setDescription(requestType ? requestType.description : "");
        setRemarks(requestType ? requestType.remarks : "");
    }, [requestType, open])


    async function handleActivateOrDeactivate(event) {

        event.preventDefault();   
		try{
            setIsBusy(true);
            const url = action === userActions.activate ? activateRequestTypeURL(requestType.id) : deactivateRequestTypeURL(requestType.id);
            const response =  await putData(url, null, GetToken())
                     const status = response.status;
                     if (status === 201 || status === 200) {
                         queryClient.invalidateQueries(dataFetchKeys.requestTypes);
                         if (action === userActions.activate)
                             dispatch({ "type": "setSnackBarInfo", value: { message: `${t('dialogRequestTypeRequestType')} '${description}' ${t('dialogRequestTypeActivated')}`, isError: false } });
                         else
                             dispatch({ "type": "setSnackBarInfo", value: { message: `${t('dialogRequestTypeRequestType')} '${description}' ${t('dialogRequestTypeDeaclivated')}`, isError: false } });
                         dispatch({ "type": "setOpenSnackbar", value: true });
                     }
                     else if (status === 401) {
                         dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
                         dispatch({ "type": "setOpenSnackbar", value: true });
                     }
                     else {
                         dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
                         dispatch({ "type": "setOpenSnackbar", value: true });
                     }     
        }
        finally{
            dispatch({ "type": "setSelectedRequestType", value: null });
            setOpen(false); 
            setIsBusy(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            style={{ backgroundColor: '#fff' }}
            fullScreen>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" className={classes.title}>
                        {action === userActions.activate ? t('dialogRequestTypeActivate') : t('dialogRequestTypeDeactivate')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <form className={classes.form} autoComplete="off" style={{ backgroundColor: '#fff' }}>
                    <div>
                        <br />
                        <Typography variant="h6" >
                            {action === userActions.activate ?
                                    t('dialogRequestTypeActivateInstruction') :
                                    t('dialogRequestTypeDeactivateInstruction')}
                        </Typography>
                        <TextField
                            margin="none"
                            required
                            fullWidth
                            variant="outlined"
                            id="description"
                            label={t('generalDescription')}
                            name="description"
                            autoComplete="off"
                            value={description}
                            disabled
                        /><br />
                        <TextField
                            margin="none"
                            required
                            fullWidth
                            variant="outlined"
                            id="requestNumberPrefix"
                            label={t('dialogRequestTypePrefix')}
                            name="requestNumberPrefix"
                            autoComplete="off"
                            disabled
                            value={requestNumberPrefix}
                        />
                        <Autocomplete
                            margin="none"
                            fullWidth
                            required
                            value={selectedWorkflow}
                            id="select-request-type"
                            options={workflows}
                            getOptionLabel={(option) => option.description}
                            disabled
                            renderInput={(params) => <TextField {...params} placeholder={t('dialogRequestTypeSelectWorkflow')} label={t('pageWorkflowsWorkflow')} variant="outlined" />}
                        />

                        <TextField
                            margin="none"
                            fullWidth
                            variant="outlined"
                            id="remarks"
                            label={t('generalRemarks')}
                            name="remarks"
                            autoComplete="off"
                            value={remarks}
                            disabled
                        /><br />
                        <FormControlLabel
                            value="start"
                            control={<Checkbox color="primary"
                                disabled
                                checked={isPlanologisch} />}
                            label={t('isPlanologisch')}
                        /><br />
                        <FormControlLabel
                            value="start"
                            control={<Checkbox color="primary"
                                disabled
                                checked={uploadRequired} />}
                            label={t('dialogRequestTypeAllowUpload')}
                        />
                    </div>
                </form>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#fff' }}>
                <Fab variant="extended" color="primary" aria-label="add"
                    onClick={handleActivateOrDeactivate} disabled={isBusy} >
                    {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />  }
                    {action === userActions.activate ? <ActivateIcon className={classes.extendedIcon} /> :
                        <DeactivateIcon className={classes.extendedIcon} />}  
                    {action === userActions.activate ? t('dialogRequestTypeActivate') : t('dialogRequestTypeDeactivate')}
                </Fab>
            </DialogActions>
        </Dialog>
        )
}