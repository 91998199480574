import { CircularProgress, Divider, Typography } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Chat as ChatIcon, Edit as EditIcon, TrackChanges as TrackIcon, Visibility as ViewIcon } from '@material-ui/icons';
import AssistantIcon from '@material-ui/icons/Assistant';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GetAppIcon from '@material-ui/icons/GetApp';
import ChangeTypeIcon from '@material-ui/icons/LowPriority';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ConvertRequestIcon from '@material-ui/icons/RepeatOne';
import ReplyIcon from '@material-ui/icons/Reply';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import UpdateIcon from '@material-ui/icons/Update';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { postData } from '../../../utils/ApiRequest';
import { requestAttachementDownloadUrl } from '../../../utils/ApiUrl';
import { appConfigurations, statusConfigs } from '../../../utils/configs';
import { appRoute, getAsBuiltDecisionRoute, getAssignAssetTypeRoute, getCloseRequestRoute, getRequestActionsRoute, getUploadAsBuiltRoute, getVerifyAsBuiltRoute, userActionType } from '../../../utils/routeConfigs';
import { GetToken } from '../../../utils/tokenActions';
import { AppContext } from '../../AuthenticatedPage';
import { appStatuses, roleConfigs } from './../../../utils/AppConstants';
import { StyledMenu, StyledMenuItem } from './../../StyledMenu';

const useStyles = makeStyles(theme => ({
  primaryItem: {
    color: theme.palette.primary.main,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    }
  },
  warningItem: {
    color: theme.palette.warning.main,
    '& .MuiListItemIcon-root': {
      color: theme.palette.warning.main,
    }
  },
  progress: {
    marginLeft: theme.spacing(1),
  },
}));

export default function MenuRequest(props) {

  const [t] = useTranslation();
  const classes = useStyles();

  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const { notifications, userRoles, currentUser } = state;
  const isAdmin = userRoles.some(r => [roleConfigs.admin].includes(r.role));
    const [IsFileDownloading, setIsFileDownloading] = React.useState(false);
    const isAmpApp = appConfigurations.isAmpApp
  const { requestObject, history, handleNotify, setOpenRequestDetailsDialog,
    setOpenResponseDialog, handleUploadFile, handleExport, anchorEl, setAnchorEl,

    handleChangeRequestType,
    handleConvertRequestType,

    handleReject,
    handleSetExpired, isBusy,
    handleRequestForDelay,
    handleRespondToDelayRequest
  } = props;

  const releventActiveNotifications = !notifications ? [] :
    notifications.filter(n =>
      n.request && n.request.id === requestObject.id &&
      n.action && n.status && n.status.description_static === statusConfigs.Active)

  const isAdminAndRequestClosed = isAdmin && requestObject.status && ![statusConfigs.Closed, statusConfigs.Rejected, statusConfigs.Expired].includes(requestObject.status.description_static);
  const isFinished = requestObject.status && [statusConfigs.Closed, statusConfigs.Finished, statusConfigs.Approved].includes(requestObject.status.description_static);
  const isActive = ((isAdmin || requestObject?.createdBy?.id === currentUser.id) && [statusConfigs.Active, statusConfigs.Approved, statusConfigs.Assigned, statusConfigs.Hold, statusConfigs.Pending].includes(requestObject.status.description_static));
  const hasReleventActiveNotifications = releventActiveNotifications && releventActiveNotifications.length > 0;

  const handleViewRequestClick = () => {
    setAnchorEl(null);
    dispatch({ type: "setSelectedRequest", value: requestObject });
    setOpenRequestDetailsDialog(true);
  };

  const handleViewResponseClick = () => {
    setAnchorEl(null);
    dispatch({ type: "setSelectedRequest", value: requestObject });
    setOpenResponseDialog(true);
  };

  function handleActionClick(notification) {
    const action = !notification || !notification.action ? undefined : notification.action;
    dispatch({ "type": "setActionById", value: notification?.actionById || "" });

    if (!action || !action.type)
      return history.push(appRoute.home);

    switch (action.type) {
      case userActionType.assignAssetType:
        return history.push({
          pathname: getAssignAssetTypeRoute(notification.id),
        });
      case userActionType.uploadAsBuilt:
        return history.push({
          pathname: getUploadAsBuiltRoute(notification.id),
        });
      case userActionType.asBuiltDecision:
        return history.push({
          pathname: getAsBuiltDecisionRoute(notification.id),
        });
      case userActionType.verifyAsBuilt:
        return history.push({
          pathname: getVerifyAsBuiltRoute(notification.id),
        });
      case userActionType.projectClosure:
      case userActionType.finalApproval:
        return history.push({
          pathname: getCloseRequestRoute(notification.id),
        });
      default:
        return history.push({
          pathname: getRequestActionsRoute(notification.id),
        });
    }
  };

  const handleEditClick = () => {
    setAnchorEl(null);
    dispatch({ type: "setSelectedRequest", value: requestObject });
    history.push(appRoute.editRequest);
  }

  const handleTrackClick = () => {
    setAnchorEl(null);
    dispatch({ type: "setSelectedRequest", value: requestObject });
    history.push(appRoute.trackRequest);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  async function handleDownloadAttachment(fileId, fileName) {
    try {
      setIsFileDownloading(true)
      setAnchorEl(null);
      const url = requestAttachementDownloadUrl(fileId);
      const response = await postData(url, null, GetToken())
      if (response.status >= 200 && response.status <= 300) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = url;
        anchor.click();
      }
    }
    finally {
      setIsFileDownloading(false)
    }
  }

  return (
    <StyledMenu
      id="card-actions-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <StyledMenuItem onClick={handleViewRequestClick}>
        <ListItemIcon>
          <ViewIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('generalView')} />
      </StyledMenuItem>

      {requestObject.isUrgent && requestObject.urgentDocument &&
        <StyledMenuItem disabled={IsFileDownloading} onClick={() => handleDownloadAttachment(requestObject.urgentDocument.id, requestObject.urgentDocument.original_file_name)}>
          <ListItemIcon>
            {IsFileDownloading && <CircularProgress color="primary" size={20} className={classes.progress} />}
            <GetAppIcon fontSize="small" /></ListItemIcon>
          <ListItemText primary={t('downloadUrgencyDocument')} />
        </StyledMenuItem>}

      {requestObject.status && requestObject.status.description_static === statusConfigs.Hold &&
        <StyledMenuItem onClick={handleEditClick}>
          <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon>
          <ListItemText primary={t('generalEdit')} />
        </StyledMenuItem>}

      <StyledMenuItem onClick={handleViewResponseClick}>
        <ListItemIcon>
          <ChatIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('generalViewResponse')} />
      </StyledMenuItem>
      <StyledMenuItem onClick={handleTrackClick}>
        <ListItemIcon>
          <TrackIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('generalTrack')} />
      </StyledMenuItem>

          {requestObject.hasResponses ? <div /> :
              ( !isAmpApp && <StyledMenuItem onClick={handleChangeRequestType} className={classes.warningItem}>
              <ListItemIcon>
                  <ChangeTypeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t('changeRequestType')} />
          </StyledMenuItem>)
      }

      {requestObject.status && requestObject.status.description_static === appStatuses.closed &&
        requestObject.requestType && requestObject.requestType.is_planologic &&
        <StyledMenuItem onClick={handleConvertRequestType} className={classes.primaryItem}>
          <ListItemIcon>
            <ConvertRequestIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('convertRequest')} color="primary" />
        </StyledMenuItem>}

      {requestObject.status && requestObject.status.description_static === statusConfigs.Hold &&
        <Divider />}

      {requestObject.status && requestObject.status.description_static === statusConfigs.Hold &&
        <StyledMenuItem onClick={() => handleNotify(requestObject.id)}>
          <ListItemIcon><NotificationsActiveIcon fontSize="small" /></ListItemIcon>
          <ListItemText primary={t('Notify for update')} />
        </StyledMenuItem>}

      <Divider />
      <StyledMenuItem onClick={() => handleUploadFile(requestObject.id)}>
        <ListItemIcon>
          <CloudUploadIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('generalUploadDocument')} />
      </StyledMenuItem>

      {hasReleventActiveNotifications &&
        <Divider />}
      {hasReleventActiveNotifications &&
        <Typography variant="body1" style={{ margin: '10px', fontWeight: 'bold' }}>
          Pending actions
        </Typography>}
      {hasReleventActiveNotifications &&
        releventActiveNotifications.filter(n => n.action !== undefined)
          .map((notification, index) => {
            return (
              <StyledMenuItem key={index} onClick={() => handleActionClick(notification)} className={classes.primaryItem}>
                <ListItemIcon>
                  <ReplyIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={`${notification.action ? notification.action.description : ""} ${notification.assetType ? notification.assetType.description : " "}`} />
              </StyledMenuItem>
            )
          })}

      {isActive && !requestObject.hasDelayRequest && handleRequestForDelay &&
        <>
          <Divider />
          <StyledMenuItem disabled={isBusy} onClick={handleRequestForDelay}>
            <ListItemIcon>
              {isBusy && <CircularProgress color="primary" size={20} className={classes.progress} />}
              <UpdateIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("delay.request.title")} />
          </StyledMenuItem>
        </>}
      {isActive && isAdmin && requestObject.hasDelayRequest &&
        <>
          <Divider />
          <StyledMenuItem disabled={isBusy} onClick={handleRespondToDelayRequest} className={classes.primaryItem}>
            <ListItemIcon>
              {isBusy && <CircularProgress color="primary" size={20} className={classes.progress} />}
              <AssistantIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("delay.response.title")} />
          </StyledMenuItem>
        </>}

      {isFinished &&
        <Divider />}
      {isFinished &&
        <StyledMenuItem disabled={isBusy} onClick={() => handleExport(requestObject.id)}>
          <ListItemIcon>
            {isBusy && <CircularProgress color="primary" size={20} className={classes.progress} />}
            <GetAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('generalDownloadRequest')} />
        </StyledMenuItem>}

      {isAdminAndRequestClosed &&
        <Divider />}
      {isAdminAndRequestClosed &&
        <StyledMenuItem onClick={() => handleReject(requestObject.id)}>
          <ListItemIcon>
            <ThumbDownIcon fontSize="small" style={{ color: "orangered" }} />
          </ListItemIcon>
          <ListItemText primary={t('reject')} />
        </StyledMenuItem>}
      {isAdminAndRequestClosed &&
        <StyledMenuItem onClick={() => handleSetExpired(requestObject.id)}>
          <ListItemIcon>
            <RemoveCircleIcon fontSize="small" style={{ color: "orangered" }} />
          </ListItemIcon>
          <ListItemText primary={t('setAsExpired')} />
        </StyledMenuItem>}
    </StyledMenu>
  )
}