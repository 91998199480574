import { AppBar, CircularProgress, Dialog, DialogActions, DialogContent, Fab, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircle as AddIcon, Cancel as DeactivateIcon, CheckCircle as ActivateIcon, Close as CloseIcon, Save as SaveIcon } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userActions } from '../../../utils/AppConstants';
import { deleteData, patchData, postData, putData } from './../../../utils/ApiRequest';
import { activateWorkFlowURL, deactivateWorkFlowURL, getBasicWorkflowCreateUrl, updateWorkflowBasicUrl } from './../../../utils/ApiUrl';
import { AppContext } from './../../AuthenticatedPage';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(10),
  },
  container: {
    maxHeight: 440,
  },
  formControl: {
    fullWidth: true,
    display: 'flex'
  },
  dialog: {
    //backgroundColor: '#F5F7FA !important'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      width: '40%',
      minWidth: '200px'
    },
    textAlign: 'center',
    width: '100%'
  },
  progress: {
    marginRight: theme.spacing(1),
  },
}));

export default function WorkflowDialog(props) {

  const [t] = useTranslation();
  const classes = useStyles();

  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const { workflows, workflow, authToken } = state;
  const [isBusy, setIsBusy] = useState(false);
  const { open, setOpen, action } = props;
  const [description, setDescription] = useState("");
  const [remarks, setRemarks] = useState("");

  useEffect(() => {

    setDescription(workflow ? workflow.description : "");
    setRemarks(workflow ? workflow.remarks : "");

  }, [workflow])

  async function handleCreateSubmit(event) {
    event.preventDefault();
    try {
      setIsBusy(true);
      const data =
      {
        "description": description,
        "remarks": remarks
      }

      const response = await postData(getBasicWorkflowCreateUrl, data, authToken)
      const status = response.status;
      if (status === 200 || status === 201) {
        const newObj = await response.json();
        let newArray = [...workflows];
        newArray.push(newObj);

        dispatch({ "type": "setWorkflows", value: newArray });
        dispatch({ "type": "setSnackBarInfo", value: { message: `${t('pageWorkflowsWorkflow')} '${description}' ${t('dialogWorkflowCreated')}`, isError: false } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else if (status === 401) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
    }
    finally {
      setIsBusy(false);
      setOpen(false);
    }
  };

  async function handleEditSubmit(event) {
    event.preventDefault();
    try {
      setIsBusy(true);
      const data =
      {
        "description": description,
        "remarks": remarks
      }

      const response = await putData(updateWorkflowBasicUrl(workflow.id), data, authToken)
      const status = response.status;
      if (status === 200 || status === 201) {
        const newObj = await response.json();
        const objIndex = workflows.findIndex(e => e.id === workflow.id);
        let newArray = [...workflows];
        newArray[objIndex] = { ...newArray[objIndex], description: newObj.description, remarks: newObj.remarks }

        dispatch({ "type": "setWorkflows", value: newArray });
        dispatch({ "type": "setSnackBarInfo", value: { message: `${t('pageWorkflowsWorkflow')} '${description}' ${t('dialogWorkflowUpdated')}`, isError: false } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else if (status === 401) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
    }
    finally {
      setIsBusy(false);
      setOpen(false);
    }
  };

  async function handleActivateOrDeactivate(event) {

    event.preventDefault();
    try {
      setIsBusy(true);
      const url = action === userActions.activate ? activateWorkFlowURL(workflow.id) : deactivateWorkFlowURL(workflow.id);
      const response = await (action === userActions.activate ? patchData(url, null, authToken) : deleteData(url, authToken))
      const status = response.status;
      if (status === 201 || status === 200) {

        const updatedObj = await response.json();
        const objIndex = workflows.findIndex(e => e.id === workflow.id);
        let newArray = [...workflows];
        newArray[objIndex] = {
          ...newArray[objIndex],
          status: updatedObj.status //action === userActions.activate ? 'Active' : 'Inactive'
        }

        dispatch({ "type": "setWorkflows", value: newArray });

        if (action === userActions.activate)
          dispatch({ "type": "setSnackBarInfo", value: { message: `${t('generalWorkflow')} '${workflow.description}' ${t('pageWorkflowsActiveted')}`, isError: false } });
        else
          dispatch({ "type": "setSnackBarInfo", value: { message: `${t('generalWorkflow')} '${workflow.description}' ${t('pageWorkflowsDeactivated')}`, isError: false } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else if (status === 401) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
    }
    finally {
      setIsBusy(false);
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  function validateForm() {
    return (description.length > 3 && description.length < 100 && remarks.length < 200);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullScreen
      className={classes.dialog}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" className={classes.title}>
            {action ? (action === userActions.activate ? t('pageWorkflowsActivate') : t('pageWorkflowsDeactivate')) :
              (workflow ? t('pageWorkflowsEditWorkflow') : t('dialogWorkflowCreate'))}
          </Typography>


        </Toolbar>
      </AppBar>
      <DialogContent style={{ backgroundColor: '#fff' }}>
        <form className={classes.form} autoComplete="off">
          <div>
            <br />
            <Typography variant="h6" >
              {action ? (action === userActions.activate ?
                t('pageWorkflowsActivateInstruction') :
                t('pageWorkflowsDeactivateInstruction')) :
                (workflow ? t('dialogWorkflowEditInstructions') : t('dialogWorkflowCreateInstruction'))}
            </Typography>
            <TextField
              margin="none"
              required
              fullWidth
              id="description"
              label={t('generalDescription')}
              name="description"
              autoComplete="off"
              value={description}
              variant="outlined"
              onChange={e => setDescription(e.target.value)}
              disabled={action === userActions.activate || action === userActions.deactivate}
            /><br />
            <TextField
              margin="none"
              fullWidth
              id="remarks"
              label={t('generalRemarks')}
              name="remarks"
              autoComplete="off"
              value={remarks}
              variant="outlined"
              multiline
              onChange={e => setRemarks(e.target.value)}
              disabled={action === userActions.activate || action === userActions.deactivate}
            />
          </div></form>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#fff' }}>
        <Fab variant="extended" color="primary" aria-label="add" disabled={isBusy || !validateForm()}
          onClick={action ? handleActivateOrDeactivate : (workflow ? handleEditSubmit : handleCreateSubmit)} >
          {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />}
          {action ?
            (action === userActions.activate ? <ActivateIcon className={classes.extendedIcon} /> : <DeactivateIcon className={classes.extendedIcon} />) :
            (workflow ? <SaveIcon className={classes.extendedIcon} /> : <AddIcon className={classes.extendedIcon} />)}  &nbsp;
          {action ? (action === userActions.activate ? "Activate work flow" : "Deactivate work flow") :
            (workflow ? t('pageWorkflowsSave') : t('pageWorkflowsAdd'))}
        </Fab>
      </DialogActions>
    </Dialog>
  )
}