import { Checkbox, FormControlLabel, IconButton, Tooltip } from '@material-ui/core';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import SearchTextBox from '../../Common/SearchTextBox';
import { statusConfigs } from '../../../utils/configs';

export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            //backgroundColor: theme.palette.common.white,
        },
    },
}))(TableRow);

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {

    const [t] = useTranslation();
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const headCells = [
        { id: 'firstName', disablePadding: true, label: t('pageUsersName') },
        { id: 'email', disablePadding: false, label: t('pageUsersEmail') },
        { id: 'phone_number', disablePadding: false, label: t('pageUsersPhone') },
        { id: 'company_name', disablePadding: false, label: t('pageUsersCompany') },
        { id: 'status_desc', disablePadding: false, label: t('pageUsersStatus') },
    ];

    return (
        <TableHead>
            <TableRow>
                <StyledTableCell padding="checkbox" />
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.align || 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ fontSize: 16 }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 40%',
        '& svg': {
            marginBottom: theme.spacing(-0.5),
            marginRight: theme.spacing(1),
        }
    },
}));

const EnhancedTableToolbar = (props) => {

    const [t] = useTranslation();
    const classes = useToolbarStyles();
    const { selected, includeDeletedUsers, handleIncludeDeletedUsers,
        handleAdd,
        handleEdit,
        handleActivateOrDeactivate,
        handleDelete,
        handleSearch
    } = props;

    const isUserDeleted = selected && selected.status_desc === statusConfigs.Deleted;
    const isUserPanding = selected && selected.status_desc === statusConfigs.Pending;
    const isUserInactive = selected && selected.status_desc === statusConfigs.Inactive;
    return (
        <Toolbar>
            <Typography className={classes.title} variant="h5" color="primary" id="tableTitle" component="div">
                {t('Users')}
            </Typography>

            <FormControlLabel
                component="div"
                control={<Checkbox checked={includeDeletedUsers} onChange={handleIncludeDeletedUsers} />}
                label={t('includeDeletedUsers')}
            />

            {handleAdd &&
                <Tooltip title="Edit user">
                    <IconButton color="primary" onClick={handleAdd}>
                        <PersonAddIcon />
                    </IconButton>
                </Tooltip>}

            {selected &&
                <Fragment>
                    {handleEdit &&
                        <Tooltip title="Edit user">
                            <IconButton color="primary" onClick={handleEdit}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>}

                    {handleActivateOrDeactivate &&
                        <Tooltip title={ isUserInactive || isUserPanding ? "Activate user":"Deactivate user"}>
                            <IconButton onClick={handleActivateOrDeactivate} >
                                 { isUserPanding || isUserInactive  ? <HowToRegIcon color='secondary' /> :<PersonAddDisabledIcon style={{color:'orange'}}  />}
                            </IconButton>
                        </Tooltip>}

                    {handleDelete && !isUserDeleted &&
                        <Tooltip title="Delete user permanently">
                            <IconButton onClick={handleDelete} style={{ color: "red" }}>
                                <DeleteForeverIcon />
                            </IconButton>
                        </Tooltip>}
                </Fragment>}
            <SearchTextBox handleChange={handleSearch} />
        </Toolbar>
    );
};

export { EnhancedTableToolbar, EnhancedTableHead, stableSort, getComparator };
