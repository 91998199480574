import { Badge } from "@material-ui/core";
import styled from 'styled-components';

const StyledBadge = styled(Badge)(() => ({
    '& .MuiAvatar-root': {
        backgroundColor: "orange",
    },
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: "0 0 0 2px #fff",
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(1)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(3)',
            opacity: 0,
        },
    },
}));

export default StyledBadge;