import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { HashRouter, Switch } from "react-router-dom";
import AnonymousPage from './components/AnonymousPage';
import AuthenticatedPage from './components/AuthenticatedPage';
import { getAppTitle } from './utils/AppConstants';
import { appConfigurations } from './utils/configs';
import { GetToken } from './utils/tokenActions';

require('dotenv').config()

export default function App() {

  const isAmpApp = appConfigurations.isAmpApp;
  const token = GetToken();
  const [isAuthenticated, setAuthentication] = useState(token !== "null" && token !== null);

  useEffect(() => {
    const changeAppTitle = () => {
      const newTitle = getAppTitle(isAmpApp);
      document.title = newTitle;
    };
    const changeFavicon = () => {
      const link = document.querySelector('link[rel="icon"]');
      if (isAmpApp) {
        link.setAttribute('href', 'amp-favicon.ico');
      } else {
        link.setAttribute('href', 'favicon.ico');
      }
    };
    changeAppTitle();
    changeFavicon();
  }, [isAmpApp])

  if (isAuthenticated) {
    var decodedToken = jwt_decode(token);
    if (Date.now() >= decodedToken.exp * 1000) {
      localStorage.clear();
      sessionStorage.clear();
      setAuthentication(false);
    }
  }

  return (
    <HashRouter>
      <Switch>
        {isAuthenticated ? <AuthenticatedPage setAuthentication={setAuthentication} /> :
          <AnonymousPage isAuthenticated={isAuthenticated} setAuthentication={setAuthentication} />}
      </Switch>
    </HashRouter>
  )
}