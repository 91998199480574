import { Collapse, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EmojiPeople as NothingFoundIcon } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NotificationItem from './NotificationItem';

const useStyles = makeStyles(theme => ({
    divNoNotifications: {
        marginLeft: theme.spacing(4)
    }
}));

export default function CollapseNotifyItems(props) {

    const [t] = useTranslation();
    const classes = useStyles();
    const { expanded, items, isFinished } = props;

    return (
        <Collapse in={expanded} timeout="auto" unmountOnExit >
            <Grid container justify="center">
                {items && items.length > 0 ?
                    items.map(item =>
                        <NotificationItem key={item.id}
                            item={item} isFinished={isFinished} />) :
                    <Grid className={classes.divNoNotifications}>
                        <Typography centered="true">
                            <NothingFoundIcon />
                            {t('pageNotificationNoNotifications')}
                        </Typography>
                    </Grid>}
            </Grid>
        </Collapse>
    );
}