import React, { Fragment } from 'react';
import RequestTrackPage from '../pages/Track/RequestTrackPage';
import { appRoute } from './../../utils/routeConfigs';
import RequestCreatePage from './../pages/Requests/RequestCreatePage';
import RequestDetailsPage from './../pages/Requests/RequestDetailsPage';
import RequestEditPage from './../pages/Requests/RequestEditPage';
import RequestListPage from './../pages/Requests/RequestListPage';
import PrivateRoute from './PrivateRoute';

export default function RouteRequest() {

    return (
        <Fragment>
            <PrivateRoute path={appRoute.requestList}>
                <RequestListPage />
            </PrivateRoute>

            <PrivateRoute path={appRoute.createRequest}>
                <RequestCreatePage />
            </PrivateRoute>

            <PrivateRoute path={appRoute.detailsRequest}>
                <RequestDetailsPage />
            </PrivateRoute>

            <PrivateRoute path={appRoute.editRequest}>
                <RequestEditPage />
            </PrivateRoute>

            <PrivateRoute path={appRoute.trackRequest}>
                <RequestTrackPage />
            </PrivateRoute>
        </Fragment>
    )
}