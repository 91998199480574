import { Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { createContext, Fragment, useReducer } from 'react';
import { Route } from "react-router-dom";
import { appRoute } from '../utils/routeConfigs';
import RhdhvLogo from './../img/logo-rhdhv.png';
import CircleLogo from './../img/logo-circle-client.svg';
import HTCLogo from './../img/logo-hightechcampus.png';
import { anonymousAppReducer, initialAnonymousAppState } from './../state/appStateAnonymous';
import SnackBarComponent from './Common/SnackBar';
import Footer from './pages/Authorization/footer';
import ForgotPassword from './pages/Authorization/ForgotPassword';
import Login from './pages/Authorization/Login';
import Register from './pages/Authorization/Register';
import VerifyEmailPage from './pages/Authorization/VerifyEmailPage';
import Homepage from './pages/Homepage';
import { useLocation } from 'react-router-dom'
import { appConfigurations } from '../utils/configs';

export const AnonymousAppContext = createContext({
  state: initialAnonymousAppState,
  dispatch: () => { },
});

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    marginTop: theme.spacing(1),
    flexGrow: 1,
    '& .MuiLink-root': {
      marginRight: theme.spacing(1),
      "&:hover": {
        cursor: 'pointer'
      },
    },
    '& form': {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      '& .MuiTextField-root': {
        marginTop: theme.spacing(1.5),
        width: '100%',
        minWidth: '200px',
      },
      '& .MuiFormControl-root': {
        marginTop: theme.spacing(1.5),
        width: '100%',
        minWidth: '200px'
      },
    },
  },
  imgCircle: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer'
    }
  },
  imgHTC: {
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(5),
    '&:hover': {
      cursor: 'pointer'
    }
  },

  gridRhdhv: {
    display: 'flex',
  },
  imgRhdhv: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(3),
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default function AnonymousPage(props) {

  const classes = useStyles();
  const { isAuthenticated, setAuthentication } = props;
  const [state, dispatch] = useReducer(anonymousAppReducer, initialAnonymousAppState);
  const { openSnackbar, snackBarInfo } = state;

  const handleBottomNavigationChange = (val) => {
    if (val === 'rhdhv')
      return window.open("https://www.royalhaskoningdhv.com/", "_blank");
    else if (val === 'circle')
      return window.open("https://circleinfrapartners.com/", "_blank");
    else if (val === 'hightechcampus')
      return window.open("https://www.hightechcampus.com/", "_blank");
  };

  const location = useLocation();

  const getComponent = () => {
    switch (location.pathname) {
      case appRoute.verifyEmail:
        return (
          <Route path={appRoute.verifyEmail}>
            <VerifyEmailPage />
          </Route>);
      case appRoute.forgotPwd:
        return (
          <Route path={appRoute.forgotPwd}>
            <ForgotPassword />
          </Route>);
      case appRoute.register:
        return (
          <Route path={appRoute.register}>
            <Register />
          </Route>);
      default:
        return (
          <Route path={[appRoute.root, appRoute.login]}>
            {isAuthenticated ? <Homepage /> :
              <Login setAuthentication={setAuthentication} />}
          </Route>);
    }
  }
  return (
    <Fragment>
      <AnonymousAppContext.Provider value={{ state, dispatch }}>
        <Grid container justify="center" alignItems="center" className={classes.root}>

          <Grid item xs={4} className={classes.gridRhdhv}>
            {appConfigurations.isAmpApp ?
              <Grid container justify="flex-end">
                <Tooltip title="Visit High Tech Campus official website">
                  <img alt="hightechcampus" src={HTCLogo} height="75px" className={classes.imgHTC} onClick={() => handleBottomNavigationChange('hightechcampus')} />
                </Tooltip>
              </Grid>
              :
              <Grid container justify="flex-end">
                <Tooltip title="Visit Royal HaskoningDHV official website">
                  <img alt="rhdhv" src={RhdhvLogo} height="110px" className={classes.imgRhdhv} onClick={() => handleBottomNavigationChange('rhdhv')} />
                </Tooltip>
              </Grid>
            }
          </Grid>
          <Grid item xs={4}>
            {getComponent()}
          </Grid>
          <Grid item xs={4} className={classes.gridCircle}>
            {!appConfigurations.isAmpApp &&
              <Grid container justify="flex-end">
                <Tooltip title="Visit Circle official website">
                  <img alt="Circle" src={CircleLogo} height="100px" className={classes.imgCircle} onClick={() => handleBottomNavigationChange('circle')} />
                </Tooltip>
              </Grid>
            }
          </Grid>
        </Grid>
        <Footer />
        <SnackBarComponent
          open={openSnackbar} info={snackBarInfo}
          dispatch={dispatch} autoHideDuration={3000} />
      </AnonymousAppContext.Provider>
    </Fragment>
  )
}
