import { INode, REACT_FLOW_CHART } from "@mrblenny/react-flow-chart";
import * as React from 'react';
import styled from 'styled-components';

const Outer = styled.div`
    padding-left: 10px;
    padding-top: 0;
    font-size: 14px;
    background: inherit;
    cursor: move;
`

export interface ISidebarItemProps {
    type: string,
    text: string,
    actionType: string,
    name: string,
    ports: INode['ports'],
    properties?: any,
    icon?:any,
}

export const SidebarItem = ({ type, text, name, actionType, ports, properties, icon }: ISidebarItemProps) => {
  return (
    <Outer
      draggable={true}
      onDragStart={ (event) => {
          event.dataTransfer.setData(REACT_FLOW_CHART, JSON.stringify({ type, text, name, actionType, ports, properties }))
      } }
      >
          <div style={{
              display: 'flex',
              alignItems: 'center'
          }}>
              {icon ? icon : <div />}
              <p>&nbsp;{`${properties.name}`}</p>
          </div>
    </Outer>
  )
}
