import { AppBar, CircularProgress, Dialog, DialogActions, DialogContent, Fab, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, Save as SaveIcon, Visibility, VisibilityOff } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPassword, userValidation } from '../../../utils/AppConstants';
import { putData } from './../../../utils/ApiRequest';
import { changePasswordURL } from './../../../utils/ApiUrl';
import { tokens } from './../../../utils/keys';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext } from './../../AuthenticatedPage';

const useStyles = makeStyles(theme => ({
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '40%',
            minWidth: '200px'
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
            width: '40%',
            minWidth: '200px'
        },
        textAlign: 'center',
        width: '100%'
    },
    container: {
        maxHeight: 440,
    },
    formControl: {
        fullWidth: true,
        display: 'flex'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    }, 
     progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function ChangePaswordDialog(props) {

    const [t] = useTranslation();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { dispatch } = appContext;
    const { open, setOpen } = props;

    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [currentPasswordError, setCurrentPasswordError] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    async function handleSubmit(event) {
        event.preventDefault();
        
        try{
            setIsBusy(true)
            const userid = localStorage.getItem(tokens.keyUserId) && localStorage.getItem(tokens.keyUserId) !== "null" && localStorage.getItem(tokens.keyUserId) !== null ?
            localStorage.getItem(tokens.keyUserId) : sessionStorage.getItem(tokens.keyUserId);
        const data =
        {
            "old_password": currentPassword,
            "new_password": newPassword,
        }

                const  response = await putData(changePasswordURL(userid), data, GetToken())
                const status = response.status;
                if (status === 200 || status === 201) {
                    dispatch({ "type": "setSnackBarInfo", value: { message: t('dialogChangePwdPasswordChanged'), isError: false } })
                    dispatch({ "type": "setOpenSnackbar", value: true })
                }
                else if (status === 401) {
                    dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
                    dispatch({ "type": "setOpenSnackbar", value: true })
                }
                else {
                    dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } })
                    dispatch({ "type": "setOpenSnackbar", value: true })
                }

        }
        finally{
            setIsBusy(false)
            setOpen(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    function validateForm() {
        return currentPassword.length > 0 &&
            isValidPassword(newPassword) &&
            newPassword === confirmNewPassword;
    }

    const handleCurrentPasswordChange = (e) => {
        setCurrentPassword(e.target.value);
        setCurrentPasswordError(e.target.value.length <= 0);
    };

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
        setNewPasswordError(!isValidPassword(e.target.value));
        setConfirmNewPasswordError(e.target.value !== confirmNewPassword);
    };

    const handleConfirmNewPasswordChange = (e) => {
        setConfirmNewPassword(e.target.value);
        setConfirmNewPasswordError(e.target.value !== newPassword);
    };

    const handleClickShowPassword = (sender) => {
        if (sender === 'current')
            return setShowCurrentPassword(!showCurrentPassword);
        else if (sender === 'newp')
            return setShowNewPassword(!showNewPassword);
        else if (sender === 'confirmnewp')
            return setShowConfirmNewPassword(!showConfirmNewPassword);
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullScreen>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" className={classes.title}>{t('dialogChangePwdChangePwd')}</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <form className={classes.form} autoComplete="off">
                    <div>
                        <br />
                        <Typography variant="h6">
                            {t('dialogChangePwdChangePwdInfo')}
                            </Typography>

                        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">
                                {t('dialogChangePwdCurrentPwd')}
                            </InputLabel>
                            <OutlinedInput
                                error={currentPasswordError}
                                id="current-password"
                                type={showCurrentPassword ? 'text' : 'password'}
                                required
                                fullWidth
                                disabled={isBusy}
                                name="password"
                                label={t('dialogChangePwdCurrentPwd')}
                                value={currentPassword}
                                onChange={e => handleCurrentPasswordChange(e)}
                                autoComplete="off"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                            onClick={() => handleClickShowPassword('current')}
                                            onMouseDown={handleMouseDownPassword}>
                                            {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                            <FormHelperText error={currentPasswordError} id="my-helper-text">{currentPasswordError ? t('dialogChangePwdErrorCurrentPwd') : ""}</FormHelperText>
                        </FormControl><br />

                        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">
                                {t('dialogChangePwdNewPwd')}
                            </InputLabel>
                            <OutlinedInput
                                id="new-password"
                                error={newPasswordError}
                                helperText={newPasswordError ? t('dialogChangePwdErrorNewPwd') : ""}
                                type={showNewPassword ? 'text' : 'password'}
                                required
                                disabled={isBusy}
                                fullWidth
                                name="password"
                                label={t('dialogChangePwdNewPwd')}
                                value={newPassword}
                                onChange={e => handleNewPasswordChange(e)}
                                autoComplete="off"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                            onClick={() => handleClickShowPassword('newp')}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                            <FormHelperText error={newPasswordError} id="my-helper-text">
                                {newPasswordError ? t('newPasswordError', { max_chars: `${userValidation.passwordMin}-${userValidation.passwordMax}` }) : ""}
                            </FormHelperText>
                        </FormControl><br />

                        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">
                                {t('dialogChangePwdConfirmPwd')}
                            </InputLabel>
                            <OutlinedInput
                                id="confirm-new-password"
                                error={confirmNewPasswordError}
                                type={showConfirmNewPassword ? 'text' : 'password'}
                                required
                                fullWidth
                                disabled={isBusy}
                                name="password"
                                label={t('dialogChangePwdConfirmPwd')}
                                value={confirmNewPassword}
                                onChange={e => handleConfirmNewPasswordChange(e)}
                                autoComplete="off"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                            onClick={() => handleClickShowPassword('confirmnewp')}
                                            onMouseDown={handleMouseDownPassword}>
                                            {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                            <FormHelperText error={confirmNewPasswordError} id="my-helper-text">{confirmNewPasswordError ? t('dialogChangePwdErrorNewConfirmPwd') : ""}</FormHelperText>
                        </FormControl>

                    </div></form>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#fff' }}>
                <Fab variant="extended" color="primary" aria-label="add" className={classes.scenarioAanmaken}
                    onClick={handleSubmit} disabled={isBusy || !validateForm()}>
                {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />  }
                    <SaveIcon className={classes.extendedIcon} />
                    {t('dialogChangePwdConfirm')}
                </Fab>
            </DialogActions>
        </Dialog>
        )
}