import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { default as React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadType } from '../../../utils/AppConstants';
import ComponentAttachments from '../Attachments/ComponentAttachments';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
    },
}));

export default function RequestActionAttachment(props) {

    const [t] = useTranslation();
    const classes = useStyles();
    const { requestAction } = props;
    const [attachments, setAttachments] = useState(requestAction && requestAction.attachments ? requestAction.attachments : []);
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    }

    return (
        !requestAction || !requestAction.attachments || requestAction.attachments.length <= 0 ? <div /> :
            <div className={classes.root}>
                <List style={{ padding: 0, margin: 0 }}>
                    <ListItem button className={classes.title} onClick={handleClick} style={{ padding: 0, margin: 0 }} >
                        {open ? <ExpandLess /> : <ExpandMore />}
                        <ListItemText primary={t('generalAttachments')} />
                    </ListItem>
                </List>

                <Collapse in={open} timeout="auto" unmountOnExit>
                    <ComponentAttachments
                        attachments={attachments} setAttachments={setAttachments}
                        uploadDisabled={true} leftAligned={true}
                        typeDownload={downloadType.requestResponse} />
                </Collapse>
            </div>
    );
}