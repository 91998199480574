import { Button, CircularProgress, Grid, Link, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { getAppTitle, isValidEmail } from '../../../utils/AppConstants';
import { appConfigurations } from '../../../utils/configs';
import LanguageSwitch from '../../Common/LanguageSwitch';
import WarningOrErrorComponent from '../../Common/WarningOrErrorComponent';
import { patchData } from './../../../utils/ApiRequest';
import { emailVerificationURL } from './../../../utils/ApiUrl';
import { AnonymousAppContext } from './../../AnonymousPage';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    fontSize: '2.5rem',
  },
  subTitle: {
    fontSize: '2.1rem',
  },
  progress: {
    marginRight: theme.spacing(1),
  },
}));

export default function VerifyEmailPage() {

  const [t] = useTranslation();
  let history = useHistory();

  const appContext = useContext(AnonymousAppContext);
  const { state, dispatch } = appContext;
  const { selectedLanguage } = state;

  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [verificationCodeError, setVerificationCodeError] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const classes = useStyles();

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      setIsBusy(true);
      const data = {
        "email": email,
        "verificationCode": parseInt(verificationCode)
      };

      const lang = selectedLanguage || 'en';
      const response = await patchData(emailVerificationURL(lang), data)
      const status = response.status;
      if (status === 200 || status === 201) {

        dispatch({ "type": "setSnackBarInfo", value: { message: t('pageVerifyVerified'), isError: false } })
        dispatch({ "type": "setOpenSnackbar", value: true })

        history.replace('/');
      }
      else if (status === 404) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('pageVerifyInvalidEmail'), isError: true } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
      else if (status === 409) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('pageVerifyIncorrectVerificationCode'), isError: true } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('generalErrorGetData'), isError: true } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
    }
    finally {
      setIsBusy(false);
    }

  }

  function onLoginClick() {
    history.replace('/');
  }
  function onForgotPasswordClick() {
    history.replace('/forgotpwd');
  }

  function validateForm() {
    return isValidEmail(email) &&
      verificationCode.length >= 4;
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(!isValidEmail(e.target.value));
  };

  const handleCodeChange = (e) => {
    setVerificationCode(e.target.value);
    setVerificationCodeError(!e.target.value.length >= 4);
  };

  return (
    <div className={classes.root}>
      <Grid container direction="column" alignItems="center" justify="center" className={classes.titleGrid}>
        <Typography color="primary" gutterBottom align="center" className={classes.title}>
          {getAppTitle(appConfigurations.isAmpApp)}
        </Typography>
        <Typography color="primary" className={classes.subTitle}>
          {t('pageVerifyVerifyEmail')}
        </Typography>
      </Grid>
      <form>
        <TextField
          error={emailError}
          helperText={emailError ? t('emailError') : ""}
          margin="none"
          required
          fullWidth
          variant="outlined"
          id="email"
          label={t('pageUsersEmail')}
          name="email"
          type="email"
          autoComplete="off"
          autoFocus
          value={email}
          onChange={e => handleEmailChange(e)}
        />
        <TextField
          error={verificationCodeError}
          helperText={verificationCodeError ? t('pageForgotPwdInvalidCode') : ""}
          margin="none"
          type="number"
          required
          fullWidth
          variant="outlined"
          id="verificationCode"
          label={t('pageForgotPwdVerificationCode')}
          name="verificationCode"
          autoComplete="off"
          value={verificationCode}
          onChange={e => handleCodeChange(e)}
        />

        <WarningOrErrorComponent info message={t('emailVerifiedInstructions')} />

        <Button
          onSubmit={handleSubmit}
          onClick={handleSubmit}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          type="submit"
          disabled={isBusy || !validateForm()}
        >
          {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />}
          {t('generalSubmit')}
        </Button>

        <Grid container direction="row">
          <Grid item xs={5}>
            <Link className={classes.linkText} onClick={onForgotPasswordClick}>
              {t('pageForgotPwdForgotPwd')}?
            </Link>
          </Grid>
          <Grid item xs={5}>
            <Grid item>
              <Link className={classes.linkText} onClick={onLoginClick}>
                {t('login')}?
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={2} justify="flex-end">
            <LanguageSwitch />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}