import { appConfigurations } from "../../utils/configs";
import { CardContent, CardHeader, IconButton, Paper, Slide } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';
import { useHistory } from "react-router-dom";
import { appRoute } from "../../utils/routeConfigs";
import imgChem1 from './../../img/Background/chemelot-site-1.jpg';
import imgChem2 from './../../img/Background/chemelot-site-2.jpg';
import imgChem3 from './../../img/Background/chemelot-site-3.jpg';
import imgHTC2 from './../../img/Background/High_Tech_Campus_2.jpg';
import imgHTC3 from './../../img/Background/High_Tech_Campus_3.jpg';
import imgHTC4 from './../../img/Background/High_Tech_Campus_4.jpg';
import HomePageImage from './HomePageImage';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(5),
  },
  paper1: {
    backgroundColor: 'transparent',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 'auto',
    marginBottom: '0px',
    paddingTop: '0px',
    paddingRight: theme.spacing(3),
  },
  card: {
    maxWidth: 1600,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    },
  },
  infoCard: {
    background: 'linear-gradient(0deg, #bfbfbf 40%, #e6e6e6 100%)',
    maxWidth: 250,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    '&:hover': {
      background: 'none',
      backgroundColor: theme.palette.primary.main,
      cursor: 'pointer',
      '& .MuiTypography-root': {
        color: theme.palette.common.white,
      },
    }
  },
  selectedCard: {
    background: 'none',
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
    '& .MuiTypography-root': {
      color: theme.palette.common.white,
    },
  },
  infoCardPaper: {
    backgroundColor: 'rgba(52, 52, 52, 0)'
  },
  root1: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(50),
      height: theme.spacing(40),
    },
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  buttonRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
    },
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  media: {
    height: 600,
    width: '80%',
    marginLeft: '10%',
  },
  detailHeading: {
    fontSize: '28px',
    textAlign: 'center'
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`
  },
  submit: {
    marginBottom: '10px'
  },
  cardHeading: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    fontStyle: 'italic',
    marginBottom: theme.spacing(1.5),
  },
  ampinfotexts: {
    position: 'absolute',
    top: '30%'
  }
}));

function Item(props) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.content} >
        <IconButton className={classes.smallIcon}>
        </IconButton>
        <Slide timeout={1000} direction="down" in={true} mountOnEnter unmountOnExit>
          <Paper elevation={0} className={classes.paper}>
            <h2 className={classes.heading}>{props.item.name}</h2>
          </Paper>
        </Slide>
      </CardContent>
      <CardMedia
        component="img"
        className={classes.media}
        image={props.item.image}
      />
      <CardContent className={classes.content} >
        <IconButton className={classes.smallIcon}>
        </IconButton>
        <Slide timeout={1000} direction="up" in={true} mountOnEnter unmountOnExit>
          <Paper elevation={0} className={classes.paper}>
            <Typography className={classes.content}>{props.item.description}</Typography>
          </Paper>
        </Slide>
      </CardContent>
    </Card>
  )
}

function InfoItem(props) {
  const classes = useStyles();
  const { item, selectedCard, setSelectedCard } = props;

  const handleClick = () => {
    setSelectedCard(item);
  }

  return (
    <Fragment>
      <Card onClick={handleClick} onMouseOver={handleClick}
        className={clsx(classes.infoCard, { [classes.selectedCard]: selectedCard && selectedCard.id === item.id })}>
        <CardHeader>
          <IconButton className={classes.smallIcon} />
        </CardHeader>
        <CardContent className={classes.infoCardContent} >
          <Paper elevation={0} square className={classes.infoCardPaper}>
            <Typography style={{ wordWrap: 'break-word' }} className={classes.cardHeading}>{item.name}</Typography>
            <Typography className={classes.content}>{item.description}</Typography>
          </Paper>
        </CardContent>
      </Card>
    </Fragment>
  )
}

export default function Homepage() {

  let history = useHistory();
  const classes = useStyles();
  const [t] = useTranslation();
  const isAmpApp = appConfigurations.isAmpApp;

  const getItems = () => {
    if (!isAmpApp) {
      return [
        {
          image: imgChem1,
          name: "Chemelot project site",
          description: "Photograph 1"
        },
        {
          image: imgChem2,
          name: "Chemelot project site",
          description: "Photograph 2"
        },
        {
          image: imgChem3,
          name: "Chemelot project site",
          description: "Photograph 3"
        }
      ];
    }

    return [
      {
        image: imgHTC2,
        name: "High Tech Campus project site",
        description: "Photograph 1"
      },
      {
        image: imgHTC3,
        name: "High Tech Campus project site",
        description: "Photograph 2"
      },
      {
        image: imgHTC4,
        name: "High Tech Campus project site",
        description: "Photograph 3"
      }
    ];
  }

  function getItemDetails(heading, details) {
    return (
      <Fragment>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Typography color="primary" className={classes.detailHeading}>
              {heading}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item xs={1} />
              <Grid item xs={10}>
                <Typography>
                  {details}
                </Typography>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Grid>

        </Grid>
      </Fragment>)
  }

  var infoItems = [
    {
      id: "1",
      name: t('homeRegistration'),
      description: t('homeRegistrationDetails'),
      details: getItemDetails(t('homeRegistration'), `${isAmpApp ? t('pageHomeProjRegisterInfoHTC') : t('pageHomeProjRegisterInfo')}`)
    },
    {
      id: "2",
      name: t('homeTrack'),
      description: t('homeTrackAppStatus'),
      details: getItemDetails(t('homeTrack'), t('pageHomeProjTrackInfo'))
    },
    {
      id: "3",
      name: t('homeProjectApproval'),
      description: t('homeProjectApprovalDetails'),
      details: getItemDetails(t('homeProjectApproval'), t('pageHomeProjApprovalInfo'))
    },
    {
      id: "4",
      name: t('homeNotifications'),
      description: t('homeNotificationsDetails'),
      details: getItemDetails(t('homeNotifications'), t('pageHomeProjNotificationInfo'))
    },
    {
      id: "5",
      name: t('homeSupport'),
      description: t('homeSupportDetails'),
      details: getItemDetails(t('homeSupport'), t('pageHomeProjSupportInfo'))
    }
  ]

  const [selectedCard, setSelectedCard] = useState(infoItems[0]);

  const handleGotoInfoPage = () => {
    history.push(appRoute.about);
  }

  return (
    <Grid className={classes.root}>
      <Grid item xs={12} sm={12}>
        <HomePageImage />
        
        <h2 className={classes.detailHeading}>{t('homeHighlights')}</h2>
        <div className={classes.root1}>
          {infoItems.map(item =>
            <InfoItem
              key={item.description}
              item={item}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
            />)}
        </div>
        {!selectedCard ? <Fragment /> : selectedCard.details}
        <div className={classes.buttonRoot} >
          <Button
            variant="outlined"
            color="primary"
            className={classes.submit}
            type="submit"
            onClick={handleGotoInfoPage}
          >
            {t('homeKnowMore')}
          </Button>
        </div>
        <div className={classes.paper1}>
          <Paper elevation={0}>
            <Carousel>
              {getItems().map(item =>
                <Item
                  key={item.name}
                  item={item}
                />)}
            </Carousel>
          </Paper>
        </div>
      </Grid>
    </Grid>
  )
}
