import { languages } from './../utils/AppConstants';

export const initialAppState = {
  authToken: undefined,
  selectedLanguage: languages.en,
  openSnackbar: false,
  snackBarInfo: null,
  notificationRefreshKey: 0,
  openNotificationDrawer: false,
  selectedPage: "",
  notifications: [],
  userRoles: [],
  workflowName: "",
  workflow: null,
  workflows: [],
  requests: [],
  selectedRequest: null,
  selectedAction: null,
  selectedRequestActionId: "",
  selectedAssetType: null,
  requestTypes: [],
  selectedRequestType: null,
  usersForActionTransfer: [],
  users: [],
  roles: [],
  usersWithRoles: [],
  selectedNotification: null,
  actionById: "",
  currentUser: null,
  currentUserRole: null,
  checkListCategories: []
}

export function appReducer(state, action) {
  switch (action.type) {
    case "setAuthToken": {
      const newState = { ...state, authToken: action.value };
      return newState;
    }
    case "setSelectedLanguage": {
      const newState = { ...state, selectedLanguage: action.value };
      return newState;
    }
    case "setOpenSnackbar": {
      const newState = { ...state, openSnackbar: action.value };
      return newState;
    }
    case "setSnackBarInfo": {
      const newState = { ...state, snackBarInfo: action.value };
      return newState;
    }
    case "setNotificationRefreshKey": {
      const newState = { ...state, notificationRefreshKey: action.value };
      return newState;
    }
    case "setOpenNotificationDrawer": {
      const newState = { ...state, openNotificationDrawer: action.value };
      return newState;
    }
    case "setSelectedPage": {
      const newState = { ...state, selectedPage: action.value };
      return newState;
    }
    case "setNotifications": {
      const newState = { ...state, notifications: action.value };
      return newState;
    }
    case "setUserRoles": {
      const newState = { ...state, userRoles: action.value };
      return newState;
    }
    case "setWorkflowName": {
      const newState = { ...state, workflowName: action.value };
      return newState;
    }
    case "setWorkflow": {
      const newState = { ...state, workflow: action.value };
      return newState;
    }
    case "setWorkflows": {
      const newState = { ...state, workflows: action.value };
      return newState;
    }
    case "setRequests": {
      const newState = { ...state, requests: action.value };
      return newState;
    }
    case "setSelectedRequest": {
      const newState = { ...state, selectedRequest: action.value };
      return newState;
    }
    case "setSelectedAction": {
      const newState = { ...state, selectedAction: action.value };
      return newState;
    }
    case "setSelectedRequestActionId": {
      const newState = { ...state, selectedRequestActionId: action.value };
      return newState;
    }
    case "setSelectedAssetType": {
      const newState = { ...state, selectedAssetType: action.value };
      return newState;
    }
    case "setRequestTypes": {
      const newState = { ...state, requestTypes: action.value };
      return newState;
    }
    case "setSelectedRequestType": {
      const newState = { ...state, selectedRequestType: action.value };
      return newState;
    }
    case "setUsersForActionTransfer": {
      const newState = { ...state, usersForActionTransfer: action.value };
      return newState;
    }
    case "setRoles": {
      const newState = { ...state, roles: action.value };
      return newState;
    }
    case "setUsers": {
      const newState = { ...state, users: action.value };
      return newState;
    }
    case "setUsersWithRoles": {
      const newState = { ...state, usersWithRoles: action.value };
      return newState;
    }
    case "setSelectedNotification": {
      const newState = { ...state, selectedNotification: action.value };
      return newState;
    }
    case "setActionById": {
      const newState = { ...state, actionById: action.value };
      return newState;
    }
    case "setCurrentUser": {
      const newState = { ...state, currentUser: action.value };
      return newState;
    }
    case "setCurrentUserRole": {
      const newState = { ...state, currentUserRole: action.value };
      return newState;
    }
    case "setCheckListCategories": {
      const newState = { ...state, checkListCategories: action.value };
      return newState;
    }
    default:
      return state;
  }
}