import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { appStatuses, roleConfigs, statusColors } from '../../../utils/AppConstants';
import RequestActionAttachment from './RequestActionAttachment';
import RequestResponseProperty from './RequestResponseProperty';
import { appConfigurations } from '../../../utils/configs';

const dateFormat = require('dateformat');
const dateStringFormat = "dd-mm-yyyy HH:MM";
const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 275,
        marginBottom: theme.spacing(2),
        backgroundColor: '#f2f2f2',
        '& .MuiGrid-root, .MuiCardContent-root': {
            padding: 0,
            margin: 0
        },
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    heading: {
        fontSize: '1.2rem',
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
    },
    expendText: {
        '& .MuiTypography-root': {
            fontWeight: 'bold',
        },
    },
    fixedWidth: {
        width: '125px'
    },
    detailsCard: {
        maxWidth: '50px',
    },
    assignedCard: {
        backgroundColor: statusColors.assigned,
    },
    completedCard: {
        backgroundColor: statusColors.completed,
    },
    pendingCard: {
        backgroundColor: statusColors.pending,
    },
    activeCard: {
        backgroundColor: theme.palette.primary.main,
    },
    rejectCard: {
        backgroundColor: statusColors.rejected,
    },
    expiredCard: {
        backgroundColor: statusColors.expired,
    },
    notRequiredCard: {
        backgroundColor: statusColors.notRequired,
    },
    holdCard: {
        backgroundColor: statusColors.hold,
    },
}));

export default function RequestResponseCard(props) {

    const [t] = useTranslation();
    const classes = useStyles();
    const { requestAction , currentUserRole } = props;
    const onHold = requestAction?.status?.description === appStatuses.hold;
    const isAmpApp = appConfigurations.isAmpApp;
    const isUser = currentUserRole?.role?.role_static === roleConfigs.user;
    const hideResponsibleUserName = isAmpApp && isUser;
  
    return (
        !requestAction ? <div /> :
            <Card className={classes.root}>
                <CardContent>
                    <Grid container xs={12} className={classes.rootGrid}>
                        <Grid item xs={1} className={clsx(classes.detailsCard, {
                            [classes.assignedCard]: requestAction?.status?.description === appStatuses.assigned,
                            [classes.completedCard]: [appStatuses.finished, appStatuses.approved].includes(requestAction?.status?.description),
                            [classes.pendingCard]: requestAction?.status?.description === appStatuses.pending,
                            [classes.activeCard]: requestAction?.status?.description === appStatuses.active,
                            [classes.rejectCard]: requestAction?.status?.description === appStatuses.rejected,
                            [classes.notRequiredCard]: requestAction?.status?.description === appStatuses.notRequired,
                            [classes.expiredCard]: requestAction?.status?.description === appStatuses.expired,
                            [classes.holdCard]: onHold,
                        })}>
                            <Typography>&nbsp;</Typography>
                        </Grid>
                        <Grid item xs={11} style={{ marginTop: '5px', marginBottom: '10px' }}>
                            {requestAction.assetType &&
                            <RequestResponseProperty heading={t('generalAssetType')} description={requestAction.assetType.description} highlightValue={true} />}
                            <RequestResponseProperty heading={t('Action')} description={requestAction.action.description} />
                           {!hideResponsibleUserName && <RequestResponseProperty heading={t('cardResponseActionBy')} description={`${requestAction.actionBy.firstName} ${requestAction.actionBy.lastName}`} />} 
                            <RequestResponseProperty heading={t('cardResponseActiondate')} description={requestAction.end_date ? dateFormat(requestAction.end_date, dateStringFormat) : "N/A"} />
                            <RequestResponseProperty heading={t('generalStatus')} description={requestAction.status ? requestAction.status.description : "N/A"} highlightValue={onHold} />
                            <RequestResponseProperty heading={t('generalRemarks')} description={`${requestAction.remarks ? requestAction.remarks : 'N/A'}`} />
                            <RequestActionAttachment requestAction={requestAction} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
    );
}