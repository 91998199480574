import { Divider, Drawer, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Fab, IconButton, List, ListSubheader, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountCircle as UserIconSub, ArrowBackIos as BackIcon, Assignment as TaskIcon, AssignmentTwoTone as TaskIconSub, BubbleChart as AssetTypeSub, Cancel as CloseIcon, ClearAll as ClearAllIcon, ExpandMore as ExpandMoreIcon, Grade as GeneralIcon, GradeTwoTone as GeneralIconSub, LocalActivity as ActionIcon, LocalActivityTwoTone as ActionIconSub, PanTool as HoldIcon, PanToolTwoTone as HoldIconSub, PeopleAlt as UserIcon, Save as SaveIcon, ScatterPlot as AssetTypeIcon } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { getData } from './../../../utils/ApiRequest';
import { getWorkflowItemsUrl, getWorkflowUrl } from './../../../utils/ApiUrl';
import { isAdmin } from './../../../utils/Roles';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext } from './../../AuthenticatedPage';
import { SidebarItem } from './../../Flowchart';
import Workflow from './../../Flowchart/Workflow';

const drawerWidth = 300;
const useStyles = makeStyles(theme => ({
    grid: {
        flexGrow: 1,
        marginTop: theme.spacing(10),
    },
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    root: {
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(10),
    },
    workflow: {
        maxHeight: '400px',
    },
    fab: {
        position: "fixed",
        bottom: theme.spacing(10),
        alignItems: 'center',
        minWidth: '24vh',
        justifyContent: 'center',
    },
    sideDrawerPanel: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(10),
    },
    sideDrawer: {
        minWidth: '300px',
        background: '#4F9AE7'//'linear-gradient(to right bottom, #D4D6E3, #7884CB)'
    },
    sideDrawerList: {
        //background: 'transparent',
        //color: '#fff'
        '& .MuiExpansionPanelDetails-root': {
            margin: 0,
            padding: 0,
            minHeight: 'auto'
        }
    },
    buttonsDiv: {
        position: 'fixed',
        right: '24px',
        bottom: '24px',
        left: 'auto',
        top: 'auto',
        zIndex:1200
    },
    toolsDiv: {
        position: 'fixed',
        left: theme.spacing(10),
        top: theme.spacing(10),
        zIndex: 1200
    },
    statusButton: {
        position: "fixed",
        //display: 'none',
        top: theme.spacing(10),
        zIndex: theme.zIndex.drawer + 4,
        backgroundColor: '#fff',
        right: theme.spacing(9),
        '&:hover': {
            backgroundColor: "#cccccc",
        },
    },
    statusButtonOpen: {
        right: drawerWidth + theme.spacing(2) + 25,
        display: 'block',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        top: '50%',
        left: theme.spacing(7),
        width: 50,
        maxWidth: 50,
        backgroundColor: '#F1F1F1',
        color: '#000'
    },
    appBarShift: {
        left: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: theme.zIndex.drawer + 3,
    },
    drawerPaper: {
        //zIndex: theme.zIndex.drawer + 3,
        position: 'absolute',
        width: drawerWidth + 50 || '100%',
        maxWidth: drawerWidth + 50 || 'initial',
        border: "1px solid rgb(212, 212, 212)",
        right: theme.spacing(1),
        maxHeight: '99%',
        height: '99%'
    },
    headingDiv: {
        marginLeft: theme.spacing(0),
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1),
        '& .MuiTypography-root': {
            fontSize: '1.2rem',
            marginLeft: theme.spacing(4),
            marginBottom: theme.spacing(1),
        },

    },
    rootList: {
        top: theme.spacing(10),
        zIndex: theme.zIndex.drawer + 3,
    },
    subDescription: {
        margin: theme.spacing(2),
        fontSize: '0.9rem'
    }
}));

function SideBarItem(props) {

    var ports = {};
    props.item.ports.map(port => {// eslint-disable-line
        ports[port.id] = { id: port.id, type: port.type };
    });

    return (
        
        <SidebarItem type={props.type} icon={props.icon}
            actionType={props.item.actionType}
            name={props.item.name}
            properties={
                {
                    id: props.item.id,
                    actionType: props.item.actionType,
                    type: props.item.type,
                    name: props.item.name
                }} ports={ports} />
    )
}

function GetIcon(category, isSub) {
    switch (category) {
        case "Task":
            return isSub ? <TaskIconSub /> :<TaskIcon />;
        case "User":
            return isSub ? <UserIconSub/> : <UserIcon />;
        case "Action":
            return isSub ? <ActionIconSub /> : <ActionIcon />;
        case "Asset-type":
            return isSub ? <AssetTypeSub /> : <AssetTypeIcon />;
        case "Hold":
            return isSub ? <HoldIconSub /> :<HoldIcon />;
        default:
            return isSub ? <GeneralIconSub /> :<GeneralIcon />;
    }
}

function SideItem(props) {

    const classes = useStyles();
    const { expanded, setExpanded } = props;
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const icon = GetIcon(props.item.type);
    const iconSub = GetIcon(props.item.type, true);
    return (
        <div >
            <ExpansionPanel square expanded={expanded === props.item.actionType} onChange={handleChange(props.item.actionType)} className={classes.sideDrawerList}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    {icon}
                    <Typography>&nbsp;{`${props.item.actionType}`}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <List component="div" disablePadding >
                        {props.item.subItems.map(subItem =>
                            <SideBarItem icon={iconSub} key={subItem.name} item={subItem} type={props.item.type} />
                        )}
                    </List>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
}

export default function WorkflowPage() {

    const [t] = useTranslation();
    let history = useHistory();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { workflow } = state;
    const [expanded, setExpanded] = React.useState('');
    const [items, SetItems] = React.useState([]);
    const [currentWorkflow, setCurrentWorkflow] = React.useState(null);
    const [openDrawer, setopenDrawer] = React.useState(true);
    const [snackBarInfo, setSnackBarInfo] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const child = useRef(null);
    const isUserAdmin = isAdmin();

    useEffect(() => {
        dispatch({ "type": "setSnackBarInfo", value: snackBarInfo });
        dispatch({ "type": "setOpenSnackbar", value: openSnackbar });
    }, [snackBarInfo, openSnackbar, dispatch])

    useEffect(() => {

        async function getWorkflow() {

            if (!workflow)
                return;

            const apiURL = getWorkflowUrl(workflow.id);
            await getData(apiURL, GetToken())
                .then(async response => {
                    if (response.status === 200 || response.status === 201) {
                        const response_json = await response.json();
                        setCurrentWorkflow(response_json);
                    }
                    else {
                        setCurrentWorkflow(null);
                    }
                })
        }

        async function getWorkflowItems() {

            const apiURL = getWorkflowItemsUrl;
            await getData(apiURL, GetToken())
                .then(async response => {
                    if (response.status === 200 || response.status === 201) {
                        const response_json = await response.json();
                        SetItems(response_json);
                    }
                    else {
                        SetItems([]);
                    }
                })
        }

        getWorkflow();
        getWorkflowItems();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClearCanvas = () => {
        child.current.clearState();
    }

    const handleSaveCanvas = () => {
        child.current.saveState();
    }

    const toggleDrawer = (open) => (event) => {

        setopenDrawer(open);
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setopenDrawer(open);
    };

    function list() {
        return (
            <div role="presentation" className={classes.sideDrawerPanel}>
                {items.map(item => <SideItem expanded={expanded} setExpanded={setExpanded} key={item.name} item={item} />)}
            </div>
        )
    }

    return (
        <div className={classes.root} id="map">
            <div>
                <IconButton color="primary" variant="contained" size="medium"
                    className={clsx(classes.statusButton, { [classes.statusButtonOpen]: openDrawer })}
                    onClick={toggleDrawer(!openDrawer)}>
                    {openDrawer ? <CloseIcon fontSize="medium" /> : <ExpandMoreIcon fontSize="medium" />}
                </IconButton>

                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="right"
                    open={openDrawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        container: document.getElementById('map'),
                        disableEnforceFocus: true,
                        style: { position: 'absolute' }
                    }}
                    SlideProps={{ direction: 'down' }}
                >
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader disableSticky={false} component="div" id="nested-list-subheader" className={classes.headingDiv}>
                                <Typography>{currentWorkflow ? `'${currentWorkflow.description}' ${t('pageWorkflowsWorkflow')} (${isUserAdmin ? t('generalEdit') : t('generalReadonly')})` : ""}</Typography>
                                <Divider />
                            </ListSubheader>
                        }
                        className={classes.rootList}
                    >
                        <Typography className={classes.subDescription}>
                            {t('dialogWorkflowInstructions')}
                        </Typography>
                        {list()}
                        <div className={classes.buttonsDiv}>
                            <Fab variant="extended" color="default" aria-label="add" onClick={() => { history.goBack() }} className={classes.button}>
                                <BackIcon className={classes.extendedIcon} />
                        </Fab>
                            {!isUserAdmin ? <div /> :
                                    <Fab variant="extended" color="primary" aria-label="add" onClick={handleClearCanvas} className={classes.button}>
                                    <ClearAllIcon className={classes.extendedIcon} /> {t('generalClear')}
                        </Fab>}
                                    {!isUserAdmin ? <div /> :
                                        <Fab variant="extended" color="primary" aria-label="add" onClick={handleSaveCanvas} className={classes.button}>
                                    <SaveIcon className={classes.extendedIcon} /> {t('generalSave')}
                        </Fab>}
                        </div>
                    </List>
                </Drawer>
            </div>
            {workflow &&
                <Workflow ref={child}
                    workflowId={workflow.id}
                    history={history}
                    setSnackBarInfo={setSnackBarInfo}
                    setOpenSnackbar={setOpenSnackbar} />}
        </div>
    );
}