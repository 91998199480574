import { Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary, Button, Fab, Grid, List, TextField, Typography, } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ArrowBackIos as BackIcon, ExpandMore as ExpandMoreIcon, Search as SearchIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useQuery } from "@tanstack/react-query";
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { appStatuses, statusColors } from '../../../utils/AppConstants';
import { appConfigurations, appPages, dataFetchKeys, queryOptions, statusConfigs } from '../../../utils/configs';
import { tokens } from '../../../utils/keys';
import { getAsBuiltDecisionRoute, getAssignAssetTypeRoute, getCloseRequestRoute, getRequestActionsRoute, getRequestListRoute, getUploadAsBuiltRoute, getVerifyAsBuiltRoute, requestListType, userActionType } from '../../../utils/routeConfigs';
import BackDrop from '../../Common/BackDrop';
import { getData } from './../../../utils/ApiRequest';
import { getDelayRequestUrl, getRequestActionListUrl, getUsersForActionTransferURL } from './../../../utils/ApiUrl';
import { isAdmin, isAssetOwner, isManager } from './../../../utils/Roles';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext } from './../../AuthenticatedPage';
import RequestDetailsDialog from './../../pages/Requests/RequestDetailsDialog';
import RequestActionTransferDialog from './../RequestAction/RequestActionTransferDialog';
import RequestTrackCard from './RequestTrackCard';
import RequestTrackListItem from './RequestTrackListItem';
import clsx from 'clsx';
import UpdateIcon from '@material-ui/icons/Update';
import AssistantIcon from '@material-ui/icons/Assistant';
import DialogCreateDelayRequest from '../request-delay/DialogCreateDelayRequest';
import DialogRespondToDelayRequest from '../request-delay/DialogRespondToDelayRequest';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    marginLeft: theme.spacing(10),
    marginTop: theme.spacing(10),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(10),
  },
  rootDetails: {
    width: '50%',
    marginTop: theme.spacing(3),
  },
  actionClass: {
    marginBottom: theme.spacing(0.5),
    color: '#fff',
  },
  finished: {
    backgroundColor: statusColors.finished,
  },
  rejected: {
    backgroundColor: statusColors.rejected,
  },
  skipped: {
    backgroundColor: statusColors.skipped,
  },
  assigned: {
    backgroundColor: statusColors.assigned,
  },
  pending: {
    backgroundColor: statusColors.pending,
  },
  hold: {
    backgroundColor: theme.palette.warning.main,
  },
  sendReminder: {
    maxWidth: 200,
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      width: '40%',
      minWidth: '200px'
    },
    textAlign: 'center',
    width: '100%'
  },
  buttonsDiv: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(10),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  }
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function RequestTrackPage() {

  const [t] = useTranslation();
  let history = useHistory();
  const classes = useStyles();

  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const { authToken, requests, selectedRequest, currentUser } = state;
  const [requestActions, setRequestActions] = React.useState([]);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [openActTransfer, setOpenActTransfer] = React.useState(false);
  const [currentAction, setCurrentAction] = React.useState(null);
  const [openRequestDetailsDialog, setOpenRequestDetailsDialog] = React.useState(false);
  const isUserAdmin = isAdmin();
  const isUserManager = isManager();
  const isUserAssetOwner = isAssetOwner();
  const canShowForward = isUserAdmin || isUserManager;
  const canShowReminder = !isUserAssetOwner;
  const searchByTypes = [
    {
      id: 1,
      label: `${t('pageReqTrackRefNo')}`
    },
    {
      id: 2,
      label: `${t('pageReqTrackReqNo')}`
    },
  ];
  const [requestOptions, setRequestOptions] = useState([]);
  const [searchByTypeValue, setSearchByTypeValue] = useState(searchByTypes[0]);
  const [openCreateDelayRequestDialog, setOpenCreateDelayRequestDialog] = useState(false);
  const [openDelayRequestRespondDialog, setOpenDelayRequestRespondDialog] = useState(false);
  const [delayRequest, setDelayRequest] = useState(null);
  const userId = currentUser?.id || "";
  const userDetails = {
    id: localStorage.getItem(tokens.keyUserId) ? localStorage.getItem(tokens.keyUserId) : sessionStorage.getItem(tokens.keyUserId),
  }
  const currentUserId = userDetails.id;

  const isAmpApp = appConfigurations.isAmpApp;
  const restrictRequestDelay = isAmpApp && isAssetOwner; // Restrict request for delay by asset owner for AMP app

  useEffect(() => {
    setRequestOptions(requests.map(r => ({ id: r.id, description: searchByTypeValue.id === 1 ? r.reference_number : r.request_number })));
  }, [requests, searchByTypeValue])

  useEffect(() => {
    setCurrentRequest(selectedRequest ?
      {
        id: selectedRequest.id,
        description: searchByTypeValue.id === 1 ? selectedRequest.reference_number : selectedRequest.request_number
      } : null);
  }, [selectedRequest, searchByTypeValue])

  useEffect(() => {
    async function GetRequestActions() {
      setRequestActions([])
      if (!currentRequest) {
        return;
      }

      const apiURL = getRequestActionListUrl(currentRequest.id);
      const response = await getData(apiURL, GetToken());

      if (response.status === 200) {
        const response_json = await response.json();
        setRequestActions(response_json);
      }
    }
    GetRequestActions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRequest]);

  const handleSearchByInputChange = (value) => {
    setSearchByTypeValue(value);
    setCurrentRequest(null);
    setRequestActions([]);
    setRequestOptions(requests.map(r => ({ id: r.id, description: value.id === 1 ? r.reference_number : r.request_number })));
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenRequestDetailsClick = () => {
    setOpenRequestDetailsDialog(true);
  };

  const closeRequestDetailsDialog = () => {
    setOpenRequestDetailsDialog(false);
  };

  const {
    data: users,
    isLoading: usersLoading,
  } = useQuery([`${dataFetchKeys.usersForActionTransfer}${userId}`],
    async () => {
      const result = await getData(getUsersForActionTransferURL, authToken);
      return await result.json()
    }, queryOptions
  );

  useEffect(() => {
    dispatch({ "type": "setUsersForActionTransfer", value: users || [] });
  }, [users, dispatch])

  const loading = usersLoading;

  const handleInputChange = (value) => {
    if (!value) {
      setRequestActions([]);
      return setCurrentRequest(null);
    }
  };

  const handleInputSelect = (value) => {
    if (!value) {
      setRequestActions([]);
      return setCurrentRequest(null);
    }

    const request = requests.find(r => r.id === value.id);
    setCurrentRequest(value);
    dispatch({ type: "setSelectedRequest", value: request || null });
  };

  function handleActionClick(notificationAction) {
    const action = notificationAction.request_action;
    const notification = notificationAction.notifications[0];
    if (action.type === userActionType.assignAssetType) {
      history.push({
        pathname: getAssignAssetTypeRoute(notification.id),
      });
    }
    else if (action.type === userActionType.uploadAsBuilt) {
      history.push({
        pathname: getUploadAsBuiltRoute(notification.id),
      });
    }
    else if (action.type === userActionType.asBuiltDecision) {
      history.push({
        pathname: getAsBuiltDecisionRoute(notification.id),
      });
    }
    else if (action.type === userActionType.verifyAsBuilt) {
      history.push({
        pathname: getVerifyAsBuiltRoute(notification.id),
      });
    }
    else if (action.type === userActionType.projectClosure || action.type === userActionType.finalApproval) {
      history.push({
        pathname: getCloseRequestRoute(notification.id),
      });
    }
    else {
      history.push({
        pathname: getRequestActionsRoute(notification.id),
      });
    }
  };

  const handleGoBack = () => {
    dispatch({ "type": "setSelectedPage", value: appPages.requests });
    history.push({
      pathname: getRequestListRoute(requestListType.active),
    });
  }

  const handleRequestForDelay = () => {
    setOpenCreateDelayRequestDialog(true);
  }

  const handleRespondToDelayRequest = () => {
    if (selectedRequest) {
      getDelayRequest(selectedRequest);
      setOpenDelayRequestRespondDialog(true);
    }

  }
  const getDelayRequest = async (request) => {
    try {
      const response = await getData(getDelayRequestUrl(request.id), authToken);
      const status = response.status;
      if (status === 200 || status === 201) {
        const data = await response.json();
        setDelayRequest(data);
      }
      else if (status === 401) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
    }
    finally { }
  }

  return (
    loading ? <BackDrop loading={loading} /> :
      <Fragment>
        <div className={classes.root}>
          <Typography style={{ textAlign: "center" }} variant="h4"> {t("pageReqTrackTracking")}  </Typography>
          <Grid container direction='column' justify='flex-end' alignItems='center' >
            <Grid item xs={6} >
              <Autocomplete
                disableClearable
                value={searchByTypeValue}
                onChange={(event, value) => { handleSearchByInputChange(value) }}
                getOptionLabel={(option) => option.label}
                style={{ width: 300, marginTop: "16px" }}
                id="controllable-states-demo"
                options={searchByTypes}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
              />

            </Grid>
            <Grid item xs={6}>
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <SearchIcon style={{ position: 'absolute', right: 20, top: 20, width: 50, height: 50, color: '#c0c0c0' }} />
                <Autocomplete
                  display="inline"
                  value={currentRequest}
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  onInputChange={(event, value) => { handleInputChange(value) }}
                  onChange={(event, value) => { handleInputSelect(value) }}
                  style={{ width: 300 }}
                  options={requestOptions}
                  getOptionLabel={(r) => r.description}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={searchByTypeValue.id === 2
                        ? `${t("pageReqTrackReqNo")}`
                        : `${t("pageReqTrackRefNo")}`}
                      margin="normal"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
          <form className={classes.form}>
            <div>
              {selectedRequest && <Button variant="outlined" onClick={handleOpenRequestDetailsClick}>{t('generalReqDetails')}</Button>}
              {selectedRequest && <RequestDetailsDialog request={selectedRequest} open={openRequestDetailsDialog} handleClose={closeRequestDetailsDialog} />}
            </div>
          </form>

          {requestActions && requestActions.length > 0 &&
            <Grid container spacing={0} direction="column" alignItems="center" justify="center">
              <div className={classes.rootDetails}>
                {requestActions.map((requestAction) => (
                  <Accordion key={requestAction.order_number}
                    className={clsx(classes.actionClass, {
                      [classes.assigned]: [appStatuses.assigned, appStatuses.active].includes(requestAction.status),
                      [classes.finished]: [appStatuses.finished, appStatuses.approved].includes(requestAction.status),
                      [classes.skipped]: [appStatuses.skipped, appStatuses.notRequired].includes(requestAction.status),
                      [classes.rejected]: [appStatuses.rejected, appStatuses.expired].includes(requestAction.status),
                      [classes.hold]: requestAction.status === appStatuses.hold,
                      [classes.pending]: requestAction.status === appStatuses.pending,
                    })}
                    expanded={expanded === requestAction.order_number} onChange={handleChange(requestAction.order_number)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel4bh-content"
                      id={requestAction.order_number}
                    >
                      <List style={{ padding: 0, margin: 0 }}>
                        <RequestTrackListItem requestAction={requestAction} avatar={requestAction.status}
                          primaryText={requestAction.node_name} secondaryText={requestAction.status} />
                      </List>

                    </AccordionSummary>
                    <AccordionDetails>
                      {requestAction.data && requestAction.data.length > 0 ?
                        <Grid container spacing={0} direction="row" alignItems="center" justify="center">
                          {requestAction.data.map((assetTypeAction, index) => (
                            <RequestTrackCard key={index} currentUserId={currentUserId} handleActionClick={handleActionClick} canShowForward={canShowForward} canShowReminder={canShowReminder} assetType={requestAction.node_name} assetTypeAction={assetTypeAction} setOpenActTransfer={setOpenActTransfer} setCurrentAction={setCurrentAction} showHeader={false} />
                          ))}
                        </Grid> :
                        <Grid container spacing={0} direction="row" alignItems="center" justify="center">
                          <RequestTrackCard currentUserId={currentUserId} handleActionClick={handleActionClick} canShowForward={canShowForward} canShowReminder={canShowReminder} assetTypeAction={requestAction} setOpenActTransfer={setOpenActTransfer} setCurrentAction={setCurrentAction} showHeader={false} />
                        </Grid>
                      }
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </Grid>}

          {currentAction && <RequestActionTransferDialog
            action={currentAction}
            open={openActTransfer}
            setOpenActTransfer={setOpenActTransfer} />}

          <div className={classes.buttonsDiv}>
            {((isAdmin || selectedRequest?.createdBy?.id === currentUser.id) &&
              [statusConfigs.Active, statusConfigs.Approved, statusConfigs.Assigned, statusConfigs.Hold, statusConfigs.Pending].includes(selectedRequest?.status?.description_static)) &&
              <Fragment>
                {!selectedRequest.hasDelayRequest && !restrictRequestDelay &&
                  <Fab variant="extended" color="primary" onClick={handleRequestForDelay}>
                    <UpdateIcon className={classes.extendedIcon} />
                    {t('delay.request.title')}
                  </Fab>}

                {isAdmin && selectedRequest.hasDelayRequest &&
                  <Fab variant="extended" color="primary" onClick={handleRespondToDelayRequest}>
                    <AssistantIcon className={classes.extendedIcon} />
                    {t('delay.response.title')}
                  </Fab>}

              </Fragment>}

            <Fab variant="extended" color="default" aria-label="add" onClick={handleGoBack} className={classes.button}>
              <BackIcon className={classes.extendedIcon} />
              {t('generalBack')}
            </Fab>
          </div>
        </div>

        {!restrictRequestDelay &&
          <DialogCreateDelayRequest
            open={openCreateDelayRequestDialog}
            setOpen={setOpenCreateDelayRequestDialog}
            request={selectedRequest} />}

        {delayRequest &&
          <DialogRespondToDelayRequest
            open={openDelayRequestRespondDialog}
            setOpen={setOpenDelayRequestRespondDialog}
            request={selectedRequest}
            delayRequest={delayRequest}
            setDelayRequest={setDelayRequest}
          />}
      </Fragment>
  );
}