import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Slide, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Pageview';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MaterialTable from 'material-table';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getData, postData, putData } from './../../../utils/ApiRequest';
import { createActionTaskItemURL, editActionTaskItemURL, enableOrDisableActionTaskItemURL, getActionForTaskURL, getActionListForTaskURL, getActiveAssetTypeURL, getActiveRequestTypeURL } from './../../../utils/ApiUrl';
import { isAdmin } from './../../../utils/Roles';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext } from './../../AuthenticatedPage';
import tableIcons from './../../tableIcons';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginLeft: theme.spacing(10),
        marginTop: theme.spacing(10),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(10),
    },
    container: {
        maxHeight: 440,
    },
    formControl: {
        fullWidth: true,
        display: 'flex'
    },
    dropdownControl: {
        width: '20%', // Fix IE 11 issue.
        minWidth: '150px',
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    goButtonControl: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    optionroot: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
        },
        '& .MuiAutocomplete-root': {
            marginRight: theme.spacing(1),
            width: '300px'
        },
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ActionTaskPage(props) {

    const [t] = useTranslation();
    const classes = useStyles();
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { notificationRefreshKey } = state;

    const [open, setOpen] = React.useState(false);
    const [alertOpen, setAlertOpen] = React.useState(false);

    const [assetTypes, setAssetTypes] = React.useState([]);
    const [actions, setActions] = React.useState([]);
    const [requestTypes, setRequestTypes] = React.useState([]);
    const [actionTasks, setActionTasks] = React.useState([]);
    const [isBusy, setIsBusy] = useState(false);
    const [actionTaskId, setActionTaskId] = useState("");
    const [assetTypeId, setAssetTypeId] = useState("");
    const [requestTypeId, setRequestTypeId] = useState("");
    const [actionId, setActionId] = useState("");

    const [description, setDescription] = useState("");
    const [remarks, setRemarks] = useState("");
    const [formTitle, setFormTitle] = useState("");
    const [formMessage, setFormMessage] = useState("");

    const isUserAdmin = isAdmin();

    useEffect(() => {

        async function GetAssetTypes() {
            const apiURL = getActiveAssetTypeURL();
            const response = await getData(apiURL, GetToken());
            if (response.status === 200 || response.status === 201) {
                const response_json = await response.json();
                setAssetTypes(response_json);
            }
            else {
                setAssetTypes([]);
            }
        }

        async function GetActions() {
            const apiURL = getActionForTaskURL();
            const response = await getData(apiURL, GetToken());
            if (response.status === 200 || response.status === 201) {
                const response_json = await response.json();
                setActions(response_json);
            }
            else {
                setActions([]);
            }
        }

        async function GetRequestTypes() {
            const apiURL = getActiveRequestTypeURL();
            const response = await getData(apiURL, GetToken());
            if (response.status === 200 || response.status === 201) {
                const response_json = await response.json();
                setRequestTypes(response_json);
            }
            else {
                setRequestTypes([]);
            }
        }

        GetAssetTypes();
        GetActions();
        GetRequestTypes();

    }, []);

    const handleAlertDialogClose = () => {
        setAlertOpen(false);
    };

    const handleNew = () => {
        setFormTitle(t('pageActionTaskNewActionTask'));
        setFormMessage(t('pageActionTaskNewActionTaskInstruction'));
        setDescription("");
        setRemarks("");
        setActionTaskId("");
        setOpen(true);
    };

    async function handleNewSubmit(event) {
        event.preventDefault();
        try{
            setIsBusy(true)
            const data =
            {
                "actionId": actionId,
                "assetTypeId": assetTypeId,
                "requestTypeId": requestTypeId,
                "description": description,
                "remarks": remarks
            }
    
           const response = await postData(createActionTaskItemURL(), data, GetToken())
              
                    const status = response.status;
                    if (status === 200 || status === 201) {
                        const newObj = await response.json();
                        let newArray = [...actionTasks];
                        newArray.push(newObj);
                        setActionTasks(newArray);
    
                        dispatch({ "type": "setNotificationRefreshKey", value: (notificationRefreshKey + 1) })
                        dispatch({ "type": "setSnackBarInfo", value: { message: `${t('pageActionTaskActionTask')} '${description}' ${t('generalCreated')}`, isError: false } })
                        dispatch({ "type": "setOpenSnackbar", value: true })
                    }
                    else if (status === 401) {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
                        dispatch({ "type": "setOpenSnackbar", value: true })
                    }
                    else {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } })
                        dispatch({ "type": "setOpenSnackbar", value: true })
                    }
        }
        finally{
        setIsBusy(false)
        setOpen(false);
        }

    };

    async function handleEdit(event, rowData) {
        setDescription(rowData.description);
        setRemarks(rowData.remarks ? rowData.remarks : "");
        setActionTaskId(rowData.id);
        setFormTitle(t('pageActionTaskEditActionTask'));
        setFormMessage(t('pageActionTaskEditActionTaskInstruction'));
        setOpen(true);
    };

    async function handleEditSubmit(event) {
        event.preventDefault();
        try{
        setIsBusy(true)
            const data = { "description": description, "remarks": remarks };
            const response = await putData(editActionTaskItemURL(actionTaskId), data, GetToken())
                    const status = response.status;
                    if (status === 200 || status === 201) {
                        const newObj = await response.json();
                        const objIndex = actionTasks.findIndex(e => e.id === actionTaskId);
                        let newArray = [...actionTasks];
                        newArray[objIndex] = { ...newArray[objIndex], description: newObj.description, remarks: newObj.remarks }
                        setActionTasks(newArray);
    
                        dispatch({ "type": "setNotificationRefreshKey", value: (notificationRefreshKey + 1) })
                        dispatch({ "type": "setSnackBarInfo", value: { message: `${t('pageActionTaskActionTask')} '${description}' ${t('generalUpdated')}`, isError: false } })
                        dispatch({ "type": "setOpenSnackbar", value: true })
                    }
                    else if (status === 401) {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
                        dispatch({ "type": "setOpenSnackbar", value: true })
                    }
                    else {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } })
                        dispatch({ "type": "setOpenSnackbar", value: true })
                    }    
        }
        finally{
        setIsBusy(false)
        setOpen(false);
        }
    };

    const handleEnableOrDisable = async (id, enable) => {
        try{
        setIsBusy(true)
            const response = await putData(enableOrDisableActionTaskItemURL(id, enable), null, GetToken());
            const status = response.status;
            if (status === 200 || status === 201) {
                if (actionTasks && actionTasks.length > 0) {
                    const newObj = await response.json();
                    const objIndex = actionTasks.findIndex(e => e.id === id);
                    let newArray = [...actionTasks];
                    newArray[objIndex] = { ...newArray[objIndex], status: newObj.status }
                    setActionTasks(newArray);
                    dispatch({ "type": "setNotificationRefreshKey", value: (notificationRefreshKey + 1) });
                }
            }
        }
        finally{
        setIsBusy(false)
        setOpen(false);
        }     
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function GetActionList() {
        setActionTasks([]);
        if (!requestTypeId || !assetTypeId || !actionId) {
            return;
        }

        const response = await getData(getActionListForTaskURL(requestTypeId, assetTypeId, actionId), GetToken());
        const status = response.status;
        if (status === 200 || status === 201) {
            setActionTasks(await response.json());
        }
        else {
            setActionTasks([]);
        }
    }

    async function handleGoButtonClick(event) {
        await GetActionList();
    };

    function validateForm() {
        return (description.length > 3 && description.length < 255 && remarks.length < 255);
    }

    function validateGo() {
        return (requestTypeId && assetTypeId && actionId);
    }

    return (
        <Fragment>
            <Grid className={classes.root}>
                <Typography variant="h4" className={classes.dropdownControl}>{t('pageActionTaskActionTasks')}</Typography>

                <div className={classes.optionroot}>
                    <Autocomplete
                        margin="none"
                        id="select-request-type"
                        options={requestTypes}
                        
                        getOptionLabel={(option) => option.description}
                        onChange={(event, value) => { setRequestTypeId(value ? value.id : "") }}
                        renderInput={(params) => <TextField {...params} required placeholder={t('pageActionTaskSelectRequestType')} label={t('dialogRequestTypeRequestType')} variant="outlined" />}
                    />
                    <Autocomplete
                        margin="none"
                        id="select-asset-type"
                        options={assetTypes}
                        getOptionLabel={(option) => option.description}
                        onChange={(event, value) => { setAssetTypeId(value ? value.id : "") }}
                        renderInput={(params) => <TextField {...params} required placeholder={t('pageActionTaskSelectAssetType')} label={t('generalAssetType')} variant="outlined" />}
                    />
                    <Autocomplete
                        margin="none"
                        id="select-Action"
                        options={actions}
                        getOptionLabel={(option) => option.description}
                        onChange={(event, value) => { setActionId(value ? value.id : "") }}
                        renderInput={(params) => <TextField {...params} required placeholder={t('pageActionTaskSelectActionTask')} label={t('pageActionTaskActionTask')} variant="outlined" />}
                    />
                    <IconButton className={classes.goButtonControl} aria-label="delete" onClick={handleGoButtonClick} disabled={!validateGo()}>
                        <SearchIcon fontSize="large" />
                    </IconButton>
                </div>
                <br />

                <MaterialTable
                    title={t('pageActionTaskActionTasks')}
                    columns={[
                        { title: t('generalDescription'), field: 'description' },
                        { title: t('generalRemarks'), field: 'remarks' },
                        { title: t('generalCreatedBy'), field: 'createdBy' },
                        { title: t('generalModifiedBy'), field: 'modifiedBy' },
                    ]}
                    data={actionTasks}
                    icons={tableIcons}
                    localization={{
                        header: {
                            actions: ''
                        }
                    }}
                    actions={isUserAdmin ? [
                        {
                            icon: tableIcons.Add,
                            tooltip: t('pageActionTaskNewActionTask'),
                            position: "toolbar",
                            onClick: () => handleNew(),
                            disabled: !validateGo()
                        },
                        {
                            icon: tableIcons.Edit,
                            tooltip: t('Edit'),
                            onClick: (event, rowData) => handleEdit(event, rowData),
                        },
                        rowData => ({
                            icon: rowData.status && rowData.status.description_static === 'Active' ? tableIcons.Enable : tableIcons.Disable,
                            tooltip: rowData.status && rowData.status.description_static === 'Active' ? t('generalDeactivate') : t('generalActivate'),
                            onClick: (event, rowData) => handleEnableOrDisable(rowData.id, rowData.status && rowData.status.description_static === 'Active' ? false : true),
                        }),
                    ] : []}
                    options={{
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF'
                        },
                        rowStyle: rowData => ({
                            backgroundColor: (rowData.status && rowData.status.description_static === 'Inactive') ? '#ffd6cc' : '#FFF'
                        }),
                        exportButton: true,

                    }}
                />

                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{formTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{formMessage}</DialogContentText>

                        <TextField
                            margin="none"
                            required
                            fullWidth
                            id="description"
                            disabled={isBusy}
                            label={t('generalDescription')}
                            name="description"
                            autoComplete="off"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        />

                        <TextField
                            margin="none"
                            fullWidth
                            id="remarks"
                            label={t('generalRemarks')}
                            name="remarks"
                            disabled={isBusy}
                            autoComplete="off"
                            value={remarks}
                            onChange={e => setRemarks(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose} color="primary">
                            {t('generalCancel')}
                        </Button>
                        <Button variant="contained" onClick={actionTaskId ? handleEditSubmit : handleNewSubmit} color="primary" disabled={!validateForm() || isBusy} type="submit">
                        {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />  }
                            {t('generalSave')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    keepMounted
                    open={alertOpen}
                    onClose={handleAlertDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    TransitionComponent={Transition}
                >
                    <DialogTitle id="alert-dialog-title">{false}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {false}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAlertDialogClose} color="default" autoFocus>
                            {t('generalClose')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Fragment>
    );
}