import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { appStatuses, userActions } from '../../../utils/AppConstants';
import { appRoute } from '../../../utils/routeConfigs';
import { isAdmin } from './../../../utils/Roles';
import { AppContext } from './../../AuthenticatedPage';
import tableIcons from './../../tableIcons';
import WorkflowDialog from './WorkflowDialog';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginLeft: theme.spacing(10),
        marginTop: theme.spacing(10),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(10),
        '& .MuiTableContainer-root': {
            marginTop: theme.spacing(1),
        }
    },
    rootHead: {
        marginBottom: theme.spacing(2),
    },
    container: {
        maxHeight: 440,
    },
}));


export default function WorkflowPage() {

    const [t] = useTranslation();
    let history = useHistory();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { workflows } = state;

    const [selectedRow, setSelectedRow] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const isUserAdmin = isAdmin();
    const [workFlowAction, setWorkFlowAction] = React.useState('');

    const handleNew = () => {
        dispatch({ "type": "setWorkflow", value: null });
        setOpen(true);
    };

    async function handleEdit(event, rowData) {
        dispatch({ "type": "setWorkflow", value: rowData });
        setOpen(true);
    };

    async function handleAction(event, rowData, userActions) {
        dispatch({ "type": "setWorkflow", value: rowData });
        setWorkFlowAction(userActions);
        setOpen(true);
    };

    async function handleWorkflowEdit(event, rowData) {
        dispatch({ "type": "setWorkflow", value: rowData });
        dispatch({ "type": "setSelectedPage", value: "workflow" });
        history.push(appRoute.workflow);
    };

    return (
        <Fragment>
            <Paper className={classes.root}>
                <MaterialTable
                    title={t('pageWorkflowsWorkflows')}

                    columns={[
                        { title: t('generalDescription'), field: 'description' },
                        { title: t('generalRemarks'), field: 'remarks' },
                    ]}
                    data={workflows}
                    icons={tableIcons}
                    localization={{
                        header: {
                            actions: ''
                        }
                    }}
                    actions={isUserAdmin ? [
                        {
                            icon: tableIcons.Add,
                            tooltip: t('pageWorkflowsNewWorkflow'),
                            position: "toolbar",
                            onClick: () => handleNew(),
                        },
                        {
                            icon: tableIcons.Edit,
                            tooltip: t('pageWorkflowsEditWorkflow'),
                            onClick: (event, rowData) => handleEdit(event, rowData),

                        },
                        {
                            icon: tableIcons.Workflow,
                            tooltip: t('pageWorkflowsWorkflowContents'),
                            onClick: (event, rowData) => handleWorkflowEdit(event, rowData),

                        },
                        rowData => ({
                            icon: (rowData.status.description_static === appStatuses.active ? tableIcons.Delete : tableIcons.Activate),
                            tooltip: (rowData.status.description_static === appStatuses.active ? t('generalDeactivate') : t('generalActivate')),
                            onClick: (event, rowData) => handleAction(event, rowData, rowData.status.description_static === appStatuses.active ? userActions.deactivate : userActions.activate),
                        }),
                    ] : [
                            {
                                icon: tableIcons.Workflow,
                                tooltip: t('pageWorkflowsWorkflowContents'),
                                onClick: (event, rowData) => handleWorkflowEdit(event, rowData),

                            }
                        ]}
                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                    options={{
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF'
                        },
                        rowStyle: rowData => ({
                            backgroundColor: (rowData.status.description_static === appStatuses.inactive) ? '#F9F5F0' :
                                    (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                        }),
                        exportButton: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 50, 100],
                        actionsColumnIndex: 100,
                    }}
                />
            </Paper>
            <WorkflowDialog
                open={open}
                setOpen={setOpen}
                action={workFlowAction} />
        </Fragment>
    );
}