import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import RequestResponse from './RequestResponse';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginLeft: theme.spacing(10),
        marginTop: theme.spacing(10),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(5),
    },

}));

const RequestResponsePage = () => {
    const classes = useStyles();
    const { requestId } = useParams();

    return (
        <Fragment>
            <Paper className={classes.root}>
                <div>
                    <RequestResponse selectedRequestId={requestId} />
                </div>
            </Paper>
        </Fragment>

    )
}

export default RequestResponsePage;