import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.modal + 10,
        color: '#fff',
    },
    backdropDiv: {
        '& .MuiTypography-root': {
            margin: theme.spacing(0.75),
            fontSize: '1.3rem',
            fontStyle: 'italic'
        },
        textAlign: 'center',
        width: '100%',
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function BackDrop(props) {

    const [t] = useTranslation();
    const classes = useStyles();
    const { loading } = props;

    return (
        <Backdrop className={classes.backdrop} open={loading}>
            <div className={classes.backdropDiv}>
                <Typography>{t('waitDataLoading')}</Typography>
                <CircularProgress className={classes.extendedIcon} color="inherit" />
            </div>
        </Backdrop>
    );
}