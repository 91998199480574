import { AppBar, CircularProgress, Dialog, DialogContent, Fab, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIos as BackIcon, Save as SaveIcon } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dataFetchKeys } from '../../../utils/configs';
import { postData } from './../../../utils/ApiRequest';
import { requestActionTransferUrl } from './../../../utils/ApiUrl';
import { languages } from './../../../utils/AppConstants';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext, queryClient } from './../../AuthenticatedPage';

const dateFormat = require('dateformat');
const dateStringFormat = "dd-mm-yyyy";
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(10),
    },
    container: {
        maxHeight: 440,
    },
    formControl: {
        fullWidth: true,
        display: 'flex'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1.5),
            width: '40%',
            minWidth: '200px'
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: theme.spacing(1.5),
            width: '40%',
            minWidth: '200px'
        },
        textAlign: 'center',
        width: '100%'
    },
    headerText: {
        fontSize: '1.5rem',
    },
    documentList: {
        width: '40%',
        minWidth: '200px',
        backgroundColor: theme.palette.background.paper,
        marginBottom: 50
    },
    buttonsDiv: {
        position: 'fixed',
        right: '24px',
        bottom: '24px',
        left: 'auto',
        top: 'auto'
    },
    button: {
        marginLeft: theme.spacing(1),
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function RequestActionTransferDetails(props) {

    const [t] = useTranslation();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { usersForActionTransfer, selectedLanguage } = state;

    const { open, setOpenActTransfer, action } = props;
    const [userOptions, setUserOptions] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState([]);
    const [isBusy, setIsBusy] = React.useState(false);

    useEffect(() => {

        const userArray = [...usersForActionTransfer];
        const index = userArray.findIndex((u) => u.id === action.user_id);
        if (index >= 0) {
            userArray.splice(index, 1);
        }

        setUserOptions(userArray);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action])

    const handleClose = () => {
        setOpenActTransfer(false);
    }

    const handleSelectUser = (event, value) => {
        setSelectedUser(value? value: []);
    };

    function validateForm() {
        return selectedUser && (selectedUser.length > 0 || (selectedUser.id && selectedUser.id.length > 0));
    }

    async function handleSubmit(event) {
        event.preventDefault();
		try{
            setIsBusy(true);
            const lang = selectedLanguage === languages.nl ? 'nl' : 'en';
            const response = await postData(requestActionTransferUrl(lang, action.request_action_id, selectedUser.id), null, GetToken())
                     const status = response.status;
                     if (status === 200 || status === 201) {
                         action.user_id = selectedUser.id;
                         action.user_name = `${selectedUser.firstName} ${selectedUser.lastName}`;
     
                         //Update notifications
                         queryClient.invalidateQueries(dataFetchKeys.notifications);
                         dispatch({ "type": "setSnackBarInfo", value: { message: t('dialofTransferTransferred'), isError: false } });
                         dispatch({ "type": "setOpenSnackbar", value: true });
                     }
                     else if (status === 401) {
                         dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
                         dispatch({ "type": "setOpenSnackbar", value: true });
                     }
                     else {
                         dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
                         dispatch({ "type": "setOpenSnackbar", value: true });
                     }
        }
        finally{
        setIsBusy(false);
        setOpenActTransfer(false); 
        }

    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            style={{ backgroundColor: '#fff' }}
            fullScreen>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" className={classes.title}>
                        {t('dialofTransferTransferAction')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <form className={classes.form} autoComplete="off" style={{ backgroundColor: '#fff' }}>
                    <div>
                        {action && action.assetType? 
                            <TextField
                                variant="outlined"
                                margin="none"
                                fullWidth
                                disabled
                                label={t('generalAssetType')}
                                value={action ? action.assetType : ""}
                            />: <div/>
                        }
                        {action && action.assetType ? <br /> : <div />}
                        <TextField
                            variant="outlined"
                            margin="none"
                            fullWidth
                            disabled
                            label={t('dialofTransferReqAction')}
                            value={action ? action.node_name : ""}
                        />
                        <br />
                        <TextField
                            variant="outlined"
                            margin="none"
                            fullWidth
                            disabled
                            label={t('dialofTransferAssignedTo')}
                            value={action ? action.user_name : ""}
                        />
                        <br />
                        <TextField
                            variant="outlined"
                            margin="none"
                            fullWidth
                            disabled
                            label={t('dialofTransferAssignmentDate')}
                            value={action ? dateFormat(new Date(action.assignment_date), dateStringFormat) : ""}
                        />
                        <br />
                        <Autocomplete
                            margin="none"
                            fullWidth
                            required
                            disabled={isBusy}
                            id="select-request-type"
                            options={userOptions}
                            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                            onChange={(event, value) => { handleSelectUser(event, value) }}
                            renderInput={(params) => <TextField {...params}
                                placeholder={t('dialofTransferSelectuser')}
                                label={t('user')}
                                variant="outlined" />}
                        />
                    </div>
                </form>
                <div className={classes.buttonsDiv}>
                    <Fab variant="extended" color="default" aria-label="add" onClick={handleClose} className={classes.button}>
                        <BackIcon className={classes.extendedIcon} /> 
                        {t('generalCancel')}
                    </Fab>
                    <Fab variant="extended" color="primary" aria-label="add" onClick={handleSubmit} disabled={isBusy || !validateForm()} className={classes.button}>
                    {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />  }
                        <SaveIcon className={classes.extendedIcon} /> 
                        {t('generalSave')}
                    </Fab>
                </div>
            </DialogContent>
        </Dialog>
    );
}