import { Divider, Grid, List, ListSubheader, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { default as React, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getData } from './../../../utils/ApiRequest';
import { getFormatScopes } from './../../../utils/ApiUrl';
import { GetToken } from './../../../utils/tokenActions';
import DialogDetailUpdate from './DialogDetailUpdate';
import ScopeTableRow from './ScopeTableRow';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
    },
    headingDiv: {
        marginBottom: theme.spacing(2),
        top: theme.spacing(8),
        backgroundColor: '#fff'
    },
    rootList: {
        width: '100%',
    },
    rootDiv: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(0.5),
    },
    heading: {
        fontSize: '1.8rem',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1)
    },
    subHeading: {
        fontSize: '1.0rem',
    }
}));

export default function FormatPage() {

    const [t] = useTranslation();
    const classes = useStyles();
    const [scopes, setScopes] = useState([]);
    const [openDetailDialog, setOpenDetailDialog] = useState(false);
    const [currentDetail, setCurrentDetail] = useState([]);
    const [currentScopeName, setCurrentScopeName] = useState([]);

    useEffect(() => {

        async function getAllScopes() {

            await getData(getFormatScopes(), GetToken())
                .then(async response => {
                    const status = response.status;
                    if (status === 200) {
                        const responseJson = await response.json();
                        setScopes(responseJson);
                    }
                    else {
                        setScopes([]);
                    }
                })
        }

        getAllScopes();

    }, [])

    return (
        <Fragment>
            <Grid container className={classes.root}>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader disableSticky component="div" id="nested-list-subheader" className={classes.headingDiv}>
                            <Typography className={classes.heading}>
                                {t('pageFormatsFormates')}
                            </Typography>
                            <Divider />
                            <Typography className={classes.subHeading}>
                                {t('pageFormatsFormatInstruction')}
                            </Typography>
                            <Typography className={classes.subHeading}>
                                {t('pageFormatsFormatInstruction1')}
                            </Typography>
                        </ListSubheader>
                    }
                    className={classes.rootList}
                >
                    {!scopes || scopes.length <= 0 ? <div /> :
                        scopes.map((scope) => (
                            <div key={scope.id} className={classes.rootDiv}>
                                <ScopeTableRow row={scope}
                                    setCurrentScopeName={setCurrentScopeName}
                                    setCurrentDetail={setCurrentDetail}
                                    setOpenDetailDialog={setOpenDetailDialog} />
                            </div>
                        ))}
                </List>
            </Grid>

            <DialogDetailUpdate
                open={openDetailDialog}
                setOpen={setOpenDetailDialog}
                scopes={scopes}
                setScopes={setScopes}
                currentScopeName={currentScopeName}
                setCurrentDetail={setCurrentDetail}
                currentDetail={currentDetail} />
        </Fragment>
    );
}