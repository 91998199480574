import React, { forwardRef } from 'react';
import Icon from "@material-ui/core/Icon";
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SaveIcon from '@material-ui/icons/Save';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SvgWorkflow from './../img/icons-svg/icon-flowchart.svg';
import ToggleOffIcon from '@material-ui/icons/ToggleOffOutlined';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import DefaultAdminIcon from '@material-ui/icons/VerifiedUser';
import SetDefaultAdminIcon from '@material-ui/icons/VerifiedUserOutlined';
import DefaultAsbuiltVerifierIcon from '@material-ui/icons/AssignmentTurnedIn';
import AsbuiltVerifierIcon from '@material-ui/icons/AssignmentTurnedInOutlined';

const tableIcons = {
    Activate: forwardRef((props, ref) => <CheckCircleIcon {...props} ref={ref} />),
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Asset: forwardRef((props, ref) => <ScatterPlotIcon {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Defaultadmin: forwardRef((props, ref) => <DefaultAdminIcon {...props} ref={ref} />),
    Defaultadminset: forwardRef((props, ref) => <SetDefaultAdminIcon {...props} ref={ref} />),
    Defaultasbuilt: forwardRef((props, ref) => <DefaultAsbuiltVerifierIcon {...props} ref={ref} />),
    Defaultasbuiltset: forwardRef((props, ref) => <AsbuiltVerifierIcon {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Disable: forwardRef((props, ref) => <ToggleOnIcon {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Enable: forwardRef((props, ref) => <ToggleOffIcon {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    None: forwardRef((props, ref) => <div {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Save: forwardRef((props, ref) => <SaveIcon {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    User: forwardRef((props, ref) => <AccountCircleIcon {...props} ref={ref} />),
    Workflow: forwardRef((props, ref) => <Icon><img alt="svg" src={SvgWorkflow} width="24px" height="24px" /></Icon>)
};

export default tableIcons;