import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import AllListIcon from '@material-ui/icons/FlipToFront';
import CompletedListIcon from '@material-ui/icons/LibraryAddCheck';
import ActiveListIcon from '@material-ui/icons/LibraryBooks';
import PanToolIcon from '@material-ui/icons/PanTool';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { getData, postData, postFormData } from '../../../utils/ApiRequest';
import { exportRequestUrl, getAllRequestTypesHavingWorkflowURL, getDelayRequestUrl, requestAttachementUploadUrl } from '../../../utils/ApiUrl';
import { attachmentType, roleConfigs } from '../../../utils/AppConstants';
import { appConfigurations, statusConfigs } from '../../../utils/configs';
import { tokens } from '../../../utils/keys';
import { requestListType } from '../../../utils/routeConfigs';
import { AppContext } from '../../AuthenticatedPage';
import BackDrop from '../../Common/BackDrop';
import DropzoneAttachmentDialog from '../../Common/DropzoneAttachmentDialog';
import DialogCreateDelayRequest from '../request-delay/DialogCreateDelayRequest';
import DialogRespondToDelayRequest from '../request-delay/DialogRespondToDelayRequest';
import RequestResponseDialog from '../RequestResponse/RequestResponseDialog';
import DialogChangeRequestType from './DialogChangeRequestType';
import DialogConvertRequest from './DialogConvertRequest';
import DialogNotifyRequestUpdate from './DialogNotifyRequestUpdate';
import DialogRejectRequest from './DialogRejectRequest';
import DialogSetRequestAsExpired from './DialogSetRequestAsExpired';
import MenuRequest from './MenuRequest';
import RequestDetailsDialog from './RequestDetailsDialog';
import { EnhancedTableHead, EnhancedTableToolbar, getComparator, stableSort, StyledTableCell, StyledTableRow } from './RequestListHeader';
import RequestTableRow from './RequestTableRow';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { getPreviousDay } from '../../../utils/DateFormat';

const dateFormat = require('dateformat');
const dateStringFormat = "dd-mm-yyyy";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(10),
    width: '95%',
    marginBottom: theme.spacing(10),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function RequestListPage() {

  const [t] = useTranslation();
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const { requests, selectedLanguage, userRoles, authToken, currentUserRole } = state;

  const history = useHistory();
  const { viewType } = useParams();
  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_on');
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState(null);
  const [showUrgentOnly, setShowUrgentOnly] = React.useState(false);
  const [openRequestDetailsDialog, setOpenRequestDetailsDialog] = React.useState(false);
  const [openResponseDialog, setOpenResponseDialog] = React.useState(false);
  const [openNotifyDialog, setOpenNotifyDialog] = React.useState(false);
  const [openChangeRequestTypeDialog, setOpenChangeRequestTypeDialog] = React.useState(false);
  const [openConvertTypeDialog, setOpenConvertTypeDialog] = React.useState(false);
  const [openAttachementDialog, setOpenAttachementDialog] = React.useState(false);
  const [openRejectDialog, setOpenRejectDialog] = React.useState(false);
  const [openSetExpireDialog, setOpenSetExpireDialog] = React.useState(false);
  const [openCreateDelayRequestDialog, setOpenCreateDelayRequestDialog] = React.useState(false);
  const [openDelayRequestRespondDialog, setOpenDelayRequestRespondDialog] = React.useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [delayRequest, setDelayRequest] = useState(null);
  const [requestTypes, setRequestTypes] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [windowScreenHeight, setwindowScreenHeight] = useState(window.innerHeight)
  const screenHeigth = windowScreenHeight - 301;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredRequests.length - page * rowsPerPage);
  const isAdmin = userRoles.some(r => [roleConfigs.admin].includes(r.role));

  const isAmpApp = appConfigurations.isAmpApp;
  const isAssetOwner = currentUserRole?.role?.role_static === roleConfigs.assetOwner;
  const restrictRequestDelay = isAmpApp && isAssetOwner; // Restrict request for delay by asset owner for AMP app

  const detectScreenHeigth = () => {
    setwindowScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", detectScreenHeigth);
    return () => {
      window.removeEventListener("resize", detectScreenHeigth);
    };
  }, [windowScreenHeight]);

  useEffect(() => {
    const previousDate = getPreviousDay(new Date());
    function getFilteredRequests() {

      const getStatusToFilter = () => {
        switch (viewType) {
          case requestListType.active:
            setIcon(<ActiveListIcon />);
            setTitle(t('pageReqActiveReq'));
            return [statusConfigs.Active, statusConfigs.Pending];

          case requestListType.complete:
            setIcon(<CompletedListIcon />);
            setTitle(t('pageReqClosedReq'));
            return [statusConfigs.Finished, statusConfigs.Closed];

          case requestListType.hold:
            setIcon(<PanToolIcon />);
            setTitle(t('pageReqHoldReq'));
            return [statusConfigs.Hold];

          case requestListType.approved:
            setIcon(<ThumbUpAltIcon />);
            setTitle(t('pageReqApprovedReq'));
            return [statusConfigs.Approved];

          case requestListType.active_expired:
            setIcon(<RemoveCircleOutlineIcon />);
            setTitle(t('pageReqActiveExpiredReq'));
            return [statusConfigs.ActiveExpired];

          case requestListType.active_expired_AsBuilt:
            setIcon(<NotInterestedIcon />);
            setTitle(t('pageReqActiveExpiredAsBuiltReq'));
            return [statusConfigs.ActiveExpiredAsBuilt];

          case requestListType.all:
          default:
            setIcon(<AllListIcon />);
            setTitle(t('pageReqAllReq'));
            return [];
        }
      }

      const statuses = getStatusToFilter();

      if (statuses.length === 0) {
        setData(requests);
        setFilteredRequests(requests);
      }
      else if (statuses.includes(statusConfigs.ActiveExpired)) {

        const activeExpired = requests.filter(r => r.status.description_static === statusConfigs.Active && new Date(r.end_date) < previousDate && !r.isChangesExpected);
        setData(activeExpired);
        setFilteredRequests(activeExpired);
      }
      else if (statuses.includes(statusConfigs.ActiveExpiredAsBuilt)) {
        const activeExpired = requests.filter(r => r.status.description_static === statusConfigs.Active && new Date(r.end_date) < previousDate && r.isChangesExpected);
        setData(activeExpired);
        setFilteredRequests(activeExpired);
      }
      else {
        const filteredData = requests.filter(r => r.status && statuses.includes(r.status.description_static));
        setData(filteredData);
        setFilteredRequests(filteredData);
      }
    }

    getFilteredRequests();

  }, [t, viewType, requests])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const closeRequestDetailsDialog = () => {
    setOpenRequestDetailsDialog(false);
    dispatch({ type: "setSelectedrequest", value: null });
  };

  function handleNotify(requestId) {
    const request = filteredRequests.find(d => d.id === requestId);
    dispatch({ type: "setSelectedrequest", value: request || null });
    setOpenNotifyDialog(true);
  }

  const handleExport = async (requestId) => {

    try {
      setIsBusy(true);
      const apiURL = exportRequestUrl(requestId);
      const response = await postData(apiURL, null, authToken);
      if (response.status >= 200 && response.status <= 300) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const pdfWindow = window.open();
        pdfWindow.location.href = url;
        dispatch({ "type": "setSnackBarInfo", value: { message: t('generalDownloaded'), isError: false } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('generalErrorDownload'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
    }
    finally {
      setIsBusy(false);
    }

  }

  const handleRequestForDelay = () => {
    setAnchorEl(null);
    setOpenCreateDelayRequestDialog(true);
  }

  const handleRespondToDelayRequest = () => {
    setAnchorEl(null);
    if (selectedRequest) {
      getDelayRequest(selectedRequest);
      setOpenDelayRequestRespondDialog(true);
    }
  }

  const getDelayRequest = async (request) => {
    try {
      const response = await getData(getDelayRequestUrl(request.id), authToken);
      const status = response.status;
      if (status === 200 || status === 201) {
        const data = await response.json();
        setDelayRequest(data);
      }
      else if (status === 401) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
    }
    finally { }
  }

  const handleChangeRequestType = () => {
    setAnchorEl(null);
    if (selectedRequest) {
      GetRequestTypes();
      setOpenChangeRequestTypeDialog(true);
    }
  }

  const handleConvertRequestType = () => {
    setAnchorEl(null);
    if (selectedRequest) {
      GetRequestTypes();
      setOpenConvertTypeDialog(true);
    }
  }

  const GetRequestTypes = async () => {
    try {
      const response = await getData(getAllRequestTypesHavingWorkflowURL(), authToken);

      if (response.status === 200) {
        const response_json = await response.json();
        setRequestTypes(response_json);
      }
      else { setRequestTypes([]); }
    }
    finally { }
  }

  function handleUploadFile(requestId) {
    setAnchorEl(null);
    const request = filteredRequests.find(d => d.id === requestId);
    dispatch({ type: "setSelectedrequest", value: request || null });
    setOpenAttachementDialog(true);
  }

  const handleAttachmentDialogClose = () => {
    setOpenAttachementDialog(false);
  }

  const handleShowUrgentOnly = () => {
    const newValue = !showUrgentOnly;
    if (!newValue) {
      setFilteredRequests(data);
    }
    else {
      setFilteredRequests(data.filter(d => d.isUrgent));
    }

    setShowUrgentOnly(newValue);
  }

  const handleUploadAttachments = async (f) => {

    if (!selectedRequest || !f || f.length === 0) {
      return;
    }

    try {
      setIsFileUploading(true);
      const formData = new FormData();
      f.forEach(file => {
        formData.append('files', file, file.path);
      })

      const url = requestAttachementUploadUrl(selectedRequest.id, attachmentType.create, selectedLanguage);
      const response = await postFormData(url, formData, authToken);
      if (response.status === 200 || response.status === 201) {

        const newObj = await response.json();
        if (!selectedRequest.attachments) {
          selectedRequest.attachments = [];
        }
        selectedRequest.attachments.push(newObj);

        dispatch({ "type": "setSnackBarInfo", value: { message: t('generalUploaded', { file_count: f.length }), isError: false } });
        dispatch({ "type": "setOpenSnackbar", value: true });
        dispatch({ type: "setSelectedrequest", value: null });
      }
      else if (response.status === 415) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('fileAllowedWarning'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else if (response.status === 401) {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
    }
    finally {
      setIsFileUploading(false);
      setOpenAttachementDialog(false);
    }
  }

  const handleSearch = (newValue) => {

    const fRequests = !showUrgentOnly ? data : data.filter(d => d.isUrgent);
    if (!newValue) {
      setFilteredRequests(fRequests);
      localStorage.setItem(tokens.searchedRequestText, "");
      return setPage(0);
    }
    const searchText = newValue.toLowerCase();
    const searchedRequests = fRequests.filter(r =>
      r.request_number.toLowerCase().indexOf(searchText) > -1 ||
      r.reference_number.toLowerCase().indexOf(searchText) > -1 ||
      r.description.toLowerCase().indexOf(searchText) > -1 ||
      r?.requestType?.description?.toLowerCase().indexOf(searchText) > -1 ||
      r?.assetTypes?.toLowerCase().indexOf(searchText) > -1 ||
      r?.createdBy?.fullName?.toLowerCase().indexOf(searchText) > -1 ||
      r?.createdBy?.company_name?.toLowerCase().indexOf(searchText) > -1 ||
      dateFormat(new Date(r?.created_on), dateStringFormat)?.toLowerCase().indexOf(searchText) > -1 ||
      dateFormat(new Date(r?.start_date), dateStringFormat)?.toLowerCase().indexOf(searchText) > -1 ||
      dateFormat(new Date(r?.end_date), dateStringFormat)?.toLowerCase().indexOf(searchText) > -1
    );

    localStorage.setItem(tokens.searchedRequestText, newValue);
    setFilteredRequests(searchedRequests);
    setPage(0);
  }

  const handleReject = (requestId) => {
    setAnchorEl(null);
    const request = filteredRequests.find(d => d.id === requestId);
    if (isAdmin && request) {
      setSelectedRequest(request);
      setOpenRejectDialog(true);
    }
  }

  const handleSetExpired = (requestId) => {
    setAnchorEl(null);
    const request = filteredRequests.find(d => d.id === requestId);
    if (isAdmin && request) {
      setSelectedRequest(request);
      setOpenSetExpireDialog(true);
    }
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
                  title={title}
                  selected={selectedRequest}
                  icon={icon}
                  setAnchorEl={setAnchorEl}
                  showUrgentOnly={showUrgentOnly}
                  handleShowUrgentOnly={handleShowUrgentOnly}
                  handleSearch={handleSearch}
                  isAmpApp={isAmpApp}
        />
        <TableContainer style={{ maxHeight: screenHeigth }}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              selected={selectedRequest}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredRequests.length}
            />
            <TableBody>
              {stableSort(filteredRequests, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((request, index) => {
                  return (
                    <RequestTableRow
                      key={`${request.id}${index}`}
                      request={request}
                      setAnchorEl={setAnchorEl}
                      selectedRequest={selectedRequest}
                      setSelectedRequest={setSelectedRequest} />
                  );
                })}
              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 * emptyRows }}>
                  <StyledTableCell colSpan={6} />
                </StyledTableRow >
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRequests.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      {selectedRequest &&
        <Fragment>
          <RequestDetailsDialog
            request={selectedRequest}
            open={openRequestDetailsDialog}
            handleClose={closeRequestDetailsDialog} />

          <RequestResponseDialog
            open={openResponseDialog}
            setOpen={setOpenResponseDialog}
            selectedRequestId={selectedRequest.id}
            currentUserRole={currentUserRole}
            />

          <DialogNotifyRequestUpdate
            open={openNotifyDialog}
            setOpen={setOpenNotifyDialog}
            requestId={selectedRequest.id} />

          <DialogChangeRequestType
            open={openChangeRequestTypeDialog}
            setOpen={setOpenChangeRequestTypeDialog}
            request={selectedRequest}
            requestTypes={requestTypes}
          />

          <DialogConvertRequest
            open={openConvertTypeDialog}
            setOpen={setOpenConvertTypeDialog}
            request={selectedRequest}
            requestTypes={requestTypes}
          />

          <DialogRejectRequest
            open={openRejectDialog}
            setOpen={setOpenRejectDialog}
            request={selectedRequest} />

          <DialogSetRequestAsExpired
            open={openSetExpireDialog}
            setOpen={setOpenSetExpireDialog}
            request={selectedRequest} />

          <DropzoneAttachmentDialog
            open={openAttachementDialog}
            handleSave={handleUploadAttachments}
            handleClose={handleAttachmentDialogClose}
            fileLimit={10}
            title={t('generalUploadDocument')}
            dropzoneTitle={t('pageReqCreateDragDropFile')}
          />

          <MenuRequest
            requestObject={selectedRequest}
            history={history}
            handleNotify={handleNotify}
            setOpenRequestDetailsDialog={setOpenRequestDetailsDialog}
            setOpenResponseDialog={setOpenResponseDialog}
            handleChangeRequestType={handleChangeRequestType}
            handleConvertRequestType={handleConvertRequestType}
            handleUploadFile={handleUploadFile}
            handleExport={handleExport}
            isBusy={isBusy}
            handleReject={handleReject}
            handleSetExpired={handleSetExpired}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            handleRequestForDelay={restrictRequestDelay? null: handleRequestForDelay}
            handleRespondToDelayRequest={handleRespondToDelayRequest}
          />

          {!restrictRequestDelay &&
            <DialogCreateDelayRequest
              open={openCreateDelayRequestDialog}
              setOpen={setOpenCreateDelayRequestDialog}
              request={selectedRequest} />}

          {delayRequest &&
            <DialogRespondToDelayRequest
              open={openDelayRequestRespondDialog}
              setOpen={setOpenDelayRequestRespondDialog}
              request={selectedRequest}
              delayRequest={delayRequest}
              setDelayRequest={setDelayRequest}
            />}

        </Fragment>}
      <BackDrop loading={isFileUploading} />
    </div>
  );
}