import { AppBar, Avatar, Breadcrumbs, Button, Divider, Drawer, Grid, IconButton, Link, List, ListItem as MuiListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, SvgIcon, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { AccountBox as EditProfileIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ExitToApp as LogoutIcon, Home as HomeIcon, Info as InfoIcon, VpnKey as ChangePwdIcon } from '@material-ui/icons';
import HelpIcon from '@material-ui/icons/Help';
import MenuIcon from '@material-ui/icons/Menu';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ReplyIcon from '@material-ui/icons/Reply';
import clsx from 'clsx';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { appRoute } from '../../utils/routeConfigs';
import { GetToken } from '../../utils/tokenActions';
import StyledBadge from '../Common/StyledBadge';
import DialogEditUser from '../pages/Users/DialogEditUser';
import DialogDelegateAssetOwner from '../pages/user_roles/DialogDelegateAssetOwner';
import { ReactComponent as flagNL } from './../../img/flag-nl.svg';
import { ReactComponent as flagUK } from './../../img/flag-uk.svg';
import ImgHeader from './../../img/header-rhdhv.jpg';
import { getData } from './../../utils/ApiRequest';
import { getUserURL } from './../../utils/ApiUrl';
import { languages } from './../../utils/AppConstants';
import { tokens } from './../../utils/keys';
import { isAssetOwner } from './../../utils/Roles';
import { AppContext } from './../AuthenticatedPage';
import ChangePaswordDialog from './../pages/Users/ChangePaswordDialog';
import AppLogo from './AppLogo';
import DialogLogoutConfirmation from './DialogLogoutConfirmation';
import NotificationBadge from './NotificationBadge';
import Sidemenu from './Sidemenu';
import StyledMenu from './StyledMenu';
import StyledMenuItem from './StyledMenuItem';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import { appConfigurations } from '../../utils/configs';

require('dotenv').config()
const drawerWidth = 340;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(1),
    },
    listItem: {
        display: 'inline-flex',
        maxWidth: 'auto',
        width: 'auto',
    },
    listItemIconActive: {
        color: theme.palette.rhdhvTheme1.textColor,
        minWidth: '40px',
    },
    listItemTextActive: {
        color: theme.palette.rhdhvTheme1.textColor,
        fontSize: '14px',
        fontWeight: '500',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fff',
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justify: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    rightToolbar: {
        marginLeft: 'auto',
        display: 'inline-flex',
        height: '72px',
    },
    username: {
        color: '#000'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(8) + 1,
        },
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(5),
        '& .MuiButton-label': {
            fontSize: 19,
        },
        '& .MuiButton-startIcon': {
            '& svg': {
                fontSize: 24
            }
        }
    },
    otherButton: {
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(0.5),
        '& .MuiIconButton-root': {
            '& :hover': {
                color: theme.palette.primary.main
            }
        }
    },
    activeColor: {
        color: theme.palette.primary.main
    },
    userInfo: {
        padding: 0,
        '& .MuiListItem-root': {
            paddingTop: 0,
            paddingBottom: 0,
        }
    },
    IntroductionDiv: {
        width: '100%',
        margin: "0",
        textAlign: 'center',
        backgroundImage: `url(${ImgHeader})`,
        backgroundPosition: 'right top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
}));

function BreadcrumbsNav() {

    let history = useHistory();
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { workflowName, selectedPage } = state;

    const handleClickBreadcrumb = (page) => {
        dispatch({ "type": "setSelectedPage", value: page })
        history.push(`/${page}`);
    }

    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ marginLeft: '24px' }}>
            {selectedPage === 'workflow' &&
                <Link color="primary" underline="always" onClick={(e) => handleClickBreadcrumb('workflowlist')} style={{ cursor: 'pointer' }}>
                    Workflows
                </Link>}
            {selectedPage === 'workflow' &&
                <Typography color="inherit">
                    {workflowName}
                </Typography>}
        </Breadcrumbs>
    );
}

const ListItem = withStyles(() => ({
    root: {},
    selected: {}
}))(MuiListItem);

export default function Navbar(props) {

    const classes = useStyles();
    const theme = useTheme();
    let history = useHistory();
    const [t, i18n] = useTranslation();

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { selectedLanguage, selectedPage, currentUserRole } = state;
    const loggedInUser = state.currentUser;
    const { setAuthenticated } = props;

    const [openUserProfileDialog, setOpenUserProfileDialog] = React.useState(false);
    const [openChangePasswordDialog, setChangePasswordDialog] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openSidebar, setSidebarOpen] = React.useState(false);
    const [anchorUserAction, setAnchorUserAction] = React.useState(null);
    const [openDelegateAssetOwner, setOpenDelegateAssetOwner] = React.useState(false);
    const userRoles = JSON.parse(localStorage.getItem(tokens.keyUserroles) ? localStorage.getItem(tokens.keyUserroles) : sessionStorage.getItem(tokens.keyUserroles));
    const isAmpApp = appConfigurations.isAmpApp
    const userDetails = {
        id: loggedInUser?.id,
        email: loggedInUser?.email,
        firstName: loggedInUser?.firstName,
        lastName: loggedInUser?.lastName,
    }

    const userName = !userDetails.firstName && !userDetails.lastName ? "N A" : `${userDetails.firstName} ${userDetails.lastName}`
    const avatarName = userName.split(" ").map((str) => str[0]).join("");
    const isUserAssetOwner = isAssetOwner();

    const handleListItemClick = (value) => {

        setAnchorUserAction(null);
        if (value === 'logout') {
            setOpen(true);
        }
    };

    const handleUserActionClick = (event) => {
        setAnchorUserAction(event.currentTarget);
    };

    const handleUserActionClose = () => {
        setAnchorUserAction(null);
    };

    const handleDrawerOpen = () => {
        setSidebarOpen(true);
    };

    const handleDelegateAssetOwner = () => {
        setAnchorUserAction(null);
        setOpenDelegateAssetOwner(true);
    };

    const handleDrawerClose = () => {
        setSidebarOpen(false);
    };

    const handleEditUserProfileClick = async () => {

        const apiURL = getUserURL(userDetails.id);
        await getData(apiURL, GetToken())
            .then(async response => {
                if (response.status === 200 || response.status === 201) {
                    const response_json = await response.json();
                    setCurrentUser(response_json);
                    setOpenUserProfileDialog(true);
                }
                else {
                    setCurrentUser(null);
                }
            })

        setAnchorUserAction(false);
    };

    const handleChangePasswordClick = () => {
        setChangePasswordDialog(true);
        setAnchorUserAction(false);
    };

    const handleLanguageChange = () => {
        i18n.changeLanguage(selectedLanguage === 'en' ? languages.nl : 'en');
        dispatch({ "type": "setSelectedLanguage", value: selectedLanguage === languages.en ? languages.nl : languages.en })
    }

    const handlePageChange = (page) => {
        history.push(page);
        dispatch({ type: "setSelectedPage", value: page })
    }

    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
            <Grid item xs={12} sm={12}>
                <AppBar position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: openSidebar,
                    })} >
                    <Toolbar>
                        <Button
                            color="primary"
                            size="large"
                            startIcon={<MenuIcon />}
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: openSidebar,
                            })}
                        >Menu
                        </Button>
                        <AppLogo title={isAmpApp ? t('AMPAppTitle') : t('acceptatieAppTitle')} />
                        <BreadcrumbsNav />
                        <section className={classes.rightToolbar}>

                            <List component="nav" className={classes.otherButton}>
                                <ListItem>
                                    <ListItemSecondaryAction>
                                        <Tooltip title={selectedLanguage === 'en' ? 'NL' : 'EN'}>
                                            <IconButton
                                                className={clsx({ [classes.activeColor]: selectedPage === 'Info' })}
                                                onClick={handleLanguageChange}>
                                                {selectedLanguage === 'en' ?
                                                    <SvgIcon component={flagNL} viewBox={`0 0 100 100`} /> :
                                                    <SvgIcon component={flagUK} viewBox={`0 0 500 500`} />}
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>

                            <Fragment>
                                <List component="nav" className={classes.otherButton}>
                                    <ListItem>
                                        <ListItemSecondaryAction>
                                            <Tooltip title={t('home')}>
                                                <IconButton
                                                    className={clsx({ [classes.activeColor]: selectedPage === appRoute.home })}
                                                    onClick={() => handlePageChange(appRoute.home)}>
                                                    <HomeIcon size="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>

                                <List component="nav" className={classes.otherButton}>
                                    <ListItem>
                                        <ListItemSecondaryAction>
                                            <Tooltip title={t('contactUs')} >
                                                <IconButton
                                                    className={clsx({ [classes.activeColor]: selectedPage === appRoute.contactUs })}
                                                    onClick={() => handlePageChange(appRoute.contactUs)}>
                                                    <InfoIcon size="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>

                                <List component="nav" className={classes.otherButton}>
                                    <ListItem>
                                        <ListItemSecondaryAction>
                                            <Tooltip title={t('help')}>
                                                <IconButton
                                                    className={clsx({ [classes.activeColor]: selectedPage === appRoute.about })}
                                                    onClick={() => handlePageChange(appRoute.about)}>
                                                    <HelpIcon size="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>

                                <List component="nav" className={classes.userInfo}>
                                    <ListItem
                                        button
                                        aria-haspopup="true"
                                        aria-controls="lock-menu"
                                        aria-label="when device is locked"
                                        onClick={handleUserActionClick}
                                    >
                                        <ListItemAvatar size="small">
                                            <StyledBadge
                                                overlap="rectangle"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                variant="dot"
                                            >
                                                <Avatar size="small" style={{ backgroundColor: "#a5c100" }}>
                                                    <Typography variant="h5" style={{ color: "#fff" }}>
                                                        {avatarName}
                                                    </Typography>
                                                </Avatar>
                                            </StyledBadge>
                                        </ListItemAvatar>
                                        <ListItemText className={classes.username}
                                            primary={`${t('welcome')}, ${userName}`}
                                            secondary={
                                                <Typography className={classes.username}>
                                                    {!userRoles || userRoles.length <= 0 || !userRoles[0].description ? 'Role: Not defined' : userRoles[0].description}
                                                </Typography>} />
                                    </ListItem>
                                </List>
                                <NotificationBadge />
                            </Fragment>

                            <StyledMenu
                                id="customized-menu"
                                anchorEl={anchorUserAction}
                                keepMounted
                                open={Boolean(anchorUserAction)}
                                onClose={handleUserActionClose}
                            >
                                <StyledMenuItem onClick={handleEditUserProfileClick}>
                                    <ListItemIcon>
                                        <EditProfileIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary={t('profile')} />
                                </StyledMenuItem>
                                <StyledMenuItem onClick={handleChangePasswordClick}>
                                    <ListItemIcon>
                                        <ChangePwdIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary={t('changePassword')} />
                                </StyledMenuItem >
                                {currentUserRole && isUserAssetOwner &&
                                    <Fragment>
                                        <Divider />
                                        {!currentUserRole.delegated &&
                                            <StyledMenuItem onClick={handleDelegateAssetOwner}>
                                                <ListItemIcon>
                                                    <ReplyIcon fontSize="small" style={{ color: "orange", transform: "rotateY(180deg)" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t('delegate.other.user')} />
                                            </StyledMenuItem>}
                                        {currentUserRole.delegated &&
                                            <StyledMenuItem onClick={handleDelegateAssetOwner}>
                                                <ListItemIcon>
                                                    <CancelScheduleSendIcon fontSize="small" style={{ color: "orange", transform: "rotateY(180deg)" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t('cancelDelegate')} />
                                            </StyledMenuItem>}
                                    </Fragment>}
                                <Divider />
                                <StyledMenuItem onClick={() => handleListItemClick('logout')}>
                                    <ListItemIcon>
                                        <LogoutIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary={t('logOut')} />
                                </StyledMenuItem>
                            </StyledMenu>
                        </section>
                    </Toolbar>
                </AppBar>

                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: openSidebar,
                        [classes.drawerClose]: !openSidebar,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: openSidebar,
                            [classes.drawerClose]: !openSidebar,
                        }),
                    }}
                >
                    <div className={classes.toolbar}>
                        <Typography variant="h5" align="right">{t('userMenu')}</Typography>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <Sidemenu />
                </Drawer>

            </Grid>

            <DialogEditUser user={currentUser} open={openUserProfileDialog} setOpen={setOpenUserProfileDialog} />
            <ChangePaswordDialog open={openChangePasswordDialog} setOpen={setChangePasswordDialog} />
            <DialogLogoutConfirmation
                open={open}
                setOpen={setOpen}
                setAnchor={setAnchorUserAction}
                setAuthenticated={setAuthenticated}
            />
            <DialogDelegateAssetOwner open={openDelegateAssetOwner} setOpen={setOpenDelegateAssetOwner} />
        </Grid>
    );
}