import DateFnsUtils from '@date-io/date-fns';
import { CircularProgress, Dialog, DialogActions, DialogContent, Fab, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save as SaveIcon } from '@material-ui/icons';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogAppBar from '../../Common/DialogAppBar';
import { deleteData, postData } from './../../../utils/ApiRequest';
import { cancelDelegationUrl, delegateUserUrl } from './../../../utils/ApiUrl';
import { languages, roleConfigs } from './../../../utils/AppConstants';
import { AppContext } from './../../AuthenticatedPage';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0, 3),
        marginTop: theme.spacing(3),
        '& .MuiGrid-container': {
            '& h3': {
                marginBottom: theme.spacing(2),
            },
            '& .MuiGrid-item': {
                width: '40%'
            },
            '& .MuiTextField-root': {
                marginBottom: theme.spacing(2),
            },
        },
        '& .MuiSwitch-root': {
            marginLeft: theme.spacing(2),
        },

        '& .Mui-disabled': {
            color: 'grey'
        },
    },
    submitButton: {
        backgroundColor: theme.palette.warning.main,
        '& svg': {
            marginRight: theme.spacing(1),
        }
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function DialogDelegateUser(props) {

    const [t] = useTranslation();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { usersWithRoles, authToken, selectedLanguage } = state;
    const { open, setOpen, userRole } = props;

    const [allUserOptions, setAllUserOptions] = useState([]);

    const [selectedUserRole, setSelectedUserRole] = useState(null);
    const [remarks, setRemarks] = useState("");
    const [expStartDate, setExpStartDate] = useState(null);
    const [expEndDate, setExpEndDate] = useState(null);
    const [isBusy, setIsBusy] = useState(false);

    const canBeDelegated = (!userRole?.delegated && !userRole?.hasDelegations && [roleConfigs.admin, roleConfigs.assetOwner, roleConfigs.manager].includes(userRole?.role?.role_static)) || false;
    const canCancelDelegation = (userRole?.delegated && [roleConfigs.admin, roleConfigs.assetOwner, roleConfigs.manager].includes(userRole?.role?.role_static)) || false;
    
    useEffect(() => {
        const userOptions = usersWithRoles
            .filter(u => u.role && !u.role.hasDelegations &&
                [roleConfigs.admin, roleConfigs.assetOwner, roleConfigs.manager].includes(u.role.role_static));
        setAllUserOptions(userOptions);

    }, [usersWithRoles])

    useEffect(() => {
        setSelectedUserRole(null);
        setRemarks("");
        setExpStartDate(null);
        setExpEndDate(null);
    }, [open])

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelectUser = (event, value) => {
        setSelectedUserRole(value);
    };

    const handleStartDateChange = (date) => {
        setExpStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setExpEndDate(date);
    };

    async function handleSubmit(event) {
        event.preventDefault();

        if (!selectedUserRole || !expStartDate || !expEndDate || (!remarks || remarks.length >= 1000)) {
            return;
        }
        try{
            setIsBusy(true);
            const data = {
                "userId": selectedUserRole.user.id,
                "startDate": expStartDate,
                "endDate": expEndDate,
                "remarks": remarks
            };

            const lang = selectedLanguage === languages.nl ? 'nl' : 'en';
            const url = delegateUserUrl(userRole.user.id, lang);
            const response = await postData(url, data, authToken)
            const status = response.status;
            if (status === 201 || status === 200) {
                let newArray = [...usersWithRoles];
                const index = newArray.findIndex(e => e.user.id === userRole.user.id);
                if (index > -1) {
                    const updatedUserRole = newArray[index];
                    updatedUserRole.delegated = true;
                    newArray.splice(index, 1, updatedUserRole);
    
                    //Update user who gets delegations
                    const delegateIndex = newArray.findIndex(e => e.user.id === selectedUserRole.user.id);
                    if (delegateIndex > -1) {
                        const newUserRole = newArray[delegateIndex];
                        newUserRole.delegateUserIds = [userRole.user.id];
                        newUserRole.hasDelegations = true;
                        newArray.splice(delegateIndex, 1, newUserRole);
                    }
    
                    dispatch({ "type": "setUsersWithRoles", value: newArray });
                    dispatch({ "type": "setSnackBarInfo", value: { message: t('delegate.user.success'), isError: false } })
                }
            }
            else if (status === 422) {
                dispatch({ "type": "setSnackBarInfo", value: { message: t("delegate.user.errors"), isError: true } })
                dispatch({ "type": "setOpenSnackbar", value: true })
            }
            else if (status === 401) {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('dialogUsersSessionExpired'), isError: true } })
                dispatch({ "type": "setOpenSnackbar", value: true })
            }
            else {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('dialogUsersSaveError'), isError: true } })
                dispatch({ "type": "setOpenSnackbar", value: true })
            }
    
        }
        finally{
        setIsBusy(false);
        setOpen(false);  
        }

    };

    const handleCancelDelegation = async (event) => {
        event.preventDefault();

        if (!userRole) {
            return;
        }

        const lang = selectedLanguage === languages.nl ? 'nl' : 'en';
        const url = cancelDelegationUrl(userRole.user.id, lang);
        const response = await deleteData(url, authToken)
        const status = response.status;
        if (status === 201 || status === 200) {
            let newArray = [...usersWithRoles];
            const index = newArray.findIndex(e => e.user.id === userRole.user.id);
            if (index > -1) {
                const updatedUserRole = newArray[index];
                updatedUserRole.delegated = false;
                newArray.splice(index, 1, updatedUserRole);

                //Update user who gets delegations
                const delegateIndex = newArray.findIndex(u => u.delegateUserIds && u.delegateUserIds.some(dd => dd.includes(updatedUserRole.user.id)))
                if (delegateIndex > -1) {
                    const newUserRole = newArray[delegateIndex];
                    newUserRole.delegateUserIds = [];
                    newUserRole.hasDelegations = false;
                    newArray.splice(delegateIndex, 1, newUserRole);
                }

                dispatch({ "type": "setUsersWithRoles", value: newArray });
                dispatch({ "type": "setSnackBarInfo", value: { message: t('cancel.delegate.user.success'), isError: false } })
            }
        }
        else if (status === 401) {
            dispatch({ "type": "setSnackBarInfo", value: { message: t('dialogUsersSessionExpired'), isError: true } })
            dispatch({ "type": "setOpenSnackbar", value: true })
        }
        else {
            dispatch({ "type": "setSnackBarInfo", value: { message: t('dialogUsersSaveError'), isError: true } })
            dispatch({ "type": "setOpenSnackbar", value: true })
        }

        setOpen(false);
    }

    const isFormValidated = () => {
        return expStartDate && expEndDate && selectedUserRole && remarks && remarks.length > 0;
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullScreen>
            <DialogAppBar
                warning
                setOpen={setOpen}
                title={t(canBeDelegated ? "delegateUserTitle" : "cancelDelegate")}
            />
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div className={classes.root}>
                    <Grid container justify="center" alignItems="center" direction="column">
                        <Grid item>
                            <Typography color="warning" style={{ fontSize: 20, marginBottom: "20px" }}>
                                {t(canBeDelegated ? "delegateUserTitle" : "cancelDelegate")}
                            </Typography>
                            <Typography color="warning" style={{ fontSize: 14, marginBottom: "20px" }}>
                                {t(canBeDelegated ? "delegateUserDescription" : "cancelDelegateUserDescription")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                disabled
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                value={userRole.user.email}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                disabled
                                fullWidth
                                id="firstName"
                                label={t('pageUsersName')}
                                name="firstName"
                                value={`${userRole.user.firstName} ${userRole.user.lastName}`}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                disabled
                                fullWidth
                                id="phoneNumber"
                                label={t('dialogUsersPhone')}
                                name="phoneNumber"
                                value={userRole.user.phone_number}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                fullWidth
                                disabled
                                id="company_name"
                                label={t('dialogUsersCompany')}
                                name="company_name"
                                value={userRole.user.company_name}
                            />
                        </Grid>
                        {canBeDelegated &&
                            <Fragment>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid item>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            inputVariant="outlined"
                                            required
                                            fullWidth
                                            format="dd-MM-yyyy"
                                            margin="normal"
                                            disabled={isBusy}
                                            id="date-picker-inline"
                                            label={t('delegateUserStartDate')}
                                            value={expStartDate}
                                            onChange={handleStartDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            style={{ marginTop: 0 }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            inputVariant="outlined"
                                            required
                                            fullWidth
                                            format="dd-MM-yyyy"
                                            margin="normal"
                                            disabled={isBusy}
                                            id="date-picker-inline"
                                            label={t('delegateUserEndDate')}
                                            value={expEndDate}
                                            onChange={handleEndDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            style={{ marginTop: 0 }}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                                <Grid item>
                                    <Autocomplete
                                        margin="none"
                                        fullWidth
                                        required
                                        id="select-request-type"
                                        options={allUserOptions}
                                        disabled={isBusy}
                                        getOptionLabel={(o) => `${o.user.firstName} ${o.user.lastName} (${o.user.email})`}
                                        getOptionDisabled={(o) => o.user.id === userRole?.user?.id}
                                        value={selectedUserRole}
                                        onChange={(event, value) => { handleSelectUser(event, value) }}
                                        renderInput={(params) => <TextField {...params}
                                            placeholder={t('delegateUser2SelectPlaceholder')}
                                            label={`${t('delegateUser2SelectLabel')}*`}
                                            variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        multiline
                                        inputProps={{ style: { minHeight: '100px' } }}
                                        margin="none"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="description"
                                        label={t('generalRemarks')}
                                        name="description"
                                        autoComplete="off"
                                        value={remarks}
                                        onChange={e => setRemarks(e.target.value)}
                                        disabled={isBusy}
                                    />
                                </Grid>
                            </Fragment>}
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#fff' }}>
                {canBeDelegated &&
                    <Fab variant="extended" color="primary" disabled={!isFormValidated() || isBusy} aria-label="add" className={classes.submitButton}
                        onClick={handleSubmit}>
                        {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />  }
                        <SaveIcon />
                        {t('delegate')}
                    </Fab>}
                {canCancelDelegation && 
                    <Fab variant="extended" color="primary" disabled={isBusy} aria-label="add" className={classes.submitButton}
                        onClick={handleCancelDelegation}>
                        <CancelScheduleSendIcon />
                        {t('cancelDelegate')}
                    </Fab>}
            </DialogActions>
        </Dialog>
    )
}