import { Button, CircularProgress, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, Link, OutlinedInput, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import LanguageSwitch from '../../Common/LanguageSwitch';
import { postData } from './../../../utils/ApiRequest';
import { registerURL } from './../../../utils/ApiUrl';
import { getAppTitle, isValidEmail, isValidPassword, userValidation } from './../../../utils/AppConstants';
import { AnonymousAppContext } from './../../AnonymousPage';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PrivacyDocumentAcceptatie from "./../../../static/Acceptaties - PN022 - Privacy Notificatie.pdf";
import PrivacyDocumentAMP from "./../../../static/PN022 - Privacy Notificatie - AMP.pdf";
import { appConfigurations } from '../../../utils/configs';

const useStyles = makeStyles(theme => ({

  root: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(2, 0, 8),

  },
  title: {
    fontSize: '2.5rem',
  },
  subTitle: {
    fontSize: '2.2rem',
  },
  outlinedInput: {
    '& .MuiInputLabel-root': {
      paddingLeft: theme.spacing(1.5),
      marginTop: theme.spacing(-0.5),
    },
    '& .MuiInputLabel-shrink': {
      paddingLeft: theme.spacing(2.5),
      marginTop: theme.spacing(-1),
    }
  },
  privacyPolicy: {
    marginTop: theme.spacing(1),
    '& svg': {
      marginBottom: theme.spacing(-1),
      marginRight: theme.spacing(1),
    },
    '& .MuiLink-root': {
      fontWeight: 'bold'
    }
  },
  progress: {
    marginRight: theme.spacing(1),
  },
}));

export default function Register() {

  const [t] = useTranslation();
  const appContext = useContext(AnonymousAppContext);
  const { state, dispatch } = appContext;
  const { selectedLanguage } = state;
  let history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [companyNameError, setCompanyNameError] = useState(false);

  const classes = useStyles();

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const isValidFirstName = (value) => {
    return value.length >= userValidation.firstNameMin && value.length <= userValidation.firstNameMax;
  }

  const isValidLastName = (value) => {
    return value.length >= userValidation.lastNameMin && value.length <= userValidation.lastNameMax;
  }

  const isValidPhoneNumber = (value) => {
    return value.length >= userValidation.phoneMin && value.length <= userValidation.phoneMax;
  }

  const isValidCompanyName = (value) => {
    return value.length >= userValidation.companyMin && value.length <= userValidation.companyMax;
  }

  function validateForm() {
    return isValidEmail(email) &&
      isValidFirstName(firstName) &&
      isValidLastName(lastName) &&
      isValidPhoneNumber(phoneNumber) &&
      isValidCompanyName(companyName) &&
      isValidPassword(password) && password === confirmPassword;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setIsBusy(true)
      const data =
      {
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "password": password,
        "phone_number": phoneNumber,
        "company_name": companyName,
      }
      const lang = selectedLanguage || 'en';
      const response = await postData(registerURL(lang), data)
      const status = response.status;
      if (status === 200 || status === 201) {
        dispatch({ "type": "setSnackBarInfo", value: { message: 'Your registration was successful and a verification code has been emailed to you. Please enter the verification code in verify email in order to complete your registration.', isError: false } })
        dispatch({ "type": "setOpenSnackbar", value: true })
        history.replace('/');
      }
      else if (status === 409) {
        dispatch({ "type": "setSnackBarInfo", value: { message: "Entered email is already in use. Please click on forgot password if you want to regenerate", isError: true } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: 'We are facing some technical issues at the moment. Please try after some time', isError: true } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
    }
    finally {
      setIsBusy(false)
    }

  }

  function onLoginClick() {
    history.replace('/');
  }
  function onForgotPasswordClick() {
    history.replace('/forgotpwd');
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(!isValidEmail(e.target.value));
  };

  const handleFirstNameChange = (e) => {
    const newVal = e.target.value;
    setFirstName(newVal);
    setFirstNameError(!isValidFirstName(newVal));
  };

  const handleLastNameChange = (e) => {
    const newVal = e.target.value;
    setLastName(newVal);
    setLastNameError(!isValidLastName(newVal));
  };

  const handlePhoneNumberChange = (e) => {
    const newVal = e.target.value;
    setPhoneNumber(newVal);
    setPhoneNumberError(!isValidPhoneNumber(newVal));
  };

  const handleCompanyNameChange = (e) => {
    const newVal = e.target.value;
    setCompanyName(newVal);
    setCompanyNameError(!isValidCompanyName(newVal));
  };

  const handleNewPasswordChange = (e) => {
    setPassword(e.target.value);
    setNewPasswordError(!isValidPassword(e.target.value));
    setConfirmNewPasswordError(e.target.value !== confirmPassword);
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmNewPasswordError(e.target.value !== password);
  };

  const handleClickShowPassword = (sender) => {
    if (sender === 'newp')
      return setShowNewPassword(!showNewPassword);
    else if (sender === 'confirmnewp')
      return setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const handlePrivacyPolicy = () => {
    appConfigurations.isAmpApp ?
      window.open(PrivacyDocumentAMP) :
      window.open(PrivacyDocumentAcceptatie)
  }

  return (
    <div className={classes.root}>
      <Grid container direction="column" alignItems="center" justify="center" className={classes.titleGrid}>
        <Typography color="primary" gutterBottom align="center" className={classes.title}>
          {getAppTitle(appConfigurations.isAmpApp)}
        </Typography>
        <Typography color="primary" className={classes.subTitle}>
          {t('register')}
        </Typography>
      </Grid>
      <form>
        <TextField
          variant="outlined"
          required
          fullWidth
          disabled={isBusy}
          id="email"
          label={t('pageUsersEmail')}
          name="email"
          type="email"
          autoComplete="off"
          value={email}
          error={emailError}
          helperText={emailError ? t('emailError') : ""}
          onChange={handleEmailChange}
        />
        <TextField
          variant="outlined"
          required
          fullWidth
          disabled={isBusy}
          id="firstName"
          label={t('dialogUsersFirstName')}
          name="firstName"
          autoComplete="off"
          value={firstName}
          error={firstNameError}
          helperText={firstNameError ? t('firstNameError', { max_chars: `${userValidation.firstNameMin}-${userValidation.firstNameMax}` }) : ""}
          onChange={handleFirstNameChange}
        />
        <TextField
          variant="outlined"
          required
          fullWidth
          disabled={isBusy}
          id="lastName"
          label={t('dialogUsersLastName')}
          name="lastName"
          autoComplete="off"
          value={lastName}
          error={lastNameError}
          helperText={lastNameError ? t('lastNameError', { max_chars: `${userValidation.lastNameMin}-${userValidation.lastNameMax}` }) : ""}
          onChange={handleLastNameChange}
        />
        <TextField
          variant="outlined"
          required
          fullWidth
          disabled={isBusy}
          id="phoneNumber"
          label={t('dialogUsersPhone')}
          name="phoneNumber"
          autoComplete="off"
          value={phoneNumber}
          error={phoneNumberError}
          helperText={phoneNumberError ? t('phoneNumberError', { max_chars: `${userValidation.phoneMin}-${userValidation.phoneMax}` }) : ""}
          onChange={handlePhoneNumberChange}
        />
        <TextField
          variant="outlined"
          required
          fullWidth
          disabled={isBusy}
          id="companyName"
          label={t('dialogUsersCompany')}
          name="companyName"
          autoComplete="off"
          value={companyName}
          error={companyNameError}
          helperText={companyNameError ? t('companyNameError', { max_chars: `${userValidation.companyMin}-${userValidation.companyMax}` }) : ""}
          onChange={handleCompanyNameChange}
        />
        <FormControl className={classes.outlinedInput}>
          <InputLabel htmlFor="new-password">
            {t('password')}
          </InputLabel>
          <OutlinedInput
            id="new-password"
            error={newPasswordError}
            type={showNewPassword ? 'text' : 'password'}
            required
            fullWidth
            disabled={isBusy}
            name="password"
            label={t('password')}
            value={password}
            onChange={e => handleNewPasswordChange(e)}
            autoComplete="off"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={() => handleClickShowPassword('newp')}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
          <FormHelperText error={newPasswordError} id="password-helper-text">
            {newPasswordError ? t('newPasswordError', { max_chars: `${userValidation.passwordMin}-${userValidation.passwordMax}` }) : ""}
          </FormHelperText>
        </FormControl><br />

        <FormControl className={classes.outlinedInput}>
          <InputLabel htmlFor="confirm-password">
            Confirm password
          </InputLabel>
          <OutlinedInput
            id="confirm-password"
            error={confirmNewPasswordError}
            type={showConfirmNewPassword ? 'text' : 'password'}
            required
            fullWidth
            disabled={isBusy}
            name="password"
            label={t('pageRegisterConfirmPwd')}
            value={confirmPassword}
            onChange={e => handleConfirmNewPasswordChange(e)}
            autoComplete="off"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={() => handleClickShowPassword('confirmnewp')}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
          <FormHelperText error={confirmNewPasswordError} id="confirm-password-helper-text">
            {confirmNewPasswordError ? t('pageRegisterPwdMatchValidation') : ""}
          </FormHelperText>

          <Typography color="primary" align="justify" className={classes.privacyPolicy}>
            <ErrorOutlineIcon />
            {t('privacyPolicyConditions')}&nbsp;
            <Link underline="always" onClick={handlePrivacyPolicy}>
              Privacy policy
            </Link>
          </Typography>

        </FormControl>
        <Button
          onSubmit={handleSubmit}
          onClick={handleSubmit}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          type="submit"
          disabled={!validateForm() || isBusy}>
          {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />}
          {t('register')}
        </Button>
        <Grid container>
          <Grid item xs={5}>
            <Link onClick={onForgotPasswordClick}>
              {t('pageForgotPwdForgotPwd')}?
            </Link>
          </Grid>
          <Grid item xs={5}>
            <Grid item>
              <Link onClick={onLoginClick}>
                {t('pageRegisterHaveAccount')}
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={2} justify="flex-end">
            <LanguageSwitch />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}