import { AppBar, Button, CircularProgress, Dialog, DialogActions, DialogContent, Fab, Grid, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import WarningIcon from '@material-ui/icons/Warning';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { statusConfigs } from '../../../utils/configs';
import { postData } from './../../../utils/ApiRequest';
import { setRequestAsExpiredUrl } from './../../../utils/ApiUrl';
import { languages } from './../../../utils/AppConstants';
import { AppContext } from './../../AuthenticatedPage';
import RequestDetailsDialog from './RequestDetailsDialog';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiDialogActions-root': {
            '& .MuiCircularProgress-root': {
                marginLeft: theme.spacing(1),
            }
        },
        '& .MuiFab-root': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.warning.main,
            '&:hover': {
                backgroundColor: "orangered",
            },
            '&:disabled': {
                color: '#bfbfbf',
                backgroundColor: '#d9d9d9',
            }
        },
    },
    container: {
        maxHeight: 440,
    },
    formControl: {
        fullWidth: true,
        display: 'flex'
    },
    dialog: {
        //backgroundColor: '#F5F7FA !important'
    },
    appBar: {
        position: 'relative',
        '& .MuiToolbar-root': {
            backgroundColor: theme.palette.warning.main,
        }
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '40%',
            minWidth: '200px',
        },
        '& .MuiTypography-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: '40%',
            minWidth: '200px',
            color: theme.palette.warning.main,
        },
        '& svg': {
            color: theme.palette.warning.main,
        },
        textAlign: 'center',
        width: '100%',
        backgroundColor: '#fff'
    },
    removeIcon: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
}));

export default function DialogSetRequestAsExpired(props) {

    const [t] = useTranslation();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { selectedLanguage, authToken, requests } = state;

    const { open, setOpen, request } = props;
    const [isSaving, setIsSaving] = React.useState(false);
    const [openRequestDetailsDialog, setOpenRequestDetailsDialog] = React.useState(false);
    const [remarks, setRemarks] = useState("");

    async function handleSubmit(event) {
        event.preventDefault();

        try {
            setIsSaving(true);

            const lang = selectedLanguage === languages.nl ? 'nl' : 'en';
            const data = { "remarks": remarks };
            const response = await postData(setRequestAsExpiredUrl(request.id, lang), data, authToken);
            const status = response.status;
            if (status === 200 || status === 201) {

                //Set request status
                const arr = [...requests];
                const currentRequest = arr.find(r => r.id === request.id);
                if (currentRequest) {
                    currentRequest.status.description_static = statusConfigs.Expired;
                    const index = arr.indexOf(currentRequest);
                    arr.splice(index, 1, currentRequest);
                    dispatch({ "type": "setRequests", value: arr });
                }

                dispatch({ "type": "setSnackBarInfo", value: { message: t('setAsExpiredDialogSuccessMessage'), isError: false } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else if (status === 401) {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
        }
        finally {
            setIsSaving(false);
            setOpen(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenRequestDetailsClick = () => {
        setOpenRequestDetailsDialog(true);
    };

    const closeRequestDetailsDialog = () => {
        setOpenRequestDetailsDialog(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            style={{ backgroundColor: '#fff' }}
            fullScreen
            className={classes.root}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" className={classes.title}>
                        {t('setAsExpiredDialogTitle')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.form}
                >
                    <WarningIcon fontSize="large" />
                    <Typography variant="h6" >
                        {t('setAsExpiredDialogMessage1')}
                    </Typography>
                    <Typography variant="h6" >
                        {t('setAsExpiredDialogMessage2')}
                    </Typography>

                    <Button variant="outlined" onClick={handleOpenRequestDetailsClick} >
                        {t('generalReqDetails')}
                    </Button>
                    <RequestDetailsDialog request={request} open={openRequestDetailsDialog} handleClose={closeRequestDetailsDialog} />

                    <TextField
                        margin="none"
                        required
                        fullWidth
                        multiline
                        variant="outlined"
                        id="remarks"
                        label={t('generalRemarks')}
                        name="remarks"
                        autoComplete="off"
                        value={remarks}
                        disabled={isSaving}
                        placeholder={t('setAsExpiredDialogRemarkPlaceholder')}
                        onChange={e => setRemarks(e.target.value)}
                        inputProps={{ style: { minHeight: '100px' } }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#fff' }}>
                <Fab variant="extended"
                    disabled={isSaving || (!remarks || remarks.length > 1000)}
                    onClick={handleSubmit} >
                           {isSaving && <CircularProgress color="primary" size={25} className={classes.progress} />}
                    <RemoveCircleIcon className={classes.removeIcon} />
                    {t('setAsExpired')}
                </Fab>
            </DialogActions>
        </Dialog>
    )
}