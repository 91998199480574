import { appConfigurations } from "./configs";

const authUrl = `${appConfigurations.authUrl}/auth`;
const baseUrl = appConfigurations.backendUrl;
// How to use only one url for login
export const loginUsingVerificationCodeURL = (loginPerameter) => {
    return `${authUrl}/login?code=${loginPerameter.code}&language=${loginPerameter.language}`
}
export const loginURL = `${authUrl}/login`

export const logoutURL = authUrl + '/logout';
export const emailVerificationURL = (language) => {
    return `${baseUrl}/auth/verify-email/${language}`
};
export const changepasswordURL = baseUrl + '/changepaasword';
export const editProfileURL = baseUrl + '/editprofile';

export function registerURL(lang) {
    return `${authUrl}/register/${lang}`;
}

export function forgotPasswordVerifyURL(lang) {
    return `${authUrl}/forgotpassword/verify/${lang}`;
}

export function forgotPasswordUpdateURL() {
    return `${authUrl}/forgotpassword/update`;
}

export const getUsersURL = `${baseUrl}/user`

export function getUserURL(userid) {
    return `${baseUrl}/user/${userid}`;
}

export function createUserURL(lang) {
    return `${baseUrl}/user/create/${lang}`;
}

export function changePasswordURL(userid) {
    return `${baseUrl}/user/changepassword/${userid}`;
}

export const getUsersForActionTransferURL = `${baseUrl}/user/for_action_transfer`;

export function getVerifyAsbuiltUsersURL() {
    return `${baseUrl}/user/canVerifyAsbuilt`;
}

export function updateUserURL(userId) {
    return `${baseUrl}/user/update/${userId}`;
}

export function getUserActivateURL(userId) {
    return `${baseUrl}/user/activate/${userId}`;
}

export function getUserDeactivateURL(userId) {
    return `${baseUrl}/user/deactivate/${userId}`;
}

export function getUserDeleteURL(userId) {
    return `${baseUrl}/user/delete/${userId}`;
}

export const getRolesURL = `${baseUrl}/role`;
export const getUserRolesURL = `${baseUrl}/user-role`;
export const getLoggedInUserRoleURL = `${baseUrl}/user-role/user`;
export function getUpdateUserRoleURL(userid, roleid) {
    return `${baseUrl}/user-role/${userid}/${roleid}`;
}

export function setUserAsDefaultAdminURL(userid) {
    return `${baseUrl}/user-action/setdefault/admin/${userid}`;
}

export function setUserAsDefaultAsbuiltVerifierURL(userid) {
    return `${baseUrl}/user-action/setdefault/${userid}`;
}

export const getAllRequestTypesURL = `${baseUrl}/request-type/all`;

export function getActiveRequestTypeURL() {
    return baseUrl + "/request-type/active/having-workflow-contents";
}

export function getAllRequestTypesHavingWorkflowURL() {
    return baseUrl + "/request-type/active/having-workflow-contents";
}

export function getCreateRequestTypeURL() {
    return baseUrl + "/request-type/create";
}

export function activateRequestTypeURL(id) {
    return baseUrl + "/request-type/activate/" + id;
}

export function deactivateRequestTypeURL(id) {
    return baseUrl + "/request-type/deactivate/" + id;
}

export function updateRequestTypeURL(id) {
    return baseUrl + "/request-type/update/" + id;
}

export const getWorkflowUrl = (id) => { return `${baseUrl}/workflow/${id}`; }
export const getWorkflowListUrl = `${baseUrl}/workflow/list/false`;
export const getActiveWorkflowHavingContentsUrl = `${baseUrl}/workflow/list/active/having-contents`;
export const getBasicWorkflowCreateUrl = `${baseUrl}/workflow/create`;
export const updateWorkflowBasicUrl = (id) => { return `${baseUrl}/workflow/${id}`; }
export const activateWorkFlowURL = (id) => { return `${baseUrl}/workflow/${id}`; }
export const deactivateWorkFlowURL = (id) => { return `${baseUrl}/workflow/${id}`; }

export const getWorkflowItemsUrl = baseUrl + "/workflow-detailed/get-contents";
export const getWorkflowAssetTypeUrl = (id) => { return `${baseUrl}/workflow-detailed/get-asset-types/${id}`; }
export const updateWorkflowContentsUrl = (id) => { return `${baseUrl}/workflow-detailed/${id}`; }

export function getRequestStatisticsRecentUrl() {
    return baseUrl + "/request/statistics/recent";
}

export function getRequestStatisticsStatuswiseUrl() {
    return baseUrl + "/request/statistics/statuswise";
}

export function getRequestStatisticsRequestTypewiseUrl() {
    return baseUrl + "/request/statistics/requesttypewise";
}

export function getRequestStatisticsByStatusUrl(status) {
    return baseUrl + "/request/statistics/bystatus" + (status ? "/" + status : "");
}

export const getAllRequestsUrl = `${baseUrl}/request`;

export function getActiveRequests() {
    return baseUrl + "/request/active";
}

export function getFinishedRequests() {
    return baseUrl + "/request/finished";
}

export function getOnholdRequests() {
    return baseUrl + "/request/onhold";
}

export function getRejectedRequests() {
    return baseUrl + "/request/rejected";
}

export function getCreateRequestUrl(lang) {
    return `${baseUrl}/request/create/${lang}`;
}

export function getRequestGetUrl(id) {
    return baseUrl + "/request/" + id;
}

export function getUpdateRequestUrl(id) {
    return baseUrl + "/request-update/update/" + id;
}

export function getDeleteRequestUrl() {
    return baseUrl + "/request/delete";
}

export function changeRequestTypeofExistingRequestUrl(requestId, requestTypeId, lang) {
    return `${baseUrl}/request/change-type/${lang}/${requestId}/${requestTypeId}`;
}

export function convertExistingRequestUrl(requestId, requestTypeId, lang) {
    return `${baseUrl}/request/convert/${lang}/${requestId}/${requestTypeId}`;
}

export function getRequestActionListUrl(requestId) {
    return `${baseUrl}/request-action/${requestId}`;
}

export function requestActionAttachmentListUrl(requestActionId) {
    return `${baseUrl}/request-action/upload/${requestActionId}`;
}

export function requestActionAttachmentUploadUrl(requestActionId) {
    return `${baseUrl}/request-action/upload/${requestActionId}`;
}

export function requestActionAttachmentDownloadUrl(requestActionId) {
    return `${baseUrl}/request-action/download/${requestActionId}`;
}

export function requestActionTransferUrl(lang, requestActionId, userId) {
    return `${baseUrl}/request-action/transfer/${lang}/${requestActionId}/${userId}`;
}

export function requestActionSkipUrl(requestActionId, lang) {
    return `${baseUrl}/request-action/skip/${requestActionId}/${lang}`;
}

export const getNotificationListUrl = `${baseUrl}/notifications`;

export function getSingleNotificationUrl(id) {
    return baseUrl + "/notifications/" + id;
}

export function getUnreadNotificationCountUrl() {
    return `${baseUrl}/notifications/count/unread`;
}

export function getActiveNotificationListUrl() {
    return baseUrl + "/notifications/active";
}

export function getFinishedNotificationListUrl() {
    return baseUrl + "/notifications/finished";
}

export function getNotificationMarkUrl(id, asRead) {
    return `${baseUrl}/notifications/update/${asRead ? "markAsRead" : "markAsUnread"}/${id}`;
}

export function getAllAssetTypeURL() {
    return `${baseUrl}/asset-type`;
}

export function getActiveAssetTypeURL() {
    return `${baseUrl}/asset-type/active`;
}

export function getCreateAssetTypeURL() {
    return `${baseUrl}/asset-type/create`;
}

export function getUpdateAssetTypeURL(id) {
    return `${baseUrl}/asset-type/update/${id}`;
}

export function activateAssetTypeURL(id) {
    return baseUrl + "/asset-type/activate/" + id;
}

export function deactivateAssetTypeURL(id) {
    return baseUrl + "/asset-type/deactivate/" + id;
}

export function linkAssetTypeRequestURL(lang, requestId) {
    return `${baseUrl}/request/asset-type/${lang}/${requestId}`;
}

export function getActionForTaskURL() {
    return `${baseUrl}/action`;
}

export function getActionListForTaskURL(requestTypeId, assetTypeId, actionId) {
    return `${baseUrl}/action-task/${requestTypeId}/${assetTypeId}/${actionId}`;
}

export function createActionTaskItemURL() {
    return `${baseUrl}/action-task/create`;
}

export function editActionTaskItemURL(actionTaskItemId) {
    return `${baseUrl}/action-task/update/${actionTaskItemId}`;
}

export function enableOrDisableActionTaskItemURL(actionTaskItemId, enable) {
    return `${baseUrl}/action-task/${enable ? 'enable' : 'disable'}/${actionTaskItemId}`;
}

export function getRequestActionResponseUrl(requestId, assetTypeId, requestActionId) {
    return `${baseUrl}/request-action-response/${requestId}/${assetTypeId}/${requestActionId}`;
}

export function getAllResponseUrl(requestId) {
    return `${baseUrl}/request-action-response/${requestId}`;
}

export function requestActionResponseSaveUrl(lang, requestId) {
    return `${baseUrl}/request-action-response/save/${lang}/${requestId}`;
}

export function requestActionResponseApproveUrl(lang, requestId) {
    return `${baseUrl}/request-action-response/approve/${lang}/${requestId}`;
}

export function responseAttachementPostUrl(responseId) {
    return `${baseUrl}/request-action-response/${responseId}`;
}

export function requestClosurePostUrl(lang, requestId, requestActionId) {
    return `${baseUrl}/request-closure/${lang}/${requestId}/${requestActionId}`;
}

export function requestClosureGetUrl(requestId) {
    return `${baseUrl}/request-closure/${requestId}`;
}

export function projectScopePostUrl(lang, requestId, requestActionId) {
    return `${baseUrl}/request-closure/project-scope/${lang}/${requestId}/${requestActionId}`;
}

export function requestClosureResponsePostUrl(lang, requestActionId, requestClosureId) {
    return `${baseUrl}/request-closure/response/${lang}/${requestActionId}/${requestClosureId}`;
}

export function requestClosureResponsesGetUrl(requestId) {
    return `${baseUrl}/request-closure/response/${requestId}`;
}

export function responseAttachementDownloadUrl(fileId) {
    return `${baseUrl}/request-action-response/download/${fileId}`;
}

export function requestAttachementGetUrl(requestId, attachmentType) {
    return `${baseUrl}/request-attachment/${requestId}/${attachmentType}`;
}

export function requestAttachementUploadUrl(requestId, type, language) {
    return `${baseUrl}/request-attachment/upload/${requestId}/${type}/${language}`;
}

export function requestAttachementDownloadUrl(fileId) {
    return `${baseUrl}/request-attachment/download/${fileId}`;
}

export function deleteAttachementUrl(attachementId) {
    return `${baseUrl}/request-attachment/${attachementId}`;
}

export function getFormatScopes() {
    return `${baseUrl}/formats/scopes`;
}

export function updateFormatDetail(formatId) {
    return `${baseUrl}/formats/update/${formatId}`;
}

export function putRequestOnHoldUrl(requestActionId, lang, release) {
    return `${baseUrl}/request-hold/${requestActionId}/${lang}/${release}`;
}

export function notifyRequestOnHoldUrl(requestId, lang) {
    return `${baseUrl}/request-hold/notify/${requestId}/${lang}`;
}

export function exportRequestUrl(requestId) {
    return `${baseUrl}/pdf-export/${requestId}`;
}

export function rejectRequestUrl(requestId, lang) {
    return `${baseUrl}/request-reject/${requestId}/${lang}`;
}

export function setRequestAsExpiredUrl(requestId, lang) {
    return `${baseUrl}/request-expire/${requestId}/${lang}`;
}

export function sendActionReminderUrl(requestActionId, lang) {
    return `${baseUrl}/action-reminder/${requestActionId}/${lang}`;
}

export function delegateUserUrl(userId, language) {
    return `${baseUrl}/user-delegation/${language}/${userId}`;
}

export function cancelDelegationUrl(userId, language) {
    return `${baseUrl}/user-delegation/${language}/${userId}`;
}

export const getDelayRequestUrl = (requestId) => {
    return `${baseUrl}/request-delay/${requestId}`;
}

export const newDelayRequestUrl = (language) => {
    return `${baseUrl}/request-delay/${language}`;
}

export const respondToDelayRequestUrl = (requestId, language) => {
    return `${baseUrl}/request-delay/${language}/${requestId}`;
}

export const checkListCategoriesUrl = `${baseUrl}/check-list`;

export const checkListCategoriesForRequestUrl = (requestId) => {
    return `${baseUrl}/check-list/${requestId}`;
}

export const getHoldDetailByRequestActionUrl = (requestActionId) => {
  return `${baseUrl}/request-hold/${requestActionId}`;
}