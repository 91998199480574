import { Box, Button, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, Link, OutlinedInput, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from "react-router-dom";
import { getAppTitle } from '../../../utils/AppConstants';
import { appConfigurations } from '../../../utils/configs';
import { appRoute } from '../../../utils/routeConfigs';
import LanguageSwitch from '../../Common/LanguageSwitch';
import { postData } from './../../../utils/ApiRequest';
import { loginURL, loginUsingVerificationCodeURL } from './../../../utils/ApiUrl';
import { tokens } from './../../../utils/keys';
import { AnonymousAppContext } from './../../AnonymousPage';

const useStyles = makeStyles(theme => ({

  root: {
    marginTop: theme.spacing(2),
  },
  formInput: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  titleGrid: {
    marginBottom: theme.spacing(5)
  },
  title: {
    fontSize: '2.5rem',
  },
  subTitle: {
    fontSize: '2.2rem',
  },
  progress: {
    marginLeft: theme.spacing(1),
  },
}));

export default function Login(props) {

  const [t] = useTranslation();

  const appContext = useContext(AnonymousAppContext);
  const { dispatch, state } = appContext;
  const { selectedLanguage } = state;
  const { setAuthentication } = props;
  const [busy, setBusy] = useState(false);

  let history = useHistory();
  let location = useLocation();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const useMFA = appConfigurations.useMFA;
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  //For redirect for previous URL
  let { from } = location.pathname || { from: { pathname: appRoute.home } }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleCodeChange = (e) => {
    const newValue = e.target.value;
    setVerificationCode(newValue);

    // Parse code
    const parsedCode = parseInt(newValue);
    setVerificationCodeError(isNaN(parsedCode) || newValue <= 4);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const verificationCodeKey = 'acceptatieVerificationCodeTime';
  const checkVerificationCode = () => {
    const savedTime = localStorage.getItem(verificationCodeKey) || null;
    if (!savedTime) {
      return false;
    }
    const difference = new Date().getTime() - new Date(savedTime).getTime();
    return difference / 60000 <= 2;
  }
  const verificationCodeSent = checkVerificationCode();

  async function handleSendVerificationCode(event) {
    event.preventDefault();
    try {
      setBusy(true);
      const data = { "email": email, "password": password };
      const response = await postData(loginURL, data);
      const responseJson = await response.json();
      if (responseJson?.code === 200) {
        localStorage.setItem(verificationCodeKey, new Date().toString());
        setMinutes(9);
        setSeconds(59);
        dispatch({ "type": "setSnackBarInfo", value: { message: "OTP sent successfully", isError: false } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
      else if (responseJson?.code === 406) {
        dispatch({ "type": "setSnackBarInfo", value: { message: "OTP can be generated only after 2 mins from last OTP request", isError: true } })
        dispatch({ "type": "setOpenSnackbar", value: true })
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: "Invalid email address or password. Please try again", isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
    }
    finally {
      setBusy(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      setBusy(true);
      const data = { "email": email, "password": password };
      const loginParameters = {
        code: parseInt(verificationCode),
        language: selectedLanguage
      }
      const url = useMFA && verificationCodeSent ? loginUsingVerificationCodeURL(loginParameters) : loginURL;
      const response = await postData(url, data);
      const status = response.status;
      const response_json = await response.json();
      if (status === 201 || status === 200) {

        // Check if verification failed
        if (useMFA && response_json === false) {
          dispatch({ "type": "setSnackBarInfo", value: { message: "Incorrect verification code", isError: true } });
          setVerificationCode('');
          return dispatch({ "type": "setOpenSnackbar", value: true });
        }

        localStorage.setItem(tokens.keyUserId, response_json.user_id.userid);
        localStorage.setItem(tokens.keyEmail, email);
        localStorage.setItem(tokens.keyToken, response_json.token);
        localStorage.setItem(tokens.keyFirstname, response_json.firstName);
        localStorage.setItem(tokens.keyLastname, response_json.lastName);
        localStorage.setItem(tokens.keyUserroles, JSON.stringify(response_json.roles));
        localStorage.setItem(verificationCodeKey, '');
        setAuthentication(true);
        dispatch({ "type": "setSnackBarInfo", value: { message: "Login is successful", isError: false } })
        dispatch({ "type": "setOpenSnackbar", value: true })
        history.replace(from);
      }
      else if (status === 502) {
        dispatch({ "type": "setSnackBarInfo", value: { message: "Your email hasn't verified yet. Please verify your email", isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else if (status === 405) {
        dispatch({ "type": "setSnackBarInfo", value: { message: "Your account is not active. Please contact application administrator", isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
      else {
        dispatch({ "type": "setSnackBarInfo", value: { message: "Invalid email address or password. Please try again", isError: true } });
        dispatch({ "type": "setOpenSnackbar", value: true });
      }
    }
    catch {
      dispatch({ "type": "setSnackBarInfo", value: { message: t("generalErrorGetData"), isError: true } });
      dispatch({ "type": "setOpenSnackbar", value: true });
    }
    finally {
      setBusy(false);
    }
  }

  function onForgotPasswordClick() {
    history.push(appRoute.forgotPwd);
  }

  function onRegisterClick() {
    history.push(appRoute.register);
  }

  function onVerifyEmailClick() {
    history.push(appRoute.verifyEmail);
  }

  const isOTPDataValid = () => {
    return email.length > 0 && password.length > 0;
  }

  const isOTPVerificationDataValid = () => {
    return isOTPDataValid() && (!useMFA ? true : verificationCode?.length > 0 && !verificationCodeError);
  }

  return (
    <div className={classes.root}>
      <Grid container direction="column" alignItems="center" justify="center" className={classes.titleGrid}>
        <Typography color="primary" gutterBottom align="center" className={classes.title}>
          {getAppTitle(appConfigurations.isAmpApp)}
        </Typography>
        <Typography component="h2" color="primary" className={classes.subTitle}>
          {t('login')}
        </Typography>
      </Grid>
      <form>
        <TextField
          margin="none"
          required
          fullWidth
          variant="outlined"
          id="email"
          label="Email"
          name="email"
          type="email"
          autoComplete="off"
          autoFocus
          value={email}
          disabled={busy}
          onChange={e => setEmail(e.target.value)}
        />
        <FormControl fullWidth className={classes.formInput} variant="outlined">
          <InputLabel htmlFor="adornment-password">{`${t('password')} *`}</InputLabel>
          <OutlinedInput
            id="adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            margin="none"
            required
            fullWidth
            name="wachtwoord"
            value={password}
            onChange={e => setPassword(e.target.value)}
            autoComplete="huidig-wachtwoord"
            disabled={busy}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={60}
          />
        </FormControl>

        {verificationCodeSent &&
          <Fragment>
            <TextField
              required
              error={verificationCodeError}
              helperText={verificationCodeError ? t('pageForgotPwdInvalidCode') : ""}
              margin="none"
              fullWidth
              variant="outlined"
              id="verificationCode"
              label={t('pageForgotPwdVerificationCode')}
              name="verificationCode"
              autoComplete="off"
              value={verificationCode}
              onChange={e => handleCodeChange(e)}
            />
            <Box>
              {seconds > 0 || minutes > 0 ? (
                <Typography variant='body1'>
                  Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </Typography>
              ) : (
                <Typography>Didn't recieve code?</Typography>
              )}
            </Box>
          </Fragment>}

        <Grid container justify="flex-end">
          <LanguageSwitch />
        </Grid>
        
        {useMFA && !verificationCodeSent &&
          <Button
            onSubmit={handleSendVerificationCode}
            onClick={handleSendVerificationCode}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            type="submit"
            disabled={!isOTPDataValid() || busy || verificationCodeError}
          >
            Send-OTP
            {busy && <CircularProgress color="primary" size={30} className={classes.progress} />}
          </Button>}

        {(!useMFA || verificationCodeSent) && 
          <Button
            onSubmit={handleSubmit}
            onClick={handleSubmit}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            type="submit"
            disabled={!isOTPVerificationDataValid() || busy || verificationCodeError}
          >
            Login
            {busy && <CircularProgress color="primary" size={30} className={classes.progress} />}
          </Button>}
        
        <Grid container>
          <Grid item xs>
            <Link onClick={onForgotPasswordClick}>{t('forgotPassword')}?</Link>
          </Grid>
          <Grid item>
            <Link onClick={onRegisterClick}>{t('register')}?</Link>
            <Link onClick={onVerifyEmailClick}>{t('verifyEmail')}?</Link>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}