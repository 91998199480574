export const requestListType = {
    all: "all",
    active: "active",
    hold: "hold",
    complete: "complete",
    approved: "approved",
    expired:"expired",
    active_expired:"active_expired",
    active_expired_AsBuilt:"active_expired_AsBuilt"
}


export const userActionType = {
    assignAssetType: "Assign asset type",
    uploadAsBuilt: "Upload",
    asBuiltDecision: "As-built decision",
    verifyAsBuilt: "Verify as-built",
    projectClosure: "Project closure",
    finalApproval: "Final approval",
    finalControl: "Control final",
    finalFeedback: "Feedback final",
    finalReview: "Review final",
}

const appRoutesWithParameters = {

    root: "/",
    verifyEmail: "/verifyemail",
    forgotPwd: "/forgotpwd",
    register: "/register",
    login: "/login",

    dashboard: "/dashboard",
    home: "/home",
    contactUs: "/contactus",
    about: "/about",
    unauthorized: "/unauthorized",
    actionPerformed: "/action_performed",

    userList: "/users",
    userRoleList: "/userroles",

    actionTasks: "/actiontasks",
    assetTypes: "/assettypes",
    workflows: "/workflowlist",
    workflow: "/workflow",
    requestTypes: "/requesttypes",
    formats: "/formats",

    createRequest: "/request/create",
    editRequest: "/request/edit",
    trackRequest: "/request/track",
    holdRequest:"/requests/hold",
    activeRequest:"/requests/active",

    requestList: "/requests",
    requestDetails: "/request/details",
    requestActions: "/request-actions",
    requestResponse: "/request-response",
    assignAssetType: "/request-action/assign-asset-type",
    uploadAsBuilt: "/request/upload",
    asBuiltDecision: "/request/asbuilt-decision",
    verifyAsBuilt: "/request/closure/verify-asbuilt",
    closeRequest: "/request/close",

    downloadFile: "/download",
}

export const appRoute = {

    root: `${appRoutesWithParameters.root}`,
    verifyEmail: `${appRoutesWithParameters.verifyEmail}`,
    forgotPwd: `${appRoutesWithParameters.forgotPwd}`,
    register: `${appRoutesWithParameters.register}`,
    login: `${appRoutesWithParameters.login}`,

    dashboard: `${appRoutesWithParameters.dashboard}`,
    home: `${appRoutesWithParameters.home}`,
    contactUs: `${appRoutesWithParameters.contactUs}`,
    about: `${appRoutesWithParameters.about}`,
    unauthorized: `${appRoutesWithParameters.unauthorized}`,
    actionPerformed: `${appRoutesWithParameters.actionPerformed}`,

    userList: `${appRoutesWithParameters.userList}`,
    userRoleList: `${appRoutesWithParameters.userRoleList}`,

    actionTasks: `${appRoutesWithParameters.actionTasks}`,
    assetTypes: `${appRoutesWithParameters.assetTypes}`,
    workflows: `${appRoutesWithParameters.workflows}`,
    workflow: `${appRoutesWithParameters.workflow}`,
    requestTypes: `${appRoutesWithParameters.requestTypes}`,
    formats: `${appRoutesWithParameters.formats}`,

    createRequest: `${appRoutesWithParameters.createRequest}`,
    editRequest: `${appRoutesWithParameters.editRequest}`,
    trackRequest: `${appRoutesWithParameters.trackRequest}`,
    holdRequest: `${appRoutesWithParameters.holdRequest}`,
    activeRequest:`${appRoutesWithParameters.activeRequest}`,
    assignAssetType: `${appRoutesWithParameters.assignAssetType}`,
    uploadAsBuilt: `${appRoutesWithParameters.uploadAsBuilt}`,
    
    asBuiltDecision: `${appRoutesWithParameters.asBuiltDecision}`,
    verifyAsBuilt: `${appRoutesWithParameters.verifyAsBuilt}`,
    closeRequest: `${appRoutesWithParameters.closeRequest}`,
    requestAction: `${appRoutesWithParameters.requestActions}`,

    requestList: `${appRoutesWithParameters.requestList}/:viewType`,
    detailsRequest: `${appRoutesWithParameters.requestDetails}/:requestId`,
    requestActionByNotificationId: `${appRoutesWithParameters.requestActions}/:notificationId?`,
    requestResponseByRequestId: `${appRoutesWithParameters.requestResponse}/:requestId?`,
    assignAssetTypeByNotificationId: `${appRoutesWithParameters.assignAssetType}/:notificationId?`,
    uploadAsBuiltByNotificationId: `${appRoutesWithParameters.uploadAsBuilt}/:notificationId?`,
    asBuiltDecisionByNotificationId: `${appRoutesWithParameters.asBuiltDecision}/:notificationId?`,
    verifyAsBuiltByNotificationId: `${appRoutesWithParameters.verifyAsBuilt}/:notificationId?`,
    closeRequestByNotificationId: `${appRoutesWithParameters.closeRequest}/:notificationId?`,

    downloadFile: `${appRoutesWithParameters.downloadFile}/:fileId?`,
}

export const getRequestListRoute = (viewType) => { return `${appRoutesWithParameters.requestList}/${viewType}` }
export const getRequestDetailsRoute = (requestId) => { return `${appRoutesWithParameters.requestDetails}/${requestId}` }
export const getRequestActionsRoute = (notificationId) => { return `${appRoutesWithParameters.requestActions}/${notificationId}` }
export const getRequestResponseRoute = (requestId) => { return `${appRoutesWithParameters.requestResponse}/${requestId}` }
export const getAssignAssetTypeRoute = (notificationId) => { return `${appRoutesWithParameters.assignAssetType}/${notificationId}` }
export const getUploadAsBuiltRoute = (notificationId) => { return `${appRoutesWithParameters.uploadAsBuilt}/${notificationId}` }
export const getAsBuiltDecisionRoute = (notificationId) => { return `${appRoutesWithParameters.asBuiltDecision}/${notificationId}` }
export const getVerifyAsBuiltRoute = (notificationId) => { return `${appRoutesWithParameters.verifyAsBuilt}/${notificationId}` }
export const getCloseRequestRoute = (notificationId) => { return `${appRoutesWithParameters.closeRequest}/${notificationId}` }