import { Button, Card, CardContent, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControlLabel, Grid,  TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RequestClosureIcon from '@material-ui/icons/PlaylistAddCheck';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { dataFetchKeys } from '../../../utils/configs';
import { appRoute } from '../../../utils/routeConfigs';
import BackDrop from '../../Common/BackDrop';
import ActionFinishedPage from '../ActionFinishedPage';
import ComponentAttachments from '../Attachments/ComponentAttachments';
import UnauthorizedPage from '../UnauthorizedPage';
import { getData, postData } from './../../../utils/ApiRequest';
import { getSingleNotificationUrl, requestAttachementGetUrl, requestAttachementUploadUrl, requestClosurePostUrl } from './../../../utils/ApiUrl';
import { appStatuses, attachmentType, downloadType, languages, requestValidation } from './../../../utils/AppConstants';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext, queryClient } from './../../AuthenticatedPage';
import ConfirmationDialog from './../../Common/ConfirmationDialog';
import RequestDetailsDialog from './../../pages/Requests/RequestDetailsDialog';

const useStyles = makeStyles(theme => ({

    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 3),
        marginLeft: theme.spacing(10),
        marginTop: theme.spacing(10),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(10),
    },
    heading: {
        textAlign: 'center',
        marginBottom: theme.spacing(3),
    },
    formControl: {
        fullWidth: true,
        display: 'flex'
    },
    submit: {
        marginLeft: theme.spacing(2),
    },
    filter: {
        maxWidth: '650px'
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        textAlign: 'center',
        width: '100%'
    },
    buttonsDiv: {
        position: 'fixed',
        right: theme.spacing(1),
        bottom: theme.spacing(10),
        left: 'auto',
        top: 'auto'
    },
    button: {
        marginLeft: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    textarea: {
        paddingBottom: theme.spacing(3),
    },
    documentList: {
        width: '100%',
        minWidth: '70ch',
        backgroundColor: theme.palette.background.paper,
    },
    deleteDocument: {
        color: '#e60000'
    },
    attachmentHeader: {
        fontSize: theme.typography.pxToRem(20),
    },
    warningCard: {
        '& .MuiTypography-root': {
            fontSize: 14,
            margin: theme.spacing(1),
        },
        '& .MuiGrid-root, .MuiCardContent-root': {
            padding: 0,
            margin: 0,
        },
        marginBottom: theme.spacing(1),
        textAlign: 'left',
    },
    warningCardLeft: {
        backgroundColor: theme.palette.warning.main,
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function RequestUploadPage() {

    const [t] = useTranslation();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { selectedRequest, selectedAction, selectedRequestActionId, selectedLanguage } = state;
    const { notificationId } = useParams();

    let history = useHistory();

    const [attachments, setAttachments] = React.useState([]);
    const [openNoAttachmentAlert, setOpenNoAttachmentAlert] = React.useState(false);
    const [openClosureForm, setOpenClosureForm] = React.useState(false);
    const [remarks, setRemarks] = React.useState("");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openRequestDetailsDialog, setOpenRequestDetailsDialog] = React.useState(false);
    const [requestActionId, setRequestActionId] = useState("")
    const [request, setRequest] = useState(null);
    const [action, setAction] = useState(null);
    const [actionStatus, setActionStatus] = useState("")
    const [loading, setLoading] = useState(false);
    const [actionAlreadyPerformed, setActionAlreadyPerformed] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [isChangesExpected, setIsChangesExpected] = React.useState(false);


    const handleCloseDialog = () => {
        setOpenDialog(false);
        history.goBack();
    };

    async function getNotificationData() {
        try {
            setLoading(true);
            const response = await getData(
                getSingleNotificationUrl(notificationId),
                GetToken()
            );
            const status = response.status;
            if (status === 200 || status === 201) {
                const response_json = await response.json();
                setAction(response_json.action);
                setRequest(response_json.request);
                setRequestActionId(response_json.requestActionId);
                setActionStatus(response_json.status.description_static)
            } else if (status === 401) {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('User Unauthorized'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
                history.push(appRoute.unauthorized);
            }
        }
        finally {
            setLoading(false);
        }
    }

    async function GetActionTasks() {

        if (!request)
            return;

        try {
            setLoading(true);
            await getData(requestAttachementGetUrl(request.id, attachmentType.asBuilt), GetToken())
                .then(async response => {
                    const status = response.status;
                    if (status === 200 || status === 201) {
                        const responseJson = await response.json();
                        setAttachments(responseJson);
                    }
                    else if (status === 401) {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
                        dispatch({ "type": "setOpenSnackbar", value: true })
                    }
                    else {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } })
                        dispatch({ "type": "setOpenSnackbar", value: true })
                    }
                })
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (notificationId) {
            getNotificationData();
        }
        else if (selectedRequest && selectedRequestActionId) {
            setRequest(selectedRequest);
            setRequestActionId(selectedRequestActionId);
            setAction(selectedAction);
        }
        else if (!request || !action) {
            history.push(appRoute.home);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRequest, notificationId]);

    useEffect(() => {
        GetActionTasks();
        setIsChangesExpected(request && request.isChangesExpected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request]);

    const handleOpenClosureClick = () => {
        if (!attachments || attachments.length <= 0) {
            setOpenNoAttachmentAlert(true);
            return;
        }

        setOpenClosureForm(true);
    }

    const handleClosureCloseClickYes = () => {
        setOpenNoAttachmentAlert(false);
        setOpenClosureForm(true);
    }

    const handleClosureFormSubmit = async () => {
        //Get session token
        try {
            setIsBusy(true);
            const data = { "remarks": remarks };
            const lang = selectedLanguage === languages.nl ? 'nl' : 'en';
            const response = await postData(requestClosurePostUrl(lang, request.id, requestActionId), data, GetToken())
            const status = response.status;
            if (status === 200 || status === 201) {
                //Update notifications
                queryClient.invalidateQueries(dataFetchKeys.notifications);
                dispatch({ "type": "setSnackBarInfo", value: { message: t('pageReqActionUploadSubmitted'), isError: false } })
                dispatch({ "type": "setOpenSnackbar", value: true })
                history.push(appRoute.home);
            }
            else if (status === 405) {
                queryClient.invalidateQueries(dataFetchKeys.notifications);
                setActionAlreadyPerformed(true);
            }
            else if (status === 401) {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
                dispatch({ "type": "setOpenSnackbar", value: true })
            }
            else {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } })
                dispatch({ "type": "setOpenSnackbar", value: true })
            }

        }
        finally {
            setIsBusy(false);
        }

        setOpenClosureForm(false);
    }

    const handleClosureFormClose = () => {
        setOpenClosureForm(false);
    }

    const handleClosureClose = () => {
        setOpenNoAttachmentAlert(false);
    }

    const handleOpenRequestDetailsClick = () => {
        setOpenRequestDetailsDialog(true);
    };

    const closeRequestDetailsDialog = () => {
        setOpenRequestDetailsDialog(false);
    };
    const isAttachmentNotValidated = () => {
        return (isChangesExpected && attachments.length <= 0);
    }
    const isValidated = () => {
        return (remarks !== undefined && remarks.length <= requestValidation.remarksMax);
    }
  
    if (loading)
        return <BackDrop loading={loading} />
    else if (!request)
        return <UnauthorizedPage />
    else if (actionAlreadyPerformed || actionStatus === appStatuses.finished)
        return <ActionFinishedPage />;

    return (
        <Fragment>
            <div className={classes.root}>
                <form className={classes.form} noValidate autoComplete="off">
                    <div>
                        <Typography variant="h5" className={classes.heading}>
                            {`${t('pageResponseActionOnReq')} (${action.description})`}
                        </Typography>

                        <Grid container justify="flex-start">
                            <Grid item xs={3} />
                            <Grid item xs={6}>
                                <Card className={classes.warningCard}>
                                    <CardContent>
                                        <Grid container >
                                            <Grid item xs={1} className={classes.warningCardLeft}>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography>
                                                    {t("dialogUploadPageWarning1")}
                                                </Typography>
                                                <Typography>
                                                    {t("dialogUploadPageWarning2")}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3} />
                        </Grid>

                        <Button variant="outlined" onClick={handleOpenRequestDetailsClick} >
                            {t('generalReqDetails')}
                        </Button>
                        <RequestDetailsDialog request={request} open={openRequestDetailsDialog} handleClose={closeRequestDetailsDialog} />
                        <br />
                        <FormControlLabel
                            value="start"
                            disabled
                            control={<Checkbox color="primary"
                                checked={isChangesExpected} 
                                onChange={(e) => setIsChangesExpected(e.target.checked)}
                                />}
                            label={t('isChangesExpected')}
                        />
                        <ComponentAttachments
                            attachments={attachments} setAttachments={setAttachments}
                            uploadDisabled={false}
                            uploadUrl={requestAttachementUploadUrl(request.id, attachmentType.asBuilt, selectedLanguage)}
                            typeDownload={downloadType.requestActionUpload} />
                    </div>
                </form>
                <div className={classes.buttonsDiv}>
                    <Fab variant="extended" disabled={isAttachmentNotValidated()} color="primary" aria-label="add" className={classes.button} onClick={handleOpenClosureClick}>
                        <RequestClosureIcon className={classes.extendedIcon} />
                        {t('requestForClosure')}
                    </Fab>
                </div>
            </div>

            <ConfirmationDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} />

            <Dialog
                open={openNoAttachmentAlert}
                onClose={handleClosureClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Submit without attachments?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("dialogUploadPageWarning3")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosureClose} color="primary" variant="contained" autoFocus>
                        {t('generalCancel')}
                    </Button>
                    <Button onClick={handleClosureCloseClickYes} color="secondary" variant="outlined">
                        {t('continueAnyway')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openClosureForm} onClose={handleClosureFormClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Project closure request</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('dialogUploadPageWarning4Line1')}
                        <br />
                        {t('dialogUploadPageWarning4Line2')}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        fullWidth
                        multiline
                        margin="dense"
                        id="remarks"
                        label="Remarks"
                        value={remarks}
                        helperText={t("charsRemaining", { num_char: requestValidation.remarksMax - remarks.length })}
                        error={remarks.length < requestValidation.remarksMin || remarks.length > requestValidation.remarksMax}
                        onChange={(e) => setRemarks(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosureFormClose} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={handleClosureFormSubmit} color="primary" variant="contained" autoFocus
                        disabled={isBusy || !isValidated()}>
                        {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />}
                        {t('generalSubmit')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}