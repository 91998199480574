import { BottomNavigation, BottomNavigationAction, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import ImgFooter from './../../../img/footer-rhdhv.jpg';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        position: 'fixed',
        bottom: theme.spacing(0),
        width: '100%',
        overflow: 'hidden',
        '& .MuiBottomNavigation-root': {
            width: '100%',
            backgroundImage: `url(${ImgFooter})`,
            backgroundPosition: 'top right',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            '& .MuiTypography-root': {
                color: '#fff',
                fontSize: '0.9rem',
                fontStyle: 'italic',
            },
            '& .MuiGrid-root': {
                minWidth: '500px'
            }
        },
        '& .MuiBottomNavigationAction-root:hover': {
            cursor: 'default'
        },
    },
}));

export default function Footer() {

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Divider />
            <BottomNavigation>
                <BottomNavigationAction icon={
                    <Grid container justify="center">
                        <Typography>Powered by Royal HaskoningDHV. &#169; All rights reserverd. </Typography>
                    </Grid>
                } />
            </BottomNavigation>
        </div>  
    );
}