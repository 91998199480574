import { Checkbox, Table, TableBody, TableContainer, TablePagination, Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import DefaultAsbuiltVerifierIcon from '@material-ui/icons/AssignmentTurnedIn';
import AsbuiltVerifierIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import ReplyIcon from '@material-ui/icons/Reply';
import DefaultAdminIcon from '@material-ui/icons/VerifiedUser';
import AdminIcon from '@material-ui/icons/VerifiedUserOutlined';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { roleConfigs } from '../../../utils/AppConstants';
import { dataFetchKeys, queryOptions, statusConfigs } from '../../../utils/configs';
import { AppContext } from '../../AuthenticatedPage';
import BackDrop from '../../Common/BackDrop';
import { getData } from './../../../utils/ApiRequest';
import { getUserRolesURL } from './../../../utils/ApiUrl';
import DialogDelegateUser from './DialogDelegateUser';
import UserRoleDialog from './UserRoleDialog';
import { EnhancedTableHead, EnhancedTableToolbar, getComparator, stableSort, StyledTableCell, StyledTableRow } from './UserRoleListHeader';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(10),
        width: '95%',
        marginBottom: theme.spacing(10),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    deleted: {
        '& .MuiTableCell-root': {
            color: theme.palette.error.main,
            fontWeight: "bold",
        },
    },
    inactive: {
        '& .MuiTableCell-root': {
            color: theme.palette.warning.main,
            fontWeight: "bold",
        },
    },
    //Important for not displaying the sort label
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    iconCell: {
        '& svg': {
            marginRight: theme.spacing(1),
            '&:hover': {
                color: theme.palette.warning.main,
                cursor: "pointer"
            }
        }
    }
}));

export default function UserRolePage() {

    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { usersWithRoles, authToken, currentUser } = state;

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selectedUserRole, setSelectedUserRole] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredUserRoles, setFilteredUserRoles] = useState([]);
    const [action, setAction] = useState('roleEdit');
    const [openUserRoleDialog, setOpenUserRoleDialog] = useState(false);
    const [openUserDelegateDialog, setOpenUserDelegateDialog] = useState(false);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUserRoles.length - page * rowsPerPage);
    const userId = currentUser?.id || "";
    const {
        data: userWithRoleList,
        isLoading: userListLoading,
    } = useQuery([`${dataFetchKeys.usersWithRoles}${userId}`],
        async () => {
            const result = await getData(getUserRolesURL, authToken);
            return await result.json()
        }, queryOptions
        );

    useEffect(() => {
        dispatch({ "type": "setUsersWithRoles", value: userWithRoleList || [] });
    }, [userWithRoleList, dispatch])

    useEffect(() => {
        setFilteredUserRoles(usersWithRoles);
    }, [usersWithRoles])

    const handleDefaultAdmin = () => {
        setAction('setDefaultAdmin');
        setOpenUserRoleDialog(true);
    }

    const handleDefaultAsBuilt = () => {
        setAction('setDefaultAsBuilt');
        setOpenUserRoleDialog(true);
    }

    const handleEdit = () => {
        setAction('roleEdit');
        setOpenUserRoleDialog(true);
    }

    const handleDelegateUser = () => {
        setOpenUserDelegateDialog(true);
    }

    const handleSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event, id) => {

        if (!id) { return; }
        if (selectedUserRole && selectedUserRole?.user?.id === id) {
            return setSelectedUserRole(null);
        }

        const userRole = filteredUserRoles.find(r => r?.user?.id === id);
        setSelectedUserRole(userRole || null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (newValue) => {

        const fUserRoles = [...usersWithRoles];
        if (!newValue) {
            return setFilteredUserRoles(fUserRoles);
        }

        const searchedUsers = fUserRoles.filter(r =>
            r?.user?.firstName.toLowerCase().indexOf(newValue) > -1 ||
            r?.user?.lastName.toLowerCase().indexOf(newValue) > -1 ||
            r?.user?.email.toLowerCase().indexOf(newValue) > -1 ||
            r?.user?.phone_number.toLowerCase().indexOf(newValue) > -1 ||
            r?.user?.company_name.toLowerCase().indexOf(newValue) > -1 ||
            r?.user?.status_desc?.toLowerCase().indexOf(newValue) > -1 ||
            r?.role?.name?.toLowerCase().indexOf(newValue) > -1
        );

        setFilteredUserRoles(searchedUsers);
    }

    const loading = userListLoading;
    
    return (
        loading ? <BackDrop loading={loading} /> :
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar
                        userRole={selectedUserRole}
                        handleEdit={handleEdit}
                        handleSearch={handleSearch}
                        handleDefaultAdmin={handleDefaultAdmin}
                        handleDefaultAsBuilt={handleDefaultAsBuilt}
                        handleDelegateUser={handleDelegateUser}
                    />
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                selected={selectedUserRole}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleSort}
                                rowCount={filteredUserRoles.length}
                            />
                            <TableBody>
                                {stableSort(filteredUserRoles, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((userRole, index) => {

                                        const isItemSelected = selectedUserRole && selectedUserRole?.user?.id === userRole?.user?.id;
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        const isAdmin = userRole?.role?.role_static === roleConfigs.admin;
                                        const isDefaultAdmin = isAdmin && userRole?.user?.default_admin;
                                        const isAsbuiltVerifier = userRole?.user?.can_check_asbuilt;
                                        const isDefaultAsbuiltVerifier = isAsbuiltVerifier && userRole?.user?.can_check_asbuilt && userRole?.user?.default_check_asbuilt;

                                        return (
                                            <StyledTableRow
                                                hover
                                                onClick={(event) => handleClick(event, userRole.user.id)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={userRole.user.id}
                                                selected={isItemSelected}
                                                className={clsx(
                                                    { [classes.deleted]: userRole.status_desc === statusConfigs.Deleted },
                                                    { [classes.inactive]: userRole.status_desc === statusConfigs.Inactive }
                                                )}
                                            >
                                                <StyledTableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                                    {userRole.user.firstName}
                                                </StyledTableCell>
                                                <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                                    {userRole.user.lastName}
                                                </StyledTableCell>
                                                <StyledTableCell>{userRole.user.email}</StyledTableCell>
                                                <StyledTableCell>{userRole.role.name}</StyledTableCell>
                                                <StyledTableCell align="center" className={classes.iconCell}>
                                                    {isDefaultAdmin &&
                                                        <Tooltip title="Default administrator">
                                                            <DefaultAdminIcon color="primary" />
                                                        </Tooltip>}
                                                    {!isDefaultAdmin && isAdmin &&
                                                        <Tooltip title="Administrator">
                                                            <AdminIcon color="primary" />
                                                        </Tooltip>}

                                                    {isDefaultAsbuiltVerifier &&
                                                        <Tooltip title="Default as-built verifier">
                                                            <DefaultAsbuiltVerifierIcon color="primary" />
                                                        </Tooltip>}

                                                    {!isDefaultAsbuiltVerifier && isAsbuiltVerifier &&
                                                        <Tooltip title="As-built verifier">
                                                            <AsbuiltVerifierIcon color="primary" />
                                                        </Tooltip>}

                                                    {userRole?.delegated &&
                                                        <Tooltip title="Delegated">
                                                            <ReplyIcon color="primary" style={{ transform: "rotateY(180deg)" }} />
                                                        </Tooltip>}

                                                    {userRole?.hasDelegations &&
                                                        <Tooltip title="Has delegations">
                                                            <ReplyIcon color="primary" style={{ transform: "rotate(180deg)" }} />
                                                        </Tooltip>}
                                                </StyledTableCell>
                                            </StyledTableRow >
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <StyledTableRow style={{ height: 53 * emptyRows }}>
                                        <StyledTableCell colSpan={6} />
                                    </StyledTableRow >
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredUserRoles.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>

                {selectedUserRole && selectedUserRole.user &&
                    <Fragment>
                        <UserRoleDialog
                            open={openUserRoleDialog}
                            setOpen={setOpenUserRoleDialog}
                            action={action}
                            userRole={selectedUserRole} />

                        <DialogDelegateUser
                            open={openUserDelegateDialog}
                            setOpen={setOpenUserDelegateDialog}
                            userRole={selectedUserRole} />
                    </Fragment>}
            </div>
    );
}