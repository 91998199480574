async function getData(url, token) {

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
    });
    return response
}

async function postData(url, data, token) {
    const body = data ? JSON.stringify(data) : null;
    const headerContent = { 'Content-Type': 'application/json' }

    if (token) {
        headerContent.authorization = `Bearer ${token}`
    }

    const response = await fetch(url, {
        method: 'POST',
        headers: headerContent,
        body: body,
    });

    return response;
}

async function postFormData(url, formData, token) {

    const headerContent = {}
    if (token) {
        headerContent.authorization = `Bearer ${token}`
    }

    const response = await fetch(url, {
        method: 'POST',
        headers: headerContent,
        body: formData,
    });

    return response;
}

async function putFormData(url, formData, token) {

    const headerContent = {}
    if (token) {
        headerContent.authorization = `Bearer ${token}`
    }

    const response = await fetch(url, {
        method: 'PUT',
        headers: headerContent,
        body: formData,
    });

    return response;
}

async function deleteData(url, token) {
    const headerContent = { 'Content-Type': 'application/json' }

    if (token) {
        headerContent.authorization = `Bearer ${token}`
    }

    const response = await fetch(url, {
        method: 'DELETE',
        headers: headerContent,
    });

    return response;
}

async function putData(url, data, token) {
    const body = data ? JSON.stringify(data) : null;
    const headerContent = { 'Content-Type': 'application/json' }

    if (token) {
        headerContent.authorization = `Bearer ${token}`
    }

    const response = await fetch(url, {
        method: 'PUT',
        headers: headerContent,
        body: body
    });

    return response;
    
}

async function patchData(url, data, token) {
  const body = data ? JSON.stringify(data) : null;
  const headerContent = { 'Content-Type': 'application/json' }

  if (token) {
    headerContent.authorization = `Bearer ${token}`
  }

  const response = await fetch(url, {
    method: 'PATCH',
    headers: headerContent,
    body: body
  });

  return response;

}

export { postData, postFormData, putFormData, getData, putData, patchData, deleteData }