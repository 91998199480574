import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment } from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        display: 'inline-flex',
        '& MuiTypography-root': {
            fontSize: 12,
        }
    },
    highlight: {
        fontWeight: 'bold',
        fontSize: '1.1rem !important',
        color: theme.palette.warning.main
    }
}));

export default function RequestResponseProperty(props) {

    const classes = useStyles();
    const { heading, description, highlightValue } = props;
    return (
        <Fragment>
            <div className={classes.root}>
                <Typography>
                    {heading}: &nbsp;
                </Typography>
                <Typography color="primary" className={highlightValue ? classes.highlight: ""}>
                    {description}
                </Typography>
            </div>
            <br />
        </Fragment>
    );
}