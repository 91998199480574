import { CircularProgress,AppBar, Checkbox, Dialog, DialogActions, DialogContent, Fab, FormControlLabel, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, Save as SaveIcon } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postData } from './../../../utils/ApiRequest';
import { getUpdateUserRoleURL, setUserAsDefaultAdminURL, setUserAsDefaultAsbuiltVerifierURL } from './../../../utils/ApiUrl';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext } from './../../AuthenticatedPage';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(10),
    },
    container: {
        maxHeight: 440,
    },
    formControl: {
        fullWidth: true,
        display: 'flex'
    },
    dialog: {
        //backgroundColor: '#F5F7FA !important'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        textAlign: 'center',
        width: '100%'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function UserRoleDialog(props) {

    const [t] = useTranslation();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { roles, usersWithRoles } = state;
    const { open, setOpen,  userRole, action } = props;
    const [selectedRole, setSelectedRole] = React.useState([]);
    const [roleId, setRoleId] = useState("");
    const [canVerifyAsbuilt, setCanVerifyAsbuilt] = useState(true);
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        setSelectedRole(userRole && userRole.role ? userRole.role : []);
        setRoleId(userRole && userRole.role ? userRole.role.id : "");
        setCanVerifyAsbuilt(userRole && userRole.user ? userRole.user.can_check_asbuilt : false);
    }, [userRole])

    async function handleEditSubmit(event) {
        event.preventDefault();
        try{   
            setIsBusy(true)
            const data = { "can_check_asbuilt" : canVerifyAsbuilt };
            const  response=  await postData(getUpdateUserRoleURL(userRole.user.id, roleId), data, GetToken());
            const status = response.status;
            if (status === 200 || status === 201) {
                const updatedRole = await response.json();
                const arr = [...usersWithRoles];
                const index = arr.findIndex((userrole) => userrole.user.id === userRole.user.id);
                if (index > -1) {
                    arr.splice(index, 1, updatedRole);
                    dispatch({ "type": "setUsersWithRoles", value: arr });
                }
                
                dispatch({ "type": "setSnackBarInfo", value: { message: `${t('pageUserRolesRole')} '${selectedRole.name}' has been assigned successfully to '${userRole.user.firstName} ${userRole.user.lastName}'`, isError: false } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else if (status === 401) {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
        }
        finally{
            setIsBusy(false)
            setOpen(false);
        }
    };

    async function handleDefaultAdminSubmit(event) {
        event.preventDefault();
        try{
            setIsBusy(true)
            const response = await postData(setUserAsDefaultAdminURL(userRole.user.id), null, GetToken());
            const status = response.status;
            if (status === 200 || status === 201) {
                const arr = [...usersWithRoles];
                arr.forEach(userWithRole => {
                    userWithRole.user.default_admin = userWithRole.user.id === userRole.user.id;
                })

                dispatch({ "type": "setUsersWithRoles", value: arr });
                dispatch({ "type": "setSnackBarInfo", value: { message: `${t('user')} '${userRole.user.firstName} ${userRole.user.lastName}' has been successfully set as default administrator'`, isError: false } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else if (status === 401) {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
        }
        finally{
            setIsBusy(false)
            setOpen(false);
        }
    };

    async function handleDefaultAsbuiltSubmit(event) {
        event.preventDefault();
        try{
            setIsBusy(true)
            const response = await postData(setUserAsDefaultAsbuiltVerifierURL(userRole.user.id), null, GetToken())
            const status = response.status;
            if (status === 200 || status === 201) {
                const arr = [...usersWithRoles];
                arr.forEach(userWithRole => {
                    userWithRole.user.default_check_asbuilt = userWithRole.user.id === userRole.user.id;
                })

                dispatch({ "type": "setUsersWithRoles", value: arr });
                dispatch({ "type": "setSnackBarInfo", value: { message: `${t('user')} '${userRole.user.firstName} ${userRole.user.lastName}' has been successfully set as default as-built verifier'`, isError: false } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else if (status === 401) {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
    
        }
        finally{
            setIsBusy(false)
            setOpen(false)
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    function validateForm() {
        return (roleId && roleId.length > 0);
    }

    const handleSelectRole = (event, value) => {
        if (value) {
            setRoleId(value.id);
            setSelectedRole(value);
        }
        else {
            setSelectedRole([]);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullScreen
            className={classes.dialog}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" className={classes.title}>
                        {action === 'setDefaultAdmin' ? t('pageUserRolesSetAsDefaultAdmin') :
                            action === 'setDefaultAsBuilt' ? t('pageUserRolesSetAsDefaultAsBuilt') :
                            t('dialogUserRolesEditUserRole')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <form className={classes.form} autoComplete="off">
                    <div>
                        <br />
                        <Typography variant="h6" >
                            {action === 'setDefaultAdmin' ? t('dialogUserRolesSetDefaultAdminDetails') :
                                action === 'setDefaultAsBuilt' ? t('dialogUserRolesSetDefaultAsBuiltDetails') :
                                t('dialogUserRolesEditUserRoleDetails')}
                        </Typography>

                        <TextField
                            margin="none"
                            disabled
                            required
                            fullWidth
                            type="email"
                            id="email"
                            label={t('pageUserRolesEmail')}
                            name="email"
                            value={userRole && userRole.user ? userRole.user.email : ""}
                            variant="outlined"
                        /><br />

                        <TextField
                            margin="none"
                            disabled
                            required
                            fullWidth
                            id="firstName"
                            label={t('pageUsersName')}
                            name="firstName"
                            value={`${userRole?.user?.firstName} ${userRole?.user?.lastName}`}
                            variant="outlined"
                        /><br />
                        <TextField
                            margin="none"
                            disabled
                            required
                            fullWidth
                            id="phone_number"
                            label={t('dialogUserRolesPhone')}
                            name="phone_number"
                            value={userRole && userRole.user ? userRole.user.phone_number : ""}
                            variant="outlined"
                        /><br />
                        <TextField
                            disabled
                            margin="none"
                            fullWidth
                            id="company_name"
                            label={t('dialogUserRolesCompany')}
                            name="company_name"
                            value={userRole && userRole.user ? userRole.user.company_name : ""}
                            variant="outlined"
                            multiline
                        /><br />
                        <Autocomplete
                            value={selectedRole ? selectedRole: []}
                            margin="none"
                            fullWidth
                            required
                            id="select-role"
                            options={roles}
                            getOptionLabel={(option) => option.name}
                            disabled={isBusy || action !== 'roleEdit'}
                            onChange={(event, value) => { handleSelectRole(event, value) }}
                            renderInput={(params) => <TextField {...params} placeholder={t('dialogUserRolesSelectRole')} label={t('pageUserRolesRole')} variant="outlined" />}
                        />
                        <FormControlLabel
                            value="start"
                            control={<Checkbox color="primary"
                                checked={canVerifyAsbuilt}
                                onChange={(e) => setCanVerifyAsbuilt(e.target.checked)}
                                disabled={isBusy || action !== 'roleEdit'}/>}
                            label={t('canVerifyAsbuilt')}
                        /><br />
                    </div></form>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#fff' }}>
                <Fab variant="extended" color="primary" aria-label="add"
                    className={classes.scenarioAanmaken}
                    disabled={isBusy || !validateForm()}
                    onClick={action === 'setDefaultAdmin' ? handleDefaultAdminSubmit :
                        action === 'setDefaultAsBuilt' ? handleDefaultAsbuiltSubmit :
                        handleEditSubmit}>
                              {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />  }
                    <SaveIcon className={classes.extendedIcon} />
                    {action === 'setDefaultAdmin' ? t('pageUserRolesSetAsDefaultAdmin') :
                        action === 'setDefaultAsBuilt' ? t('pageUserRolesSetAsDefaultAsbuilt') :
                        t('dialogUserRolesUpdateRole')}
                </Fab>
            </DialogActions>
        </Dialog>
    )
}