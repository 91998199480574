import React, { Fragment } from 'react';
import { appRoute } from '../../utils/routeConfigs';
import AssignAssetTypePage from './../pages/RequestAction/AssignAssetTypePage';
import RequestClosurePage from './../pages/RequestAction/RequestClosurePage';
import RequestInternalOrExternalPage from './../pages/RequestAction/RequestInternalOrExternalPage';
import PrivateRoute from './PrivateRoute';

export default function RouteRequestActionAdmin(props) {

    return (
        <Fragment>
            <PrivateRoute path={appRoute.assignAssetTypeByNotificationId}>
                <AssignAssetTypePage />
            </PrivateRoute>

            <PrivateRoute path={appRoute.asBuiltDecisionByNotificationId}>
                <RequestInternalOrExternalPage />
            </PrivateRoute>

            <PrivateRoute path={appRoute.closeRequestByNotificationId}>
                <RequestClosurePage />
            </PrivateRoute>
        </Fragment>
    )
}