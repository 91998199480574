import DateFnsUtils from '@date-io/date-fns';
import { AppBar, Button, CircularProgress, Dialog, DialogActions, DialogContent, Fab, Grid, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, Save as SaveIcon } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postData } from '../../../utils/ApiRequest';
import { newDelayRequestUrl } from '../../../utils/ApiUrl';
import RequestDetailsDialog from '../Requests/RequestDetailsDialog';
import { languages, requestValidation } from './../../../utils/AppConstants';
import { AppContext } from './../../AuthenticatedPage';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiGrid-container': {
            '& h3': {
                marginBottom: theme.spacing(2),
            },
            '& .MuiGrid-item': {
                width: '40%'
            },
            '& .MuiTextField-root, .MuiAutocomplete-root': {
                marginBottom: theme.spacing(1),
            },
        },
        '& .MuiSwitch-root': {
            marginLeft: theme.spacing(2),
        },
        '& .MuiList-root': {
            paddingBottom: theme.spacing(10),
        },
    },
    appBar: {
        position: 'relative',
        '& .MuiToolbar-root': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function DialogCreateDelayRequest(props) {

    const [t] = useTranslation();
    const classes = useStyles();
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { selectedLanguage, requests, authToken } = state;
    const { open, setOpen, request } = props;
    const [isSaving, setIsSaving] = React.useState(false);
    const [openRequestDetailsDialog, setOpenRequestDetailsDialog] = React.useState(false);
    const [expEndDate, setexpEndDate] = useState(null);
    const [remarks, setRemarks] = useState("");
    const [expEndDateError, setExpEndDateError] = useState(true);
    const [remarksError, setRemarksError] = useState(true);

    async function handleSubmit(event) {

        event.preventDefault();

        try {
            setIsSaving(true);

            const data = {
                "requestId": request.id,
                "delayDate": expEndDate,
                "remarks": remarks
            };

            const lang = selectedLanguage === languages.nl ? 'nl' : 'en';

            const response = await postData(newDelayRequestUrl(lang), data, authToken);
            const status = response.status;
            if (status === 200 || status === 201) {
                const arr = [...requests];
                const index = arr.findIndex(r => r.id === request.id);
                if (index >= 0) {
                    const requestToUpdate = arr[index];
                    requestToUpdate.hasDelayRequest = true;
                    arr.splice(index, 1, requestToUpdate);
                    dispatch({ "type": "setRequests", value: arr });
                }

                dispatch({ "type": "setSnackBarInfo", value: { message: t('delay.request.success'), isError: false } });
                setOpen(false);
            }
            else if (status === 401) {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
            else {
                dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
                dispatch({ "type": "setOpenSnackbar", value: true });
            }
        }
        finally {
            setIsSaving(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const validateForm = () => {
        return !expEndDateError && !remarksError;
    }

    const handleOpenRequestDetailsClick = () => {
        setOpenRequestDetailsDialog(true);
    };

    const closeRequestDetailsDialog = () => {
        setOpenRequestDetailsDialog(false);
    };

    const handleEndDateChange = (date) => {
        setexpEndDate(date);
        setExpEndDateError(date === undefined || date === null);
    };

    const handleRemarksChange = (e) => {
        const newValue = e.target.value;
        setRemarks(newValue);
        setRemarksError(!newValue || newValue.length < requestValidation.remarksMin || newValue.length > requestValidation.remarksMax);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            style={{ backgroundColor: '#fff' }}
            fullScreen>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" className={classes.title}>
                        {t('delay.request.title')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.root} style={{ backgroundColor: '#fff' }}>
                <Grid container justifyContent="center" alignItems="center" direction="column">
                    <ErrorIcon fontSize="large" />
                    <Typography variant="h6" >
                        {t('delay.request.description')}
                    </Typography>

                    <Button variant="outlined" onClick={handleOpenRequestDetailsClick} >
                        {t('generalReqDetails')}
                    </Button>
                    <RequestDetailsDialog request={request} open={openRequestDetailsDialog} handleClose={closeRequestDetailsDialog} />

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                inputVariant="outlined"
                                required
                                fullWidth
                                format="dd-MM-yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label={t('delay.prposed.end.date')}
                                value={expEndDate}
                                disabled={isSaving}
                                onChange={handleEndDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                    <Grid item>
                        <TextField
                            margin="none"
                            variant="outlined"
                            multiline
                            fullWidth
                            rows={5}
                            id="remarks"
                            label={t('generalRemarks')}
                            name="remarks"
                            autoComplete="off"
                            value={remarks}
                            disabled={isSaving}
                            helperText={t("charsRemaining", { num_char: requestValidation.remarksMax - remarks.length })}
                            error={remarks.length > requestValidation.remarksMax}
                            onChange={handleRemarksChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#fff' }}>
                <Fab variant="extended" color="primary" aria-label="add" disabled={!validateForm() || isSaving}
                    onClick={handleSubmit} >
                    <SaveIcon className={classes.extendedIcon} />
                    {isSaving && <CircularProgress color="primary" size={25} className={classes.progress} />}
                    {t('submit')}
                </Fab>
            </DialogActions>
        </Dialog>
    )
}