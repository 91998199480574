import { ListItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomListItem = withStyles(theme => ({
    root: {
        paddingTop: 0,
        paddingBottom: theme.spacing(0.1),
        border: "1px solid rgb(212, 212, 212)",
        marginBottom: theme.spacing(1),
        '& .MuiListItemText-primary': {
            fontSize: 14,
            paddingBottom: theme.spacing(0.5),
        },
        '& .MuiCheckbox-root': {
            marginRight: theme.spacing(2),
        },
    }
}))(ListItem);

export default CustomListItem;