import { DropzoneDialog } from 'material-ui-dropzone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { uploadFileSize, uploadFileTypes, uploadFileExtensions } from '../../utils/AppConstants';

export default function DropzoneAttachmentDialog(props) {

    const [t] = useTranslation();
    const { open, handleSave, handleClose, fileLimit, title, dropzoneTitle } = props;

    return (
        <DropzoneDialog
            dialogTitle={title}
            filesLimit={fileLimit}
            open={open}
            onSave={handleSave}
            acceptedFiles={uploadFileTypes}
            maxFileSize={uploadFileSize}
            onClose={handleClose}
            cancelButtonText={t('generalCancel')}
            submitButtonText={t('generalSubmit')}
            dropzoneText={`${dropzoneTitle}. ${t('fileAllowedText', { suppoered_files: uploadFileExtensions.map(f => f).join(", ") })}`}
            showPreviews={true}
            useChipsForPreview={true}
            showAlerts={['error']}
        />
    );
}