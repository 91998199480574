import { Button, Grid, List, ListItem, ListSubheader, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PostAdd as AddDocumentIcon } from '@material-ui/icons';
import { DropzoneDialog } from 'material-ui-dropzone';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadType } from '../../../utils/AppConstants';
import CardAttachment from '../Attachments/CardAttachment';
import { postFormData } from './../../../utils/ApiRequest';
import { uploadFileSize, uploadFileTypes } from './../../../utils/AppConstants';
import { AppContext } from './../../AuthenticatedPage';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(10),
    '& .MuiList-root': {
      width: "100%"
    },
  },
  heading: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    '& svg': {
      marginTop: theme.spacing(-5),
      marginRight: theme.spacing(1),
    }
  },
  headingFinal: {
    color: theme.palette.primary.main
  },
  formControl: {
    fullWidth: true,
    display: 'flex'
  },
  submit: {
    marginLeft: theme.spacing(2),
  },
  filter: {
    maxWidth: '650px'
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      minWidth: '200px'
    },
    textAlign: 'center',
    width: '100%'
  },
  footerDiv: {
    position: 'fixed',
    bottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    paddingRight: theme.spacing(20),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  buttonHold: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.warning.main,
  },
  buttonRelease: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function ComponentAttachments(props) {

  const [t] = useTranslation();
  const classes = useStyles();

  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const { authToken } = state;
  const { attachments, setAttachments, uploadDisabled, uploadUrl, typeDownload, leftAligned, disabledDownload, allowDelete } = props;

  const [openAttachementDialog, setOpenAttachementDialog] = React.useState(false);

  const handleAttachmentDialogClose = () => {
    setOpenAttachementDialog(false)
  }

  const handleOpenAttachmentDialog = () => {
    setOpenAttachementDialog(true)
  }

  const handleUploadDocument = async (f) => {

    const data = new FormData();
    data.append('files', f[0]);

    //Get session token
    const response = await postFormData(uploadUrl, data, authToken);
    if (response.status === 200 || response.status === 201) {

      const newObjs = await response.json();
      if (newObjs.length > 0) {
        const arr = [...attachments];
        arr.push(newObjs[0]);
        setAttachments(arr);
      }

      dispatch({ "type": "setSnackBarInfo", value: { message: `${t('generalFile')} '${f[0].path}' ${t('generalUploaded')}`, isError: false } })
      dispatch({ "type": "setOpenSnackbar", value: true })
    }
    else if (response.status === 401) {
      dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
      dispatch({ "type": "setOpenSnackbar", value: true })
    }
    else {
      dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } })
      dispatch({ "type": "setOpenSnackbar", value: true })
    }
    setOpenAttachementDialog(false);
  }

  return (
    <Grid container direction="row" className={classes.root} spacing={3} justify={leftAligned ? "flex-start" : "center"} alignItems="center">
      <List dense={false}
        subheader={uploadDisabled ? <span /> :
          <ListSubheader color="primary" className={classes.attachmentHeader} component="div" id="nested-list-subheader">
            <Typography variant="h6" className={classes.headerText}>
              {typeDownload === downloadType.asBuilt || typeDownload === downloadType.requestActionUpload ? 'As-built' : t('generalAttachments')}
            </Typography>
          </ListSubheader>}>

        {uploadDisabled ? <div /> :
          <ListItem style={{ display: 'flex', justify: 'center' }}>
            <Grid container justify="center">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenAttachmentDialog}
                startIcon={<AddDocumentIcon />}
                disabled={uploadDisabled}
              >
                {t('generalAddAttachment')}
              </Button>
            </Grid>


          </ListItem>}

        {attachments.map(attachment =>
          <CardAttachment
            key={attachment.id}
            attachment={attachment}
            typeDownload={typeDownload}
            disabledDownload={disabledDownload}
            allowDelete={allowDelete}
            attachments={attachments}
            setAttachments={setAttachments}
          />
        )}
      </List>

      <DropzoneDialog
        dialogTitle={t('generalUploadDocument')}
        filesLimit={1}
        open={openAttachementDialog}
        onSave={handleUploadDocument}
        acceptedFiles={uploadFileTypes}
        maxFileSize={uploadFileSize}
        onClose={handleAttachmentDialogClose}
        cancelButtonText={t('generalCancel')}
        submitButtonText={t('generalSubmit')}
        dropzoneText={t('pageReqCreateDragDropFile')}
        showPreviews={true}
        useChipsForPreview={true}
        showAlerts={['error']}
      />
    </Grid>
  );
}