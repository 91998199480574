import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { createContext, Fragment, useEffect, useReducer } from 'react';
import { tokens } from '../utils/keys';
import { GetToken } from '../utils/tokenActions';
import { appReducer, initialAppState } from './../state/appState';
import RhdhvFooter from './Common/RhdhvFooter';
import SnackBarComponent from './Common/SnackBar';
import LandingRoutedPage from './LandingRoutedPage';
import Navbar from './Menu/NavBar';
import DrawerNotifications from './pages/Notifications/DrawerNotifications';

export const queryClient = new QueryClient();
export const AppContext = createContext({
    state: initialAppState,
    dispatch: () => { },
});

export default function AuthenticatedPage(props) {

    const [state, dispatch] = useReducer(appReducer, initialAppState);
    const { setAuthentication } = props;
    const { authToken, openSnackbar, snackBarInfo } = state;
    const token = GetToken();

    useEffect(() => {
        dispatch({ "type": "setAuthToken", value: token });
    }, [token])

    useEffect(() => {

        const user = {
            id: localStorage.getItem(tokens.keyUserId) || "",
            email: localStorage.getItem(tokens.keyEmail) || "",
            firstName: localStorage.getItem(tokens.keyFirstname) || "",
            lastName: localStorage.getItem(tokens.keyLastname) || "",
        }

        const userRoles = JSON.parse(localStorage.getItem(tokens.keyUserroles) || {});
        dispatch({ "type": "setCurrentUser", value: user });
        dispatch({ "type": "setUserRoles", value: userRoles });

    }, [token, dispatch])

    return (
        <Fragment>
            <AppContext.Provider value={{ state, dispatch }}>
                <QueryClientProvider client={queryClient}>
                    {!authToken ? <Fragment/> :
                        <Fragment>
                            <Navbar setAuthenticated={setAuthentication} />
                            <DrawerNotifications/>
                            <LandingRoutedPage />
                            <RhdhvFooter/>
                        </Fragment>}
                </QueryClientProvider>
                <SnackBarComponent
                    open={openSnackbar} info={snackBarInfo}
                    dispatch={dispatch} autoHideDuration={3000} />
            </AppContext.Provider>
        </Fragment>
    )
}