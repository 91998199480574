import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import 'chartjs-plugin-datalabels';
import React, { Fragment, useEffect } from 'react';
import { Doughnut, HorizontalBar, Polar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { getData } from './../../utils/ApiRequest';
import { getRequestStatisticsByStatusUrl, getRequestStatisticsRecentUrl, getRequestStatisticsRequestTypewiseUrl, getRequestStatisticsStatuswiseUrl } from './../../utils/ApiUrl';
import { GetToken } from './../../utils/tokenActions';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        alignItems: 'flex-start',
        marginLeft: theme.spacing(10),
        marginTop: theme.spacing(10),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(10),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    chartPaper: {
        marginBottom: theme.spacing(10),
    }
}));

const backgroundColor1 = [
    '#EC87C0',
    '#AC92EC',
    '#5D9CEC',
    '#4FC1E9',
    '#48CFAD',
    '#A0D468',
    '#E8CE4D',
    '#FFCE54',
    '#FC6E51',
    '#ED5565',
    '#8067B7',
];
const hoverBackgroundColor1 = [
    '#D770AD',
    '#967ADC',
    '#4A89DC',
    '#3BAFDA',
    '#37BC9B',
    '#8CC152',
    '#EDBA27',
    '#F6BB42',
    '#E9573F',
    '#DA4453',
    '#937BE1',
];

const legend = {
    display: true,
    position: 'bottom'
};

const fontsize = 20;

export default function DashboardPage() {

    const [t] = useTranslation();
    const classes = useStyles();
    const [allLabelData, setAllLabelData] = React.useState([]);
    const [allData, setAllData] = React.useState([]);

    const tooltip = {
        callbacks: {
            title: function (tooltipItem, data) {
                return data['labels'][tooltipItem[0]['index']];
            },
            label: function (tooltipItem, data) {
                return `${t('pageDashboardNumReq')}: ${data['datasets'][0]['data'][tooltipItem['index']]}`;
            },
        },
        backgroundColor: '#c7c7cc',
        titleFontSize: 16,
        titleFontColor: '#0066ff',
        bodyFontColor: '#000',
        bodyFontSize: 14,
        displayColors: false,
    }

    const dataRequestsPerUser = {
        labels: allLabelData,
        datasets: [
            {
                label: t('pageDashboardRequests'),
                backgroundColor: backgroundColor1,
                hoverBackgroundColor: hoverBackgroundColor1,
                data: allData
            },
        ]
    }

    const [activeLabelData, setActiveLabelData] = React.useState([]);
    const [activeData, setActiveData] = React.useState([]);
    const dataActiveRequestsPerUser = {
        labels: activeLabelData,
        datasets: [
            {
                label: t('pageDashboardRequests'),
                backgroundColor: backgroundColor1,
                hoverBackgroundColor: hoverBackgroundColor1,
                data: activeData
            }
        ]
    }

    const [recentLabelData, setRecentLabelData] = React.useState([]);
    const [recentData, setRecentData] = React.useState([]);
    const dataRequestsLastMonth = {
        labels: recentLabelData,
        datasets: [
            {
                label: t('pageDashboardNumReq'),
                backgroundColor: backgroundColor1,
                hoverBackgroundColor: hoverBackgroundColor1,
                barPercentage: 0.9,
                barThickness: 35,
                maxBarThickness: 50,
                minBarLength: 2,
                data: recentData
            }
        ]
    }

    const [statuswiseLabelData, setStatuswiseLabelData] = React.useState([]);
    const [statuswiseData, setStatuswiseData] = React.useState([]);
    const dataRequestsStatus = {
        labels: statuswiseLabelData,
        datasets: [
            {
                label: t('pageDashboardRequests'),
                backgroundColor: backgroundColor1,
                hoverBackgroundColor: hoverBackgroundColor1,
                data: statuswiseData
            }
        ]
    }

    const [requestTypewiseLabelData, setRequestTypewiseLabelData] = React.useState([]);
    const [requestTypewiseData, setRequestTypewiseData] = React.useState([]);
    const dataRequestTypes = {
        labels: requestTypewiseLabelData,
        datasets: [
            {
                label: t('pageDashboardRequests'),
                backgroundColor: backgroundColor1,
                hoverBackgroundColor: hoverBackgroundColor1,
                data: requestTypewiseData
            }
        ]
    }

    useEffect(() => {

        async function handleStatisticsAll() {

            const apiURL = getRequestStatisticsByStatusUrl();
            const response = await getData(apiURL, GetToken());
            if (response.status === 200 || response.status === 201) {
                const response_json = await response.json();
                setAllLabelData(response_json.labels);
                setAllData(response_json.data);
            }
            else {
                setAllLabelData([]);
                setAllData([]);
            }
        }

        async function handleStatisticsActive() {
            const apiURL = getRequestStatisticsByStatusUrl("active");
            const response = await getData(apiURL, GetToken());
            if (response.status === 200 || response.status === 201) {
                const response_json = await response.json();
                setActiveLabelData(response_json.labels);
                setActiveData(response_json.data);
            }
            else {
                setActiveLabelData([]);
                setActiveData([]);
            }
        }

        async function handleStatisticsRecent() {
            const apiURL = getRequestStatisticsRecentUrl();
            const response = await getData(apiURL, GetToken());
            if (response.status === 200 || response.status === 201) {
                const response_json = await response.json();
                setRecentLabelData(response_json.labels);
                setRecentData(response_json.data);
            }
            else {
                setRecentLabelData([]);
                setRecentData([]);
            }
        }

        async function handleStatisticsStatuswise() {
            const apiURL = getRequestStatisticsStatuswiseUrl();
            const response = await getData(apiURL, GetToken());

            if (response.status === 200 || response.status === 201) {
                const response_json = await response.json();
                setStatuswiseLabelData(response_json.labels);
                setStatuswiseData(response_json.data);
            }
            else {
                setStatuswiseLabelData([]);
                setStatuswiseData([]);
            }
        }

        async function handleStatisticsRequestTypewise() {
            const apiURL = getRequestStatisticsRequestTypewiseUrl();
            const response = await getData(apiURL, GetToken());
            if (response.status === 200 || response.status === 201) {
                const response_json = await response.json();
                setRequestTypewiseLabelData(response_json.labels);
                setRequestTypewiseData(response_json.data);
            }
            else {
                setRequestTypewiseLabelData([]);
                setRequestTypewiseData([]);
            }
        }

        handleStatisticsActive();
        handleStatisticsAll();
        handleStatisticsRecent();
        handleStatisticsStatuswise();
        handleStatisticsRequestTypewise();

    }, []);

    return (
        <Fragment>

            <div className={classes.root}>
                <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                    <Typography variant="h3">
                        {t('dashboard')}
                    </Typography>
                </Grid>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <Paper elevation={0} className={classes.chartPaper}>
                            <Polar
                                data={dataRequestsPerUser}
                                options={{
                                    title: {
                                        display: true,
                                        text: t('pageDashboardReqPerUser'),
                                        fontSize: fontsize
                                    },
                                    legend: legend,
                                    maintainAspectRatio: false,
                                    tooltips: tooltip,
                                    //animation: {
                                    //    duration: 4000,
                                    //    easing: 'easeInBounce'
                                    //},
                                }}
                                height={600}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper elevation={0} className={classes.chartPaper}>
                            <HorizontalBar
                                data={dataRequestsLastMonth}
                                options={{
                                    title: {
                                        display: true,
                                        text: t('pageDashboardRecentReq'),
                                        fontSize: fontsize
                                    },
                                    scales: {
                                        xAxes: [{
                                            stacked: true
                                        }],
                                        yAxes: [{
                                            stacked: true
                                        }]
                                    },
                                    legend: legend,
                                    tooltips: tooltip,
                                }}
                            />
                        </Paper>
                        <Paper elevation={0} className={classes.chartPaper}>
                            <Doughnut
                                data={dataActiveRequestsPerUser}
                                options={{
                                    title: {
                                        display: true,
                                        text: t('pageDashboardActiveReqPerUser'),
                                        fontSize: fontsize
                                    },
                                    legend: legend,
                                    tooltips: tooltip,
                                }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper elevation={0} className={classes.chartPaper}>
                            <Doughnut
                                data={dataRequestsStatus}
                                options={{
                                    title: {
                                        display: true,
                                        text: t('pageDashboardReqByStatus'),
                                        fontSize: fontsize
                                    },
                                    legend: legend,
                                    tooltips: tooltip,
                                }}
                            />
                        </Paper>
                        <Paper elevation={0} className={classes.chartPaper}>
                            <Doughnut
                                data={dataRequestTypes}
                                options={{
                                    title: {
                                        display: true,
                                        text: t('pageDashboardReqByReqType'),
                                        fontSize: fontsize
                                    },
                                    legend: legend,
                                    tooltips: tooltip,
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    );
}