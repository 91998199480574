import { Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({

    expandClass: {
        float: 'right',
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpenClass: {
        transform: 'rotate(180deg)',
    },
    fabButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: "#808080",
        color: "#fff",
    }
}));

export default function CollapseDivButton(props) {
    const { expanded, handleExpandClick, title } = props;
    const classes = useStyles();

    return (
        <Fab variant="extended" className={classes.fabButton} onClick={handleExpandClick}>
            {title}
            <ExpandMoreIcon className={clsx(classes.expandClass, {
                [classes.expandOpenClass]: expanded,
            })} />
        </Fab>
    );
}