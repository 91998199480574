import { AppBar, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, Fab, FormControlLabel, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircle as AddIcon, Close as CloseIcon } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dataFetchKeys } from '../../../utils/configs';
import { postData } from './../../../utils/ApiRequest';
import { getCreateRequestTypeURL } from './../../../utils/ApiUrl';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext, queryClient } from './../../AuthenticatedPage';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(10),
    },
    container: {
        maxHeight: 440,
    },
    formControl: {
        fullWidth: true,
        display: 'flex'
    },
    dialog: {
        //backgroundColor: '#F5F7FA !important'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        textAlign: 'center',
        width: '100%'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function DialogCreateRequestType(props) {

    const [t] = useTranslation();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { dispatch } = appContext;

    const { unusedWorkflows, open, setOpen  } = props;
    const [description, setDescription] = useState("");
    const [remarks, setRemarks] = useState("");
    const [requestNumberPrefix, setRequestNumberPrefix] = useState("");
    const [uploadRequired, setUploadRequired] = useState(true);
    const [isPlanologisch, setIsPlanologisch] = useState(false);
    const [selectedWorkflow, setSelectedWorkflow] = useState("");
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        setSelectedWorkflow(null);
        setRequestNumberPrefix("");
        setUploadRequired("");
        setIsPlanologisch(false);
        setDescription("");
        setRemarks("");
    }, [open])

    async function handleCreate(event) {
        event.preventDefault();
		try{
            setIsBusy(true);
            const data =
            {
                "description": description,
                "workflowId": selectedWorkflow.id,
                "remarks": remarks,
                "upload_required": uploadRequired,
                "is_planologic": isPlanologisch,
                "num_prefix": requestNumberPrefix
            }
    
           const response =   await postData(getCreateRequestTypeURL(), data, GetToken())
                    const status = response.status;
                    if (status === 200 || status === 201) {
                        queryClient.invalidateQueries(dataFetchKeys.requestTypes);
                        dispatch({ "type": "setSnackBarInfo", value: { message: `${t('dialogRequestTypeRequestType')} '${description}' ${t('dialogRequestTypeCreated')}`, isError: false } });
                        dispatch({ "type": "setOpenSnackbar", value: true });
                    }
                    else if (status === 409) {
                        dispatch({ "type": "setSnackBarInfo", value: { message: `${t('dialogRequestTypeErrorDescConflict')} i.e. ${description}`, isError: true } });
                        dispatch({ "type": "setOpenSnackbar", value: true });
                    }
                    else if (status === 401) {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
                        dispatch({ "type": "setOpenSnackbar", value: true });
                    }
                    else {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
                        dispatch({ "type": "setOpenSnackbar", value: true });
                    }
        }
        finally{
        setIsBusy(false);
        dispatch({ "type": "setSelectedRequestType", value: null });
        setOpen(false);
        }
    };

    function validateForm() {
        return description && description.length > 3 && description.length < 100 &&
            requestNumberPrefix && requestNumberPrefix.length > 0 && requestNumberPrefix.length < 5 &&
            selectedWorkflow && remarks.length < 255;
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelectWorkflow = (event, value) => {
        setSelectedWorkflow(value);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            style={{ backgroundColor: '#fff' }}
            fullScreen>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" className={classes.title}>
                        {t('dialogRequestTypeCreateNew')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <form className={classes.form} autoComplete="off" style={{ backgroundColor: '#fff' }}>
                    <div>
                        <br />
                        <Typography variant="h6" >
                            {t('dialogRequestTypeCreateInstruction')}
                        </Typography>
                        <TextField
                            margin="none"
                            required
                            fullWidth
                            disabled={isBusy}
                            variant="outlined"
                            id="description"
                            label={t('generalDescription')}
                            name="description"
                            autoComplete="off"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        /><br />
                        <TextField
                            margin="none"
                            required
                            disabled={isBusy}
                            fullWidth
                            variant="outlined"
                            id="requestNumberPrefix"
                            label={t('dialogRequestTypePrefix')}
                            name="requestNumberPrefix"
                            autoComplete="off"
                            value={requestNumberPrefix}
                            onChange={e => setRequestNumberPrefix(e.target.value)}
                        />
                        <Autocomplete
                            margin="none"
                            fullWidth
                            disabled={isBusy}
                            required
                            value={selectedWorkflow}
                            id="select-request-type"
                            options={unusedWorkflows}
                            getOptionLabel={(option) => option.description}
                            onChange={(event, value) => { handleSelectWorkflow(event, value) }}
                            renderInput={(params) => <TextField {...params} placeholder={t('dialogRequestTypeSelectWorkflow')} label={t('pageWorkflowsWorkflow')} variant="outlined" />}
                        />

                        <TextField
                            margin="none"
                            disabled={isBusy}
                            fullWidth
                            variant="outlined"
                            id="remarks"
                            label={t('generalRemarks')}
                            name="remarks"
                            autoComplete="off"
                            value={remarks}
                            onChange={e => setRemarks(e.target.value)}
                        /><br />
                        <FormControlLabel
                            value="start"
                            disabled={isBusy}
                            control={<Checkbox color="primary"
                                checked={isPlanologisch} onChange={(e) => setIsPlanologisch(e.target.checked)} />}
                            label={t('isPlanologisch')}
                        /><br />
                        <FormControlLabel
                            value="start"
                            disabled={isBusy}
                            control={<Checkbox color="primary"
                                checked={uploadRequired} onChange={(e) => setUploadRequired(e.target.checked)} />}
                            label={t('dialogRequestTypeAllowUpload')}
                        />
                    </div>
                </form>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#fff' }}>
                <Fab variant="extended" color="primary" aria-label="add" disabled={isBusy || !validateForm()}
                    onClick={handleCreate} >
                     {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />  }
                    <AddIcon className={classes.extendedIcon} />
                    {t('dialogRequestTypeAdd')}
                </Fab>
            </DialogActions>
        </Dialog>
        )
}