import { Avatar,  Grid, Link, List, ListItem, ListItemAvatar, ListItemText,Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Language as WebsiteIcon } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ChemelotLogo from './../../img/logo-chemelot.png';
import RhdhvLogo from './../../img/logo-rhdhv.png';
import CircleLogo from './../../img/logo-circle-client.svg';
import HTCLogo from './../../img/logo-hightechcampus.png';
import Footer from './Authorization/footer';
import { appConfigurations } from '../../utils/configs';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  linkText: {
    "&:hover": {
      cursor: 'pointer'
    }
  },
  img: {
    left: theme.spacing(8),
    '&:hover': {
      cursor: 'pointer',
      filter: "contrast(110%) brightness(110%)",
    },
  },
  imgHTC: {
    left: theme.spacing(8),
    paddingLeft:theme.spacing(2.2),
    '&:hover': {
      cursor: 'pointer',
      filter: "contrast(110%) brightness(110%)",
    },
  },
  details: {
    fontSize: 17,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    maxWidth: '60%',
  },
  rootList: {
    color: theme.palette.primary.main,
    '& .MuiListItemText-secondary': {
      color: theme.palette.primary.main
    }
  },
  imgCircle: {
    top: theme.spacing(13),
    right: theme.spacing(5),
    width: "250px",
    height: "100px",
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function ContactUsPage() {

    const [t] = useTranslation();
    const classes = useStyles();
    function handleBottomNavigationChange(val) {
        if (val === 'rhdhv')
            return window.open("https://www.royalhaskoningdhv.com/", "_blank");
        else if (val === 'circle')
            return window.open("https://https://circleinfrapartners.com/", "_blank");
        else if (val === 'chemelot')
            return window.open("https://www.chemelot.nl/", "_blank");
        else if (val === 'hightechcampus')
            return window.open("https://www.hightechcampus.com/", "_blank");
    };
    
    return (
        <div>
            <div className={classes.root}>
                <Grid container spacing={3} direction="column" style={{ width: '100%' }}>
                    <Grid item xs>
                        <Tooltip title="Visit Royal HaskoningDHV official website">
                            <img alt="rhdhv" src={RhdhvLogo} height="110px" className={classes.img} onClick={() => handleBottomNavigationChange('rhdhv')} />
                        </Tooltip>
                        <Typography className={classes.details} align="justify" variant="h6">{t('homeAboutRhdhvDetails1')}</Typography>
                        <Typography className={classes.details} align="justify" variant="h6">{t('homeAboutRhdhvDetails2')}</Typography>
                        <Typography className={classes.details} align="justify" variant="h6">{t('homeAboutRhdhvDetails3')}</Typography>
                        <List className={classes.rootList}>
                            <ListItem>
                                <ListItemAvatar><Avatar><WebsiteIcon /></Avatar></ListItemAvatar>
                                <ListItemText primary={<Link href="https://www.royalhaskoningdhv.nl/" target="_blank">https://www.royalhaskoningdhv.nl</Link>} />
                            </ListItem>
                        </List>

                        <Typography className={classes.details} align="justify" variant="h6">
                            {t('contactForQuestions')} &nbsp;
                            <Link href={`mailto:${appConfigurations.isAmpApp ? "tech.info.beheer@hightechcampus.com" : "acceptatiecommissie@rhdhv.com"}`} target="_top">
                            {`${appConfigurations.isAmpApp ? "tech.info.beheer@hightechcampus.com" : "acceptatiecommissie@rhdhv.com"}`}
                            </Link>
                        </Typography>
                    </Grid>
                    {appConfigurations.isAmpApp ?
                        <Grid item xs>
                            <Tooltip title="Visit High Tech Campus official website">
                                <img alt="hightechcampus" src={HTCLogo} height="60px"  className={classes.imgHTC} onClick={() => handleBottomNavigationChange('hightechcampus')} />
                            </Tooltip>
                            <Typography className={classes.details} align="justify" variant="h6">{t('homeAboutHighTechCampusDetails1')}</Typography>
                            <List className={classes.rootList}>
                                <ListItem>
                                    <ListItemAvatar><Avatar><WebsiteIcon /></Avatar></ListItemAvatar>
                                    <ListItemText primary={<Link href="https://www.hightechcampus.com/" target="_blank">https://www.hightechcampus.com/</Link>} />
                                </ListItem>
                            </List>
                        </Grid>
                        :
                        <Grid item xs>
                        <Tooltip title="Visit Circle official website">
                            <img alt="circle" src={CircleLogo} height="100px" className={classes.img} onClick={() => handleBottomNavigationChange('circle')} />
                        </Tooltip>
                        <Typography className={classes.details} align="justify" variant="h6">{t('homeAboutCircleDetails1')}</Typography>
                        <Typography className={classes.details} align="justify" variant="h6">{t('homeAboutCircleDetails2')}</Typography>
                        <Typography className={classes.details} align="justify" variant="h6">{t('homeAboutCircleDetails3')}</Typography>
                        <List className={classes.rootList}>
                            <ListItem>
                                <ListItemAvatar><Avatar><WebsiteIcon /></Avatar></ListItemAvatar>
                                <ListItemText primary={<Link href="https://circleinfrapartners.com/" target="_blank">https://circleinfrapartners.com</Link>} />
                            </ListItem>
                        </List>
                    </Grid>
                    }

                    {!appConfigurations.isAmpApp &&
                        <Grid item xs>
                            <Tooltip title="Visit Chemelot official website">
                                <img alt="circle" src={ChemelotLogo} height="120px" className={classes.img} onClick={() => handleBottomNavigationChange('chemelot')} />
                            </Tooltip>
                            <Typography className={classes.details} align="justify" variant="h6">{t('homeAboutChemelotDetails1')}</Typography>
                            <Typography className={classes.details} align="justify" variant="h6">{t('homeAboutChemelotDetails2')}</Typography>
                            <Typography className={classes.details} align="justify" variant="h6">{t('homeAboutChemelotDetails3')}</Typography>
                            <List className={classes.rootList}>
                                <ListItem>
                                    <ListItemAvatar><Avatar><WebsiteIcon /></Avatar></ListItemAvatar>
                                    <ListItemText primary={<Link href="https://www.chemelot.nl/" target="_blank">https://www.chemelot.nl/</Link>} />
                                </ListItem>
                            </List>
                        </Grid>}

                </Grid>
            </div>
            <Footer />
        </div>
    );
}