import { INodeDefaultProps } from "@mrblenny/react-flow-chart"
import * as React from 'react'
import styled from 'styled-components'

//const DarkBox = styled.div`
//  position: absolute;
//  padding: 30px;
//  background: #3e3e3e;
//  color: white;
//  border-radius: 10px;
//`
const DefaultShape = styled.div`
  position: absolute;
  width: 65px;
  height: 65px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  border: 1px solid;
  box-shadow: 5px 10px 18px #B2BABB;
  text-shadow: 2px 2px 4px #000000;
  background: rgba(27, 38, 49, 0.5);
`

const UserShape = styled.div`
  position: absolute;
  width: 100px;
  height: 30px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 25px;
  border: 1px solid;
  box-shadow: 5px 10px 18px #B2BABB;
  text-shadow: 2px 2px 4px #000000;
  background: rgba(133, 193, 233, 0.5);
`

const TaskShape = styled.div`
    position: absolute;
  width: 65px;
  height: 65px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 20%;
  border: 1px solid;
  box-shadow: 5px 10px 18px #B2BABB;
  text-shadow: 2px 2px 4px #000000;
background: rgba(243, 156, 18, 0.5);
`
const ActionShape = styled.div`
  position: absolute;
  width: 120px;
  height: 50px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 25px;
  border: 1px solid;
  box-shadow: 5px 10px 18px #B2BABB;
  text-shadow: 2px 2px 4px #000000;
  background: rgba(99, 57, 116, 0.5);
`

const HoldShape = styled.div`
  position: absolute;
  width: 120px;
  height: 50px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 25px;
  border: 1px solid;
  box-shadow: 5px 10px 18px #B2BABB;
  text-shadow: 2px 2px 4px #000000;
  background: rgba(247, 104, 5, 0.4);
`

const NodeCustom = React.forwardRef(({ node, children, ...otherProps }: INodeDefaultProps, ref: React.Ref<HTMLDivElement>) => {

    switch (node.properties.actionType.toLowerCase()) {

        case "task":
            return (
                <TaskShape ref={ref} {...otherProps}>
                    {children}
                </TaskShape>
            );

        case "user":
            return (
                <UserShape ref={ref} {...otherProps}>
                    {children}
                </UserShape>
            );

        case "action":
            return (
                <ActionShape ref={ref} {...otherProps}>
                    {children}
                </ActionShape>
            );

        case "hold":
            return (
                <HoldShape ref={ref} {...otherProps}>
                    {children}
                </HoldShape>
            );

        default:
            return (
                <DefaultShape ref={ref} {...otherProps}>
                    {children}
                </DefaultShape>
            );
    }
})

export default NodeCustom;

