import { Checkbox, Collapse, Grid, IconButton, Link, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@material-ui/icons/Info';
const useStyles = makeStyles(theme => ({
  root: {

  },
  title: {
    '& .MuiIconButton-root': {
      marginTop: theme.spacing(-1),
      marginRight: theme.spacing(1),
    },
    '& .MuiLink-root:hover': {
      cursor: 'pointer',
    }
  }
}));

function RequestCheckListRow(props) {

  const { disabled, checkListItem, checkListCategories, setCheckListCategories } = props;
  const [selectedValue, setSelectedValue] = useState(checkListItem?.itemValue || "");

  const handleChange = (categoryItem, val) => {
    setSelectedValue(val);
    const arr = [...checkListCategories]
    const category = arr.find(c => c.items.some(i => i.id === categoryItem.id));
    if (category) {
      const item = category.items.find(i => i.id === categoryItem.id);
      item.itemValue = val;

      setCheckListCategories(arr);
    }
  }

  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        style={{ color: selectedValue === "" ? "red" : "inherit" }}
      >
        {checkListItem.description}
        {checkListItem.subItems && (
          <Tooltip placement="right" title={checkListItem?.subItems || ""}>
            <IconButton disableRipple>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="center">
        <Checkbox
          disabled={disabled}
          size="small"
          checked={selectedValue === "Yes"}
          onChange={() => handleChange(checkListItem, "Yes")}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          disabled={disabled}
          size="small"
          checked={selectedValue === "No"}
          onChange={() => handleChange(checkListItem, "No")}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          disabled={disabled}
          size="small"
          checked={selectedValue === "Unknown"}
          onChange={() => handleChange(checkListItem, "Unknown")}
        />
      </TableCell>
    </TableRow>
  );
}

export default function RequestCheckList(props) {

  const classes = useStyles();
  const [t] = useTranslation();
  const { disabled, checkListCategories, setCheckListCategories } = props;

  const [checkListOpen, setCheckListOpen] = React.useState(true);
  const handleExpend = () => {
    setCheckListOpen(!checkListOpen);
  }

  return (
    checkListCategories.length === 0 ?
      <Fragment /> :
      <Fragment>
        <div className={classes.title} >
          <IconButton onClick={handleExpend}>
            {checkListOpen ? <ExpandLessIcon /> : <ExpandMoreIcon/>}
          </IconButton>
          <Link variant="h5" onClick={handleExpend}>
            {t("checklist")}
          </Link>
        </div>
        <Grid item className={classes.root}>
          <Collapse in={checkListOpen}>
            <TableContainer component={Paper}>
              <Table size="small" className={classes.table} aria-label="simple table">
                <TableBody>
                  {checkListCategories.map((checkListCategory) =>
                    <Fragment>
                      <TableRow style={{ background: "lightgray" }}>
                        <TableCell>
                          <Typography variant="h6" style={{ fontSize: 17, fontWeight: 'bold' }}>
                            {checkListCategory.description}{checkListCategory.remarks ? ` (${checkListCategory.remarks})` : ""}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h6" style={{ fontSize: 16 }}>
                            {checkListCategory.description === "Project management" ? t("ownManagement") : t("yes")}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h6" style={{ fontSize: 16 }}>
                            {checkListCategory.description === "Project management" ? "CSM" : t("no")}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h6" style={{ fontSize: 16 }}>
                            {t("unknown")}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      {(checkListCategory?.items || []).map(checkListItem =>
                        <Fragment key={checkListItem.id}>
                          <RequestCheckListRow
                            disabled={disabled}
                            checkListCategories={checkListCategories}
                            setCheckListCategories={setCheckListCategories}
                            checkListItem={checkListItem}
                          />
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </Grid>
      </Fragment>
  );
}