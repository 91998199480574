import { CircularProgress, Dialog, DialogActions, DialogContent, Fab, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save as SaveIcon } from '@material-ui/icons';
import React, { useContext ,useState } from 'react';
import { useTranslation } from 'react-i18next';
import { statusConfigs } from '../../../utils/configs';
import DialogAppBar from '../../Common/DialogAppBar';
import { postData } from './../../../utils/ApiRequest';
import { getUserActivateURL, getUserDeactivateURL } from './../../../utils/ApiUrl';
import { appStatuses } from './../../../utils/AppConstants';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext } from './../../AuthenticatedPage';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0, 3),
        marginTop: theme.spacing(3),
        '& .MuiGrid-container': {
            '& h3': {
                marginBottom: theme.spacing(2),
            },
            '& .MuiGrid-item': {
                width: '40%'
            },
            '& .MuiTextField-root, .MuiAutocomplete-root': {
                marginBottom: theme.spacing(2),
            },
        },
        '& .MuiSwitch-root': {
            marginLeft: theme.spacing(2),
        },

        '& .Mui-disabled': {
            color: 'grey'
        },
    },
    submitButton: {
        backgroundColor: theme.palette.warning.main,
    },
    saveIcon:{
        marginRight: theme.spacing(1),
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function DialogActivateUser(props) {

    const [t] = useTranslation();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { users } = state;
    const { open, setOpen, user } = props;
    const deActivate = user.status_desc === appStatuses.active;
    const [isBusy, setIsBusy] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    async function handleSubmit(event) {
        event.preventDefault();
        try{
            setIsBusy(true);
            const url = !deActivate ? getUserActivateURL(user.id) : getUserDeactivateURL(user.id);
            const response =  await postData(url, null, GetToken())
                     const status = response.status;
                     if (status === 201 || status === 200) {
                         let newArray = [...users];
                         const index = newArray.findIndex(e => e.id === user.id);
                         if (index > -1) {
                             const updatedUser = newArray[index];
                             updatedUser.status_desc = !deActivate ? statusConfigs.Active : statusConfigs.Inactive;
                             newArray.splice(index, 1, updatedUser);
     
                             dispatch({ "type": "setUsers", value: newArray });
                             const message = `${t('dialogUsersUser')} '${user.firstName} ${user.lastName}' ${t(deActivate ? "dialogUsersUserDeactivated" : "dialogUsersUserActivated")}`;
                             dispatch({ "type": "setSnackBarInfo", value: { message: message, isError: false } })
                         }
                     }
                     else if (status === 401) {
                         dispatch({ "type": "setSnackBarInfo", value: { message: t('dialogUsersSessionExpired'), isError: true } })
                         dispatch({ "type": "setOpenSnackbar", value: true })
                     }
                     else {
                         dispatch({ "type": "setSnackBarInfo", value: { message: t('dialogUsersSaveError'), isError: true } })
                         dispatch({ "type": "setOpenSnackbar", value: true })
                     }
        }
        finally{    
        setIsBusy(false);
        setOpen(false);   
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullScreen>
            <DialogAppBar warning setOpen={setOpen} title={!deActivate ? t('dialogUsersActivateUser') : t('dialogUsersDeactivateUser')} />
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <div className={classes.root}>
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                        <Grid item>
                            <Typography variant="h5" style={{ fontSize: 18, marginBottom: "20px" }}>
                                {t('userDetailsDescription')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                disabled
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="off"
                                value={user.email}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                disabled
                                fullWidth
                                id="firstName"
                                label={t('dialogUsersFirstName')}
                                name="firstName"
                                autoComplete="off"
                                value={user.firstName}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                disabled
                                fullWidth
                                id="lastName"
                                label={t('dialogUsersLastName')}
                                name="lastName"
                                autoComplete="off"
                                value={user.lastName}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                disabled
                                fullWidth
                                id="phoneNumber"
                                label={t('dialogUsersPhone')}
                                name="phoneNumber"
                                autoComplete="off"
                                value={user.phone_number}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                fullWidth
                                disabled
                                id="company_name"
                                label={t('dialogUsersCompany')}
                                name="company_name"
                                autoComplete="off"
                                value={user.company_name}
                            />
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#fff' }}>
                <Fab variant="extended" disabled={isBusy} color="primary" aria-label="add" className={classes.submitButton}
                    onClick={handleSubmit}>
                    {isBusy && <CircularProgress color="primary" size={25} className={classes.progress} />  }
                    <SaveIcon className={classes.saveIcon} />
                    {!deActivate ? t('dialogUsersActivateUser') : t('dialogUsersDeactivateUser')}
                </Fab>
            </DialogActions>
        </Dialog>
    )
}