import { Button, Collapse, IconButton, ListItemIcon, ListItemText, Table, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from './../../AuthenticatedPage';
import CustomListItem from './CustomListItem';

const useRowStyles = makeStyles(theme => ({
    heading: {
        '& .MuiTypography-root': {
            fontSize: '1.1rem',
        },
        margin: 0
    },
    tableStyle: {
        borderColor: '#cccccc',
        borderStyle: 'solid',
        borderWidth: 1,
    },
    smallButton: {
        //marginRight: theme.spacing(1),
        padding: 0,
        width: '30px',
        height: '30px',
        maxWidth: '30px',
        minWidth: '30px',
        '& .MuiSvgIcon-root': {
            marginLeft: theme.spacing(1.5),
        },
    },
    headerCell: {
        backgroundColor: theme.palette.primary.dark,
        color: '#fff',
        fontWeight: 'bold'
    },
    textDiv: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& .MuiTypography-root': {
            fontSize: '0.9rem',
            width: '95%'
        },
    }
}));

export default function ScopeTableRow(props) {

    const [t] = useTranslation();
    const appContext = useContext(AppContext);
    const { state } = appContext;
    const { selectedLanguage } = state;

    const { row, setCurrentScopeName, setCurrentDetail, setOpenDetailDialog } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    const handleClick = () => {
        setOpen(!open);
    }

    function handleDetailUpdateDialog(detail) {
        setCurrentScopeName(selectedLanguage === 'nl-NL' ? row.description_nl : row.description);
        setCurrentDetail(detail);
        setOpenDetailDialog(true);
    }

    return (
        <div className={classes.fc}>
            <CustomListItem button onClick={handleClick} className={classes.heading}>
                <ListItemIcon>
                    <IconButton edge="start" aria-label="comments">
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </ListItemIcon>
                <ListItemText primary={selectedLanguage === 'nl-NL' ? row.description_nl : row.description} />
            </CustomListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Table size="small" stickyHeader className={classes.tableStyle}>
                    {!row.details || row.details.length <= 0 ? 
                        <TableRow>
                            <TableCell>
                                {t('pageFormatsNoContents')}
                            </TableCell>
                        </TableRow>:
                        <TableHead >
                            <TableRow >
                                <TableCell className={classes.headerCell} style={{ width: '2%' }} />
                                <TableCell className={classes.headerCell} style={{ width: '18%' }}>
                                    {t('pageFormatsEnglishText')}
                                </TableCell>
                                <TableCell className={classes.headerCell} style={{ width: '40%' }}>
                                    {t('pageFormatsEnglishText')}
                                </TableCell>
                                <TableCell className={classes.headerCell} style={{ width: '40%' }}>
                                    {t('pageFormatsDutchText')}
                                </TableCell>
                        </TableRow>
                    </TableHead>}
                    {
                        !row.details || row.details.length <= 0 ? <div /> :
                            row.details.map((detail) => (
                                <TableRow key={detail.id}>
                                    <TableCell component="th" scope="row" >
                                        <Button variant="outlined" color="primary" className={classes.smallButton} onClick={() => handleDetailUpdateDialog(detail)} startIcon={<EditIcon />} />
                                    </TableCell>
                                    <TableCell component="th" scope="row" >
                                        <div className={classes.textDiv}>
                                            <Typography style={{ wordWrap: 'break-word' }} >{detail.description_type}</Typography>
                                        </div>
                                    </TableCell>
                                    <TableCell component="th" scope="row" >
                                        <div className={classes.textDiv}>
                                            <Typography style={{ wordWrap: 'break-word' }} >{detail.description}</Typography>
                                        </div> 
                                    </TableCell>
                                    <TableCell component="th" scope="row" >
                                        <div className={classes.textDiv}>
                                            <Typography style={{ wordWrap: 'break-word' }} >{detail.description_nl}</Typography>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                    }
                </Table>
            </Collapse>
        </div>
    );
}