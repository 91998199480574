import React, { Fragment } from 'react';
import { appRoute } from '../../utils/routeConfigs';
import Aboutpage from './../pages/Aboutpage';
import ContactUsPage from './../pages/ContactUsPage';
import DashboardPage from './../pages/DashboardPage';
import Homepage from './../pages/Homepage';
import PrivateRoute from './PrivateRoute';

export default function RouteInfoHelp() {

    return (
        <Fragment>
            <PrivateRoute path={appRoute.dashboard}>
                <DashboardPage />
            </PrivateRoute>
            <PrivateRoute path={appRoute.home}>
                <Homepage />
            </PrivateRoute>
            <PrivateRoute path={appRoute.contactUs}>
                <ContactUsPage />
            </PrivateRoute>
            <PrivateRoute path={appRoute.about}>
                <Aboutpage />
            </PrivateRoute>
        </Fragment>
    )
}