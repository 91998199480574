import { AppBar, CircularProgress, Dialog, DialogActions, DialogContent, Fab, Grid, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, Save as SaveIcon } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postData } from './../../../utils/ApiRequest';
import { notifyRequestOnHoldUrl } from './../../../utils/ApiUrl';
import { languages } from './../../../utils/AppConstants';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext } from './../../AuthenticatedPage';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(10),
    },
    container: {
        maxHeight: 440,
    },
    formControl: {
        fullWidth: true,
        display: 'flex'
    },
    dialog: {
        //backgroundColor: '#F5F7FA !important'
    },
    appBar: {
        position: 'relative',
        '& .MuiToolbar-root': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '40%',
            minWidth: '200px',
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: theme.spacing(2),
            width: '40%',
            minWidth: '200px'
        },
        '& .MuiTypography-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: '40%',
            minWidth: '200px',
            color: theme.palette.primary.main,
        },
        '& svg': {
            color: theme.palette.primary.main,
        },
        textAlign: 'center',
        width: '100%',
        backgroundColor: '#fff'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    progress: {
        marginRight: theme.spacing(1),
    },
}));

export default function DialogNotifyRequestUpdate(props) {

    const [t] = useTranslation();
    const classes = useStyles();
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { selectedLanguage } = state;

    const { open, setOpen, requestId } = props;
    const [remarks, setRemarks] = useState("");
    const [isSaving, setIsSaving] = React.useState(false);

    async function handleSubmit(event) {
        event.preventDefault();

        try {
            setIsSaving(true);
            const lang = selectedLanguage === languages.nl ? 'nl' : 'en';
            const data = { "remarks": remarks };

            const response = await postData(notifyRequestOnHoldUrl(requestId, lang), data, GetToken())
                    const status = response.status;
                    if (status === 200 || status === 201) {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('requestOnHoldNotificationSuccess'), isError: false } });
                        dispatch({ "type": "setOpenSnackbar", value: true });
                    }
                    else if (status === 401) {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } });
                        dispatch({ "type": "setOpenSnackbar", value: true });
                    }
                    else {
                        dispatch({ "type": "setSnackBarInfo", value: { message: t('saveError'), isError: true } });
                        dispatch({ "type": "setOpenSnackbar", value: true });
                    }
        }
        finally {
            setRemarks('');
            setIsSaving(false);
            setOpen(false);
        }
    };

    const handleClose = () => {
        setRemarks('');
        setOpen(false);
    };

    function validateForm() {
        return (remarks && remarks.length > 10 && remarks.length < 200);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            style={{ backgroundColor: '#fff' }}
            fullScreen>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" className={classes.title}>
                        {t('dialogNotifyForHoldRequest')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ backgroundColor: '#fff' }}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.form}
                >
                    <ErrorIcon fontSize="large" />
                    <Typography variant="h6" >
                        {t('dialogNotifyForHoldRequestMessage1')}
                    </Typography>
                    <Typography variant="h6" >
                        {t('dialogNotifyForHoldRequestMessage2')}
                    </Typography>
                    <TextField
                        margin="none"
                        required
                        fullWidth
                        multiline
                        variant="outlined"
                        id="remarks"
                        label={t('generalRemarks')}
                        name="remarks"
                        autoComplete="off"
                        value={remarks}
                        disabled={isSaving}
                        placeholder={t('dialogNotifyForHoldRemarkPlaceholder')}
                        onChange={e => setRemarks(e.target.value)}
                        inputProps={{ style: { minHeight: '100px' } }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#fff' }}>
                <Fab variant="extended" color="primary" aria-label="add" disabled={!validateForm() || isSaving}
                    onClick={handleSubmit} >
                    <SaveIcon className={classes.extendedIcon} />
                    {isSaving && <CircularProgress color="primary" size={25} className={classes.progress} />}
                    {t('submit')}
                </Fab>
            </DialogActions>
        </Dialog>
        )
}