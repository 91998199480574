import React, { Fragment } from 'react';
import { appRoute } from '../../utils/routeConfigs';
import ActionTaskPage from './../pages/ActionTask/action-task-page';
import AssetTypePage from './../pages/Asset-type/Asset-type-page';
import FormatPage from './../pages/Formats/FormatPage';
import RequestTypesPage from './../pages/RequestType/RequestTypesPage';
import WorkflowListPage from './../pages/Workflow/WorkflowListPage';
import WorkflowPage from './../pages/Workflow/WorkflowPage';
import PrivateRoute from './PrivateRoute';

export default function RouteLookup() {

    return (
        <Fragment>
            <PrivateRoute path={appRoute.actionTasks}>
                <ActionTaskPage />
            </PrivateRoute>
            <PrivateRoute path={appRoute.assetTypes}>
                <AssetTypePage />
            </PrivateRoute>
            <PrivateRoute path={appRoute.workflows}>
                <WorkflowListPage />
            </PrivateRoute>
            <PrivateRoute path={appRoute.workflow}>
                <WorkflowPage />
            </PrivateRoute>
            <PrivateRoute path={appRoute.requestTypes}>
                <RequestTypesPage />
            </PrivateRoute>
            <PrivateRoute path={appRoute.formats}>
                <FormatPage />
            </PrivateRoute>
        </Fragment>
    )
}