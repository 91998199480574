import React, { Fragment } from 'react';
import { appRoute } from '../../utils/routeConfigs';
import DownloadPage from '../pages/Attachments/DownloadPage';
import RequestActionPage from './../pages/RequestAction/RequestActionPage';
import RequestActionUploadPage from './../pages/RequestAction/RequestActionUploadPage';
import RequestClosureAsbuiltPage from './../pages/RequestAction/RequestClosureAsbuiltPage';
import RequestResponsePage from './../pages/RequestResponse/RequestResponsePage';
import PrivateRoute from './PrivateRoute';

export default function RouteRequestAction() {

    return (
        <Fragment>
            <PrivateRoute path={appRoute.downloadFile}>
                <DownloadPage/>
            </PrivateRoute>

            <PrivateRoute path={appRoute.requestActionByNotificationId}>
                <RequestActionPage />
            </PrivateRoute>

            <PrivateRoute path={appRoute.requestResponseByRequestId}>
                <RequestResponsePage />
            </PrivateRoute>

            <PrivateRoute path={appRoute.uploadAsBuiltByNotificationId}>
                <RequestActionUploadPage />
            </PrivateRoute>

            <PrivateRoute path={appRoute.verifyAsBuiltByNotificationId}>
                <RequestClosureAsbuiltPage />
            </PrivateRoute>
        </Fragment>
    )
}