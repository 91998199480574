import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BackDrop from '../../Common/BackDrop';
import { getData } from './../../../utils/ApiRequest';
import { getRequestGetUrl } from './../../../utils/ApiUrl';
import { GetToken } from './../../../utils/tokenActions';
import RequestDetails from './RequestDetails';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(10),
    },
    errorDiv: {
        '& .MuiTypography-root': {
            '& svg': {
                fontSize: 32,
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(-0.75),
            },
        },
    }
}));

export default function RequestDetailsPage() {

    const [t] = useTranslation();
    const classes = useStyles();

    const { requestId } = useParams();
    const [request, setRequest] = useState();
    const [loading, setLoading] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);
    
    useEffect(() => {

        async function getRequest() {

            if (!requestId) {
                setHasError(true);
                return setRequest(null);
            }

            try {
                setLoading(true);
                setHasError(false);
                const response = await getData(getRequestGetUrl(requestId), GetToken());

                if (response.status === 200) {
                    const response_json = await response.json();
                    setRequest(response_json);
                }
                else {
                    setHasError(true);
                }
            }
            finally {
                setLoading(false);
            }
        }

        getRequest();

    }, [requestId])

    return (
        loading ? <BackDrop loading={loading} /> :
            <div className={classes.root}>
                {hasError ?
                    <Grid container direction="column" justify="center" alignItems="center" className={classes.errorDiv}>
                        <Typography variant="h1" color="error" style={{ fontSize: '150px' }}>
                            {"404"}
                        </Typography>
                        <Typography variant="h5" color="error">
                            <WarningIcon />
                            {t('generalItemNotFoundError')}
                        </Typography>
                    </Grid> :
                    !request ? <Fragment /> :
                        <Fragment>
                            <Grid container justify="center">
                                <Typography variant="h3" color="primary">
                                    {t('generalReqDetails')}
                                </Typography>
                            </Grid>
                            <RequestDetails request={request} isDialog={false} />
                        </Fragment>}
            </div>
    );
}