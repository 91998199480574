import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import ImgFooter from './../../img/footer-rhdhv.jpg';
import { appConfigurations } from '../../utils/configs';
import HTCLogo from './../../img/logo-hightechcampus.png';

const useStyles = makeStyles(theme => ({
  footerDiv: {
    position: 'fixed',
    bottom: 0,
    zIndex: 50,
    backgroundImage: `url(${ImgFooter})`,
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: theme.spacing(8),
    border: '1px solid gray'
  },
  copyrightText: {
    position: 'absolute',
    right: 0,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2.5),
    color: theme.palette.common.white,
  },
  HTCLogo: {
    position: 'absolute',
    left: 25,
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(1),
    filter: "contrast(1%) brightness(200%)",
  }
}));

export default function RhdhvFooter() {

  const classes = useStyles();

  return (
    <Grid container justify='space-between'>
      <Grid item>
        <Grid container justify="flex-end" className={classes.footerDiv}>
          {appConfigurations.isAmpApp &&
            <img alt="hightechcampus" src={HTCLogo} height="50px" className={classes.HTCLogo} />
          }
          <Typography className={classes.copyrightText}>Powered by Royal HaskoningDHV. &#169; All rights reserverd. </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}