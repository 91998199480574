import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiCard-root': {
      '& .MuiTypography-root': {
        fontSize: 14,
        margin: theme.spacing(1),
      },
      '& .MuiGrid-root, .MuiCardContent-root': {
        padding: 0,
        margin: 0,
      },
      marginBottom: theme.spacing(1),
      textAlign: 'left',
    },
    '& .warning': {
      backgroundColor: theme.palette.warning.main,
    },
    '& .info': {
      backgroundColor: theme.palette.warning.main,
    },
    '& .success': {
      backgroundColor: "green",
    },
  },
}));

export default function WarningOrErrorGrid(props) {

  const classes = useStyles();
  const { warning, info, success, message, extraMessage } = props;

  const getClassName = () => {
    if (warning) { return "warning"; }
    if (info) { return "info"; }
    if (success) { return "success"; }
    return "success";
  }

  return (
    <Grid container justify="flex-start" className={classes.root}>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Grid container >
              <Grid item xs={1} className={getClassName()}>
              </Grid>
              <Grid item xs={11}>
                <Typography>
                  {message}
                </Typography>
                {extraMessage && 
                  <Typography>
                    {extraMessage}
                  </Typography>}
              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
}