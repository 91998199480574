import { useQuery } from "@tanstack/react-query";
import React, { Fragment, useContext, useEffect } from 'react';
import { Route } from "react-router-dom";
import { appRoute } from "../utils/routeConfigs";
import { getData } from './../utils/ApiRequest';
import { checkListCategoriesUrl, getAllRequestsUrl, getAllRequestTypesURL, getLoggedInUserRoleURL, getNotificationListUrl, getRolesURL, getWorkflowListUrl } from './../utils/ApiUrl';
import { appConfigurations, dataFetchKeys, queryOptions, queryOptionsEnabled } from './../utils/configs';
import { AppContext } from './AuthenticatedPage';
import BackDrop from './Common/BackDrop';
import Homepage from "./pages/Homepage";
import RouteErrors from "./Routes/RouteErrors";
import RouteInfoHelp from "./Routes/RouteInfoHelp";
import RouteLookup from "./Routes/RouteLookup";
import RouteRequest from "./Routes/RouteRequest";
import RouteRequestAction from "./Routes/RouteRequestAction";
import RouteRequestActionAdmin from "./Routes/RouteRequestActionAdmin";
import RouteUserManagement from "./Routes/RouteUserManagement";

export default function LandingRoutedPage() {

  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const { authToken, currentUser } = state;
  const userId = currentUser?.id || "";
  const {
    data: allRequests,
    isLoading: currentUserLoading,
  } = useQuery([`${dataFetchKeys.allRequests}${userId}`],
    async () => {
      const result = await getData(getAllRequestsUrl, authToken);
      return await result.json()
    }, queryOptions
  );

  const {
    data: allNotifications,
    isLoading: notificationLoading,
  } = useQuery([`${dataFetchKeys.notifications}${userId}`],
    async () => {
      const result = await getData(getNotificationListUrl, authToken);
      return await result.json()
    }, queryOptions
  );

  const {
    data: allWorkflows,
    isLoading: workflowsLoading,
  } = useQuery([`${dataFetchKeys.workflows}${userId}`],
    async () => {
      const result = await getData(getWorkflowListUrl, authToken);
      return await result.json()
    }, queryOptions
  );

  const {
    data: allRequestTypes,
    isLoading: requestTypesLoading,
  } = useQuery([`${dataFetchKeys.requestTypes}${userId}`],
    async () => {
      const result = await getData(getAllRequestTypesURL, authToken);
      return await result.json()
    }, queryOptions
  );

  const {
    data: roleList,
    isLoading: roleListLoading,
  } = useQuery([`${dataFetchKeys.roles}${userId}`],
    async () => {
      const result = await getData(getRolesURL, authToken);
      return await result.json()
    }, queryOptions
  );

  const {
    data: userRole,
    isLoading: userRoleLoading,
  } = useQuery([`${dataFetchKeys.userRole}${userId}`],
    async () => {
      const result = await getData(getLoggedInUserRoleURL, authToken);
      return await result.json()
    }, queryOptions
    );

  const {
    data: checklistCategoryList,
    isLoading: checklistCategoryListLoading,
  } = useQuery([dataFetchKeys.checkListCategories],
    async () => {
      const result = await getData(checkListCategoriesUrl, authToken);
      return await result.json()
    }, queryOptionsEnabled(appConfigurations.isAmpApp)
  );

  useEffect(() => {
    dispatch({ "type": "setRequests", value: allRequests || [] });
  }, [allRequests, dispatch])

  useEffect(() => {
    dispatch({ "type": "setNotifications", value: allNotifications || [] });
  }, [allNotifications, dispatch])

  useEffect(() => {
    dispatch({ "type": "setWorkflows", value: allWorkflows || [] });
  }, [allWorkflows, dispatch])

  useEffect(() => {
    dispatch({ "type": "setRequestTypes", value: allRequestTypes || [] });
  }, [allRequestTypes, dispatch])

  useEffect(() => {
    dispatch({ "type": "setRoles", value: roleList || [] });
  }, [roleList, dispatch])

  useEffect(() => {
    dispatch({ "type": "setCurrentUserRole", value: userRole || null });
  }, [userRole, dispatch])

  useEffect(() => {
    dispatch({ "type": "setCheckListCategories", value: checklistCategoryList || [] });
  }, [checklistCategoryList, dispatch])

  const loading = currentUserLoading ||
    notificationLoading ||
    workflowsLoading ||
    requestTypesLoading ||
    roleListLoading ||
    userRoleLoading ||
    (appConfigurations.isAmpApp? checklistCategoryListLoading: false);

  return (
    loading ? <BackDrop loading={loading} /> :
      <Fragment>
        <Route exact path={[appRoute.root, appRoute.login]}>
          <Homepage />
        </Route>
        <RouteLookup />
        <RouteInfoHelp />
        <RouteRequest />
        <RouteUserManagement />
        <RouteErrors />
        <RouteRequestAction />
        <RouteRequestActionAdmin />
      </Fragment>
  )
}